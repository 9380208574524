// Components
import { Spinner } from "components/core/spinner";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "components/ui/tooltip";

// Utils
import { format } from "utils/format"
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

// Icons
import { 
    GoCheckCircleFill as CheckIcon, 
    GoCircle as CircleIcon,
    GoHash as NameRankIcon,
    GoSkipFill as ShutdownIcon,
    GoXCircleFill as XIcon 
} from "react-icons/go";

// Constants
import { to } from "constants/routes";
import classNames from "classnames";

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg";

export function JobStatus({job, size="md"}: {job:Job, size?: string}) {
    return <p className="flex items-center justify-between text-container-foreground group relative">

                {job.status==="running"?<Spinner className={classNames("text-yellow-500", size==="md"? "h-5 w-5": size==="lg"? "h-7 w-7": "h-4 w-4", "mr-2")} />:
                job.status==="success"?<CheckIcon className={classNames(size==="md"? "h-5 w-5": size==="lg"? "h-7 w-7": "h-4 w-4", "mr-2 text-emerald-500 ")} />:
                job.status==="failed"?<XIcon className={classNames(size==="md"? "h-5 w-5": size==="lg"? "h-7 w-7": "h-4 w-4", "mr-2 text-red-500 ")} />:
                job.status==="manual-reset"?<ShutdownIcon className={classNames(size==="md"? "h-5 w-5": size==="lg"? "h-7 w-7": "h-4 w-4", "mr-2 text-pink-500 ")} />:
                <CircleIcon className={classNames(size==="md"? "h-5 w-5": size==="lg"? "h-7 w-7": "h-4 w-4", "mr-2 text-cyan-300 ")} />
                }
                <span className='hidden font-mono text-sm group-hover:block absolute top-1 -translate-y-1/3 left-full bg-background rounded-md text-cyan-500 px-2 py-1'>{job.status|| '-'}</span>
                
            </p>
}

export function JobRow(job: Job) {
    const { activeOrg } = useActiveOrg()
    const {ressource: ressourceParams} = useParams();
    const {t, i18n} = useTranslation();
    const timeLapsed = job.startedAt && job.endedAt && (new Date(job.endedAt).getTime() - new Date(job.startedAt).getTime())
    return <Link to={job.id ? to.job(activeOrg.slug, ressourceParams || "", job.id) : ""}>
        <div className="block p-3 hover:bg-background/30">
                <div className="flex flex-col md:flex-row md:items-center gap-2 justify-between p-1 text-container-foreground">
                    <div className="flex items-center w-full md:truncate">
                        <JobStatus job={job} />
                        <p className="font-medium ml-1 mr-3">{job.rank}.</p>
                        <p className="text-white font-medium whitespace-pre-wrap leading-5">{job.name}</p>
                    </div>
                    <div className="flex items-center justify-between md:w-1/2 space-x-4">
                        <div className="md:w-1/2">
                            <p className="text-container-foreground">{job.startedAt && format.datetime(new Date(job.startedAt), i18n.language)}</p>
                        </div>
                        <div className="flex items-center justify-start text-left group">
                           {job.nameRank&&<>
                           <TooltipProvider>
                                <Tooltip delayDuration={200}>
                                    <TooltipTrigger asChild>
                                        <p className="whitespace-nowrap"><NameRankIcon className="inline mb-1 mr-1 "/>{job.nameRank}</p>
                                    </TooltipTrigger>
                                    <TooltipContent side="left" className="bg-background text-container-foreground text-sm">
                                        {t("job-name-rank-explain", {name: job.name, rank: job.nameRank})}
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            </>}
                        </div>
                        <div className="w-1/4 text-right">
                            <p className={classNames("text-sm font-mono", timeLapsed? "text-white": "text-container-foreground")}> { 
                            timeLapsed?
                            format.timeLapsed(timeLapsed/1000): 
                            job.status==="running"?
                            t("job-not-finished"):
                            t("job-cancelled")
                        }</p>
                        </div>
                    </div>
                </div>
        </div></Link>
}
