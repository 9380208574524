import { Skeleton } from "components/core/skeletons";
import { PageSubtitle } from "components/core/typo";
import { Link } from "react-router-dom";
import { AddModelFilesModal } from "components/models/upload";
import { FilePreview } from "components/ressources/files";

// Icons
import { BiSolidChevronDown as ChevronDownIcon } from "react-icons/bi"
import { VscNewFile as NewFileIcon } from "react-icons/vsc";

// Utils
import { format } from "utils/format";
import classNames from "classnames";
import { formatBytesToKBOrMB } from "utils/sizes";

// API
import { deleteModelFile } from "api/models";

// Hooks
import { useModel } from "hooks/useModel"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "hooks/useAPI";

function FileRow({ id, name, numBytes, downloadUrl, createdAt, extension }: { id:string, name: string, numBytes: number, downloadUrl:string, createdAt:string, extension:string}) {
    const { i18n } = useTranslation()
    const [preview, setPreview] = useState<boolean>(false)
    const {t} = useTranslation("common");
    const handleResult = () => {
        setModel((prev:any) => {
            if (!prev) return prev;
            return {
                ...prev,
                files: prev.files?.filter((file:any) => file.id !== id)
            }
        })
    }
    const [, { execute:deleteFile }] = useAPI(deleteModelFile, { fileId: id }, { immediate: false, onResult: handleResult, successToastMessage: t("delete-file.success"), errorToastMessage: t("delete-file.error")})
    const { setModel } = useModel();
    const handleDelete = () => {
        deleteFile();
    }
    return <>
        <div onClick={()=> setPreview(!preview)} className="block p-3.5 px-4 cursor-pointer hover:bg-background/30 group">
            <div className="flex items-center">
                <div className="flex items-center justify-between w-full p-1">
                    <div className="flex flex-col items-start justify-start sm:flex-row gap-x-2 sm:items-center">
                        <Link onClick={(e)=> e.stopPropagation()} target="_blank" to={downloadUrl}><p className="pr-4 text-sm break-all hover:cursor-pointer hover:underline">{name}</p></Link>
                        <p className="text-sm text-container-foreground">{formatBytesToKBOrMB(numBytes)}</p>
                    </div>
                    <div onClick={()=> setPreview(!preview)} className="flex items-center text-sm transition-colors shrink-0 hover:cursor-pointer text-container-foreground group-hover:text-white">
                        <p className="text-container-foreground">{format.datetime(new Date(createdAt || ""), i18n.language)}</p>
                        <ChevronDownIcon className={classNames("ml-1 text-lg mt-0.5 transition-transform", preview && "rotate-180")}/>
                    </div> 
                    
                </div>
                
            </div>
            <div className={classNames("transition-all overflow-hidden", preview? "":"h-0")}>
                {
                    preview && <FilePreview extension={extension} url={downloadUrl} deleteFile={handleDelete} />
                }
            </div>
        </div>
    </>
}

function NoFiles(){
    const {t} = useTranslation("common");

    return <div className="p-3">
        <h3 className="text-slate-400">{t("no-model-files.title")}</h3>
        </div>
}

export function NewFileButton(){
    const [open, setOpen] = useState<boolean>(false)
    const {t} = useTranslation("common");
    const {model, reload} = useModel();
    return <>
        <p className="flex items-center text-sm transition-colors disabled:hover:cursor-default hover:cursor-pointer text-container-foreground hover:text-gray-300" onClick={()=>setOpen(true)}>
            <NewFileIcon className="mr-1.5 text-base shrink-0"/>{t("upload-file.new-file")}
        </p> 
        <AddModelFilesModal open={open} setOpen={setOpen} modelId={model?.id || ""} reloadList={()=>reload()} />
    </>
}

export function FilesList(){
    const {t} = useTranslation("common");
    const {model} = useModel();
    if (!model){
        return <div className="gap-3 flex flex-col">
            <Skeleton className="h-20"/>
            <Skeleton className="h-20"/>
            <Skeleton className="h-20"/>
        </div>
    }

    return <div className="mt-8">
        <div className="flex flex-wrap items-end justify-between gap-y-2">
            <div className="flex flex-col items-start gap-x-3 gap-y-2">
                <PageSubtitle>{t("model-files")}</PageSubtitle>
            </div>
            <NewFileButton/>
        </div>
        <div className="mt-3 rounded-md bg-container shadow border-container-light border">
            <div className="block hover:bg-background/30">
            {(!model.files || model.files?.length===0) && <NoFiles/>}
            {(model.files||[]).map((file, i) => <FileRow key={i} {...file} />)}
            </div>
        </div>
    </div>
}