export function omitKey (obj: any, omitKey: any) {
    if (!obj || typeof obj !== 'object') return {}
    if (typeof omitKey === 'string') {
        const {[omitKey]: _, ...rest} = obj
        return rest
    }
    if (Array.isArray(omitKey)) {
        const rest = {...obj}
        omitKey.forEach(key => delete rest[key])
        return rest
    }
}

export function compareObjects (obj1: any, obj2: any) {
    const obj1Keys = Object.keys(obj1)
    const obj2Keys = Object.keys(obj2)
    if (obj1Keys.length !== obj2Keys.length) return false
    return obj1Keys.every(key => obj1[key] === obj2[key])
}

export function deepCompareWithJSON(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const trimAllDictValues = (obj:any) => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "string") {
            obj[key] = obj[key].trim()
        } else if (typeof obj[key] === "object") {
            trimAllDictValues(obj[key])
        }
    })
    return obj
}

/**
    * @param {Object} data
    * @param {String} metricsType
    * @return {Object}
    * @description Filter data by metricsType
    * @returns array of objects that have the same metricsType
*/
export function filterByMetricsType(data: any, metricsType: string): any {
    const filteredData: any = [];
    
    for (const key in data) {
        if (data[key].meta.metricsType === metricsType) {
            filteredData.push(data[key]);
        }
    }
    
    return filteredData;
}

export const utilsDict = {
    omitKey,
    compareObjects,
    deepCompareWithJSON,
    trimAllDictValues,
    filterByMetricsType
}