
// Components
import { Header } from "components/models/header";
import { Summary } from "components/models/summary";
import { TwoColumnLayout } from "layouts/two-columns";
import { FilesList } from "components/models/files";
import { ModelReadmeCard } from "components/models/readme";


export function ModelDetails() {
    return <>
        <Header/>
        <TwoColumnLayout
            left={<Content/>}
            right={<Summary/>}
        />
        
    </>
}

function Content() {
    return <>
        <ModelReadmeCard/>
        <FilesList/>
    </>
}