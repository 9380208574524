// Components
import { CreateIncidentsModal } from "components/incidents/create"
import { IncidentsList } from "components/incidents/list"
import { Button } from "components/core/buttons"

// API
import { TextInput } from "components/core/inputs"

// Icons
import { FiSearch as SearchIcon } from "react-icons/fi"

// Filters
import { useFiltersFromURL } from "hooks/useTableFilters"

// Hooks
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useIncidents } from "hooks/useIncidents"


export function IncidentsCreateAndList(){
    const { t } = useTranslation("common")

    const [open, setOpen] = useState<boolean>(false)

    const tableId = 'incidents';
    const filters = useFiltersFromURL(tableId);

    const { setQuery, setIncidents, execute:refreshIncidents, setPage } = useIncidents()
    
 
    return <>
        <CreateIncidentsModal open={open} setOpen={setOpen} onCreate={refreshIncidents} setIncidents={setIncidents} />
        <div className="flex flex-col flex-wrap w-full gap-4 my-4 sm:flex-row">
            <div className="flex-1">
                <TextInput Icon={SearchIcon} defaultValue={filters.filterParams.query?.toString() || ""} placeholder={t("search-incidents")} theme="outlined" onChange={(v:any)=> {setQuery(v); setPage(1) }} />
            </div>
            <Button onClick={()=>setOpen(true)}>{t("new-incident")}</Button> 
        </div>
        <IncidentsList />
    </>
}