import React from 'react';

// Components
import { Navigate } from 'react-router-dom';
import { Layout } from 'layouts';
import { Spinner } from 'components/core/spinner';

// Hooks
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function Logout(){
    /*
        Check if logged in.
    */
    const {logout, isConnected, loading, loadingLogout} = useAuth();
    const [searchParams] = useSearchParams();
  
    useEffect(()=>{
        logout && logout();
        localStorage.removeItem("activeOrg")
    }, [logout])

    if ((!isConnected) && (!loadingLogout) && (!loading)){
        if (searchParams.get("error")){
            return <Navigate to={`/login?error=${searchParams.get("error")}`}/>
        }
        return <Navigate to={"/login"}/>
    }
    return <Layout.Full center>
            <Spinner size='md'/>
    </Layout.Full>
}