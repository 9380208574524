import { Dispatch, Fragment, SetStateAction } from 'react'
import { Dialog, Transition } from '@headlessui/react'


import { IoClose as XMarkIcon } from 'react-icons/io5'


interface SideBarProps {
    children: React.ReactNode;
    sidebarOpen: boolean;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}
export function SideBar({ children, sidebarOpen, setSidebarOpen }: SideBarProps) {

    return (
        <>
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                        </button>
                        </div>
                    </Transition.Child>

                    <div className="flex flex-col px-6 pb-2 overflow-y-auto border-r grow gap-y-5 bg-container-light border-slate-500/30">
                        <div className="flex flex-col items-start flex-1 shrink-0">
                            
                            <div className='w-full h-full'>
                                {children}
                            </div>
                        </div>

                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition.Root>







        </div>
        </>
    )

}
