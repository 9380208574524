// Components
import { Button } from "components/core/buttons"
import { Link } from "react-router-dom"
import { RoleGuard } from "components/auth/roleguard"

// Constants
import { to } from "constants/routes"

// Icons
import { AiOutlineAppstoreAdd as AddRessourceIcon } from "react-icons/ai"

// Hooks
import { useTranslation } from "react-i18next"
import { useActiveOrg } from "hooks/useActiveOrg"

export function RessourcesEmpty(){
    const { t } = useTranslation()
    const { activeOrg } = useActiveOrg()

    return <>
        <div className="flex flex-col items-center justify-center mb-5">
            <h2 className="text-2xl font-bold">{t("no-ressources")}</h2>
            <p className="text-container-foreground">{t("no-ressources-message")}</p>
        </div>
        <RoleGuard requiredRole="admin" redirect={false}>
            <div className="flex justify-center">
                <Link to={to.addResource(activeOrg?.slug)}>
                    <Button className="flex items-center">
                        <AddRessourceIcon className="mr-1 text-xl shrink-0"/> {t("add-ressource.title")}
                    </Button>
                </Link>
            </div>
        </RoleGuard>
        
    </>
}