import { Changelog } from "components/core/changelog";
import { useTranslation } from "react-i18next";


export function ChangelogPage(){
    const {i18n} = useTranslation();
    return <>
        <Changelog content={i18n.language.includes("en")? contentEN: contentFR}/>
    </>
}


const contentFR = 
`
# Journal des modifications

## 17 avril 2024

- **Ajout de la page des modèles.** Vous pouvez dorénavant télécharger et entreposer vos modèles en production sur la plateforme. Pour le téléversement, vous pouvez procéder via l'interface de l'application. Pour le téléchargement, vous pouvez utiliser la fonction \`hc.download_model(name, version)\` de l'API python dont la version est >=1.2.1. Nous ajouterons bientôt la possibilité de téléverser des modèles via l'API.

    Plusieurs fonctionnalités sont disponibles pour les modèles:

    1. **Champs de description**: Vous pouvez ajouter une description complète à vos modèles pour les identifier plus facilement.
    2. **Versions**: Vous pouvez ajouter plusieurs versions d'un même modèle. Les noms des modèles sont uniques par organisation.
    3. **Fichiers**: Vous pouvez téléverser plusieurs fichiers par modèles.
    4. **Attributs**: Vous pouvez ajouter des attributs comme des valeurs de validation pour les modèles.
    5. **Téléchargements**: Le nombre de téléchargements de vos modèles est enregistré.
    6. **Activité**: Vous pouvez indiquer si un modèle est actif ou non en production.


## 16 avril 2024

- **Création de la page de journal des modifications.** Les nouvelles fonctionnalités seront ajoutées sur cette page.

`

const contentEN =
`
# Changelog

## April 17, 2024

- **Addition of the models page.** You can now upload and store your production models on the platform. For uploading, you can proceed via the application interface. For downloading, you can use the \`hc.download_model(name, version)\` function from the python API whose version is >=1.2.1. We will soon add the possibility to upload models via the API.

    Several features are available for models:

    1. **Description text**: You can add a full description to your models to identify them more easily.
    2. **Versions**: You can add multiple versions of the same model. Model names are unique per organization.
    3. **Files**: You can upload multiple files per model.
    4. **Attributes**: You can add attributes such as validation values for models.
    5. **Downloads**: The number of downloads of your models is recorded.
    6. **Activity**: You can indicate whether a model is active or not in production.

## April 16, 2024

- Creation of the changelog page. New features will be added here.

`;
