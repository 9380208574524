// Components
import { ControlledRessourceEditor } from "components/ressources/builder"
import { Disable } from "components/ressources/delete"

// API
import { editRessource } from "api/ressources"

// Hooks
import { useAPI } from "hooks/useAPI"
import { useRouteOrg } from "hooks/useRouteOrg"
import { useCallback} from "react"
import { useRessource } from "hooks/useRessource"
import { Spinner } from "components/core/spinner"
import { useTranslation } from "react-i18next"

export function Edit(){
    
    const { t } = useTranslation("common")
    
    const { routeOrg } = useRouteOrg()
    const { ressource, setRessource } = useRessource()
  
    const [, {execute, loading}] = useAPI(editRessource, ressource, {immediate: false, successToastMessage: t("edit-ressource.success"), errorToastMessage: t("edit-ressource.error")})
    
    const onSubmit = useCallback((e:Event)=>{
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    if (!ressource || !routeOrg || !setRessource) return <><div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div></>

    return <>
        <ControlledRessourceEditor onSubmit={onSubmit} ressource={ressource} setRessource={setRessource} loading={loading} />
        <Disable />
    </>
}
