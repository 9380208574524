// Components
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs";

import { Skeleton } from "components/core/skeletons";
import { Text } from "components/core/typo";
import {
    DropDownDotsButton,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "components/ui/dropdown-menu"
import { DeleteModalDialog } from "components/models/delete";
import { ToggleModelStatus } from "components/models/status";
import { StatusIndicator } from "components/models/status";

// Constants
import { to } from "constants/routes";

// Icons
import { RiDeleteBin5Fill as DeleteIcon } from "react-icons/ri"
import { MdRadioButtonChecked as RadioOnIcon, MdRadioButtonUnchecked as RadioOffIcon } from "react-icons/md"

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg";
import { useModel } from "hooks/useModel";
import { useTranslation } from "react-i18next";

import { useState } from "react";

export function Header(){
    const {model, loading} = useModel();
    const {t} = useTranslation("common");
    const {orgParams} = useActiveOrg();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const crumbs:Crumb[] = [
        {
            name: t("models"),
            path: to.models(orgParams || "") 
        },
        {
            name: model?.name || "",
            loading,
            path: to.model(orgParams || "", model?.id || "")
        }
    ]

    return <div className="mb-5">
        <Breadcrumbs crumbs={crumbs} />
        
        <div className="flex items-center justify-between w-full">
            <Text.PageTitle className="">
                {loading?
                    <Skeleton className="w-64 h-12"/>
                    : 
                    <div className="flex items-start gap-3 flex-col">
                        {model?.name}
                        <StatusIndicator disabled={!!model?.disabled} />
                    </div>
                }
            </Text.PageTitle>
            
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <DropDownDotsButton/>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <ToggleModelStatus>
                        <DropdownMenuItem className="flex items-center gap-x-2">
                            {model?.disabled ? <><RadioOffIcon/> {t("active")}</>:<><RadioOnIcon/>{t("active")}</>}
                        </DropdownMenuItem>
                    </ToggleModelStatus>
                    <DropdownMenuItem onClick={()=>setDeleteOpen(true)} className="flex items-center gap-x-2 text-red-500"><DeleteIcon />{t("delete")}</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
        <DeleteModalDialog open={deleteOpen} setOpen={setDeleteOpen}/>
        </div>
}