// Components
import { Modal } from "components/core/modal"
import { Popover, PopoverTrigger, PopoverContent  } from "components/ui/popover"
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "components/ui/command"
import { Link } from "react-router-dom"
import { ScrollArea } from "components/ui/scroll-area"

// Utils
import { cn } from "utils/cn"

// Icons
import { BiTransfer as OrgIcon} from "react-icons/bi"
import { RadioGroup } from "components/core/radio/group"
import { Check, ChevronsUpDown } from "lucide-react"

// Constants
import { to } from "constants/routes"

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "hooks/useMediaQuery"

export function SelectActiveOrg(){

    const {user} = useAuth()
    const {activeOrg, setActiveOrg} = useActiveOrg()
    const [selectedOrg, setSelectedOrg] = useState(activeOrg)
    const [open, setOpen] = useState(false)
    const { t } = useTranslation("common")

    const isLG = useMediaQuery("lg")

    useEffect(() => {
        setSelectedOrg(activeOrg)
    }, [activeOrg])

    useEffect(() => {
        setOpen(false)
    }, [isLG])

    const handleSelect = useCallback((selectedOrg:any) => {
        if(selectedOrg && activeOrg && selectedOrg.slug === activeOrg.slug) return
        setActiveOrg && setActiveOrg(selectedOrg)
        setSelectedOrg(selectedOrg)
        setOpen(false)
    },[setActiveOrg, activeOrg])

    if ((!user && !activeOrg) || (user && !user.organizations)) return <></>

    return <>
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <div className="flex w-full gap-2 items-center lg:px-3 py-2 cursor-pointer transition-all text-container-foreground hover:text-gray-100">
                    <OrgButton/>
                    <span className="text-ellipsis whitespace-nowrap overflow-hidden w-full">
                        {
                            selectedOrg?.name
                        }
                    </span>
                    <ChevronsUpDown className="w-4 h-4 shrink-0"/>
                </div>
            </PopoverTrigger>
            <PopoverContent className="p-0 lg:w-[230px]">
                <Command className="bg-background">

                    <CommandInput placeholder={t("search")} />
                        <ScrollArea className="h-48">
                        <CommandGroup>
                            {
                                user?.organizations?.map((org:any)=>
                                    <Link key={org?.slug} to={to.ressources(org?.slug)} className="block mb-1 last:mb-0">
                                        <CommandItem 
                                            value={org} 
                                            onSelect={()=>handleSelect(org)} 
                                            className={
                                                cn("px-1 cursor-pointer", 
                                                selectedOrg?.slug === org?.slug ? "text-gray-100 bg-container-light":"text-container-foreground")
                                            }
                                        >
                                            <Check
                                                className={cn(
                                                    "mr-2 h-4 w-4",
                                                    selectedOrg?.slug === org?.slug ? "opacity-100 text-primary" : "opacity-0"
                                                )}
                                            />
                                            {org?.name}
                                        </CommandItem>
                                    </Link>
                                )
                            }

                        </CommandGroup>
                        <CommandEmpty>{t("no-organizations-found")}</CommandEmpty>
                        </ScrollArea>
                </Command>
            </PopoverContent>
        </Popover>
    </>
}

function OrgButton() {
    return <>  
        <OrgIcon className="w-5 h-5 lg:mr-1 shrink-0" />
    </>
}

function ModalButton({onClick}:{onClick?:any}){

    return <div onClick={onClick}>
        <div className="flex justify-center mb-1 hover:cursor-pointer opacity-70 hover:opacity-100">
            <OrgButton/>
        </div>
    </div>
}

export function SelectActiveOrgModal(){

    const { t } = useTranslation("common")
    const [open, setOpen] = useState(false)
    const {user} = useAuth()
    const navigate = useNavigate()
    const {activeOrg, setActiveOrg} = useActiveOrg()
    const orgs = user.organizations || []
    const [selectedOrg, setSelectedOrg] = useState(activeOrg)

    useEffect(() => {
        setSelectedOrg(activeOrg)
    }, [activeOrg])

    const handleSelect = useCallback((selectedOrg:any) => {
        if(selectedOrg && activeOrg && selectedOrg.slug === activeOrg.slug) return
        setActiveOrg && setActiveOrg(selectedOrg)
        setSelectedOrg(selectedOrg)
        navigate(to.ressources(selectedOrg.slug))
        
    },[setActiveOrg, activeOrg, navigate])

    if ((!orgs && !activeOrg) || (user && !user.organizations) ) return <></>

    return <>

        <ModalButton onClick={()=>setOpen(true)} />
        <Modal open={open} setOpen={setOpen} title={t("choose-organization")}>
            <div className="max-h-[300px] overflow-y-auto">
                <RadioGroup value={selectedOrg} values={orgs || []} setValue={handleSelect} stringify={(d:any)=> d && d.name} indexingKey={"slug"} />
            </div>
        </Modal>

    </>
}

SelectActiveOrg.Modal = SelectActiveOrgModal