// Components
import { SearchContainer,} from "components/core/container"
import { Spinner } from "components/core/spinner"
import { Skeleton } from "components/core/skeletons"
import { Paginate } from "components/core/paginate"
import { PageTitle } from "components/core/typo"
import { Pill } from "components/core/pill"
import { IncidentRow } from "components/incidents/list"
import { CreateIncidentsModal } from "components/incidents/create"

// Icons
import { HiShieldExclamation as IncidentIcon } from "react-icons/hi"
import { AiOutlineClose as RemoveIcon } from "react-icons/ai"

// Utils
import { format } from "utils/format"
import { calendar as utilsCalendar } from "utils/calendar"

// Hooks
import { useTranslation } from "react-i18next"
import { useFilterDates } from "hooks/useFilterDates"
import { useRessourceIncidents } from "hooks/useRessourceIncidents"
import { useState } from "react"
import { useRessource } from "hooks/useRessource"

function RessourceIncidentsList() {
    const { t, i18n } = useTranslation("common")
    const { filter:filterDates, setFilter:setFilterDates } = useFilterDates()
    const { incidents, setIncidents, loading, paging, setPage, setQuery } = useRessourceIncidents()
    const { ressource } = useRessource()
    const [ open, setOpen ] = useState(false)
    
    if (!incidents && loading) return <>
        <Skeleton className="h-36" />
    </>
    return <>
        <div className="flex flex-wrap items-end justify-between gap-y-2 gap-x-2">
            <div className="flex flex-col items-start gap-x-3 gap-y-2">
                <PageTitle noMargin>{t("recent-incidents")}</PageTitle>
                {
                    filterDates.length > 0 && <Pill theme="accent"><span className="whitespace-pre-wrap">{filterDates[0] && format.date(filterDates[0], i18n.language)}{filterDates[1] && " - "}</span>
                        <span>
                            {filterDates[1] && format.date(filterDates[1], i18n.language)}
                        </span>
                        <RemoveIcon onClick={()=>setFilterDates([])} className="ml-1 cursor-pointer"/>
                    </Pill>
                }
            </div>
            <p className="flex items-center text-sm transition-colors disabled:hover:cursor-default hover:cursor-pointer text-container-foreground hover:text-gray-300" onClick={()=>setOpen(true)}>
                <IncidentIcon className="mr-1.5 text-lg shrink-0"/>{t("signal-incident")}
            </p> 
        </div>
        <div className="flex flex-col mt-3 lg:flex-row gap-x-5 gap-y-8">
            <div className="w-full">
                <SearchContainer loading={incidents && loading || false} placeholder={t("search-incidents")} setQuery={setQuery}>
                    {
                        incidents && incidents.map(({ id, name, status, acknowledged, messageCount, createdAt}) => {
                            return <IncidentRow 
                                key={'incident-'+id}
                                createdAt={createdAt}
                                id={id} name={name || ""}
                                status={status}
                                acknowledged={acknowledged}
                                messageCount={messageCount} />
                        })
                    }
                    {
                        loading && !incidents && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
                    }
                    {
                        paging && paging.numResults === 0 && <ListEmptyRow />
                    }
                </SearchContainer>
                <Paginate {...paging} setPage={setPage} />
                <CreateIncidentsModal open={open} setOpen={setOpen} defaultRessources={[ressource]} prependIncident={(paging && paging.page === 1 && (utilsCalendar.dayInArray(new Date(), filterDates) || filterDates.length === 0)) || false} setIncidents={setIncidents} />
            </div>
        </div>
        
    </>
}

function ListEmptyRow() {

    const { t } = useTranslation("common")

    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("no-incidents-message")}
            </div>
        </div>
    </>
}

export const RessourceIncidents = {
    List: RessourceIncidentsList
}
