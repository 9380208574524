// Components
import { Layout } from "layouts"
import { SignupForm } from "components/auth/forms/signup-form";
import { Logo } from "components/tools/logo"

export function Signup(){

    return <>
        <Layout.Full center>
            <div>
                <Logo.Xl className="w-64 mb-12" />
                <SignupForm className="w-screen px-4 sm:w-[480px]" />
            </div>

        </Layout.Full>
    </>
}