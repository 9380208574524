
import { json } from '@codemirror/lang-json';
import { javascript } from '@codemirror/lang-javascript';
import { atomone } from '@uiw/codemirror-theme-atomone'
import { nord } from '@uiw/codemirror-theme-nord'
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { sublime } from '@uiw/codemirror-theme-sublime';
import CodeMirrorMerge from 'react-codemirror-merge';
import { EditorState } from '@codemirror/state';
import { EditorView } from 'codemirror';

interface EditorProps {
  original: string;
  modified: string;
  language?: keyof typeof languages;
  theme?: keyof typeof themes
}

const languages = {
  json: json(),
  javascript: javascript()
}

const themes = {
  'nord': nord,
  'atomone': atomone,
  'vscode-dark': vscodeDark,
  'sublime': sublime
}

export function MergeEditor({original = "", modified="", language="json", theme}:EditorProps) {
  return <CodeMirrorMerge orientation="a-b" theme={theme? themes[theme] : atomone} className='overflow-visible'>
            <CodeMirrorMerge.Original 
                value={original}
                extensions={[languages[language], 
                  EditorView.lineWrapping,
                    EditorView.editable.of(false), 
                    EditorState.readOnly.of(true)]}/>
            <CodeMirrorMerge.Modified
                value={modified}
                extensions={[languages[language], 
                          EditorView.lineWrapping,
                            EditorView.editable.of(false), 
                            EditorState.readOnly.of(true)]}
            />
            </CodeMirrorMerge>
}
