import { RadioGroup as RG } from "@headlessui/react";
import classNames from "classnames";
import { BiCheck as CheckIcon } from "react-icons/bi";

interface RadioGroupProps {
  value: any;
  setValue: any;
  values: any[];
  stringify?: any;
  indexingKey?: any;
  descriptionKey?: string;
  iconKey?: string;
  setOnClick?: boolean;
}

export function RadioGroup({
  value,
  setValue,
  values,
  stringify,
  indexingKey,
  descriptionKey,
  iconKey,
  setOnClick,
}: RadioGroupProps) {
  return (
    <div className="w-full py-4">
      <div className="w-full mx-auto">
        <RG
          value={value}
          onChange={setValue}
          by={indexingKey || ((a, b) => a === b)}>
          <div className="space-y-2">
            {values.map((option, index) => (
              <RG.Option
                onClick={() => setOnClick && setValue(option)}
                key={
                  typeof indexingKey === "function"
                    ? indexingKey(option)
                    : option[indexingKey] ||
                    `${option}-${index}`
                }
                value={option}
                className={({ active, checked }) =>
                  `${active ? "" : ""}
                  ${checked
                    ? "bg-primary text-white"
                    : "bg-container-foreground/20 text-white"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }>
                {({ checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        {iconKey && (
                          <div className="mr-4 text-2xl">
                            {option[iconKey] &&
                              option[iconKey]()}
                          </div>
                        )}
                        <div className="text-sm">
                          <RG.Label
                            as="p"
                            className={`font-medium ${checked ? "" : ""
                              }`}>
                            {stringify
                              ? stringify(option)
                              : option}
                          </RG.Label>
                          <RG.Description
                            as="span"
                            className={`inline ${checked ? "" : ""
                              }`}>
                            <span
                              className={classNames(
                                checked
                                  ? "text-white"
                                  : "text-gray-300"
                              )}>
                              {descriptionKey
                                ? option[
                                descriptionKey
                                ]
                                : ""}
                            </span>
                          </RG.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="text-white rounded-full shrink-0 bg-black/10">
                          <CheckIcon className="w-6 h-6" />
                        </div>
                      )}
                      {!checked && (
                        <div className="w-6 h-6 rounded-full shrink-0"></div>
                      )}
                    </div>
                  </>
                )}
              </RG.Option>
            ))}
          </div>
        </RG>
      </div>
    </div>
  );
}

interface RadioOptionProps {
  option: any;
  checked: any;
  setValue: any;
  stringify?: any;
  indexingKey?: any;
  descriptionKey?: string;
  icon?: React.ReactNode;
  hideCheck?: boolean;
  children?: React.ReactNode;
  noPadding?: boolean;
}
export function RadioOption({
  option,
  checked,
  stringify,
  descriptionKey,
  icon,
  setValue,
  hideCheck,
  children,
  noPadding
}: RadioOptionProps) {
  return (
    <>
      <div
        onClick={() => setValue(option)}
        className={classNames(
          checked
            ? "bg-primary text-white"
            : "bg-container-foreground/20 text-white", !noPadding && "px-5 py-4",
          "relative flex cursor-pointer rounded-lg shadow-md focus:outline-none select-none"
        )}>
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center w-full">
              {icon && (
                <div className="mr-4 text-2xl">{icon}</div>
              )}
              <div className="text-sm w-full">
                {children ? (
                  children
                ) : (
                  <>
                    <p className={"font-medium"}>
                      {stringify
                        ? stringify(option)
                        : option}
                    </p>
                    <span className="inline">
                      <span
                        className={classNames(
                          checked
                            ? "text-white"
                            : "text-gray-300"
                        )}>
                        {descriptionKey
                          ? option[descriptionKey]
                          : ""}
                      </span>
                    </span>
                  </>
                )}
              </div>
            </div>
            {!hideCheck && checked && (
              <div className="text-white rounded-full shrink-0 bg-black/10">
                <CheckIcon className="w-6 h-6" />
              </div>
            )}
            {!hideCheck && !checked && (
              <div className="w-6 h-6 rounded-full shrink-0"></div>
            )}
          </div>
        </>
      </div>
    </>
  );
}
