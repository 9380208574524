// Types
import { IconType } from "react-icons"

// Icons
import { GoIssueOpened as OpenIcon, GoIssueClosed as ClosedIcon, GoIssueReopened as ReopenIcon, GoIssueDraft as DraftIcon } from "react-icons/go"
import { BsCheckLg as CheckIcon } from "react-icons/bs"
import { MdOutlineClose as CloseIcon } from 'react-icons/md'
import { TbProgressCheck as ProgressIcon } from "react-icons/tb"

// Utils
import classNames from "classnames"
import { format } from "utils/format"

// Hooks
import { useCallback } from "react";
import { useAPI } from "hooks/useAPI";
import { createIncidentMessage } from "api/incidents";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useIncidentMessages } from "hooks/useIncidentMessages";

const eventThemes = {
    "default": "bg-gray-400",
    "accent": "bg-primary",
    "emerald": "bg-emerald-500",
    "rose": "bg-rose-500",
    "violet": "bg-violet-500",
}

type Event = {
    Icon: IconType;
    message?: string;
    theme: keyof typeof eventThemes;
    options?: (message:string)=>Record<string,string>
}

const events: Record<string, Event> = {
    incident_open: {
        Icon: OpenIcon,
        message: "opened-issue-on",
        theme: "accent"
    },
    incident_open_anonymous: {
        Icon: OpenIcon,
        message: "opened-issue-on-anonymous",
        theme: "accent"
    },
    incident_reopened: {
        Icon: ReopenIcon,
        message: "reopened-issue-on",
        theme: "accent"
    },
    incident_closed: {
        Icon: ClosedIcon,
        message: "closed-issue-on",
        theme: "violet"
    },
    incident_acknowledged: {
        Icon: CheckIcon,
        message: "acknowledged-issue-on",
        theme: "emerald"
    },
    incident_removed_acknowledged: {
        Icon: CloseIcon,
        message: "removed-acknowledged-issue-on",
        theme: "rose"
    },
    incident_progress: {
        Icon: ProgressIcon,
        message: "progressed-issue-on",
        options: (message)=> ({progress: message}),
        theme: "violet"
    },
    default: {
        Icon: DraftIcon,
        theme: "default"
    }
}

export function Event({ type, customMessage, authorName, date }: { type: keyof typeof events, customMessage?: string, authorName?: string, date?: string }) {
    const { t, i18n } = useTranslation("common")
    if (!events[type]) type = "default"
    const theme = events[type].theme
    const Icon = events[type].Icon
    const optionsFunction = events[type].options
    const options = optionsFunction ? optionsFunction(customMessage || "") : {}
    const formattedDate = format.datetime(new Date(date || ""), i18n.language)
    return <>
        <div>
            <div className="relative min-h-[65px] ml-20 border-l-2 border-l-container-light">
                <div className={classNames(eventThemes[theme], "absolute top-0 flex items-center justify-center p-1 -translate-x-1/2 -translate-y-1/2 rounded-full")}><Icon className="text-xl" /></div>
                <p className="ml-6 text-sm -translate-y-[10px] pb-4">{events[type].message ? authorName + " " + t(events[type].message || "", { date: formattedDate, ...options }) : customMessage}</p>
            </div>
        </div>
    </>
}

export function usePostTimelineEvent(incidentId:string, type: string, message:string="", toast=false) {
    const { t } = useTranslation("common")
    const { user } = useAuth()
    const { setMessages } = useIncidentMessages()
    const onPostResult = useCallback((result: any) => {
        result.author = { name: user.name, firstname: user.firstname, lastname: user.lastname }
        result.createdAt = new Date(result.createdAt).toISOString()
        setMessages((prev: any) => [...prev, result])
    }, [])
    
    const requestOptions = toast ? { errorToastMessage: t("error-updating-issue"), successToastMessage: t("success-update-issue") } : {}

    const [, { execute, loading }] = useAPI(createIncidentMessage, { incidentId: incidentId, message: message, messageType: type }, { immediate: false, onResult: onPostResult, ...requestOptions })
    return { execute, loading }
}