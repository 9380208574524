import { useState, useEffect, useCallback } from 'react';

// Hook
export function useDebounce(value:any, delay:number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}


// Hook to debounce a function and provide a cancel method
export function useDebounceFunction<T extends (...args: any[]) => any>(fn: T, delay: number) {
  const [debouncing, setDebouncing] = useState<NodeJS.Timeout | null>(null);

  const debouncedFn = useCallback((...args: Parameters<T>) => {
    if (debouncing) {
      clearTimeout(debouncing);
    }
    setDebouncing(setTimeout(() => fn(...args), delay));
  }, [fn, delay, debouncing]);

  const cancel = useCallback(() => {
    if (debouncing) {
      clearTimeout(debouncing);
      setDebouncing(null);
    }
  }, [debouncing]);

  useEffect(() => {
    return () => {
      if (debouncing) {
        clearTimeout(debouncing);
      }
    };
  }, [debouncing]);

  return { debouncedFn, cancel };
}