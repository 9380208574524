import classNames from "classnames"
import { Link } from "react-router-dom"

const themes = {
    blank: "",
    basic: "hover:text-white hover:cursor-pointer w-fit",
    default: "block text-gray-300 hover:text-white hover:cursor-pointer w-fit transition-all"
}

interface InternalLinkProps {
    to: string,
    className?: string,
    children?: React.ReactNode,
    props?: any,
    theme?: keyof typeof themes
}

export function InternalLink({to, className, children, props, theme = "default"}: InternalLinkProps){
    return <Link to={to} className={classNames(className, themes[theme])} {...props}>{children}</Link>
}