// Components
import { OrgMembersContext } from "contexts/org-members";

// Types
import { Dispatch } from "react";

// Hooks
import { useContext } from "react";


export function useOrgMembers():{members: any[], setMembers: Dispatch<any>, selectedMember: any, setSelectedMember: Dispatch<any>, loading: boolean, orgId:any}{
    return useContext(OrgMembersContext);
}