import { useAuth } from "hooks/useAuth";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface useRouteOrgResult {
    routeOrg: any;
}

export function useRouteOrg():useRouteOrgResult{

    const { user } = useAuth();
    const { org } = useParams();

    const result = useMemo(() => {
        const result = {
            routeOrg: null
        }

        if(user?.organizations?.length && org){
            result.routeOrg = user.organizations.find((o:any) => o.slug === org);
        }

        return result;
    }
    ,[user, org]);

    return result;
}