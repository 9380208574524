import { fetchCall, QueryOptions } from 'api'

// Types

// Get jobs for a resource
export function getResourceJobs({ ressourceId, createdAfter, createdBefore, name, fields, page, limit, orderBy, orderDirection, search, status }: 
    { search:string, ressourceId: string, name: string, createdAfter:string, createdBefore:string, status?: string } & QueryOptions) {
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressourceId}/jobs`,
        query: {
            fields,
            page,
            limit,
            name,
            ressource: ressourceId,
            order_by: orderBy,
            order_direction: orderDirection,
            created_after: createdAfter,
            created_before: createdBefore,
            search,
            status
        },
    };
    return fetchCall(options);
}

// Get specific job
export function getJob({ jobId, fields }: { jobId: string, fields?: string[] }) {
    const options = {
        method: "GET",
        endpoint: `/app/jobs/${jobId}`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

// Delete specific job
export function deleteJob({ jobId }: { jobId: string }) {
    const options = {
        method: "DELETE",
        endpoint: `/app/jobs/${jobId}`,
    };
    return fetchCall(options);
}

// Update job
export function updateJob({jobId, name, status}: {jobId: string, name: string, status: string}) {
    const options = {
        method: "PATCH",
        endpoint: `/app/jobs/${jobId}`,
        body: {name, status}
    };
    return fetchCall(options);
}