/* eslint-disable */
// Utils
import { uploadFile } from "utils/upload"

// API
import { createModelFiles } from "api/models";
import { useTranslation } from "react-i18next";
import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { Uploader } from "components/core/uploader";
import { Modal } from "components/core/modal";
import { Button } from "components/core/buttons";
import { toast } from "react-hot-toast"

interface UploadModelFileProps {
    modelId: string,
    files: File[],
    onError?:(e:any)=>void,
    onResult?:(r?:any)=>void
    onFinally?:()=>void
}

export const uploadModelFilesAsync = async ({modelId, files, onError, onResult, onFinally}:UploadModelFileProps) => {
    const filesToGenerate = files.map((file) => ({
        name: file.name,
        numBytes: file.size
    }));
    const [ createUploadPromise ] = createModelFiles({modelId, files: filesToGenerate})
    createUploadPromise.then((result:any) => {
        const promises:Promise<unknown>[] = []
        result?.results?.map((r:any, i:number) => 
            {
                return r?.uploadPolicy?.policy?.url && i < files?.length? promises.push(uploadFile(r.uploadPolicy.policy.url, files[i], r.uploadPolicy.policy.fields)) : null
            }
        )
        return Promise.all(promises)
    }).then((result:any) => {
        if(onResult) onResult(result)
    }).catch((e:any) => {
        if(onError) onError(e)
    }).finally(() => {
        if(onFinally) onFinally()
    })
    
}



export function AddModelFilesModal({open, setOpen, modelId, reloadList}:{open:boolean, setOpen:Dispatch<SetStateAction<boolean>>, reloadList: () => void, modelId: string}){
    const { t } = useTranslation("common")
    const [files, setFiles] = useState<File[]>()
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

    const onUploadError = useCallback((e:any) => {
        toast.error(t("error-upload-file",{e}))
        setLoadingUpload(false)
        setFiles(undefined)
        setOpen(false)
    },[])

    const onUploadSuccess = useCallback((r:any) => {
        toast.success(t("success-upload-file", {r}))
        setLoadingUpload(false)
        setOpen(false)
        setFiles(undefined)
        reloadList()
    },[])
    const execute = () => {
        setLoadingUpload(true)
        if (files && files.length > 0 && modelId) {
            uploadModelFilesAsync({
                modelId,
                files: files,
                onError: onUploadError,
                onResult: onUploadSuccess
            })
        }
    }
    return <>
        <Modal title={t("upload-file.title")} open={open} setOpen={setOpen} size="lg" onClose={()=>setFiles(undefined)}>
            {
               <>
                    <div className="mb-4">
                        <Uploader.DropZone multiple id="file-upload" files={files} setFiles={setFiles}  />
                    </div>
                    <Modal.Footer type="doubleButton">
                        <Button type="reset" theme="gray" onClick={()=>{setOpen(false); setFiles(undefined)}}>{t("cancel")}</Button>
                        <Button loading={loadingUpload} disabled={loadingUpload} onClick={execute}>{t("save")}</Button>
                    </Modal.Footer> 
                </>
            }
        </Modal>
    </>
}
