// Constants
import { routes } from "constants/routes";

// Components
import { Navigate } from "react-router-dom";
import { Spinner } from "components/core/spinner";

// Hooks
import { useRessource } from "hooks/useRessource";
import { useActiveOrg } from "hooks/useActiveOrg";

export function RessourceGuard({children}:{children:React.ReactNode}){

    const { ressource, loading } = useRessource()
    const { activeOrg } = useActiveOrg()

    if (!ressource && loading) return <div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div>

    if (!ressource && !loading || (ressource && ressource.organization.slug != activeOrg.slug && !loading)) return <Navigate to={routes.notFound} replace></Navigate>

    return <>
        {children}
    </>

}