// Components
import { Container } from "components/core/container"
import { Text } from "components/core/typo"

export interface StatsHighlightProps {
    title?: string
    targetNumber: number|string
    targetUnit?: string
}

export function StatsHighlight({title, targetNumber, targetUnit}: StatsHighlightProps){

    return <>
        <Container>
            <div className="flex flex-col">
                <p className="mb-2 text-container-foreground">{title}</p>
                <Text.TitleMedium>{targetNumber}{targetUnit}</Text.TitleMedium>
            </div>
        </Container>
    </>
}

export const Stats = {
    Highlight: StatsHighlight
}