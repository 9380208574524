// Components
import { Popover } from "components/core/popover"

// Utils
import classNames from "classnames"

// Icons
import { BsThreeDots as DotsIcon } from "react-icons/bs"

interface MenuProps {
    children: React.ReactNode
    className?: string
    label?: string | React.ReactNode
}

export const ChildrenStyles = "flex items-center w-full px-3 py-2 rounded hover:bg-gray-600/30 hover:cursor-pointer"
export const ChildrenDangerStyles = "flex items-center w-full px-3 py-2 text-red-500 rounded hover:bg-gray-600/30 hover:cursor-pointer"

export function ChildrenMenu({ children, className, label }: MenuProps) {

    return <>
        <Popover position="bottom-end" label={label ? label : <MenuButton/>} hideChevron>
            <div className={classNames(className)}>
                {children}
            </div>
        </Popover>
    </>
}

function MenuButton() {

    return <div className="px-2">
        <DotsIcon/>
    </div>
}