import { useEffect, useCallback } from 'react';

type KeyCombination = string | string[];

function isKeyCombinationPressed(keyCombination: KeyCombination, event: KeyboardEvent): boolean {
    if (typeof keyCombination === 'string') {
        return event.key === keyCombination;
    }

    const keysPressed = new Set([
        ...(event.ctrlKey ? ['Control'] : []),
        ...(event.shiftKey ? ['Shift'] : []),
        ...(event.altKey ? ['Alt'] : []),
        ...(event.metaKey ? ['Meta'] : []),
        event.key,
    ]);

    return keyCombination.every(key => keysPressed.has(key));
}

export function useKeyCombo(keyCombo: KeyCombination, callback: () => void, dependencies: React.DependencyList = [],  logKey?:boolean): void {
    const callbackRef = useCallback(callback, dependencies); // Ensure callback stability

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (logKey) {
                console.log(event.key);
            }
            if (isKeyCombinationPressed(keyCombo, event)) {
                callbackRef();
            }
        };

        document.addEventListener('keydown', handler);
        return () => document.removeEventListener('keydown', handler);
    }, [keyCombo, callbackRef]);
}