import { useContext } from "react";
import { Models, ModelsContext } from "contexts/models";

/**
 * @example const {model, loading, error} = useRessources(); 
 * @description This hook returns the list of ressources from the ModelsContext
 * @returns {Models} model list, loading boolean, error {ressources, loading, error}
 */
export function useModels():Models{
    return useContext(ModelsContext);
}

