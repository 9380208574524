import { calendar as utilsCalendar } from 'utils/calendar'

export function filterAfterDate(date:Date):string | null{
    if (!date) return null
    return utilsCalendar.toStartOfDate(date).toISOString()
}

export function filterBeforeDate(date:Date):string | null{
    if (!date) return null
    return utilsCalendar.toEndOfDate(date).toISOString()
}

export const query = {
    filterAfterDate,
    filterBeforeDate
}