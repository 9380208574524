// Utils
import classNames from "classnames"

// Hooks
import { useTranslation } from "react-i18next"

// Icons
import { BiInfoCircle as InfoIcon, BiCheckCircle as SuccessIcon, BiError as ErrorIcon, BiErrorCircle as WarningIcon  } from "react-icons/bi"

interface TextProps {
    className?: string,
    props?: any,
    children?: React.ReactNode,
    description?: string,
    noMargin?: boolean
}

const baseBoxStyle = "border-t-4 rounded-t-[4px] rounded-b-md p-3 flex"
export function TextInfo({className, props, children}: TextProps){
    return <div className={classNames("bg-cyan-900 border-cyan-300", className, baseBoxStyle)}  {...props}>
        <div><InfoIcon className="mr-2 text-2xl text-cyan-300"/></div>{children}
    </div>
}

export function TextSuccess({className, props, children}: TextProps){
    return <div className={classNames("bg-teal-900 border-emerald-500", className, baseBoxStyle)}  {...props}>
        <div><SuccessIcon className="mr-2 text-2xl text-emerald-500"/></div>{children}
    </div>
}

interface ErrorProps extends TextProps {
    code?: string,
    payload?: any,
    detail?: string
}
export function TextError({className, props, children, code = "", payload, detail}: ErrorProps){
    const {t} = useTranslation("errors")
    return <div className={classNames("bg-red-900/40 border-red-500", className, baseBoxStyle)}  {...props}>
        <div><ErrorIcon className="mr-2 text-2xl text-red-500"/></div>{children? children : code && code !== "" && t(code,payload)!== code ? t(code, payload): detail ? detail : t("unknown")}
    </div>
}

export function TextWarning({className, props, children}: TextProps){
    return <div className={classNames("bg-yellow-900/40 border-yellow-500", className, baseBoxStyle)}  {...props}>
        <div><WarningIcon className="mr-2 text-2xl text-yellow-500"/></div>{children}
    </div>
}

export function Title({className, props, children, description, noMargin}: TextProps){
    return <div className={classNames(!noMargin && "mb-5")}>
        <h2 className={classNames("text-[27px] leading-7 font-semibold", className)} {...props}>{children}</h2>
        {description && <div className="mt-1 leading-7 text-container-foreground">{description}</div>}
    </div>
}

export function TitleSmall({className, props, children, description}: TextProps){
    return <div className="mb-5">
        <h2 className={classNames("text-base font-semibold", className)} {...props}>{children}</h2>
        {description && <p className="mt-1 leading-7 text-container-foreground">{description}</p>}
    </div>
}

export function TitleMedium({className, props, children, description}: TextProps){
    return <div>
        <h2 className={classNames("text-[24px] font-semibold", className)} {...props}>{children}</h2>
        {description && <p className="mt-1 leading-7 text-container-foreground">{description}</p>}
    </div>
}

export function PageTitle({className, props, children, noMargin}: TextProps){
    return <Title noMargin={noMargin} className={classNames(className, !noMargin && "mt-5")} {...props}>
        {children}
    </Title>
}
export function PageSubtitle({className, props, children}: TextProps){
    return <h2 className={classNames("text-[20px] leading-5 font-medium",className)} {...props}>
        {children}
    </h2>
}
export function Subtitle({className, props, children}: TextProps){
    return <p className={classNames("text-base text-muted-foreground", className)} {...props}>{children}</p>
}

export const Text = {
    Info: TextInfo,
    Success: TextSuccess,
    Error: TextError,
    Warning: TextWarning,
    Title,
    TitleSmall,
    TitleMedium,
    PageTitle,
    Subtitle
}