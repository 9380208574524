// Icons
import { BsThreeDots as DotsIcon } from "react-icons/bs"

// Components
import { Popover } from "components/core/popover"

// Utils
import classNames from "classnames"

// Hooks
import { useNavigate } from "react-router-dom"

type MenuItem = {
    path?: string
    onClick?: () => void
    name: string
    Icon: any,
    iconClassName?: string
    itemClassName?: string
    closeOnClick?: boolean
}

interface MenuProps {
    items: MenuItem[]
    className?: string,
    closePopover?: any
}

export function MoreMenu({ items, className }: MenuProps) {

    return <>
        <Popover position="bottom-end" label={<MenuButton/>} hideChevron addCloseProp>
            <MenuDropDown items={items} className={className}  />
        </Popover>
    </>
}

function MenuButton() {

    return <div className="px-2">
        <DotsIcon/>
    </div>
}

function MenuDropDown({ items, className, closePopover }: MenuProps) {
    const navigate = useNavigate()
    return <div className={classNames("flex flex-col min-w-[10rem] p-1 text-sm", className)}>
        {
            items.map(({ path, onClick, closeOnClick=true, name, Icon, itemClassName, iconClassName }, index) => (
                <div key={"drop-menu-"+index} onClick={(e)=> {e.preventDefault(); closeOnClick && closePopover && closePopover(); path && navigate(path); onClick && onClick(); } } className="hover:cursor-pointer">
                    <span className={classNames("flex items-center w-full px-3 py-2 rounded hover:bg-gray-600/30", itemClassName)}><Icon className={classNames("mr-3", iconClassName)}/>{name}</span>
                </div>
            ))
        }
    </div>
}
