// Utils
import classNames from "classnames"

// Components
import { Link } from "react-router-dom"

// Constants
import { routes } from "constants/routes"

interface LogoProps {
    console?: boolean,
    className?: string,
    toHome?: boolean
}

export function LogoXl({ console, className, toHome }: LogoProps) {
    return <>
        <div className={classNames("relative mx-auto select-none flex justify-center w-fit")}>
            {
                toHome ?
                    <Link to={routes.home}>
                        <img src={console ? "/logoXL-Console.svg" : "/logoXL.svg"} alt="Hectiq Console" className={className} />
                    </Link>
                    :
                    <img src={console ? "/logoXL-Console.svg" : "/logoXL.svg"} alt="Hectiq Console" className={className} />
            }
        </div>
    </>
}

export function LogoSmall({ className, toHome }: LogoProps) {
    return <>
        {
            toHome ?
                <Link to={routes.home}>
                    <img src="/logoSM.svg" alt="Hectiq Console" className={className} />
                </Link>
                :
                <img src="/logoSM.svg" alt="Hectiq Console" className={className} />
        }
    </>
}

export const Logo = {
    Xl: LogoXl,
    Small: LogoSmall
}