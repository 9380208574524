import { useContext } from "react";
import { IncidentMessagesContextResult, IncidentMessagesContext } from "contexts/incident-messages";

/**
 * @example const {messages} = useIncidentMessages(); 
 * @description This hook returns a Messages from the IncidentMessagesContext
 * @returns {messages}
 */
export function useIncidentMessages():IncidentMessagesContextResult{
    return useContext(IncidentMessagesContext);
}

