// Icons
import { AiFillGithub as GithubIcon } from "react-icons/ai"
import { BsLink as LinkIcon } from "react-icons/bs"
import { HiCodeBracket } from "react-icons/hi2"
import { MdInfoOutline as InfoIcon } from "react-icons/md"

// Utils
import { validate } from "utils/validate"
import classNames from "classnames";
import { CopyButton } from "components/core/buttons/copy";


const themes: { default: string; accent: string; } = {
    default: "text-white",
    accent: "text-pink-200",
};
type Theme = keyof typeof themes

interface MetaDataInputProps {
    content: string;
    Icon?: any;
    format?: string;
    theme?: Theme;
    copy?: boolean;
}

function MetaDataInput({content, Icon, format, copy, theme="default"}: MetaDataInputProps){
    return (
        <div className="flex ">
            <p className="flex items-center h-6 capitalize text-container-foreground">{Icon && <Icon className="mr-2 text-lg "/> }</p>
            <div className={classNames("flex items-center mb-1  break-all", themes[theme] )}>
                {validate.isUrl(content) ? 
                    <a href={validate.cleanUrl(content)} target="_blank" rel="noreferrer" className="hover:underline">{content}</a>:
                    format==="code"?
                    <code>{content}</code>:
                    <p >{content}</p>
                }
             {copy && <span className="inline-flex ml-2 text-gray-300 "><CopyButton content={content} label={"copy"} className="opacity-50"/></span>}

            </div>
        </div>
    );
}
export function MetaDataDetails({metadata}: Record<string, any>){
    const showedKeys = [{ key: "url", icon: LinkIcon}, 
                { key: "githubUrl", icon: GithubIcon}, 
                { key: "slug", format: 'code', icon: HiCodeBracket, copy:true}, 
                { key: "github_url", icon: GithubIcon}, 
                { key: "description", icon: InfoIcon}]
    return <>
        {
            Object.keys(metadata).map((key:string, index) => {
                if (!showedKeys.find((k:any) => k.key === key)) return null
                if (metadata[key] === "") return null
                const definition =  showedKeys.find((k:any) => k.key === key);
                const Icon = definition?.icon
                const format = definition?.format
                return <MetaDataInput key={index} content={metadata[key]} Icon={Icon} format={format} copy={definition?.copy} />
            })
        }
        
    </>
}

export function MetaDataSummary({metadata}: Record<string, any>){
    const showedKeys = [ 
    { key: "description", icon: InfoIcon}]
    
    return <>
        {
            Object.keys(metadata).map((key:string, index) => {
                if (!showedKeys.find((k:any) => k.key === key)) return null
                if (metadata[key] === "") return null
                const Icon = showedKeys.find((k:any) => k.key === key)?.icon
                return <div key={index} className="flex items-center">
                    <p className="flex items-center capitalize text-container-foreground">{Icon ? <Icon className="mr-2 text-lg "/> : <span className="mr-2">{key}</span>}</p>
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap text-container-foreground lg:text-md md:text-sm">
                        {metadata[key]}
                    </div>
                </div>
            })
        }
    </>
    
}

export const MetaData = {
    Summary: MetaDataSummary,
    Details: MetaDataDetails
}