// Hooks
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";

// Components
import { Layout } from 'layouts';
import { Spinner } from "components/core/spinner";
import { Navigate } from "react-router-dom";

// Constants
import { routes } from "constants/routes";
import { useEffect } from "react";

interface AuthGuardProps {
    children: React.ReactNode;
    blockConnected?: boolean;
}

/**
 * Guards a route to make sure the user is connected or not connected
 * @param children Children to render if user connection meets the requirements
 */
export function AuthGuard({ children, blockConnected = false }: AuthGuardProps) {
    const { i18n } = useTranslation();
    const { isConnected, loading, error, user } = useAuth();
    const block = blockConnected ? isConnected : !isConnected ;

    useEffect(() => {
        if (user && user.language && i18n.language !== user.language.toLowerCase()) {
            i18n.changeLanguage(user.language.toLowerCase());
        }
    }, [user])

    if ((block && loading) || (!blockConnected && error) || (blockConnected && loading)){
        return <Layout.Full center>
            <Spinner size="md" />
        </Layout.Full>
    }

    if (block && !isConnected) return <Navigate to={routes.login} />;

    if (block && isConnected) return <Navigate to={routes.home} />;

    return <>{children}</>;
}