// Hooks
import { useTranslation } from "react-i18next"

// Components
import { Text } from "components/core/typo"
import { EditInfos } from "components/account/edit-infos"

export function Settings(){
    const { t } = useTranslation("common")
    return <>
        <Text.PageTitle className="mb-10">{t("account")}</Text.PageTitle>
        <EditInfos/>
    </>
}