import { useEffect, useState } from 'react'

const getMatches = (query: string): boolean => {
  // Prevents SSR issues
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches
  }
  return false
}
export const screenSizes = {
    'sm': '(min-width: 640px)',
    'md': '(min-width: 768px)',
    'lg': '(min-width: 1024px)',
    'xl': '(min-width: 1280px)',
    '2xl': '(min-width: 1536px)',
}

export function useMediaQuery(query: string | keyof typeof screenSizes): boolean {

    if (query in screenSizes) query = screenSizes[query as keyof typeof screenSizes]

    const [matches, setMatches] = useState<boolean>(getMatches(query))


    useEffect(() => {
        function handleChange() {
        setMatches(getMatches(query))
        }

        const matchMedia = window.matchMedia(query)

        // Triggered at the first client-side load and if query changes
        handleChange()

        // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange)
        } else {
            matchMedia.addEventListener('change', handleChange)
        }

        return () => {
        if (matchMedia.removeListener) {
            matchMedia.removeListener(handleChange)
        } else {
            matchMedia.removeEventListener('change', handleChange)
        }
        }
    }, [query])

    return matches
}
