export const routes = {
    home : "/", // Home page
    login : "/login", // Login page,
    logout : "/logout", // Logout page,
    signup : "/create-account", // Signup page,
    forgotPassword : "/forgot-password", // Forgot password page,
    resetPassword : "/reset-password", // Reset password page,
    status : "/status", // Status page,
    account : "/account", // Account page,
    organizations : "/organizations", // Organisations page,
    incidents : "incidents", // Incidents page,
    models : "models", // Models page,
    ressources : "ressources", // Ressources page,
    notFound: "/404", // 404 page
    manageOrgAccess: "settings",
    createRessource: "create",
    editRessource: "edit",
    ressourceDocumentation: "documentation",
    noOrganizations: "/no-organizations",
    ressourceJobs: "jobs",
    pypiClient: "https://pypi.org/project/hectiq-console/",
    documentation: "https://hectiq-console.surge.sh",
    annotations: "annotations",
    changelog: "/changelog",
}

export const to = {
    ressource : (orgId:string, ressourceId:string)=> "/" + orgId + "/" + routes.ressources + "/" + ressourceId,
    ressourceDocumentation : (orgId:string, ressourceId:string)=> "/" + orgId + "/" + routes.ressources + "/" + ressourceId + "/" + routes.ressourceDocumentation,
    job : (orgId:string, ressourceId:string, jobId: string)=> "/" + orgId + "/" + routes.ressources + "/" + ressourceId + "/"+ routes.ressourceJobs + "/" + jobId,
    ressources : (orgId?:string)=> orgId? "/" + orgId + "/" + routes.ressources : "/" + routes.ressources,
    manageOrgAccess: (orgId:string)=>routes.organizations + "/" + orgId + "/" + routes.manageOrgAccess,
    addResource: (orgId:string)=> "/" + orgId + "/" + routes.ressources + "/" + routes.createRessource,
    incidents: (orgId?:string)=> orgId? "/" + orgId + "/" + routes.incidents : "/" + routes.incidents,
    incident: (orgId:string, incidentId:string)=> "/" + orgId + "/" + routes.incidents + "/" + incidentId,
    models: (orgId?:string)=> orgId? "/" + orgId + "/" + routes.models : "/" + routes.models,
    model: (orgId:string, modelId:string)=> "/" + orgId + "/" + routes.models + "/" + modelId,
    annotations: (orgId:string) => "/" + orgId + "/" + routes.annotations,
    annotationWorkspace: (orgId:string, ressourceId:string) => "/" + orgId + "/" + routes.annotations + "/" + ressourceId,
    annotation: (orgId:string, ressourceId:string, annotationId:string) => "/" + orgId + "/" + routes.annotations + "/" + ressourceId + "/" + annotationId,
}