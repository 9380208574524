// Hooks
import { useAuth } from "hooks/useAuth";

// Components
import { Popover } from "components/core/popover";
import { dropdownPages } from "constants/navigation-profile";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";

// Constants
import { routes } from "constants/routes";
import classNames from "classnames";

// Icons
import { FiLogOut as LogoutIcon } from 'react-icons/fi'
import { GoLinkExternal } from "react-icons/go";
import { useTranslation } from "react-i18next";

interface ProfileNavItemProps {
    path: string,
    name: string,
    Icon?: IconType | null,
    indexingKey?: string,
    className?: string
    onClick?: () => void
}
function ProfileNavItem({className, onClick, path, name, Icon, indexingKey}: ProfileNavItemProps){

    return <Link to={path} key={indexingKey} onClick={()=> onClick && onClick()} className={classNames("block p-1.5 px-3 text-sm hover:bg-container/40", className)}>
        <div className="flex items-center p-2 rounded-md gap-x-2 ">
            {Icon && <Icon className="text-lg"/>}
            <span>{name}</span>
        </div>
    </Link>
}


function ProfileNav({closePopover}: {closePopover?: any}){
    const { t } = useTranslation("common");
    return <>
        <div className="w-full min-w-[220px]">
            {
                dropdownPages.map(({path, name, Icon}:{path:string, name:string, Icon: IconType | null}, i)=>
                    <ProfileNavItem onClick={()=> closePopover && closePopover()} path={path} name={t(name)} Icon={Icon} key={i} />
                )
            }
            <ProfileNavItem path={routes.changelog} name={t("changelog")} />
            <ProfileNavItem path={routes.documentation} name={t("documentation")} Icon={GoLinkExternal}  />
            <ProfileNavItem path={routes.logout} name={t("logout")} Icon={LogoutIcon} className="border-t bg-container text-destructive border-gray-500/30" />
        </div>
    </>
}

export function ProfileDropDown(){
    const {user} = useAuth();
    const label = <ProfileIcon firstname={user && user.firstname} lastname={user && user.lastname}/>
    return <div>
        <Popover label={label} position="bottom-end">
            <ProfileNav />
        </Popover>
    </div>

}

interface ProfileIconProps {
    firstname?: string;
    lastname?: string;
}
export function ProfileIcon({firstname, lastname} : ProfileIconProps){
    return <div className="flex items-center gap-x-2">
        <div className='flex items-center justify-center text-xs leading-none bg-teal-500 rounded-full w-7 h-7'>
            {firstname && lastname && firstname[0].toUpperCase() + lastname[0].toUpperCase()}
        </div>
        <span className='hidden lg:block max-w-[170px] overflow-hidden text-ellipsis whitespace-nowrap'>{ firstname && lastname && firstname + " " + lastname}</span>
    </div>
}

export const Profile = {
    Icon: ProfileIcon,
    DropDown: ProfileDropDown
}