import React from 'react';
import classnames from 'classnames';


const positions = {
  left: "left-0 top-0",
  center: "left-1/2 -translate-x-1/2",
  right: "-right-full top-full ml-1 -translate-y-1/2",
} 
const styles = {
  light: "bg-white text-gray-700 border",
  dark: "bg-black text-white"
}

interface TooltipProps {
    content: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    onClick?: any;
    position?: "left" | "center" | "right";
    color?: "light" | "dark";
}
export function Tooltip({content, children, className, onClick, position="right", color="dark"}: TooltipProps){
                
  return <div className='relative group'>
    {children}
    {
        content && <div className={classnames("absolute w-full h-full min-w-max max-w-xs z-50 pointer-events-none", positions[position], className)}>
            <div className={classnames("rounded-md text-sm  shadow px-3 py-1 text-center block w-auto min-w-xs max-w-md z-50", 
                              positions[position], 
                              styles[color], 
                              'group-hover:opacity-100 group-hover:visible  opacity-0 invisible transition-all')} onClick={onClick}>
            {content}
            </div>
        </div>
   
    }

  </div>
}