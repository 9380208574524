import { routes } from "constants/routes";

export const dropdownPages = [
    {
        name: "account",
        path: routes.account,
        Icon: null,
    },
    {
        name: "organizations",
        path: routes.organizations,
        Icon: null,
    },
    
]