// Components
import { ControlledRessourceBuilder } from "components/ressources/builder"

// Constants
import { to } from "constants/routes"

// API
import { createRessource } from "api/ressources"

// Utils
import { defaultMetrics } from "components/ressources/metrics/list"

// Hooks
import { useAPI } from "hooks/useAPI"
import { useRouteOrg } from "hooks/useRouteOrg"
import { useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useRessources } from "hooks/useRessources"

export function Create(){
    const { t } = useTranslation("common")
    const { reload } = useRessources()
    const { routeOrg } = useRouteOrg()
    const navigate = useNavigate()
    const [ressource, setRessource] = useState<Ressource>({
        name: "",
        organization: routeOrg.slug,
        url: "",
        type: null,
        githubUrl: "",
        description: "",
        metrics: defaultMetrics["api"] || []
    })
    
    const handleResult = useCallback((result:any) => {
        reload?.()
        navigate(to.ressource(routeOrg.slug, result.slug))
    }, [navigate, routeOrg])

    const [, {execute, loading}] = useAPI<Ressource>(createRessource, ressource, 
        {
            immediate: false, 
            onResult: handleResult,
            successToastMessage: t("add-ressource.success"),
            errorToastMessage: t("add-ressource.error")
        })
    
    const onSubmit = useCallback((e:Event)=>{
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    if (!routeOrg) return <></>

    return <>
        <ControlledRessourceBuilder loading={loading} onSubmit={onSubmit} ressource={ressource} setRessource={setRessource} />
    </>
}

