// Hooks
import { useTranslation } from "react-i18next"
import { useAuth } from "hooks/useAuth"
import { useEffect, useState } from "react"
import { useAPI } from "hooks/useAPI"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { useCallback } from "react";

// API
import { validateSignupToken, signup } from "api/auth";

// Constants
import { routes } from "constants/routes"

// Components
import { TextInput } from "components/core/inputs"
import { Text } from "components/core/typo"
import { Button } from "components/core/buttons"
import { InternalLink } from "components/core/internalLink";
import classNames from "classnames";

// Utils
import { validate } from "utils/validate";
import { Spinner } from "components/core/spinner";

export function SignupForm({className}: {className?: string}) {
    const { t } = useTranslation("common")

    const [searchParams] = useSearchParams();
    const params = useMemo(() => ({ token: searchParams.get("t") }), [searchParams]);
    const { setUser } = useAuth();

    const [email, setEmail] = useState<string>("test");
    const [org, setOrg] = useState<string>("test");
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [requirements, setRequirements] = useState(validate.passwordRequirements(password));
    const navigate = useNavigate();

    const [isValidToken, { loading:loadingToken }] = useAPI(validateSignupToken, params);
    const [result, { loading, execute }] = useAPI(signup, { token: params.token, email, firstname, lastname, password }, { immediate: false, successToastMessage: t("signup-success"), errorToastMessage: t("signup-error")});
    
    const disabled = !validate.isPassword(password) || !(password === password2) || password === "" || firstname.trim() === "" || lastname.trim() === "";

    useEffect(()=>{
        if (isValidToken) {
            setEmail(isValidToken.email);
            setOrg(isValidToken.organization);
        }
    }, [isValidToken]);

    useEffect(()=>{
        if (result) {
            setUser && setUser(result);
            navigate(routes.home);
        }
    }, [result, setUser, navigate]);

    const onSubmit = useCallback((e:any)=>{
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    const handlePasswordChange = (v:string) => {
        setRequirements(validate.passwordRequirements(v));
        setPassword(v);
    }

    if (loadingToken && !isValidToken) return <>
        <div className="flex justify-center h-72">
            <Spinner size="md"/>
        </div>
    </>

    if (!loadingToken && !isValidToken) return <>
        <div className="h-72">
            <Text.Title description={t("invalid-token.description")}>{t("invalid-token.title")}</Text.Title>
            <InternalLink to={routes.login} className="mt-5">{t("return-to-login")}</InternalLink>
        </div>
    </>

    return <form onSubmit={onSubmit} className={classNames(className)} autoComplete="off">

        <Text.Title>{t("signup")}</Text.Title>
        <div className="text-center mb-7">
            <span>{email}</span> <span className="text-primary">{org}</span>
        </div>
        <div className="grid grid-cols-2 mb-4 gap-x-3">
            <TextInput onChange={setFirstname} placeholder={t("firstname")} theme="default" />
            <TextInput onChange={setLastname} placeholder={t("lastname")} theme="default" />
        </div>
        <TextInput label={t("password")} onChange={handlePasswordChange} requirements={[...t("update-user.password-requirements", requirements)]} autoComplete="new-password" placeholder={t("password")}   type="password" theme="default" />

        <div className="mt-3">
            <TextInput onChange={setPassword2} autoComplete="new-password" placeholder={t("confirm-password")} type="password" theme="default" />
        </div>

        <div className="mt-7">
            <Button disabled={disabled} loading={loading} type="submit" className="w-full" >{t("signup")}</Button>
        </div>
        <InternalLink to={routes.login} className="mt-5">{t("return-to-login")}</InternalLink>

    </form>
}