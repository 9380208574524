import { Dispatch, SetStateAction, useEffect } from 'react'

import { Logo } from 'components/tools/logo';

// Icons
import { HiBars3 as Bars3Icon } from 'react-icons/hi2'
import { Profile } from 'components/nav/profile';

// Components
import { NotificationBar } from 'components/notificationBar';

// Hooks
import { useNotificationBar } from 'hooks/useNotificationBar';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useActiveOrg } from 'hooks/useActiveOrg';

// Constants
import { to } from 'constants/routes';
import classNames from 'classnames';

interface TopBarProps {
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export function TopBarSmall({setSidebarOpen}: TopBarProps){
    const { notification } = useNotificationBar()
    
    return <>

        <div className="fixed top-0 w-full z-40 flex flex-col shadow-sm sm:hidden bg-background sm:px-6 lg:hidden">
            <div className=''>
                <TopNotificationBar />
            </div>
            <div className='flex w-full p-4 items-center'>
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="w-6 h-6 text-white" aria-hidden="true" />
                </button>
                <div className="flex-1"><Logo.Xl toHome className='h-5'/></div>
                <div>
                    <Profile.DropDown />
                </div>
            </div>
            
        </div>
        {notification && <div className={classNames('mb-20 sm:hidden')}></div>}
    </>
}

export function TopBarXl(){
    
    return <>
        <div className="fixed top-0 right-0 w-full z-40 items-center justify-end hidden px-4 py-4 pt-6 -ml-3 h-14 gap-x-6 bg-background sm:px-2 lg:px-6 sm:flex">
            <div className='w-fit left-0 fixed ml-[75px] lg:ml-[250px] transition-all'>
                <TopNotificationBar />
            </div>
            <div className='shrink-0'>
                
                <Profile.DropDown />
            </div>
        </div>
    </>
}

export function TopNotificationBar(){
    const { showNotification, hideNotification, notification } = useNotificationBar()
    const { t } = useTranslation("common")
    
    const { user } = useAuth()
    const { activeOrg } = useActiveOrg()
    // get the organization status
    const orgStatus = user.organizations && user.organizations.find((o:any) => o.slug === activeOrg?.slug)?.subscriptionStatus   
    useEffect(() => {
        hideNotification()
        orgStatus === "checkout_required" && showNotification({message: t("payments-settings.checkout-required-warning"), href:to.manageOrgAccess(activeOrg?.slug), type:"warning"})
        orgStatus === "payment_failed" && showNotification({message: t("payments-settings.checkout-failed-warning"), href:to.manageOrgAccess(activeOrg?.slug), type:"warning"})
    
    }, [activeOrg])
    
    return <>
        <div className='w-full'>
            <NotificationBar notification={notification} onClose={hideNotification} />
        </div>
    </>
}


export const TopBar = {
    Small: TopBarSmall,
    Xl: TopBarXl,
    Notification: TopNotificationBar
}