
// Hooks
import { createContext, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAPI } from "hooks/useAPI";
import { useSyncFiltersWithUrl } from "hooks/useTableFilters";
import { useState, Dispatch, SetStateAction } from "react";

// Types
import { labelSets, annotedBySets, defaultAnnotatedBySet, defaultLabelSet, defaultFilter, Filter, filterHandler } from "contexts/annotations";

// API
import { getAnnotation, getAnnotationPreviousNext } from "api/annotations";

// Constants
import { routes } from "constants/routes";

const AnnotationContext = createContext<AnnotationContextResult>(
    {
        loading: false,
        annotatedBy: [],
        setAnnotatedBy: () => {},
        annotatedBySet: defaultAnnotatedBySet,
        setAnnotatedBySet: () => {},
        labels: [],
        setLabels: () => {},
        labelSet: defaultLabelSet,
        setLabelSet: () => {},
        datesFilter: [],
        setDatesFilter: () => {},
    }
)

export type AnnotationContextResult= {
    annotation?: any;
    annotationParams?: any;
    setAnnotation?: any;
    loading: boolean;
    next?: any;
    previous?: any;
    hasFilterParams?: boolean;
    annotatedBy: string[];
    setAnnotatedBy: Dispatch<SetStateAction<string[]>>;
    annotatedBySet: typeof annotedBySets[number];
    setAnnotatedBySet: Dispatch<SetStateAction<typeof annotedBySets[number]>>;
    labels: string[];
    setLabels: Dispatch<SetStateAction<string[]>>;
    labelSet: typeof labelSets[number];
    setLabelSet: Dispatch<SetStateAction<typeof labelSets[number]>>;
    datesFilter: Date[];
    setDatesFilter: Dispatch<SetStateAction<Date[]>>;
}

const AnnotationContextProvider = ({ children }:{children: React.ReactNode}) => {
    const navigate = useNavigate()

    const { ressource:ressourceParams, annotation:annotationParams } = useParams()
    const [labels, setLabels] = useState<string[]>([])
    const [labelSet, setLabelSet] = useState<typeof labelSets[number]>(defaultLabelSet)
    const [datesFilter, setDatesFilter] = useState<Date[]>([])
    const [annotatedBy, setAnnotatedBy] = useState<string[]>([])
    const [annotatedBySet, setAnnotatedBySet] = useState<typeof annotedBySets[number]>(defaultAnnotatedBySet)
    const [ filter, setFilter] = useState<Filter>(defaultFilter)
    
    const params = useMemo(() => (
        {
            ressourceId: ressourceParams, 
            annotationId: annotationParams, 
            fields: ['idx', 'private_id', 'created_at', 'labels', 'plotting', 'view_count', 'metadata']
        }
    ), [annotationParams])
        
    // Annotations filters
    const tableId = "annotations"
    const FiltersGetSetArray: {key:string, getter:()=>any, setter:Dispatch<SetStateAction<any>>, defaultValue?:any}[] = [
        { key: 'labels', getter: () => labels, setter: (v:string) => v !== "" ? setLabels(v.split(",")) : setLabels([])  },
        { key: 'labelSet', getter: () => labelSet, setter: setLabelSet , defaultValue: defaultLabelSet},
        { key: 'annotatedBy', getter: () => annotatedBy, setter: (v:string) => v !== "" ? setAnnotatedBy(v.split(",")) : setAnnotatedBy([]) },
        { key: 'annotatedBySet', getter: () => annotatedBySet, setter: setAnnotatedBySet, defaultValue: defaultAnnotatedBySet },
        { key: 'datesFilter', getter: () => datesFilter, setter: (v:string) => v !== "" ? setDatesFilter(v.split(",").map((d:string) => new Date(d))) : setDatesFilter([]) },
        { key: 'filter', getter: () => filter, setter: setFilter, defaultValue: defaultFilter},// filter is not used here to filter, but we need to keep it in sync with the url
        {
            key: 'page',
            getter: () => 1,
            setter: () => {},
            defaultValue: 1
        } // page is not a filter, but we need to keep it in sync with the url
    ]
    const {isSynced, isAllDefaultOrEmpty} = useSyncFiltersWithUrl(tableId, FiltersGetSetArray);
    
    const validateParams = useCallback((params:any) => {
        return params.annotationId !== undefined
    }, [])

    const handleErrors = useCallback(() => {
        navigate(routes.notFound)
    }, [navigate])

    const [annotation, { loading: loadingAnnotation, setResult: setAnnotation}] = useAPI<Annotation>(getAnnotation, params, 
        {
            immediate: true, 
            onCatch: handleErrors,
            validateParams
        })
    
    const previousNextParams = useMemo(() => (
        {
            ressourceId: ressourceParams,
            annotationId: annotationParams,
            labels: labels,
            labelSet: labelSet,
            annotatedBy: annotatedBy,
            annotatedBySet: annotatedBySet,
            createdFromDate: datesFilter[0] ? datesFilter[0].toISOString() : undefined,
            createdToDate: datesFilter[1] ? datesFilter[1].toISOString() : datesFilter[0] ? datesFilter[0].toISOString() : undefined,
            orderBy: filterHandler(filter).orderBy,
            fields: ['idx', 'private_id']
    }), [annotationParams, datesFilter, labels, labelSet, annotatedBy, annotatedBySet])
    
    const validatePreviousNextParams = useCallback((params:any) => {
        if (params.annotationId === undefined) return false
        return isSynced
    }, [isSynced, annotationParams]);
    const [previousNext,] = useAPI(getAnnotationPreviousNext, previousNextParams, {immediate: true, validateParams:validatePreviousNextParams})
    return (
        <AnnotationContext.Provider value={
            {
                annotation,
                setAnnotation,
                loading:loadingAnnotation,
                annotationParams,
                previous: previousNext?.previous,
                next:previousNext?.next,
                hasFilterParams: !isAllDefaultOrEmpty,
                annotatedBy,
                setAnnotatedBy,
                annotatedBySet,
                setAnnotatedBySet,
                labels,
                setLabels,
                labelSet,
                setLabelSet,
                datesFilter,
                setDatesFilter
            }
        }>
            {children}
        </AnnotationContext.Provider>
    );

};

export { AnnotationContext, AnnotationContextProvider };
