import React, { createContext, useState } from 'react';

export type NotificationType = 'warning' | 'success' | 'info' | 'error' | 'blank';

export interface Notification {
  message: string | React.ReactNode;
  type: NotificationType;
  href?: string;
  isRemovable: boolean;
}

interface NotificationBarContextType {
  notification: Notification | null;
  showNotification: (notification: {message: string | React.ReactNode, href?:string, type: NotificationType, isRemovable?: boolean}) => void;
  hideNotification: () => void;
}

export const NotificationBarContext = createContext<NotificationBarContextType | undefined>(undefined);

export function NotificationBarProvider({ children }:{children: React.ReactNode}) {
  const [notification, setNotification] = useState<Notification | null>(null);

  const showNotification = ({message, type,href, isRemovable=false}: {message: string | React.ReactNode, href?:string, type: NotificationType, isRemovable?: boolean}) => {
    setNotification({ message, type,href, isRemovable });
  };


  const hideNotification = () => {
    setNotification(null);
  };

  return (
    <NotificationBarContext.Provider value={{ notification, showNotification, hideNotification }}>
      {children}
    </NotificationBarContext.Provider>
  );
}
