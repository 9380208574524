// Hooks
import { DisableOnLoading } from "components/annotations";
import { Editor } from "components/core/codemirror";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSolidDownload as DownloadIcon } from "react-icons/bi"
import { Link } from "react-router-dom";


export function DownloadLog({url}:{url:string}){
    const { t } = useTranslation("common")
    return <>
        <Link to={url} target="_blank" className="flex items-center w-full px-3 py-2 rounded hover:bg-gray-600/30">
            <DownloadIcon className="mr-1 text-lg" />
            <span>{
                t("download-log")
            }</span>
        </Link>
    </>
}


export function LogContentFromUrl({logDownloadUrl}: {logDownloadUrl?: string}){

    const [value, setValue] = useState<string>("");
    const [loading, setLoading] = useState(!logDownloadUrl);

    useEffect(()=>{
        if (!logDownloadUrl) return;
        setLoading(true);
        const xhr = new XMLHttpRequest();
        xhr.open("GET", logDownloadUrl, true);
        xhr.onreadystatechange = ()=>{
            setLoading(false);
            if (xhr.status==200 && xhr.readyState === 4 ) {
                setValue(xhr.responseText);
            }
        };
        xhr.send()
    }, [logDownloadUrl]);

    if (!logDownloadUrl) return <div>No logs</div>;

    return <DisableOnLoading loading={loading}>
            {value && <Editor className="overflow-y-auto rounded-b-md max-h-[700px] disableActiveLine" language="shell" theme="nord" value={value}/>}
     </DisableOnLoading>
}


export function LogContent({value}: {value: string}){
    return <Editor className="overflow-y-auto rounded-b-md max-h-[700px] disableActiveLine" language="shell" theme="nord" value={value}/>
}