import { ActiveOrgContextProvider } from 'contexts/active-org';
import { NotificationBarProvider } from 'contexts/notification-bar';

export function DashboardContexts({children}:{children: React.ReactNode}){

    return <>
        <ActiveOrgContextProvider>
            <NotificationBarProvider>
                {children}
            </NotificationBarProvider>
        </ActiveOrgContextProvider>
    </>
}