// API
import { getAnnotations } from "api/annotations"

// Filters
import { useSyncFiltersWithUrl } from "hooks/useTableFilters"

// Hooks
import { Dispatch, SetStateAction, createContext, useMemo, useState, useCallback } from "react";
import { useSearch } from "hooks/useSearch";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export const labelSets = ["any",  "all", "none", "empty"] as const
export const annotedBySets = ["any", "all", "none"] as const

export const defaultLabelSet = "any"
export const defaultAnnotatedBySet = "any"

export type AnnotationsResult = {
    annotations: any;
    setAnnotations: Dispatch<SetStateAction<any[]>>;
    loading: boolean;
    setPage: Dispatch<SetStateAction<any>>;
    paging: any;
    filter:Filter;
    filters:Filter[];
    defaultFilter:Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    datesFilter: Date[];
    setDatesFilter: Dispatch<SetStateAction<Date[]>>;
    labels: string[];
    setLabels: Dispatch<SetStateAction<string[]>>;
    labelSet: typeof labelSets[number];
    setLabelSet: Dispatch<SetStateAction<typeof labelSets[number]>>;
    annotatedBy: string[];
    setAnnotatedBy: Dispatch<SetStateAction<string[]>>;
    annotatedBySet: typeof annotedBySets[number];
    setAnnotatedBySet: Dispatch<SetStateAction<typeof annotedBySets[number]>>;
    hasAnyFilter: boolean;
    resetAllFilters?: () => void;
}


export type Filter = 
    "newest" | 
    "oldest" | 
    "recently-updated" | 
    "least-recently-updated" 

export type OrderBy = "created_at" | "updated_at" 
export type OrderDirection = "asc" | "desc"

// Record of Filter, value is object with orderBy and orderDirection
export const filterDict:Record<Filter, {orderBy:OrderBy, orderDirection:OrderDirection}> = {
    "newest": {orderBy: "created_at", orderDirection: "desc"},
    "oldest": {orderBy: "created_at", orderDirection: "asc"},
    "recently-updated": {orderBy: "updated_at", orderDirection: "desc"},
    "least-recently-updated": {orderBy: "updated_at", orderDirection: "asc"}
}

export const filters:Filter[] = Object.keys(filterDict) as Filter[]

export const defaultFilter:Filter = "newest"

export function filterHandler(filter:Filter):{orderBy:OrderBy, orderDirection:OrderDirection}{
    return filterDict[filter]
}

const AnnotationsContext = createContext<AnnotationsResult>({
    annotations: [],
    setAnnotations: () => {},
    loading: false,
    setPage: () => {},
    paging: {},
    filter: defaultFilter,
    filters: filters,
    defaultFilter: defaultFilter,
    setFilter: () => {},
    datesFilter: [],
    setDatesFilter: () => {},
    labels: [],
    setLabels: () => {},
    labelSet: "any",
    setLabelSet: () => {},
    annotatedBy: [],
    setAnnotatedBy: () => {},
    annotatedBySet: "any",
    setAnnotatedBySet: () => {},
    hasAnyFilter: false,
    resetAllFilters: () => {}
    
});

const AnnotationsContextProvider = ({ children }:{children: React.ReactNode}) => {
   
    const { t } = useTranslation("common")
    const { ressource: ressourceParams } = useParams<{ressource:string}>()
    const [ filter, setFilter] = useState<Filter>(defaultFilter)
    const [ datesFilter, setDatesFilter] = useState<Date[]>([])
    const [ labels, setLabels] = useState<string[]>([])
    const [ labelSet, setLabelSet] = useState<typeof labelSets[number]>(defaultLabelSet)
    const [annotatedBy, setAnnotatedBy] = useState<string[]>([])
    const [annotatedBySet, setAnnotatedBySet] = useState<typeof annotedBySets[number]>(defaultAnnotatedBySet)
    
    const [searchParams] = useSearchParams();
    
    const [syncedUrlFilters, setSyncedUrlFilters] = useState<boolean | undefined>()

    const handleFilter = useCallback(():{orderBy:OrderBy, orderDirection:OrderDirection} => {
        return filterHandler(filter)
    },[filter])

    // idx, created_at, updated_at, label
    // private_id for url
    const params = useMemo(() => ({
        ressourceId: ressourceParams,
        labels: labels,
        labelSet: labelSet,
        annotatedBy: annotatedBy,
        annotatedBySet: annotatedBySet,
        orderBy: handleFilter().orderBy, 
        orderDirection: handleFilter().orderDirection,
        createdFromDate: datesFilter[0] ? datesFilter[0].toISOString() : undefined,
        createdToDate: datesFilter[1] ? datesFilter[1].toISOString() : datesFilter[0] ? datesFilter[0].toISOString() : undefined
    }), [handleFilter, datesFilter, labels, labelSet, annotatedBy, annotatedBySet])
    
    const tableId = "annotations"
    const validateParams = useCallback(() => {
        
        if (syncedUrlFilters || searchParams.size === 0) return true;
        
        return isAllDefaultOrEmpty
    }, [searchParams, syncedUrlFilters])
    
    const fields = ["id", "idx", "created_at", "updated_at", "labels", "private_id"];
    const [annotations, {setResult: setAnnotations, loading, setPage, paging }] = useSearch(getAnnotations, params, {limit: 25, fields, validateParams, errorToastMessage: t("annotations.error-fetching")})
    
    // Annotations filters
    const FiltersGetSetArray: {key:string, getter:()=>any, setter:Dispatch<SetStateAction<any>>, defaultValue?: any}[] = [
        {key: 'filter', getter: () => filter, setter: setFilter, defaultValue: defaultFilter},
        {
            key: 'page',
            getter: () => paging?.page || 1,
            setter: (page:any) => {if (page >= 1)setPage(parseInt(page))},
            defaultValue: 1
        },
        { key: 'labels', getter: () => labels, setter: (v:string) => v !== "" ? setLabels(v.split(",")) : setLabels([]), defaultValue: []},
        { key: 'labelSet', getter: () => labelSet, setter: setLabelSet, defaultValue: defaultLabelSet},
        { key: 'annotatedBy', getter: () => annotatedBy, setter: (v:string) => v !== "" ? setAnnotatedBy(v.split(",")) : setAnnotatedBy([]), defaultValue: []},
        { key: 'annotatedBySet', getter: () => annotatedBySet, setter: setAnnotatedBySet, defaultValue: defaultAnnotatedBySet}, 
        { key: 'datesFilter', getter: () => datesFilter, setter: (v:string) => v !== "" ? setDatesFilter(v.split(",").map((d:string) => new Date(d))) : setDatesFilter([]), defaultValue: []}
    ]
    const {isAllDefaultOrEmpty } = useSyncFiltersWithUrl(tableId, FiltersGetSetArray , ()=>setSyncedUrlFilters(true));
    const hasAnyFilter = useMemo(() => {
        return datesFilter.length > 0 || labels.length > 0 || labelSet !== defaultLabelSet || annotatedBy.length > 0 || annotatedBySet !== defaultAnnotatedBySet
    }, [datesFilter, labels, labelSet, annotatedBy, annotatedBySet, filter])
    
    const resetAllFilters = useCallback(() => {
        setDatesFilter([])
        setLabels([])
        setLabelSet(defaultLabelSet)
        setAnnotatedBy([])
        setAnnotatedBySet(defaultAnnotatedBySet)
        setFilter(defaultFilter)
    }, [])
    
    return (
        <AnnotationsContext.Provider value={{datesFilter,  setDatesFilter, annotations, setAnnotations, loading, setPage, paging, filter, setFilter, filters, defaultFilter, labels, setLabels, labelSet, setLabelSet, annotatedBy, setAnnotatedBy, annotatedBySet, setAnnotatedBySet, hasAnyFilter, resetAllFilters}}>
            {children}
        </AnnotationsContext.Provider>
    );

};

export { AnnotationsContext, AnnotationsContextProvider };