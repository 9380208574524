// Utils
import { toast } from "react-hot-toast"

// Hooks
import { useState, useCallback, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { Casing } from "utils/casing";


interface CachedResult {
    result: any;
    timestamp: number;
}
/**Tempalte method*/
export function useAPI<T = any>(
  method: (...extraParams: any) => any,
  params: any,
  {
    onResult,
    onCatch,
    immediate = true,
    defaultLoading,
    validateParams,
    successToastMessage,
    cacheTimeout = 0,
    errorToastMessage
  }: APIOptions = {}
): [T | undefined, APIResult] {
  const [result, setResult] = useState<T>();
  const [loading, setLoading] = useState(defaultLoading || immediate);
  const [error, setError] = useState<any>();
  const activeController = useRef<any>();
  const isMounted = useIsMounted();
  const lastResult = useRef<CachedResult>();

  const execute = useCallback((extraParams?:any)=>{
    if (!method) return;
    if (validateParams && !validateParams(params)) return;

    if (!error && cacheTimeout && lastResult.current && lastResult.current.timestamp + cacheTimeout*1000 > Date.now()){
      if (!isMounted.current) return;
      if (onResult){
        onResult(lastResult.current.result);
      }
      setResult(lastResult.current.result);
      setError(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    const handleSuccess = (snakeRes:any)=>{
      const res = Casing.recursiveCamelize(snakeRes);
      // if (!isMounted.current) return;
      lastResult.current = {result: res, timestamp: Math.floor(Date.now())};
      if (onResult){
        onResult(res);
      }
      setResult(res);
      setLoading(false);
      if (successToastMessage){
        toast.success(successToastMessage);
      }
    }
    const onError = (res:any)=>{
      if (!isMounted.current) return;
      setLoading(false);
      try {
        res.json()
            .then(({detail, code, payload}:any)=>{setLoading(false); setError({code, detail, payload})})
            .catch(()=>{setLoading(false); setError({code: 'default'}); if (onCatch){onCatch()} });
      }
      catch {
        setLoading(false);
        if (onCatch) onCatch();
        setError({code: 'default'});
      }
      if (errorToastMessage){
        toast.error(errorToastMessage);
      }
    }

    if (activeController.current ) activeController.current.abort();
    const [promise, {controller}] = method(extraParams? ({...extraParams, ...params}): params)
    activeController.current = controller;
    return promise.then(handleSuccess).catch(onError);
  }, [method, onResult, params, isMounted, validateParams, onCatch, successToastMessage, errorToastMessage])

  // Fire when page reload
  useEffect(() => {
    if (immediate) execute();
  }, [execute]);

  return [result, {loading, error, execute, setResult, setError}];
}