import { useContext } from "react";
import { IncidentContextResult, IncidentContext } from "contexts/incident";

/**
 * @example const {incident} = useIncident(); 
 * @description This hook returns a Incident from the IncidentContext
 * @returns {Incident} Incident {Incident}
 */
export function useIncident():IncidentContextResult{
    return useContext(IncidentContext);
}

