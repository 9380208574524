
// Components
import { TextEditor } from "components/core/editor";
import { PageSubtitle } from "components/core/typo";
import { Skeleton } from "components/core/skeletons";

// API
import { updateModel } from "api/models";

// Hooks
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useModel } from "hooks/useModel";
import { useAPI } from "hooks/useAPI";


export function ModelReadmeCard(){
    const {t} = useTranslation("common");
    const {model, setModel} = useModel();
    const [value, setValue] = useState();
    const [editing, setEditing] = useState(false);
    const params = {
        modelId: model?.id,
        readme: value
    }
    const handleResult = () => {
        setModel({...model, readme: value})
        setEditing(false)
    }
    const [,{execute,loading}] = useAPI(
        updateModel, 
        params,
        {
            immediate: false,
            onResult: handleResult
        }
    );

    if (!model) return <Skeleton className="h-32" />;
    return <>
    <div className="">
        <div className="flex flex-col items-start gap-x-3 gap-y-2">
            <PageSubtitle>{t("model-readme.title")}</PageSubtitle>
        </div>
        <div className="rounded-md bg-container shadow border-container-light mt-3 border">
            <TextEditor
                title={t("model-readme.title-short")}
                placeholder={t("model-readme.no-readme")}
                editing={editing} 
                toggleEditing={setEditing} 
                initialValue={model?.readme} 
                value={value} 
                setValue={setValue} 
                onSave={execute} 
                loadingSave={loading}
            />
        </div>
    </div>
    </>
}
