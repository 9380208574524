import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import commonFR from 'translations/locales/fr/common.json';
import commonEN from 'translations/locales/en/common.json';
import errorsFR from 'translations/locales/fr/errors.json';
import errorsEN from 'translations/locales/en/errors.json';
import docsFR from 'translations/locales/fr/docs.json';
import docsEN from 'translations/locales/en/docs.json';

//  https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    common: commonFR,
    errors: errorsFR,
    docs: docsFR,
  },
  en: {
    common: commonEN,
    errors: errorsEN,
    docs: docsEN,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common', 'errors', 'docs'],
    fallbackLng: "fr",
    returnObjects: true,
    keySeparator: ".",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
});

i18n.services.formatter && i18n.services.formatter.add('lowercase', (value) => {
  return value.toLowerCase();
})

export default i18n;