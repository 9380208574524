import { timeFormatLocale } from 'd3-time-format';
import {format as d3Format} from 'd3-format';

const enUs = timeFormatLocale({
    dateTime: "%x, %X",
    date: "%-m/%-d/%Y",
    time: "%-I:%M:%S %p",
    periods: ["AM", "PM"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });

const frCA = timeFormatLocale(
{
    "dateTime": "%e %b %Y à %X",
    "date": "%e %b %Y",
    "time": "%H:%M:%S",
    "periods": ["", ""],
    "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    "shortDays": ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
    "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    "shortMonths": ["jan.", "fév.", "mar.", "avr.", "mai", "jui.", "jul.", "août", "sep.", "oct.", "nov.", "déc."]
    });

function getLocale(locale: string): any {
    if (locale.includes("en")) {
        return enUs;
    }
    if (locale.includes("fr")) {
        return frCA;
    }
    return enUs;
}

function formatUnixToDate(unixTimestamp: number, showTime = false): string {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${showTime ? `${hours}:${minutes}` : ''}`;
}

function formatUnixToTime(unixTimestamp: number): string {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
}

function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function jsonToCSV(objArray: any[]): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    const headers = Object.keys(objArray[0]);
    str += headers.join(',') + '\r\n';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
            if (line !== '') line += ','
            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
}


function date(date: Date, locale: string = 'default'): string {
    // Should convert to local time but it will break some other codes that rely on UTC time (filtering, etc.)
    return getLocale(locale).format("%x")(date);
}

function datetime(date: Date, locale: string = 'default'): string {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return getLocale(locale).format("%c")(localDate);
}

function timeLapsed(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds< 1){
        return `${seconds*1000}ms`;
    }
    if (days > 0) {
        return `${days}d ${hours % 24}h ${minutes % 60}m`;
    }
    if (hours > 0) {
        return `${hours}h ${minutes % 60}m`;
    }
    if (minutes > 0) {
        // Convert seconds and minutes to integer
        return `${minutes}m ${d3Format(".2f")(seconds % 60)}s`;
    }
    return `${d3Format(".4g")(seconds)}s`;
}
export const format = {
    unixToDate: formatUnixToDate,
    unixToTime: formatUnixToTime,
    capitalizeFirstLetter,
    timeLapsed,
    date,
    datetime,
    jsonToCSV
}

