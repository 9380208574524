import { Outlet } from "react-router-dom";

import { Layout } from "layouts";

export function Dashboard(){

    return <Layout.Dashboard>
        <Outlet />
    </Layout.Dashboard>

}