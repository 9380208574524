// Utils
import classNames from "classnames"

const themes = {
    default: "bg-gray-100 text-gray-800 ring-gray-300",
    accent: "bg-inherit text-primary-light ring-primary-light",
    violet: "bg-inherit text-violet-400 ring-violet-400"
}

interface PillProps {
    theme?: keyof typeof themes
    className?: string
    children?: React.ReactNode
}

export function Pill({theme="default", className, children}:PillProps){
    return <>
        <span className={classNames("inline-flex w-fit items-center rounded-md px-2 py-1 text-xs font-medium  ring-1 ring-inset", themes[theme], className)}>
            { children }
        </span>
    </>
}