import classNames from "classnames"


interface PulseProps {
    size?: keyof typeof sizes
}

const sizes = {
    1: "h-3 w-3",
    2: "h-4 w-4",
    3: "h-5 w-5",
    4: "h-6 w-6",
    5: "h-7 w-7"
}

export function PulseSuccess({size=1}: PulseProps){
    return <div className={classNames(sizes[size], "relative")}>
        <div className="absolute w-full h-full origin-bottom-right -translate-x-1/2 -translate-y-1/2 rounded-full bg-emerald-500 animate-ping-slow top-1/2 left-1/2"></div>
        <div className="absolute w-5/6 -translate-x-1/2 -translate-y-1/2 rounded-full h-5/6 bg-emerald-500 animate-pulse top-1/2 left-1/2"></div>
    </div>
}

export function PulseError({size=1}: PulseProps){
    return <div className={classNames(sizes[size], "relative")}>
        <div className="absolute w-full h-full origin-bottom-right -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full top-1/2 left-1/2"></div>
        <div className="absolute w-5/6 -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full h-5/6 top-1/2 left-1/2"></div>
    </div>
}

export function PulseWarning({size=1}: PulseProps){
    return <div className={classNames(sizes[size], "relative")}>
        <div className="absolute w-full h-full origin-bottom-right -translate-x-1/2 -translate-y-1/2 bg-yellow-500 rounded-full animate-ping-slow top-1/2 left-1/2"></div>
        <div className="absolute w-5/6 -translate-x-1/2 -translate-y-1/2 bg-yellow-500 rounded-full h-5/6 animate-pulse top-1/2 left-1/2"></div>
    </div>
}

export const Pulse = {
    Success: PulseSuccess,
    Error: PulseError,
    Warning: PulseWarning
}