// Components
import { DisableOnLoading, JsonViewer } from "components/annotations";
import { Button } from "components/core/buttons";
import { Modal } from "components/core/modal";
import { ChildrenMenu, ChildrenDangerStyles, ChildrenStyles } from "components/core/moreMenu/children-Menu";
import { Skeleton } from "components/core/skeletons";
import { PageTitle, TitleMedium } from "components/core/typo";
import { JobStatus } from "components/jobs/list";
import { DownloadLog } from "components/jobs/logs";
import { StepRow } from "components/jobs/step";
import { Stats } from "components/ressources/stats";

// Icons 
import { AiFillDelete as DeleteIcon } from "react-icons/ai"
import { GoPlug as ResetJobStatusIcon } from "react-icons/go";

// API
import { deleteJob, updateJob } from "api/jobs";

// Utils
import { format } from "utils/format"

// Constants
import { to } from "constants/routes"
import classNames from "classnames";
import { Controls } from "components/annotations/controls";

// Context
import { SimilarJobsContextProvider } from "contexts/similar-job"
import { SimilarJobList } from "components/jobs/similar";
import { Spinner } from "components/core/spinner";
import { RessourceFiles } from "components/ressources/files";

// Hooks
import { useJob } from "hooks/useJob"
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAPI } from "hooks/useAPI";


function DeleteJob({job}: {job: Job}){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { ressource, org} = useParams();

    const handleResult = useCallback(() => {
        setOpen(false)
        navigate(to.ressource(org || "", ressource || ""))
    }, [])


    const [, {loading, execute}] = useAPI(deleteJob, {jobId: job.id}, {
        immediate: false, 
        onResult: handleResult,
        onCatch: ()=>setOpen(false),
        successToastMessage: t("delete-job.success", {name: job.name}),
        errorToastMessage: t("delete-job.error", {name: job.name})
    })

    return <>
        <div onClick={()=>setOpen(true)} className={classNames(ChildrenDangerStyles)}>
            <DeleteIcon className="mr-2 mt-0. !h-4 !w-4"/><span className="">{t("delete-job.button")}</span>
        </div>
            <Modal title={t("delete-job.title")} open={open} setOpen={setOpen}>
                <Modal.Body>
                    <p>{t("delete-job.description", {name: job.name})}</p>
                </Modal.Body>

                <Modal.Footer type="doubleButton">
                    <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                    <Button theme="alert" loading={loading} onClick={execute}>{t("delete")}</Button>
                </Modal.Footer>
            </Modal>
    </>
}

function JobSteps(){
    const {t} = useTranslation();
    const {job} = useJob();
    return  <>
                <TitleMedium className="py-2">{t("job-steps")}</TitleMedium>
                {job?
                    <div className="block bg-container rounded-md">
                    {job.steps && job.steps.map((step:any) =>
                        <StepRow key={step.id} {...step}/>)
                    }
                    {
                        job && !job.steps && <p className="p-5 text-center text-container-foreground">{t("no-jobs")}</p>
                    }
                    </div>:
                    <Skeleton className="h-72" />

                }
            </>
}

function JobHighlight(){
    const {job, loading} = useJob();
    const {t, i18n} = useTranslation();
    const timeLapsed = job && job.startedAt && job.endedAt && (new Date(job.endedAt).getTime() - new Date(job.startedAt).getTime())
    return <div className="grid grid-cols-1 gap-5 mb-5 sm:grid-cols-3">
        {
            (!job || loading) ? <>
                <Skeleton className="h-24" />
                <Skeleton className="h-24" />
                <Skeleton className="h-24" />
            </>
                :
                <>
                    <Stats.Highlight title={t("time-lapsed")} targetNumber={timeLapsed?format.timeLapsed(timeLapsed/1000): ""} />
                    <Stats.Highlight title={t("num-steps")} targetNumber={job.steps? job.steps.length : "-"} />
                    <Stats.Highlight title={t("started-at")} targetNumber={job.startedAt? format.datetime(new Date(job.startedAt), i18n.language): ""} />
                </>
        }

    </div>
}

function JobMetadata(){
    const {t} = useTranslation();
    const {job, loading} = useJob();
    if (!job || loading) return <Skeleton className="h-72 mt-10" />
    return <JsonViewer title={t("metadata")} 
                        noHighlight={true}
                        data={job.metadata} 
                        loading={loading}
                        show={true}
                        fileName={"metadata-"+ job.id} />
}

function UpdateJobStatusButton(){
    const {t} = useTranslation();
    const {job, setJob} = useJob();
    const [, {loading, execute}] = useAPI(updateJob, {jobId: (job && job.id) || ""}, {immediate: false, onResult: (d: Job)=>(setJob((e: Job)=>({...e, status: d.status})))})
    return <p onClick={()=>execute({status: 'manual-reset'})} 
                className={classNames(ChildrenStyles, "gap-x-2")}>
                    {loading? <Spinner/>:<ResetJobStatusIcon />}{t("reset-job-status")}
        </p>
}

export function JobDetails(){
    const {job, jobId, loading} = useJob();
    
    return <DisableOnLoading loading={loading|| false}>
                <PageTitle className="pt-12"><p className="inline-flex">{job && <JobStatus job={job} size="lg"/>}
                <span>{job? job.name : ""}</span>
                {job && <span className="ml-2 font-normal text-container-foreground">#{job.rank}</span>} 
                </p></PageTitle>
                <p className="mb-3 text-container-foreground">{job? job.description : ""}</p>
                <div className="text-container-foreground pb-1 flex items-center justify-between space-x-3">
                    {job?<Controls previous={job.previousJob|| ""} next={job.nextJob|| ""} routeMethodName={"job"}/>:
                    <div/>}

                    <ChildrenMenu className="w-52">
                        {job && job.logDownloadUrl && <DownloadLog url={job.logDownloadUrl}/>}
                        <UpdateJobStatusButton />

                        {job && <DeleteJob job={job}/>}
                    </ChildrenMenu>
                </div>
                <JobHighlight/>
                <JobSteps/>
                <div className="pt-10">
                    <RessourceFiles.List disableAddFile={true} jobId={jobId}/>
                </div>
                <JobMetadata/>
                <SimilarJobsContextProvider name={job && job.name} excludeId={jobId}>
                        <div className="mt-10"><SimilarJobList/></div>
                </SimilarJobsContextProvider>
            </DisableOnLoading>
}
