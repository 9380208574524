// Hooks
import { useActiveOrg } from "hooks/useActiveOrg"
import { useParams } from "react-router-dom"
import { useAuth } from "hooks/useAuth"
import { useEffect } from "react"

export function SetActiveOrgFromUrl({children}:{children: React.ReactNode}){

    const { user} = useAuth()
    const { activeOrg, setActiveOrg } = useActiveOrg()
    const { org:orgParams } = useParams()

    useEffect(() => {
        // Sets the active org to the one in the url if direct link is used
        if (orgParams && orgParams !== activeOrg?.slug) setActiveOrg && setActiveOrg(user?.organizations?.find((org:any) => org.slug === orgParams) || activeOrg)
    },[])

    if (orgParams && orgParams !== activeOrg?.slug) return null
    
    return <>
        {children}
    </>
}