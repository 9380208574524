// Components
import { Badge } from "components/ui/badge";

// API
import { updateModel } from "api/models";


// Hooks
import { useModel } from "hooks/useModel"
import { useAPI } from "hooks/useAPI";
import { useTranslation } from "react-i18next";

export function StatusIndicator({disabled}: {disabled: boolean}){
    const {t} = useTranslation("common");
    return <>

        <Badge variant={disabled ?"purple-outlined" : "cyan-outlined" }>{disabled ? t("inactive"):t("active")}</Badge>
    </>
}



export function ToggleModelStatus({children}: {children: React.ReactNode}) {
    const {model, setModel} = useModel();
    const {t} = useTranslation("common");
    const isDisabled = model?.disabled;
    const prevDisabled = model?.disabled;
    const params = {
        modelId: model?.id,
        disabled: !isDisabled
    }
    
    const handleError = () => {
        setModel({...model, disabled: prevDisabled})
    }
    
    const [,{execute}] = useAPI(
        updateModel, 
        params,
        {
            immediate: false, 
            successToastMessage: isDisabled ? t("activate-model.success") : t("disable-model.success"), 
            errorToastMessage: isDisabled ? t("activate-model.success") : t("disable-model.error"),
            onCatch: handleError,
        }
    );
    
    const handleUpdate = () => {
        setModel({...model, disabled: isDisabled ? null: new Date().toISOString()})
        execute();
    }
    
    return <>
        <div onClick={handleUpdate}>
            {children}
        </div>
    </>
}