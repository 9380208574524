// API
import { getOrgIncidents } from "api/incidents"

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg";
import { useMemo, useState, createContext, useCallback, Dispatch, SetStateAction} from "react";
import { useSearch } from "hooks/useSearch";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useFiltersFromURL } from "hooks/useTableFilters";

const IncidentsContext = createContext<Incidents>({});

export type Incidents= {
    incidents?: Incident[];
    setIncidents?: any;
    error?: any;
    loading?: boolean;
    paging?: any;
    setPage?: any;
    query?: string;
    setQuery?: any;
    status?: IncidentStatus;
    setStatus?: any;
    filter?:Filter;
    filters?:Filter[];
    defaultFilter?:Filter;
    setFilter?: Dispatch<SetStateAction<Filter>>;
    execute?: any;
}

export type Filter = 
    "newest" | 
    "oldest" | 
    "recently-updated" | 
    "least-recently-updated" | 
    "name-asc" | 
    "name-desc" | 
    "ressource-asc" | 
    "ressource-desc" |
    "acknowledged" |
    "not-acknowledged" |
    "progress-asc" |
    "progress-desc"
export type OrderBy = "created_at" | "updated_at" | "name" | "ressource" | "acknowledged" | "progress"
export type OrderDirection = "asc" | "desc"

// Record of Filter, value is object with orderBy and orderDirection
export const filterDict:Record<Filter, {orderBy:OrderBy, orderDirection:OrderDirection}> = {
    "newest": {orderBy: "created_at", orderDirection: "desc"},
    "oldest": {orderBy: "created_at", orderDirection: "asc"},
    "recently-updated": {orderBy: "updated_at", orderDirection: "desc"},
    "least-recently-updated": {orderBy: "updated_at", orderDirection: "asc"},
    "name-asc": {orderBy: "name", orderDirection: "asc"},
    "name-desc": {orderBy: "name", orderDirection: "desc"},
    "ressource-asc": {orderBy: "ressource", orderDirection: "asc"},
    "ressource-desc": {orderBy: "ressource", orderDirection: "desc"},
    "acknowledged": {orderBy: "acknowledged", orderDirection: "desc"},
    "not-acknowledged": {orderBy: "acknowledged", orderDirection: "asc"},
    "progress-asc": {orderBy: "progress", orderDirection: "asc"},
    "progress-desc": {orderBy: "progress", orderDirection: "desc"},
}

export const filters:Filter[] = Object.keys(filterDict) as Filter[]

export function incidentsfilterHandler(filter:Filter):{orderBy:OrderBy, orderDirection:OrderDirection}{
    return filterDict[filter]
}

export const defaultFilter:Filter = "recently-updated"

const IncidentsContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { t } = useTranslation("common")

    const urlFilters = useFiltersFromURL("incidents")

    const { activeOrg } = useActiveOrg()
    
    const [ incidents, setIncidents] = useState<Incident[]>()
    const [ query, setQuery] = useState<string>(urlFilters?.filterParams.query?.toString() || "")
    const [ status, setStatus] = useState<IncidentStatus>(urlFilters?.filterParams.status?.toString() as IncidentStatus || "open")
    const debounceQuery = useDebounce(query, 500);
    const [ filter, setFilter] = useState<Filter>(urlFilters?.filterParams.filter?.toString() as Filter || defaultFilter)
    
    const handleResult = useCallback((result:any) => {
        setIncidents(result.results || result)
    },[])
    
    const handleFilter = useCallback(():{orderBy:OrderBy, orderDirection:OrderDirection} => {
        if (!filters.includes(filter)) return filterDict[defaultFilter]
        return incidentsfilterHandler(filter)
    },[filter])
    const defaultPage = isNaN(parseInt(urlFilters?.filterParams.page?.toString() || "")) ? 1 : parseInt(urlFilters?.filterParams.page?.toString() || "")
    const params = useMemo(() => ({orgId: activeOrg && activeOrg.slug, search: query, status, orderBy: handleFilter().orderBy, orderDirection: handleFilter().orderDirection}), [debounceQuery, handleFilter, status])
    const fields = useMemo(() => (["id","severity", "acknowledged", "progress", "created_at", "name", "status", "short_description", "ressource.name", "description", "message_count"]), [])
    const [, {loading, paging, setPage, error, execute}] = useSearch(getOrgIncidents, params, { defaultPage: defaultPage,onResult: handleResult, limit: 25, fields, errorToastMessage: t("error-try-again")} )
    return (
        <IncidentsContext.Provider value={{execute, incidents, setIncidents, error, paging, setPage, query, setQuery, status, setStatus, filter, setFilter, filters, defaultFilter, loading}}>
            {children}
        </IncidentsContext.Provider>
    );

};

export { IncidentsContext, IncidentsContextProvider };