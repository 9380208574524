export const convertBytesToMB = (bytes:number) => {
    return bytes / 1000000
}

export const formatBytesToKBOrMB = (bytes:number) => {
    if (bytes < 1000){
        return `${bytes} B`
    }
    else if (bytes < 1000000){
        return `${(bytes / 1000).toFixed(2)} KB`
    }
    else if(bytes > 1000000){
        return `${convertBytesToMB(bytes).toFixed(2)} MB`
    }
    return `${(bytes / 1000).toFixed(2)} KB`
}