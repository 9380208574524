import { useState, useEffect, RefObject } from 'react';

// Define the shape of the hook's return value
interface Size {
  width: number | undefined;
  height: number | undefined;
}

// Create a custom hook that accepts a ref and returns its size
export function useElementSize<T extends HTMLElement>(ref: RefObject<T>): Size {
  // State for storing the width and height
  const [size, setSize] = useState<Size>({ width: undefined, height: undefined });

  useEffect(() => {
    // Function to update the size state
    function updateSize() {
      if (ref.current) {
        setSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    }

    // Update the size at the first run
    updateSize();

    // Set up a resize observer to listen for size changes
    const resizeObserver = new ResizeObserver(updateSize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // Clean up observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]); // Only re-run if the ref object changes

  return size;
}

