import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

const FilterDatesContext = createContext<FilterDatesContextResult>({} as FilterDatesContextResult);

export type FilterDatesContextResult= {
    filter: Date[],
    setFilter: Dispatch<SetStateAction<Date[]>>
}

const FilterDatesContextProvider = ({ children }:{children: React.ReactNode}) => {
    const [filter, setFilter] = useState<Date[]>([]);
    return (
        <FilterDatesContext.Provider value={{filter, setFilter}}>
            {children}
        </FilterDatesContext.Provider>
    );
    
};

export { FilterDatesContext, FilterDatesContextProvider };