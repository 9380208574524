// Hooks
import { useTranslation } from "react-i18next"

// Components
import { ListOrganizations } from "components/organizations/list"
import { Text } from "components/core/typo"
import { Skeleton } from "components/core/skeletons"
import { OrganizationDetails } from "pages/organizations/details"
import { ManageOrganization } from "pages/organizations/manage"
import { NoOrganizations } from "pages/organizations/no-organizations"
import { Invite } from "components/organizations/invite"

// Hooks
import { useAuth } from "hooks/useAuth"

export function OrganizationsList() {
    const { t } = useTranslation("common")
    const { user } = useAuth()
   
    return <>

        <div className="flex flex-wrap items-center justify-between mb-4 gap-y-4">
            <Text.PageTitle className="pr-2">{t("organizations")}</Text.PageTitle>
            {user && user.organizations && user.organizations.filter((o:any) => o.access.role=== "admin").length > 0 && <Invite.Modal/>}
        </div>
        {
            !user ? <Skeleton className="h-20"/>:
            <ListOrganizations />
        }
    </>
}

export const Organizations = {
    List: OrganizationsList,
    Details: OrganizationDetails,
    Manage: ManageOrganization,
    NoOrganizations
}