// Components
import { Container, ContainerHeader } from "components/core/container"
import { Uploader } from "components/core/uploader"
import { ChildrenMenu, ChildrenStyles } from "components/core/moreMenu/children-Menu"
import { AutoFocusContainer } from "components/core/autofocus"
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { ToggleStatus } from "components/incidents/timeline/status"
import { Link, useParams } from "react-router-dom"
import { TextArea } from "components/core/inputs"

// API
import { createIncidentMessage, getIncidentMessage, updateIncidentMessage, deleteIncidentMessage } from "api/incidents"

// Utils
import classNames from "classnames";
import { format } from "utils/format";
import { formatBytesToKBOrMB } from "utils/sizes"
import { uploadIncidentFilesAsync } from "components/incidents/uploadFile"
import { incidentMessageQueryFields } from "contexts/incident-messages"

// Constants
import { supportedFiles } from "constants/supported-files"

// Icons
import { AiOutlineLink as AttachmentIcon } from "react-icons/ai"
import { HiOutlineEnvelope as SystemIcon } from "react-icons/hi2"

// Hooks
import { Dispatch, SetStateAction, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useIncidentMessages } from "hooks/useIncidentMessages";
import { useAuth } from "hooks/useAuth";
import { useAPI } from "hooks/useAPI"


interface MessageProps {
    id?: string;
    message?: string;
    files?: any[];
    author?: {
        name: string;
        firstname: string;
        lastname: string;
        email: string;
    };
    createdAt?: string;
}
export function Message({ id, message, files, author, createdAt }: MessageProps) {
    const { t, i18n } = useTranslation("common")
    const {user} = useAuth();
    const [editing, setEditing] = useState<boolean>(false)
    const date = format.datetime(new Date(createdAt || ""), i18n.language)
    if (!author ) return null
    if (!message) message = t("comments.no-description")
    const supportedImgFormats = supportedFiles.images
    const imgFiles = files?.filter((file: any) => supportedImgFormats.includes(file.extension.toLowerCase()))
    const otherFiles = files
    return <>
        <MessageContainer firstname={author.firstname} lastname={author.lastname} >
            <Container noPadding>
                <ContainerHeader className="flex items-start border-b gap-y-0.5 text-container-foreground  border-b-gray-500/20">
                    <div className="flex flex-col flex-1 sm:flex-row gap-y-0.5 sm:flex-wrap">
                        <span className="font-semibold break-all whitespace-pre-wrap text-ellipsis">{(author.name || t("anonymous")) + " "}</span>
                        <span className="text-sm leading-5 sm:leading-3">{t("comments.commented-on", { date: date })}</span>
                    </div>
                    {
                        user.email === author.email && <div className="absolute top-2 right-1">
                            <MessageMenu id={id} setEditing={setEditing} />
                        </div>
                    }
                </ContainerHeader>
                {
                    imgFiles && imgFiles.length > 0 && <div className="grid grid-cols-1 gap-4 p-4 bg-container-light ">
                        {
                            imgFiles.map((file: any, index: number) => {
                                return <div key={"img-" + index} className="">
                                    <img src={file.downloadUrl} />
                                </div>

                            })
                        }
                    </div>
                }
                {
                    otherFiles && otherFiles.length > 0 && <div className="flex flex-col py-3 border-b bg-container-light gap-y-1 border-b-gray-500/20">
                        {
                            otherFiles.map((file: any, index: number) => {
                                return <div key={"file-" + index} className="flex items-center px-3 text-sm text-container-foreground">
                                    <AttachmentIcon className="mr-1 shrink-0" /><Link to={file.downloadUrl} target="_blank" className="break-all hover:underline">{file.name}</Link>
                                </div>
                            })
                        }
                    </div>

                }
                {
                    !editing ?
                    <div className="p-3 break-words whitespace-pre-wrap">
                        {message}
                    </div>
                    :
                    user.email === author.email && <EditMessage onCancel={()=>setEditing(false)} onSave={()=>setEditing(false)} defaultMessage={message} id={id} author={author}/>
                }

            </Container>
        </MessageContainer>
        <MessageSeparator/>
        
    </>
}

function MessageMenu({id, setEditing}: {id?:string, setEditing: Dispatch<SetStateAction<boolean>>}){
    const { t } = useTranslation("common")
    const [open, setOpen] = useState<boolean>(false)

    return <>
        <ChildrenMenu className="flex flex-col min-w-[12rem] p-1 text-sm">
           <li onClick={()=>setEditing((prev:boolean)=>!prev)} className={classNames(ChildrenStyles)}>{t('edit')}</li>
           <li onClick={()=>setOpen(true)} className={classNames(ChildrenStyles, 'text-red-500')}>{t('delete')}</li>
        </ChildrenMenu>
        
        <DeleteMessageModal id={id} open={open} setOpen={setOpen} />
        
    </>
}

function DeleteMessageModal({open, setOpen, id}: {open:boolean, setOpen:Dispatch<SetStateAction<boolean>>, id?:string}){
    const { t } = useTranslation("common")
    
    const { setMessages } = useIncidentMessages()
    
    const handleDelete = () => {
        setMessages((prev: any) => prev.filter((prevMessage: any) => prevMessage.id !== id))
        setOpen(false)
    }
    
    const [, { execute: executeDeleteMessage, loading: loadingDeleteMessage }] = useAPI(deleteIncidentMessage, { messageId: id }, { immediate: false, onResult: handleDelete, errorToastMessage: t("comments.error-deleting"), successToastMessage: t("comments.success-deleting") })
    
    return <>
        <Modal open={open} setOpen={setOpen} title={t("comments.delete")}>
            <Modal.Body>
                {t("comments.delete-comment-warning")}
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button onClick={executeDeleteMessage} loading={loadingDeleteMessage} disabled={loadingDeleteMessage} theme="alertOutlined">{t("delete")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

function EditMessage({defaultMessage, id, author, onCancel, onSave}: {defaultMessage: string, id?: string, author?: {email: string}, onCancel?: ()=>void, onSave?: ()=>void}){
    const { t } = useTranslation("common")
    const [message, setMessage] = useState<string>(defaultMessage)
    const { user } = useAuth()
    const { setMessages } = useIncidentMessages()
    
    const handleCancel = () => {
        setMessage(defaultMessage)
        onCancel && onCancel()
    }
    
    const handleSave = () => {
        onSave && onSave()
        setMessages((prev: any) => prev.map((prevMessage: any) => {
            if (prevMessage.id === id) {
                return { ...prevMessage, message: message }
            }
            return prevMessage
        }))
    }
    
    const [, { execute: executeUpdateMessage, loading: loadingUpdateMessage }] = useAPI(updateIncidentMessage, { messageId: id, message: message }, { immediate: false, onResult: handleSave, errorToastMessage: t("comments.error-updating"), successToastMessage: t("comments.success-updating") })
    
    if (!id || !author) return null
    if (user.email !== author.email) return null
    return <>
        <div className="px-2 pt-2">
            <AutoFocusContainer>
                <TextArea defaultValue={message} onChange={setMessage} theme="dark" className="rounded-t-none min-h-[220px]"/>
            </AutoFocusContainer>
        </div>
        <div className="flex flex-col items-center w-full gap-2 p-2 sm:justify-end sm:flex-row">
            <Button className="flex items-center justify-center w-full h-10 sm:w-32" theme="gray" onClick={handleCancel}>{t('cancel')}</Button>
            <Button disabled={loadingUpdateMessage || !message} loading={loadingUpdateMessage} className="flex items-center justify-center w-full h-10 sm:w-32" onClick={executeUpdateMessage}>{t('save')}</Button>
            
        </div>
        
    </>
}
    

export function AddMessageToTimeline() {
    const { t } = useTranslation("common")
    const { user } = useAuth()
    const [value, setValue] = useState<string>("")
    const [files, setFiles] = useState<File[]>()
    const [dragActive, setDragActive] = useState<boolean>(false)
    const { setMessages } = useIncidentMessages()
    const { incident: incidentParams } = useParams<{ incident: string }>()

    const [, { execute: executeGetMessage }] = useAPI(
        getIncidentMessage, {
        fields: incidentMessageQueryFields
    },
        {
            immediate: false,
            onResult: (result: any) => setMessages((prev: any) => [...prev, result])
        })

    const handleUploadEnd = useCallback((result: any) => {
        // Upload completed
        setFiles(undefined)
        setValue("")
        // New message id from post message result
        const newMessageId = result.id
        // Get the new message with the files
        executeGetMessage({ messageId: newMessageId })
    }, [])

    const onMessagePostResult = useCallback((result: any) => {
        result.author = { name: user.name, firstname: user.firstname, lastname: user.lastname, email: user.email }

        if (files && files.length > 0 && incidentParams) {
            uploadIncidentFilesAsync({ incidentId: incidentParams, messageId: result.id, files: files, onFinally: () => handleUploadEnd(result) })
        }
        else {
            // No files, append result without waiting for upload
            setValue("")
            setMessages((prev: any) => [...prev, result])
        }

    }, [files])
    
    const handleClipBoardPaste = useCallback((e: any) => {
        if (!e.clipboardData || !e.clipboardData.files || e.clipboardData.files.length <= 0) return
        const file = e.clipboardData.files[0]
        setFiles((prev) => [...(prev || []), file])
    }, [])
    
    
    const [, { execute: executePostMessage, loading: loadingPostMessage }] = useAPI(createIncidentMessage, { incidentId: incidentParams, message: value }, { immediate: false, onResult: onMessagePostResult, errorToastMessage: t("comments.error-posting"), successToastMessage: t("comments.success-posting") })
    
    const hasFiles = useMemo(() => files && files.length > 0, [files])
    const empty = useMemo(() => (!value || value === "") && !hasFiles, [value, hasFiles])
    
    return <>
        <MessageContainer firstname={user.firstname} lastname={user.lastname}>
            <ContainerHeader className="flex flex-col px-3 sm:flex-row text-container-foreground sm:flex-wrap">
                <span className="font-semibold break-all whitespace-pre-wrap text-ellipsis">{t("comments.write")}</span>
            </ContainerHeader>
            <div className="p-3">
                <Uploader.Blank multiple id="file-upload" setFiles={setFiles} onDragActive={() => setDragActive(true)} onDragInactive={() => setDragActive(false)}>
                    <div className={classNames(dragActive && "ring-teal-400 ring-2 border-transparent", "overflow-hidden border rounded border-gray-500/70 focus-within:border-transparent group ring-gray-200/70 focus-within:ring-2 focus-within:ring-primary-light")}>
                        <TextArea onPaste={handleClipBoardPaste} clear={value === ""} className="block w-full h-24 p-2 rounded-b-none focus:outline-none bg-background hover:placeholder:text-gray-300" theme="blank" placeholder={t("comments.leave-comment")} defaultValue={value} onChange={setValue} />
                        <div className={classNames(dragActive && "border-t-teal-400", "flex flex-col p-1 px-2 text-sm border-t border-dotted rounded-b text-container-foreground border-t-gray-500/70 group-focus-within:border-t-primary-light bg-container-light")}>
                            <p><label htmlFor="file-upload" className="text-white hover:cursor-pointer">{t("upload-file.choose-file")}</label>{t("upload-file.or-drag")}</p>
                            {
                                files && files.map((file: File, index) => {
                                    return <div key={"file-" + index} className="flex items-center text-sm">
                                        {file.name} - {formatBytesToKBOrMB(file.size)}
                                        <span className="ml-2 text-red-500 hover:cursor-pointer hover:underline" onClick={() => setFiles((prev) => prev?.filter((_, i) => i !== index))}>
                                            {t("remove")}
                                        </span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Uploader.Blank>
            </div>
            <div className="flex flex-col justify-end px-3 mb-3 gap-y-2 sm:flex-row gap-x-2">
                <ToggleStatus />
                <Button onClick={executePostMessage} loading={loadingPostMessage} className="flex items-center justify-center w-full h-10 leading-none sm:w-32" disabled={empty || loadingPostMessage}>{t("comments.post")}</Button>
            </div>
        </MessageContainer>
    </>
}

export function MessageContainer({ children, firstname, lastname }: { children: React.ReactNode, firstname:string, lastname:string }) {

    return <>
        <div className="flex">
                <div className="w-12 mt-1.5 mr-2 hidden sm:block"><ProfileIcon firstname={firstname} lastname={lastname} /></div>
                <div className="relative w-full max-w-full">
                    <Container noPadding>
                        {children}
                    </Container>
                    <ArrowLeft className={classNames("absolute top-2 -left-[10px] print:hidden hidden sm:block")} />
                </div>
        </div>
    </>
}

export function MessageSeparator(){
    return <div>
        <div className="ml-20 border-l-2 h-14 border-l-container-light">

        </div>
    </div>
}

function ProfileIcon({ firstname, lastname, className }: { firstname?: string, lastname?: string, className?: string }) {
    return <>
        <div className={classNames(className, 'flex items-center justify-center w-8 h-8 text-sm rounded-full', firstname || lastname ? "bg-teal-500": "bg-pink-500")}>
            {!firstname || !lastname?
                <SystemIcon className="w-6 h-6"/>:
                firstname[0].toUpperCase() + lastname[0].toUpperCase()
            }
        </div>
    </>
}

function ArrowLeft({ className }: { className?: string }) {
    return <>
        <div className={classNames(className, "w-0 h-0 border-t-[14px] border-b-[14px] border-r-[14px] border-t-transparent border-b-transparent border-r-container-light")}></div>
    </>
}