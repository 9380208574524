import { fetchCall } from 'api'

// Types
interface GetModelsParams extends SearchOptions {
    organization: string;
    search?: string;
    keepLatestVersion?: boolean
}

// Get all models
export function getModels({organization, search, keepLatestVersion, fields, page, limit, orderBy, orderDirection }: GetModelsParams) {
    const options = {
        method: "GET",
        endpoint: `/app/models`,
        query: {
            organization,
            search,
            keepLatestVersion,
            fields,
            page,
            limit,
            orderBy,
            orderDirection
        }
    };
    return fetchCall(options);
}


interface GetModelParams {
    modelId: string;
    fields: string[];
}
// Get specific model
export function getModel({modelId, fields}: GetModelParams){
    const options = {
        method: "GET",
        endpoint: `/app/models/${modelId}`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

// Create model
interface CreateModelParams{
    organization: string;
    name: string;
    version: string;
}
export function createModel({organization, name, version}: CreateModelParams) {
    const options = {
        method: "POST",
        endpoint: `/app/models`,
        body: {
            organization,
            name,
            version
        }
    };
    return fetchCall(options);
}

// Create model file
interface CreateModelFileParams{
    modelId: string,
    files: { name: string, numBytes: number }[];
}
export function createModelFiles({modelId, files}: CreateModelFileParams) {
    const options = {
        method: "POST",
        endpoint: `/app/models/${modelId}/files`,
        body: {
            files
        }
    };
    return fetchCall(options);
}

// Delete model
interface DeleteModelParams{
    modelId: string;
}
export function deleteModel({modelId}: DeleteModelParams) {
    const options = {
        method: "DELETE",
        endpoint: `/app/models/${modelId}`
    };
    return fetchCall(options);
}

// Update model
interface UpdateModelParams{
    modelId: string;
    name?: string;
    version?: string;
    description?: string;
    updateMetadata?: any;
    setMetadata?: any;
    disabled?: boolean;
    readme?: any;
}
export function updateModel({modelId, name, version, description, updateMetadata, setMetadata, disabled, readme}: UpdateModelParams) {
    const options = {
        method: "PUT",
        endpoint: `/app/models/${modelId}`,
        body: {
            name,
            version,
            description,
            updateMetadata,
            setMetadata,
            disabled,
            readme
        }
    };
    return fetchCall(options);
}

// Delete model file
interface DeleteModelFileParams{
    fileId: string;
}
export function deleteModelFile({fileId}: DeleteModelFileParams) {
    const options = {
        method: "DELETE",
        endpoint: `/app/model-files/${fileId}`
    };
    return fetchCall(options);
}

// On download model files
interface OnDownloadModelParams{
    modelId: string;
}
export function onDownloadModel({modelId}: OnDownloadModelParams) {
    const options = {
        method: "GET",
        endpoint: `/app/models/${modelId}/download`
    };
    return fetchCall(options);
}