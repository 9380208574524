// Components
import { Container, SearchContainer} from "components/core/container"
import { Spinner } from "components/core/spinner"
import { Skeleton } from "components/core/skeletons"
import { Button } from "components/core/buttons"
import { Paginate } from "components/core/paginate"
import { Modal } from "components/core/modal"
import { Uploader } from "components/core/uploader"
import { Link } from "react-router-dom"
import { Calendar } from "components/core/calendar"
import { PageTitle } from "components/core/typo"
import { Pill } from "components/core/pill"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogDescription } from "components/ui/dialog"

// Icons
import { VscNewFile as NewFileIcon } from "react-icons/vsc"
import { BiSolidChevronDown as ChevronDownIcon } from "react-icons/bi"
import { BiSolidDownload as DownloadIcon } from "react-icons/bi"
import { AiOutlineClose as RemoveIcon } from "react-icons/ai"

// Utils
import { formatBytesToKBOrMB } from "utils/sizes"
import { toast } from "react-hot-toast"
import { uploadRessourceFilesAsync } from "components/ressources/uploadFile"
import { query as utilsQuery } from "utils/query"
import { format } from "utils/format"
import classNames from "classnames"

// Constants
import { supportedFiles } from "constants/supported-files"

// API
import { getRessourceFilesList } from "api/ressources"

// Hooks
import { useTranslation } from "react-i18next"
import { useSearch } from "hooks/useSearch"
import { useMemo, useState, useCallback, Dispatch, SetStateAction, useEffect } from "react"
import { useRessource } from "hooks/useRessource"
import { useDebounce } from "hooks/useDebounce"
import { useFilterDates } from "hooks/useFilterDates"

function RessourceFilesList({limit=5, jobId, disableAddFile}:{limit?:number, jobId?:string, disableAddFile?:boolean}) {
    const { t, i18n } = useTranslation("common")
    const [fileList, setFileList] = useState<any[]>()
    const [query, setQuery] = useState<string>("")
    const { filter:filterDates, setFilter:setFilterDates } = useFilterDates()
    const [open, setOpen] = useState<boolean>(false)
    const debounceQuery = useDebounce(query, 500);
    const debounceFilterDates = useDebounce(filterDates, 500);
    const { resourceId } = useRessource()
    const params = useMemo(() => (
        {
            ressourceId: resourceId, 
            search: query,
            jobId,
            orderDirection: "desc",
            createdAfter: filterDates && utilsQuery.filterAfterDate(filterDates[0]),
            createdBefore: filterDates&& utilsQuery.filterBeforeDate(filterDates[1] || filterDates[0])
        }
    ), [debounceQuery, jobId, debounceFilterDates])
        
    const validateParams = useCallback(() => {
        if (!params.ressourceId) return false
        return true
    }, [])
    
    const fields = useMemo(() => (["id", "name", "num_bytes", "extension", "created_at", "obj_key"]), [])
    const handleResult = useCallback((result:any) => {
        setFileList(result.results || result)
    },[])

    const [,{ paging, setPage, loading, execute }] = useSearch(getRessourceFilesList, params, {onResult: handleResult, fields:fields, limit:limit, validateParams: validateParams})

    useEffect(() => {
        // Make sure to reset paging when query changes
        if (query || query == "" || filterDates[0] || filterDates[1]) {
            setPage(1)
        }
    }, [debounceQuery, debounceQuery])

    if (!fileList && loading) return <>
        <Skeleton className="h-36" />
    </>
    return <>
        <div className="flex flex-wrap items-end justify-between gap-y-2">
            <div className="flex flex-col items-start gap-x-3 gap-y-2">
                <PageTitle noMargin>{t("upload-file.recent-files")}</PageTitle>
                 {
                    filterDates && filterDates.length > 0 && 
                    <Pill theme="accent"><span className="whitespace-pre-wrap">{filterDates[0] && format.datetime(filterDates[0], i18n.language)}{filterDates[1] && " - "}</span>
                        <span>
                            {filterDates[1] && format.datetime(filterDates[1], i18n.language)}
                        </span>
                        <RemoveIcon onClick={()=>setFilterDates([])} className="ml-1 cursor-pointer"/>
                    </Pill>
                }
            </div>
            {!disableAddFile && <p className="flex items-center text-sm transition-colors disabled:hover:cursor-default hover:cursor-pointer text-container-foreground hover:text-gray-300" onClick={()=>setOpen(true)}><NewFileIcon className="mr-1.5 text-base shrink-0"/>{t("upload-file.new-file")}</p> }
        </div>
        
        <div className="flex flex-col mt-3 lg:flex-row gap-x-5 gap-y-8">
            <div className="w-full ">
                <SearchContainer loading={fileList && loading || false} placeholder={t("upload-file.search-file")} setQuery={setQuery}>
                    {
                        fileList && fileList.map(({id, name, numBytes, downloadUrl, createdAt, extension}) => {
                            return <FileRow key={'file-'+id} name={name} url={downloadUrl} size={numBytes} createdAt={createdAt} extension={extension} />
                        })
                    }
                    {
                        loading && !fileList && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
                    }
                    {
                        paging && paging.numResults === 0 && <ListEmptyRow />
                    }
                </SearchContainer>
                <Paginate {...paging} setPage={setPage} />

            </div>
        </div>
        
        <AddFilesModal open={open} setOpen={setOpen} reloadList={execute} />
    </>
}

export function FilterFiles({dates, setDates}:{dates:Date[], setDates:Dispatch<SetStateAction<Date[]>>}){
    const { t } = useTranslation("common")
    return <>
        <Container subtitle={t("filters")}>
            <div>
                <Calendar.CompactMonth select="range" selectedDates={dates} setSelectedDates={setDates} maxPickableDate={new Date()} />
            </div>
        </Container>
    </>
}

export function AddFilesModal({open, setOpen, reloadList}:{open:boolean, setOpen:Dispatch<SetStateAction<boolean>>, reloadList: () => void}){
    const { t } = useTranslation("common")
    const [files, setFiles] = useState<File[]>()
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const { ressource, loading:loadingRessource } = useRessource()

    const onUploadError = useCallback((e:any) => {
        toast.error(t("error-upload-file",{e}))
        setLoadingUpload(false)
        setOpen(false)
    },[])

    const onUploadSuccess = useCallback((r:any) => {
        toast.success(t("success-upload-file", {r}))
        setLoadingUpload(false)
        setOpen(false)
        reloadList()
    },[])

    const execute = () => {
        setLoadingUpload(true)
        if (files && files.length > 0 && ressource) {
            uploadRessourceFilesAsync({
                ressourceId: ressource.slug,
                files: files,
                onError: onUploadError,
                onResult: onUploadSuccess
            })
        }
    }
    return <>
        <Modal title={t("upload-file.title")} open={open} setOpen={setOpen} size="lg" onClose={()=>setFiles(undefined)}>
            {
                loadingRessource ? <>
                    <div className="flex items-center justify-center p-4 h-14"><Spinner/></div>
                </>
                :
                <>
                    <div className="mb-4">
                        <Uploader.DropZone multiple id="file-upload" files={files} setFiles={setFiles}  />
                    </div>
                    <Modal.Footer type="doubleButton">
                        <Button type="reset" theme="gray" onClick={()=>{setOpen(false); setFiles(undefined)}}>{t("cancel")}</Button>
                        <Button loading={loadingUpload} disabled={loadingUpload || loadingRessource} onClick={execute}>{t("save")}</Button>
                    </Modal.Footer> 
                </>
            }
        </Modal>
    </>
}

function FileRow({ name, size, url, createdAt, extension }: { name: string, size: number, url:string, createdAt:string, extension:string}) {
    const { i18n } = useTranslation()
    const [preview, setPreview] = useState<boolean>(false)

    return <>
        <div onClick={()=> setPreview(!preview)} className="block p-3.5 px-4 cursor-pointer hover:bg-background/30 group">
            <div className="flex items-center">
                <div className="flex items-center justify-between w-full p-1">
                    <div className="flex flex-col items-start justify-start sm:flex-row gap-x-2 sm:items-center">
                        <Link onClick={(e)=> e.stopPropagation()} target="_blank" to={url}><p className="pr-4 text-sm break-all hover:cursor-pointer hover:underline">{name}</p></Link>
                        <p className="text-sm text-container-foreground">{formatBytesToKBOrMB(size)}</p>
                    </div>
                    <div onClick={()=> setPreview(!preview)} className="flex items-center text-sm transition-colors shrink-0 hover:cursor-pointer text-container-foreground group-hover:text-white">
                        <p className="text-container-foreground">{format.datetime(new Date(createdAt || ""), i18n.language)}</p>
                        <ChevronDownIcon className={classNames("ml-1 text-lg mt-0.5 transition-transform", preview && "rotate-180")}/>
                    </div> 
                    
                </div>
                
            </div>
            <div className={classNames("transition-all overflow-hidden", preview? "":"h-0")}>
                {
                    preview && <FilePreview extension={extension} url={url} />
                }
            </div>
        </div>
    </>
}

export function FilePreview({extension, url, deleteFile}:{extension:string, url:string, deleteFile?:() => void}){
    const { t } = useTranslation("common")
    const isImg = useMemo(() => supportedFiles.images.includes(extension), [extension])
    const isText = useMemo(() => supportedFiles.text.includes(extension), [extension])
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    return <>
        <div className="py-4 cursor-default" onClick={(e)=>e.stopPropagation()}>
            {
                isImg && <Link onClick={(e)=>e.stopPropagation()} target="_blank" to={url}>
                    <img src={url} alt="preview" className="object-cover object-top w-full h-full" />
                </Link>
            }
            {
                isText && <object data={url} width="100%" className={classNames(extension === "pdf" ? "h-[400px]": "bg-gray-200 text-black rounded h-[400px]")}>
                    Object not supported.
                </object>
            }
            {
              
                !isImg && !isText && <div onClick={(e)=>e.stopPropagation()} className="flex flex-col items-center justify-center h-32 border rounded-sm gap-y-2 border-gray-500/20 bg-background ">
                    <span className="text-sm text-container-foreground">
                        {
                            t("upload-file.preview-unavailable")
                        }
                    </span>
                 
                </div>
            }
            
            <div className="flex justify-end mt-3">
                {
                    deleteFile && <Dialog open={openDelete} onOpenChange={setOpenDelete}>
                        <Button onClick={()=>setOpenDelete(true)} theme="textDestructive" className="mr-auto">{t("delete")}</Button>
                        <DialogContent>
                            <DialogHeader>
                                {t("delete-file.title")}
                            </DialogHeader>
                            <DialogDescription>
                                {t("delete-file.description")}
                            </DialogDescription>
                            <DialogFooter>
                                <DialogClose asChild>
                                    <Button theme="gray" className="w-full" onClick={()=>setOpenDelete(false)}>{t("cancel")}</Button>
                                </DialogClose>
                                <Button theme="alert" className="w-full" onClick={()=>{deleteFile(); setOpenDelete(false)}}>{t("delete")}</Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                }
                <DownloadFile url={url} />
            </div>
            
        </div>
    </>
}

export function DownloadFile({url}:{url:string}){
    const { t } = useTranslation("common")
    return <>
        <Link to={url} target="_blank" className="flex items-center transition-colors hover:text-primary-light">
            <DownloadIcon className="mr-1 text-lg" />
            <span>{
                t("upload-file.download-file")
            }</span>
        </Link>
    </>
}


function ListEmptyRow() {

    const { t } = useTranslation("common")

    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("upload-file.no-files-uploaded-yet")}
            </div>
        </div>
    </>
}

export const RessourceFiles = {
    List: RessourceFilesList,
    AddFilesModal: AddFilesModal,
    Filter: FilterFiles
}
