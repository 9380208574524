// Utils
import classNames from "classnames";

// Hooks
import { useState, useEffect } from "react";
import { useDebounce } from "hooks/useDebounce";

export function LoadingBar({loading}:{loading?:boolean}){
    const [isFull, setIsFull] = useState(false);
    const loadingDebounced = useDebounce(loading, 10)
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);
    
    useEffect(() => {
      
        let timer: NodeJS.Timeout;  
        if (loading) {
            // Start the loading animation
            setIsFull(false); // Reset to not full at the start of loading
        } else {
            // Loading is complete, so set the bar to full
            setIsFull(true);
            // Start the disappearing animation after a small delay to ensure the bar appears full
            timer = setTimeout(() => {
            setIsFull(false); // This will trigger the bar to fade out
            }, 1000); // The delay should match the duration of the "disappear" animation
        }
        return () => clearTimeout(timer); // Clear the timeout if the component unmounts or the effect re-runs
    
    }, [loadingDebounced]);
    return (
        <div className="w-full h-[1px] rounded overflow-hidden">
            <div
            className={
                classNames("h-full bg-primary-light transition-all duration-500 ease-out",
                isFull?"w-full":"w-0", 
                loading ? "loading-bar-animating" : isMounted ? "loading-bar-full": "")
            }></div>
        </div>
    );
}