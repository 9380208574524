// Components
import { Modal } from "components/core/modal"
import { Calendar } from "components/core/calendar"

// Hooks
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

export function FilterDatesModal({open, setOpen, dates, setDates}:{open:boolean, setOpen:Dispatch<SetStateAction<boolean>>,dates:Date[], setDates:Dispatch<SetStateAction<Date[]>>}){
    const { t } = useTranslation("common")
    return <>
        <Modal title={t("filter-by-dates")} open={open} setOpen={setOpen} size="sm">
            <div className="mt-2">
                <Calendar.CompactMonth date={dates.length > 0 ? dates[0] : undefined} select="range" selectedDates={dates} setSelectedDates={setDates} maxPickableDate={new Date()} />
            </div>
        </Modal>
    </>
}

export function FilterDates({dates, setDates}:{dates:Date[], setDates:Dispatch<SetStateAction<Date[]>>}){
    return <>
        <Calendar.CompactMonth date={dates.length > 0 ? dates[0] : undefined} select="range" selectedDates={dates} setSelectedDates={setDates} maxPickableDate={new Date()} />
    </>
}