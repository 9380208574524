// Hooks
import { useTranslation } from "react-i18next"
import { useActiveOrg } from "hooks/useActiveOrg"
import { useState } from "react"

// API
import { inviteUserToOrganization } from "api/organizations"
import { RadioGroup } from "components/core/radio/group"

// Utils
import { isEmail } from "utils/validate"

// Components
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { Skeleton } from "components/core/skeletons"
import { TextInput } from "components/core/inputs"
import { Text } from "components/core/typo"
import { useAPI } from "hooks/useAPI"
import { useAuth } from "hooks/useAuth"
import { CodeBlock } from "components/core/syntax-highlighter"
import { HiCheckCircle } from "react-icons/hi2";
import { Select } from "components/core/select"

// Constants
import roles from "constants/roles"

function InviteButton({onClick}:any){
    const { t } = useTranslation("common")

    return <Button onClick={onClick} className="w-full px-6 h-fit sm:w-fit">{t("invite-member")}</Button>
}

interface InviteModalProps {
    defaultOrg?: any
}
export function InviteModal({defaultOrg}:InviteModalProps){
    const { t } = useTranslation("common")
    const [open, setOpen] = useState(false)

    const {user} = useAuth()
    const {activeOrg,} = useActiveOrg()
    const [selectedOrg, setSelectedOrg] = useState(defaultOrg || activeOrg)
    const [email, setEmail] = useState("")

    const [role, setRole] = useState("member")

    const handleResult = ()=> {
        setEmail("")
        // setOpen(false)
    }

    const [result, {execute, loading, setResult}] = useAPI(inviteUserToOrganization, {email, orgId: selectedOrg && selectedOrg.slug, role: role}, { immediate: false, onResult: handleResult, onCatch: ()=> setOpen(false), errorToastMessage: t("error-invite"), successToastMessage: t("confirmation-invite")})

    const resetModal = (timeout = true) => {
        setTimeout(()=> {
            setEmail("")
            setSelectedOrg(defaultOrg || activeOrg)
            setResult(undefined)
        }, timeout ? 200 : 0)
    }

    const listOrgWhereAdmin = user && user.organizations.filter((o:any)=> o.access.role === "admin" || o.access.role === "dev")

    if (!user  || listOrgWhereAdmin.length <= 0) return null

    return <>
        <InviteButton onClick={()=> {resetModal(false); setOpen(true);}}/>
        <Modal open={open} setOpen={setOpen} title={t("invite-member")}>
            {result ?
                <>
                    <p className="text-gray-200"><HiCheckCircle className="inline text-purple-400 h-7 w-7"/> {t("confirmation-invite")}</p>
                    <Text.TitleSmall className="pb-2 mt-5 -mb-5 text-gray-200">{t("url-callback")}</Text.TitleSmall>
                    <CodeBlock language="bash" copyValue={result && result.callback}>
                        {t("url-callback-description")}
                    </CodeBlock>
                </>    
                :
                <>
                <div className="mt-2">
                    <TextInput trim defaultValue={email} onChange={setEmail} className="mb-3 " theme="outlined" label={t("email")} placeholder={t("email-placeholder-invite")} />
                </div>
                <Select label={t("role")} theme="outlined" options={Object.keys(roles)} toString={(v:string)=>t("roles."+v)} selected={role} setSelected={setRole} indexingKey={(v:any)=>v} />
                <Text.TitleSmall className="pb-2 mt-5 -mb-5 text-gray-200">{t("select-organization")}</Text.TitleSmall>
                <div className="mt-4 mb-2 overflow-y-auto max-h-48">
                    {!user ?
                        <Skeleton className="h-20"/>
                        :
                        <RadioGroup value={selectedOrg} values={ listOrgWhereAdmin || []} setValue={setSelectedOrg} indexingKey="slug" stringify={(d:any)=> d && d.name}/>
                    }
                </div>

                <Modal.Footer type="singleButton">
                    <Button onClick={execute} loading={loading} disabled={!isEmail(email) || !selectedOrg || loading}  className="">{t("send")}</Button>
                </Modal.Footer>
                </>
                }
        </Modal>
    </>
}

export const Invite = {
    Modal: InviteModal
}