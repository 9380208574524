
import { Navigate } from "react-router-dom"

import { routes, to } from "constants/routes"

import { useActiveOrg } from "hooks/useActiveOrg"
import { useAuth } from "hooks/useAuth"

export function Home(){
    const { activeOrg } = useActiveOrg()
    const { user, loading } = useAuth()
    if (user && !user.organizations && !activeOrg && !loading) return <Navigate to={routes.noOrganizations}></Navigate>
    if (!activeOrg) return <></>
    return <Navigate replace to={to.ressources(activeOrg.slug)}></Navigate>
}