// API
import { getAnnotationWorkspace } from "api/annotations"

// Hooks
import { Dispatch, SetStateAction, createContext, useCallback, useMemo, useState } from "react";
import { useSearch } from "hooks/useSearch";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const AnnotationWorkspaceContext = createContext<AnnotationWorkspaceResult>({
    workspace: {} as Workspace,
    setWorkspace: () => {},
    loading: false,
    setPage: () => {},
    paging: {}
});

export type AnnotationWorkspaceResult = {
    workspace: Workspace;
    setWorkspace: Dispatch<SetStateAction<Workspace>>;
    loading: boolean;
    setPage: Dispatch<SetStateAction<any>>;
    paging: any;
}

const AnnotationWorkspaceContextProvider = ({ children }:{children: React.ReactNode}) => {
   
    const { t } = useTranslation("common")
    const [workspace, setWorkspace] = useState<any>()
    const { ressource: ressourceParams } = useParams<{ressource:string}>()
    const params = useMemo(() => ({ressourceId: ressourceParams}), [])

    const handleResult = useCallback((result:any) => {
        setWorkspace(result)
    },[])

    const [,{loading, setPage, paging}] = useSearch(getAnnotationWorkspace, params, {fields: ["id", "description", "ressource", "input_extraction_script", "output_extraction_script"], onResult: handleResult, errorToastMessage: t("annotations.workspace.error-fetching")})
    
    return (
        <AnnotationWorkspaceContext.Provider value={{workspace, setWorkspace, loading, setPage, paging}}>
            {children}
        </AnnotationWorkspaceContext.Provider>
    );

};

export { AnnotationWorkspaceContext, AnnotationWorkspaceContextProvider };