import { fetchCall } from "api";

interface GetRessourcesParams extends SearchOptions {
    orgId: string;
}
export function getOrgRessources({ orgId, fields }: GetRessourcesParams) {
    const options = {
        method: "GET",
        endpoint: `/app/organization/${orgId}/ressources`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

export function getRessource({ ressourceId, fields }: { ressourceId: string, fields?: string[]}) {
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressourceId}`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

export function createRessource({ name, organization, url, githubUrl, description, type, metrics }: Ressource) {
    const options = {
        method: "POST",
        endpoint: `/app/ressources`,
        body: {
            name,
            type,
            organization,
            url,
            githubUrl,
            description,
            metrics
        }
    };
    return fetchCall(options);
}

export function editRessource({ slug, name, organization, url, type, githubUrl, description}: Ressource) {
    const options = {
        method: "PATCH",
        endpoint: `/app/ressources/${slug}`,
        body: {
            name,
            organization,
            url,
            githubUrl,
            type,
            description
        }
    };
    return fetchCall(options);
}

export function disableRessource({ ressource }: { ressource: string }) {
    const options = {
        method: "POST",
        endpoint: `/app/ressources/${ressource}/disable`
    };
    return fetchCall(options);
}

// Create a ressource metric
export function createRessourceMetric({ ressourceId, name, metricsType }: { ressourceId: string, name: string, metricsType: string }) {
    
    const options = {
        method: "POST",
        endpoint: `/app/ressources/${ressourceId}/metrics`,
        body: {
            name,
            metricsType
        }
    };
    return fetchCall(options);
}

// Disable a ressource metric
export function disableRessourceMetric({ ressourceId, metricName }: { ressourceId: string, metricName: string }) {
    const options = {
        method: "PATCH",
        endpoint: `/app/ressources/${ressourceId}/metrics/${metricName}`,
        body: {
            disabled: true
        }
    };
    return fetchCall(options);
}

export function getMetrics({ressource, fields}: {ressource: string, fields?: string[]}) {
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressource}/metrics`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

export function getMetricsData({ressource, start, end}: {ressource: string, start: any, end: any}) {
    const options = {
        method: "GET",
        endpoint: `/app/ressource/${ressource}/metrics-data?start=${start}&end=${end}`
    };
    return fetchCall(options);
}

// Get metrics summary
export function getMetricsSummary({ressource, start, end}: {ressource: string, start: string, end: string}) {
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressource}/metrics-summary`,
        query: {
            start,
            end
        }
    };
    return fetchCall(options);
}

interface incidentsCountParams {
    ressource: string,
    start: string,
    end: string,
    freq: "hour" | "day" | "week" | "month"
}
// Get incidents counts
export function getIncidentCounts({ressource, start, end, freq}: incidentsCountParams) {
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressource}/incident-counts?from_date=${start}&to_date=${end}&freq=${freq}`
    };
    return fetchCall(options);
}

export function getRessourceFilesList({ressourceId, jobId, page, limit, createdAfter, createdBefore, orderBy, orderDirection, fields, search}: {jobId: string, ressourceId: string, createdAfter:string, createdBefore:string, page: number, limit: number, orderBy: string, orderDirection: string, fields:string[], search: string}) {    
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressourceId}/files`,
        query: {
            jobId,
            page,
            limit,
            orderBy,
            orderDirection,
            createdAfter,
            createdBefore,
            search,
            fields
        }
    };
    return fetchCall(options);
}

// Post files to an ressource
export function createRessourceFileUploadURL({ ressourceId, files }: { ressourceId: string, files: { name: string, numBytes: number, extension: string }[] }) {
    const options = {
        method: "POST",
        endpoint: `/app/ressources/${ressourceId}/files`,
        body: {
            files: files,
        }
    };
    return fetchCall(options);
}