// API
import { getResourceJobs } from "api/jobs"

// Hooks
import { useMemo, useState, createContext, useCallback, Dispatch, SetStateAction} from "react";
import { useSearch } from "hooks/useSearch";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const SimilarJobsContext = createContext<Jobs>({});

export type Jobs= {
    jobs?: Job[];
    setJobs?: any;
    error?: any;
    loading?: boolean;
    paging?: any;
    setPage?: any;
    setQuery?: any;
    setStatus?: any;
    filter?:Filter;
    filters?:Filter[];
    defaultFilter?:Filter;
    setFilter?: Dispatch<SetStateAction<Filter>>;
    execute?: any;
}

export type Filter = 
    "newest" | 
    "oldest" 
export type OrderBy = "started_at";
export type OrderDirection = "asc" | "desc";

// Record of Filter, value is object with orderBy and orderDirection
export const filterDict:Record<Filter, {orderBy:OrderBy, orderDirection:OrderDirection}> = {
    "newest": {orderBy: "started_at", orderDirection: "desc"},
    "oldest": {orderBy: "started_at", orderDirection: "asc"}
}

export const filters:Filter[] = Object.keys(filterDict) as Filter[]

export function filterHandler(filter:Filter):{orderBy:OrderBy, orderDirection:OrderDirection}{
    return filterDict[filter]
}

export const defaultFilter:Filter = "newest"

const SimilarJobsContextProvider = ({name, excludeId, children }:{name?: string, excludeId?: string, children: React.ReactNode}) => {
    const { t } = useTranslation("common")
    const { ressource: ressourceParams } = useParams<{ressource:string}>();

    const [ query, setQuery] = useState<string>("")
    const debounceQuery = useDebounce(query, 500);
    const [ filter, setFilter] = useState<Filter>(defaultFilter)
    

    const handleFilter = useCallback(():{orderBy:OrderBy, orderDirection:OrderDirection} => {
        return filterHandler(filter)
    },[filter])

    const params = useMemo(() => ({
        ressourceId:ressourceParams,
        name,
        orderBy: handleFilter().orderBy,
        orderDirection: handleFilter().orderDirection,
    }), [debounceQuery,handleFilter, name])
    
    const fields = useMemo(() => (["id","started_at", "ended_at", "name", "status", "description", "rank"]), [])
    
    const [allJobs, {execute, loading, paging, setPage, setResult:setJobs, error}] = useSearch<Job>(getResourceJobs, 
        params, {limit:10,
             fields, 
             validateParams: (({name}: {name?: string})=>name?true:false),
             errorToastMessage: t("error-try-again")} )
    
    const jobs = useMemo(() => {
        if (!allJobs) return
        return allJobs.filter((job:Job) => job.id !== excludeId)
    }, [allJobs, excludeId])
    
    return (
        <SimilarJobsContext.Provider value={{execute, jobs, setJobs, error, paging, setPage, setQuery, filter, setFilter, filters, defaultFilter, loading}}>
            {children}
        </SimilarJobsContext.Provider>
    );

};

export { SimilarJobsContext, SimilarJobsContextProvider };