
import { useContext } from "react";
import { NotificationBarContext } from "contexts/notification-bar";

/**
 * Custom hook that provides access to the notification bar context.
 * @returns The notification bar context.
 * @throws {Error} If used outside of a NotificationProvider.
 */
export const useNotificationBar = () => {
    const context = useContext(NotificationBarContext);
    if (!context) {
      throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
  };

