import { ForgotPasswordForm } from "components/auth/forms/forgot-password-form"
import { Layout } from "layouts"
import { Logo } from "components/tools/logo"
export function ForgotPassword(){

    return <>
        <Layout.Full center>
            <div>
                <Logo.Xl className="w-64 mb-12" />
                <ForgotPasswordForm className="w-screen px-4 sm:w-[480px]" />
            </div>
        </Layout.Full>
    </>
}