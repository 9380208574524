import { BrowserRouter, Route, Routes } from "react-router-dom";

// Components
import { AuthGuard } from 'components/auth/authguard';
import { OrgGuard } from 'components/auth/orgguard';
import { Dashboard } from 'components/dashboard/dashboard';
import { AuthContextProvider } from 'contexts/auth';
import { DashboardContexts } from 'components/dashboard/dashboard-contexts';
import { Toaster } from 'react-hot-toast';
import { Outlet } from "react-router-dom";
import ErrorBoundary from "components/core/errors/error-boundary";
import { SetActiveOrgFromUrl } from "components/organizations/set-active-org"

// Constants
import { toastOptions, toastPosition } from 'constants/toasts';
import { routes } from 'constants/routes';

// Contexts
import { RessourcesContextProvider } from "contexts/ressources";
import { RessourceContextProvider } from "contexts/ressource";
import { IncidentsContextProvider } from "contexts/incidents";
import { IncidentContextProvider } from "contexts/incident";
import { IncidentMessagesContextProvider } from "contexts/incident-messages";
import { AnnotationHistoryContextProvider } from "contexts/annotation-history";
import { AnnotationsContextProvider } from "contexts/annotations";
import { AnnotationWorkspaceContextProvider } from "contexts/annotation-workspace";
import { OrgApiKeysContextProvider } from "contexts/api-keys"

// Pages
import { Home } from 'pages/home/home';
import { Login } from 'pages/auth/login';
import { Logout } from 'pages/auth/logout';
import { ForgotPassword } from 'pages/auth/forgot-password';
import { ResetPassword } from 'pages/auth/reset-password';
import { Settings } from 'pages/account/settings';
import { Organizations } from 'pages/organizations';
import { Ressources } from 'pages/ressources';
import { Incidents } from "pages/incidents";
import { Signup } from "pages/auth/signup";
import { Annotations } from "pages/annotations";
import { Jobs } from "pages/jobs";
import { ChangelogPage } from "pages/changelog";
import { Error404 } from 'pages/errors/404';
import { RessourceGuard } from "components/auth/ressourceguard";
import { JobContextProvider } from "contexts/job";
import { ModelsContextProvider } from "contexts/models";
import { ModelsPage } from "pages/models";
import { ModelContextProvider } from "contexts/model";
import { ModelPage } from "pages/models/details";

function App() {
  return (<>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AuthContextProvider>
                <Routes>
                  <Route path={routes.login} element={<Login />} />
                  <Route path={routes.signup} element={<Signup/>} />
                  <Route path={routes.forgotPassword} element={<ForgotPassword />} />
                  <Route path={routes.resetPassword} element={<ResetPassword />} />

                  <Route path={routes.logout} element={<AuthGuard><Logout /></AuthGuard>} />
                  <Route path={routes.home} element={<ErrorBoundary><AuthGuard><DashboardContexts><Dashboard/></DashboardContexts></AuthGuard></ErrorBoundary>}>
                      <Route index element={<Home />} />
                      <Route path={routes.noOrganizations} element={<Organizations.NoOrganizations/>} />
                      
                      <Route path=":org" element={<OrgGuard><SetActiveOrgFromUrl><Outlet/></SetActiveOrgFromUrl></OrgGuard>}>
                        <Route path={routes.ressources} element={<RessourcesContextProvider><Outlet/></RessourcesContextProvider>} >
                          <Route index element={<Ressources.All />}/>
                          <Route path={routes.createRessource} element={<Ressources.Create />} />
                          <Route path={":ressource"} element={<RessourceContextProvider><Ressources.Header/></RessourceContextProvider>}>
                            <Route index element={<Ressources.Details />} />
                            <Route path={routes.editRessource} element={<Ressources.Edit />} />
                            <Route path={routes.ressourceDocumentation} element={<Ressources.Documentation />} />
                            <Route path="jobs">
                              <Route path={":job"} element={<JobContextProvider><Outlet/></JobContextProvider>}>
                                <Route index element={<Jobs.Details />} />
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                        <Route path={routes.incidents} element={<IncidentsContextProvider><Outlet/></IncidentsContextProvider>}>
                          <Route index element={<Incidents.List />} />
                          <Route path={":incident"} element={<IncidentContextProvider><IncidentMessagesContextProvider><Incidents.Details/></IncidentMessagesContextProvider></IncidentContextProvider>}/>
                        </Route>

                        <Route path={"models"} element={<ModelsContextProvider><Outlet/></ModelsContextProvider>}>
                          <Route index element={<ModelsPage/>} />
                          <Route path={":model"} element={<ModelContextProvider><ModelPage/></ModelContextProvider>}/>
                        </Route>

                        <Route path={routes.annotations} element={<AnnotationHistoryContextProvider><RessourceContextProvider><Outlet/></RessourceContextProvider></AnnotationHistoryContextProvider>} >
                          <Route index element={<Annotations.ListWorkspaces/>} />
                          <Route path={":ressource"} element={<RessourceGuard><AnnotationWorkspaceContextProvider><AnnotationsContextProvider><Outlet/></AnnotationsContextProvider></AnnotationWorkspaceContextProvider></RessourceGuard>}>
                            <Route index element={<Annotations.List/>} />
                            <Route path=":annotation" element={<Annotations.Details/>} />
                          </Route>
                        </Route>
                      </Route>

                      <Route path={routes.organizations} >
                        <Route index element={<Organizations.List />} />
                        <Route path=":org" element={<OrgGuard><Outlet/></OrgGuard>}>
                          <Route index element={<OrgGuard><Organizations.Details /></OrgGuard>} />
                          <Route path={routes.manageOrgAccess} element={<OrgGuard><OrgApiKeysContextProvider><Organizations.Manage /></OrgApiKeysContextProvider></OrgGuard>} />
                        </Route>
                      </Route>

                      <Route path={routes.account} element={<Settings/>} />

                      <Route path={routes.changelog} element={<ChangelogPage/>} />
                  </Route>
                  <Route path="/404" element={<Error404/>}/>
                  <Route path="/*" element={<Error404/>}/>
                </Routes>
        </AuthContextProvider>
      </BrowserRouter>
      <Toaster position={toastPosition} 
        toastOptions={toastOptions} />
  </>
  );
}

export default App;
