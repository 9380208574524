

// Constants
import { routes } from "constants/routes"

// Components
import { RoleGuard } from "components/auth/roleguard"
import { Manage } from "components/organizations/manage"
import { Text } from "components/core/typo"
import { Invite } from "components/organizations/invite"
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs"
import { Keys } from "components/organizations/api-keys"
import { Section } from "components/core/section"
import { Payments } from "components/organizations/payments"

// Icons
import { GoPasskeyFill as KeyIcon } from "react-icons/go";

// Hooks
import { useAuth } from "hooks/useAuth"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useState } from "react"


export function ManageOrganization(){

    const { t } = useTranslation("common")

    const { user } = useAuth()
    const { org: orgParams } = useParams()
    const [open, setOpen] = useState(false)

    const org = user && user.organizations && user.organizations.find((o:any) => o.slug && (o.slug === orgParams))
    const crumbs:Crumb[] = [
        {
            name: t("organizations"),
            path: routes.organizations
        },
        {
            name: t("settings"),
            path: ""
        }
    ]
    return <>
        <RoleGuard userRole={org.access.role} requiredRole="admin">
            <Breadcrumbs crumbs={crumbs} />
            <div className="flex flex-wrap items-center justify-between mb-4 gap-y-4">
                <Text.PageTitle noMargin className="pr-2">{t("manage-org-title", {name: org.name})}</Text.PageTitle>
                <Invite.Modal defaultOrg={org}/>
            </div>

            <div className="mt-10">
                <Section.Blank  title={t("members")} description="">
                    <Manage.Access/>
                </Section.Blank>
            </div>
            <div className="mt-10">
                <Section.Blank title={t("api-keys")}>
                    <div className="flex justify-end">
                        <p onClick={()=>setOpen(true)} className="flex items-center mb-3 text-sm transition-colors disabled:hover:cursor-default hover:cursor-pointer text-container-foreground hover:text-gray-300">
                            <KeyIcon className="mr-1.5 text-lg shrink-0"/>{t("create-api-key.title-short")}
                        </p> 
                    </div>
                    <Keys.List/>
                </Section.Blank>
            </div>
            <div className="mt-10 mb-10">
                <Section.Blank title={t("payments-settings.title")}>
                    <Payments.Settings/>
                </Section.Blank>
            </div>
            <Keys.CreateKeyDialog open={open} setOpen={setOpen}/> 
        </RoleGuard>
    </>
}