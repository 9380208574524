import { useContext } from "react";
import { JobContextResult, JobContext } from "contexts/job";

/**
 * @example const {job} = useJob(); 
 * @description This hook returns a Job from the JobContext
 * @returns {Job} Job {Job}
 */
export function useJob():JobContextResult{
    return useContext(JobContext);
}

