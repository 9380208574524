
// Components
import { Text } from "components/core/typo"
import { CodeBlock } from "components/core/syntax-highlighter"
import { Link } from "react-router-dom"
import { Skeleton } from "components/core/skeletons"

// Constants
import { routes } from "constants/routes"

// Utils
import { isDefaultMetric } from "components/ressources/metrics/list"

// API
import { getMetrics } from "api/ressources"

// Hooks
import { useTranslation } from "react-i18next"
import { useChildrenIndexFromClass } from "hooks/useChildrenIndexFromClass"
import { useRessource } from "hooks/useRessource"
import { useEffect, useState, useMemo, useCallback } from "react"
import { useSearch } from "hooks/useSearch"

const installHectiqConsole = `
pip install hectiq-console
`.trim()
const integrateFastAPI = function({ressource, metrics}: {ressource: string, metrics?: Metric[] } ){
    const customMetricsDict = metrics?.map(({slug, metricsType}) => `'${slug}': '${metricsType}'`).join(",\n ") || ""
    return `
from fastapi import FastAPI, Request
from hectiq_console import HectiqConsoleStarletteMiddleware, store_metrics

app = FastAPI(title="Example")

# Declare your custom metrics types
custom_metrics = {${customMetricsDict}}

# Add your middleware. It tracks the latency and the number of requests. 
app.add_middleware(HectiqConsoleStarletteMiddleware, 
                    custom_metrics=custom_metrics,
                    ressource="${ressource}")

@app.get("/predict")
async def root(request: Request):
    return {"message": "✅ This route is monitored by the hectiq console."}
`.trim()}

const integrateMetricsFastAPI = function({slug, name}: {slug: string, name?: string}){
    return `
@app.get("/predict")
def route(request: Request):
    # Add ${name}
    store_metrics(request=request, key="${slug}", value=random.random())
`.trim()}

function StarletteIntegration({loading, metrics}:{loading?: boolean, metrics?: Metric[]}){
    const { t } = useTranslation("docs")
    const { ressource } = useRessource();
    const integrationCode = integrateFastAPI({ressource: ressource?.slug, metrics});

    if (loading && !metrics) return <>
        <Skeleton className="h-20 mt-5"/>
    </>

    return <StepsContainer>

                <Step title={t("install-hectiq-console.title")} description={t("install-hectiq-console.description")}>
                <CodeBlock language="bash" copyValue={installHectiqConsole}>
                        {installHectiqConsole}
                </CodeBlock>
                </Step>

                <Step title={t("integration-fastapi.title")} 
                        description={t("integration-fastapi.description-1")}
                        postDescription={t("integration-fastapi.description-2")}>
                <CodeBlock language="python" copyValue={integrationCode}>
                        {integrationCode}
                </CodeBlock>
                </Step>
                
            </StepsContainer>
}

export function Integration(){
    const { t } = useTranslation("docs")
    const { ressource } = useRessource();

    const fields = useMemo(() => ["name", "type", "slug"], []);
    const paramsMetrics = useMemo(()=> ({
        ressource: ressource?.slug,
        fields: ["name", "type", "slug"]
    }),[ressource])
    
    const validateParams = useCallback((params:any) => {
        return params.ressource !== undefined
    }, [])

    const [metrics, {loading}] = useSearch(getMetrics, paramsMetrics, {fields, immediate: true, validateParams })
    const customMetrics = useMemo(() => metrics?.filter((metric:Metric) => !isDefaultMetric(metric)), [metrics])

    if (!ressource) return <></>
    return <>
        <Text.PageTitle>{t("client-installation.title")}</Text.PageTitle>
        <code>{ressource.slug}</code>
        <Link to={routes.pypiClient} target="_blank" className="block mb-1 w-fit text-primary-light hover:underline">
            {t("view-pypi-documentation")}
        </Link>
        <Link to={routes.documentation} target="_blank" className="block mb-1 w-fit text-primary-light hover:underline">
            {t("view-documentation")}
        </Link>
        <p>
            {t("client-installation.description", {ressource: ressource?.name})}
        </p>
        
        <StarletteIntegration metrics={customMetrics} loading={loading}/>

        <div className="mt-10">
            <MetricsIntegration loading={loading} metrics={customMetrics} />
        </div>
    </>
}

function MetricsIntegration({loading, metrics}:{loading?: boolean, metrics?: Metric[]}){
    const { t } = useTranslation("docs");
    
    if (loading && !metrics) return <>
        <Skeleton className="h-20 mt-5"/>
    </>
    if (metrics && metrics.length === 0 && !loading) return <></>
    return <>
        <Text.TitleSmall>{t("available-metrics.title")}</Text.TitleSmall>
        <p>
            {t("available-metrics.description")}
        </p>
        
        <div className="flex gap-y-5 flex-col mt-3">
            {
                metrics && metrics.map(({slug, name}) => {
                    return <MetricContainer key={slug}>
                        <CodeBlock title={name} copyValue={integrateMetricsFastAPI({name, slug: slug || ""})}>
                            {integrateMetricsFastAPI({name, slug: slug || ""})}
                        </CodeBlock>
                    </MetricContainer>
                })
            }
        </div>
    </>
}

function MetricContainer({children}:{children: React.ReactNode}){
    return <div>
        {children}
    </div>
}

export function Step({children, title, description, postDescription}:{children: React.ReactNode, title?: string, description?: string, postDescription?: string}){
    const {elementRef, getIndex} = useChildrenIndexFromClass("steps-container")
    const [index, setIndex] = useState<number>()
    useEffect(() => {
        const i = getIndex()
        setIndex(i !== undefined ? i+1 : undefined)
    }, [])
    return <div ref={elementRef} className="relative pb-5 pl-8 text-sm border-l-2 border-container-light">
        <div className="absolute left-0 flex items-center justify-center text-sm -translate-x-1/2 rounded-full w-7 aspect-square bg-container-light">
            {index}
        </div>
        {
            title && <div className="mb-4 text-lg font-semibold">
                {title}
            </div>
        }
        {
            description && <div className="mb-4">
                {description}
            </div>
        }
        {children}
        {
            postDescription && <div className="mt-4">
                {postDescription}
            </div>
        }
    </div>
}

export function StepsContainer({children}:{children: React.ReactNode}){
    return <div className="px-4 mt-10 steps-container">
        {children}
    </div>
}
