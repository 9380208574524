
import { json } from '@codemirror/lang-json';
import { javascript } from '@codemirror/lang-javascript';
import CodeMirror from '@uiw/react-codemirror';
import { atomone } from '@uiw/codemirror-theme-atomone' 
import { nord } from '@uiw/codemirror-theme-nord'
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { StreamLanguage } from '@codemirror/language';
import { shell } from '@codemirror/legacy-modes/mode/shell';

interface EditorProps {
  value: string;
  className?: string;
  editable?: boolean;
  language?: keyof typeof languages;
  theme?: keyof typeof themes
}

const languages = {
  json: json(),
  javascript: javascript(),
  shell: StreamLanguage.define(shell),
}

const themes = {
  'nord': nord,
  'atomone': atomone,
  'vscode-dark': vscodeDark,
  'sublime': sublime
}

export function Editor({value = "", className, editable=false, language="json", theme}:EditorProps) {

  return <>
    <CodeMirror className={className} 
            editable={editable} 
            theme={theme? themes[theme] : "dark"} 
            value={value} 
            extensions={[languages[language]]} />
  </>
}
