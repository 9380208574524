import { useContext } from "react";
import { ActiveOrg, ActiveOrgContext } from "contexts/active-org";

/**
 * @example const {activeOrg, setActiveOrg} = useActiveOrg(); 
 * @description This hook returns the ActiveOrg object from the ActiveOrgContext
 * @returns {ActiveOrg} Active org object [org, setOrg]
 */
export function useActiveOrg():ActiveOrg{
    return useContext(ActiveOrgContext);
}

