// API
import { disableRessource } from "api/ressources"

// Components
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { Section } from "components/core/section"

// Constants
import { to } from "constants/routes"

// Hooks
import { useAPI } from "hooks/useAPI"
import { useTranslation } from "react-i18next"
import { useCallback, useMemo, useState } from "react"
import { useRessource } from "hooks/useRessource"
import { useNavigate } from "react-router-dom"
import { useActiveOrg } from "hooks/useActiveOrg"
import { useRessources } from "hooks/useRessources"

export function Disable(){

    const { t } = useTranslation("common")
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const { activeOrg } = useActiveOrg()
    const { ressource } = useRessource()
    const { setRessources } = useRessources()

    const params = useMemo(()=>({ressource: ressource?.slug}), [ressource])

    const handleResult = useCallback(() => {
        setOpen(false)
        setRessources?.((prev:Ressource[])=>prev?.filter((r:Ressource)=>r.slug !== ressource?.slug))
        navigate(to.ressources(activeOrg?.slug))
    }, [activeOrg])

    const [, {execute, loading}] = useAPI(disableRessource, params, 
        {
            immediate: false, 
            onResult: handleResult,
            onCatch: ()=>setOpen(false),
            successToastMessage: t("delete-ressource.success", {name: ressource?.name}),
            errorToastMessage: t("delete-ressource.error", {name: ressource?.name})
        })

    return <>
    <div className="mt-16">
        <Section.Contained title={t("delete-ressource.title-short")}>
            <div className="flex flex-col items-center sm:flex-row gap-y-4 sm:justify-between">
                <p className="text-container-foreground">{t("delete-ressource.warning", {name: ressource?.name})}</p>
                <Button theme="alertOutlined" className="w-full px-6 sm:w-44" onClick={()=>setOpen(true)}>{t("delete")}</Button>
            </div>
            
        </Section.Contained>
    </div>

    <Modal title={t("delete-ressource.title")} open={open} setOpen={setOpen}>
        <Modal.Body>
            <p>{t("delete-ressource.description", {name: ressource?.name})}</p>
        </Modal.Body>

        <Modal.Footer type="doubleButton">
            <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
            <Button theme="alert" loading={loading} onClick={execute}>{t("delete")}</Button>
        </Modal.Footer>
    </Modal>
    </>
}