// Utils
import { uploadFile } from "utils/upload"

// API
import { createIncidentFileUploadURL } from "api/incidents"

interface UploadIncidentProps {
    incidentId: string,
    files: File[],
    messageId?: string,
    onError?: (e: any) => void,
    onResult?: (r?: any) => void
    onFinally?: () => void
}

export const uploadIncidentFilesAsync = async ({ incidentId, files, messageId, onError, onResult, onFinally }: UploadIncidentProps) => {
    const getFileExtension = (fileName: string) => {
        const match = fileName.match(/\.([a-zA-Z0-9]+)$/);
        return match ? match[1] : "";
    };

    const filesToGenerate = files.map((file) => ({
        name: file.name,
        numBytes: file.size,
        extension: getFileExtension(file.name),
    }));

    const [createUploadPromise] = createIncidentFileUploadURL({ incidentId, messageId: messageId, files: filesToGenerate })
    createUploadPromise.then((result: any) => {
        const promises: Promise<unknown>[] = []
        result.map((r: any, i: number) => {
            return r?.uploadPolicy?.policy?.url && i < files?.length? promises.push(uploadFile(r.uploadPolicy.policy.url, files[i], r?.uploadPolicy?.policy?.fields)) : null
        })
        return Promise.all(promises)
    }).then((result: any) => {
        if (onResult) onResult(result)
    }).catch((e: any) => {
        if (onError) onError(e)
    }).finally(() => {
        if (onFinally) onFinally()
    })

}