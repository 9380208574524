// API
import { getModel } from "api/models"

// Components
import { Navigate } from "react-router-dom"

// Constants
import { routes } from "constants/routes"

// Hooks
import { useMemo, useState, createContext, useCallback} from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "hooks/useAPI";

const ModelContext = createContext<ModelContextResult>({});

export type ModelContextResult= {
    model?: Model;
    modelId?: string;
    setModel?: any;
    error?: any;
    loading?: boolean;
    reload?: any;
}

const ModelContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { model:modelParams } = useParams<{model:string}>()

    const [model, setModel] = useState<Model>()

    const validateParams = useCallback(() => {
        if (!modelParams) return false
        return true
    }, [])

    const params = useMemo(() => {
        return {
            modelId: modelParams, 
            fields: 
            [
                "id", 
                "name", 
                "version",
                "total_bytes",
                "num_files",
                "files",
                "downloads_timeseries",
                "metadata",
                "created_at",
                "description",
                "disabled",
                "readme"
            ]
        }
    }, [modelParams])
    
    const [, { loading, error, execute}] = useAPI(getModel, params, {validateParams, onResult: setModel})
    if (error) return <Navigate to={routes.notFound} replace></Navigate>
    
    return (
        <ModelContext.Provider value={{model, modelId: modelParams, setModel, error, loading, reload: execute}}>
            {children}
        </ModelContext.Provider>
    );
    
};

export { ModelContext, ModelContextProvider };