import { useState } from "react"

// hooks
import { useTranslation } from "react-i18next"
import { useAuth } from "hooks/useAuth"
import { useAPI } from "hooks/useAPI"
import { useCallback } from "react"

// API
import { login } from 'api/auth';

// Utils
import classNames from "classnames"

// Components
import { TextInput } from "components/core/inputs"
import { Button } from "components/core/buttons"
import { InternalLink } from "components/core/internalLink"
import { Text } from "components/core/typo"
import { Navigate, useSearchParams } from "react-router-dom"

// Constants
import { routes } from "constants/routes"

interface LoginFormProps {
    className?: string,
    props?: any
}

interface Form {
    email: string,
    password: string
}

export function LoginForm({className, props}: LoginFormProps){
    const {t} = useTranslation("common")
    const [form, setForm] = useState<Form>({
        email: "",
        password: ""
    })
    const { setUser }  = useAuth()
    const [searchParams] = useSearchParams();

    const [result, {loading, error, execute}] = useAPI(login, form,
        { onResult: (({user}:any)=>{user && setUser && setUser(user)}),
        immediate: false});

    const onSubmit = useCallback((e:Event)=>{
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    if (result){
        if (searchParams.get("ref")){
          return <Navigate to={searchParams.get("ref") || ""} state={result}/>
        }
        return <Navigate to={routes.home} state={result}/>
    }

    return <form className={classNames(className)} onSubmit={onSubmit} {...props}>
        {error && <Text.Error className="mb-3" {...error}></Text.Error>}
        <TextInput className="remove-autofill" onChange={(value:string)=> setForm((d)=>({ ...d, email:value}))} label={t("email")} placeholder={t("email-placeholder")} theme="default" />

        <div className="mt-5">
            <TextInput className="remove-autofill" onChange={(value:string)=> setForm((d)=>({ ...d, password:value}))} label={t("password")} type="password" placeholder={t("password")} theme="default" />
        </div>
        <div className="mt-7">
            <Button className="w-full" disabled={form.email.trim().length === 0 || form.password.trim().length === 0} loading={loading} type="submit">{t("connect")}</Button>
        </div>
        <InternalLink to={routes.forgotPassword} className="mt-5">{t("forgot-password")}?</InternalLink>

    </form>
}