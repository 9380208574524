// Constants
import { routes } from "constants/routes"

// Components
import { Spinner } from "components/core/spinner"
import { Navigate } from "react-router-dom"

// Hooks
import { useAuth } from "hooks/useAuth"
import { useParams } from "react-router"
import { useMemo } from "react"

interface OrgGuardProps {
    org?: string,
    children: any
}

export function OrgGuard({org, children}: OrgGuardProps){

    const { user } = useAuth()

    const { org: orgParams } = useParams()

    const orgAccess = useMemo(() => {
        return ((user.organizations && user.organizations.find((o:any) => o.slug === org || o.slug === orgParams)) || false)
    }, [user, org, orgParams])

    if (!user || orgAccess === undefined) return <>
        <div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div>
    </>

    if (!orgAccess) return <Navigate to={routes.notFound} replace></Navigate>
    return <>
        {children}
    </>

}