import { createContext, useMemo } from "react";

// Hooks
import { useLocalStorage } from "hooks/useLocalStorage";

// API
import { useAuth } from "hooks/useAuth";
import { useParams } from "react-router-dom";

const ActiveOrgContext = createContext<ActiveOrg>({});

export type ActiveOrg = {
    activeOrg?: any;
    orgParams?: string;
    setActiveOrg?: (org: string) => void;
}

const findOrgBySlug = (orgs: any[], slugToFind: string) => {
    return orgs.find(({slug}) => slug === slugToFind)
}

const ActiveOrgContextProvider = ({ children }:{children: React.ReactNode}) => {
   
    const { org: orgParams } = useParams<{org:string}>();

    const [activeOrgStorage, setActiveOrgStorage] = useLocalStorage("activeOrg");

    const { user } = useAuth()
    const orgs = user && user.organizations

    const activeOrg = useMemo(()=> {
        if (!orgs) return
        if (activeOrgStorage) {
            const org = findOrgBySlug(orgs, activeOrgStorage)
            if (org) return org
            else {
                setActiveOrgStorage(orgs[0].slug)
                return orgs[0]
            }
        }
        else if (orgs.length > 0) {
            return orgs[0]
        }
    },[activeOrgStorage, orgs])

    return (
        <ActiveOrgContext.Provider value={{activeOrg, orgParams, setActiveOrg: (org:any)=> setActiveOrgStorage(org.slug)}}>
            {children}
        </ActiveOrgContext.Provider>
    );

};

export { ActiveOrgContext, ActiveOrgContextProvider };