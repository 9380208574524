// Components
import { SearchContainer } from "components/core/container";
import { JobRow } from "components/jobs/list";
import { Spinner } from "components/core/spinner";
import { Paginate } from "components/core/paginate";
import { TitleMedium } from "components/core/typo";

// Hooks
import { useSimilarJobs } from "hooks/useSimilarJobs";
import { useTranslation } from "react-i18next";


function ListEmptyRow() {
    const { t } = useTranslation("common")
    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("no-similar-jobs")}
            </div>
        </div>
    </>
}

export function SimilarJobList(){
    const { t } = useTranslation("common")
    const { jobs, filter:selectedFilter, setFilter:setSelectedFilter, filters, loading, paging, setPage, setQuery } = useSimilarJobs()

    return <>
                <TitleMedium className="py-2">{t("similar-jobs")}</TitleMedium>
        <div className="flex flex-col mt-3 lg:flex-row gap-x-5 gap-y-8">
            <div className="w-full">
                <SearchContainer loading={jobs && loading || false} 
                        placeholder={t("search-jobs")} 
                        filters={filters}
                        noSearch={true}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={(v:any)=>{setSelectedFilter && setSelectedFilter(v); setPage(1)}}
                        setQuery={setQuery}>
                    {
                        jobs && jobs.map((job: Job) => {
                            return <JobRow 
                                key={'job-'+job.id}
                                {...job}/>
                        })
                    }
                    {
                        loading && !jobs && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
                    }
                    {
                        paging && paging.numResults === 0 && <ListEmptyRow />
                    }
                </SearchContainer>
                <Paginate {...paging} setPage={setPage} />
            </div>
        </div>
        </>
}