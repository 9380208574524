// Components
import { Text } from "components/core/typo"
import { ChildrenMenu, ChildrenStyles } from "components/core/moreMenu/children-Menu"
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { Link } from "react-router-dom"

// Utils
import classNames from "classnames"
import { utilsDict } from "utils/dict"

// Icons
import { LuSettings as EditIcon } from "react-icons/lu"
import { HiOutlineClipboardDocumentList as IntegrationIcon } from "react-icons/hi2"

// Constants
import { to } from "constants/routes"

// API
import { updateAnnotationWorkspace } from "api/annotations"

// Hooks
import { useAnnotationWorkspace } from "hooks/useAnnotationWorkspace"
import { useTranslation } from "react-i18next"
import { useState, Dispatch, SetStateAction, useCallback, useMemo } from "react"
import { TextInput } from "components/core/inputs"
import { useAPI } from "hooks/useAPI"
import { useParams } from "react-router-dom"

export function WorkspaceInfos(){
    const { t } = useTranslation("common")
    const { workspace, setWorkspace } = useAnnotationWorkspace()
    const [ openSettings, setOpenSettings ] = useState(false)
    const { org:orgParams } = useParams()

    if (!workspace) return <></>
    return <>
        <div className="flex justify-end -mb-4 sm:hidden">
            <ChildrenMenu>
                    <div onClick={()=>setOpenSettings(true)} className={classNames(ChildrenStyles)}><EditIcon className="mr-2 mt-0.5"/><span className="text-sm">{t("settings")}</span></div>
            </ChildrenMenu>
        </div>
        
        <div className="flex items-center justify-between">
            <Text.PageTitle>{workspace?.ressource? workspace?.ressource?.name : t("annotations.workspace.title")}</Text.PageTitle>
            <div className="hidden sm:block">
                <ChildrenMenu className="p-1 text-sm">
                    <div onClick={()=>setOpenSettings(true)} className={classNames(ChildrenStyles)}><EditIcon className="mr-2 mt-0.5"/><span className="text-sm">{t("settings")}</span></div>
                    <Link to={to.ressourceDocumentation(orgParams || "", workspace?.ressource?.slug || "")}>
                        <div className={ChildrenStyles}>
                            <IntegrationIcon className="mr-2 text-lg" />
                            <span>{t("documentation")}</span>
                        </div>
                    </Link>
                </ChildrenMenu>
            </div>
        </div>
            
        
        <div className="flex gap-y-1.5 flex-col mb-10 text-container-foreground">
            <div>
                {workspace.description}
            </div>
        </div>
        <SettingsModal open={openSettings} setOpen={setOpenSettings} workspace={workspace} setWorkspace={setWorkspace}/>
    </>
}

interface SettingsModalProps {
    open:boolean
    setOpen:Dispatch<SetStateAction<boolean>>
    workspace: Workspace
    setWorkspace:Dispatch<SetStateAction<Workspace>>
}

function SettingsModal({open, setOpen, workspace, setWorkspace}:SettingsModalProps){
    const { t } = useTranslation("common")
    const [newWorkspace, setNewWorkspace] = useState<Workspace>(workspace)
    
    const handleResult = useCallback(() => {
        setWorkspace((utilsDict.trimAllDictValues(newWorkspace)))
        setOpen(false)
    }, [newWorkspace])
    
    const isDifferent = useMemo(() => {
        return !utilsDict.deepCompareWithJSON(utilsDict.trimAllDictValues(workspace), utilsDict.trimAllDictValues(newWorkspace))
    }, [newWorkspace])
    
    const [, {execute, loading}] = useAPI(updateAnnotationWorkspace,{ressourceId: workspace?.ressource?.slug, workspace: utilsDict.trimAllDictValues(newWorkspace)}, {immediate: false, onResult: handleResult, errorToastMessage: t("annotations.workspace.error-updating"), successToastMessage: t("annotations.workspace.success-updating")})
    
    return <>
        <Modal onClose={(()=>setNewWorkspace(workspace))} open={open} setOpen={setOpen} title={t("annotations.workspace.settings")}>
            <Modal.Body>
                <TextInput label={t("description")} theme="outlined" placeholder={t("description")} defaultValue={newWorkspace.description} onChange={(v:string)=>setNewWorkspace({...newWorkspace, description: v})} />
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>{setNewWorkspace(workspace); setOpen(false)}}>{t("cancel")}</Button>
                <Button disabled={!isDifferent || loading} onClick={execute}>{t("save")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

function _InIcon(){
    return <>
        <div className="text-gray-300 rounded-sm w-8 ml-2 top-1/2 -translate-y-1/2 justify-center py-0.5 text-xs flex items-center bg-primary absolute">IN</div>
    </>
}

function _InactiveIcon(){
    return <>
        <div className="text-gray-300 rounded-sm w-8 ml-2 top-1/2 -translate-y-1/2 justify-center py-0.5 text-xs flex items-center bg-gray-500 absolute">IN</div>
    </>
}

function _OutIcon(){
    return <>
        <div className="text-gray-300 rounded-sm w-8 ml-2 top-1/2 -translate-y-1/2 justify-center py-0.5 text-xs flex items-center bg-violet-500 absolute">OUT</div>
    </>
}

function _InactiveOutIcon(){
    return <>
        <div className="text-gray-300 rounded-sm w-8 ml-2 top-1/2 -translate-y-1/2 justify-center py-0.5 text-xs flex items-center bg-gray-500 absolute">OUT</div>
    </>
}