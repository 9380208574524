import { createContext, useMemo } from "react";

// Hooks
import { useSearch } from "hooks/useSearch";
import { useParams } from "react-router-dom";

// API
import { getOrganizationApiKeys } from "api/organizations";

const OrgApiKeysContext = createContext<any>({});

const OrgApiKeysContextProvider = ({ children }:{children: React.ReactNode}) => {
    const {org: orgId} = useParams()
    const params = useMemo(()=> ({orgId}), [orgId])
    const [apiKeys, {loading, execute}] = useSearch<ApiKey>(getOrganizationApiKeys, params)

    return (
        <OrgApiKeysContext.Provider value={{apiKeys, loading, orgId: params.orgId, reload: execute}}>
            {children}
        </OrgApiKeysContext.Provider>
    );

};

export { OrgApiKeysContext, OrgApiKeysContextProvider };