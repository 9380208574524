import { fetchCall, QueryOptions } from 'api'

// Get all incidents for an organization
export function getOrgIncidents({ orgId, ressourceId, createdAfter, createdBefore, fields, page, limit, orderBy, orderDirection, search, status }: { search:string, orgId: string, ressourceId?:string, createdAfter:string, createdBefore:string, status?: IncidentStatus } & QueryOptions) {
    const options = {
        method: "GET",
        endpoint: `/app/organization/${orgId}/incidents`,
        query: {
            fields,
            page,
            limit,
            ressource: ressourceId,
            order_by: orderBy,
            order_direction: orderDirection,
            created_after: createdAfter,
            created_before: createdBefore,
            search,
            status
        },
    };
    return fetchCall(options);
}

// Get an incident
export function getIncident({ incidentId, fields }: { incidentId: string, fields?: string[] }) {
    const options = {
        method: "GET",
        endpoint: `/app/incidents/${incidentId}`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

// Post a new incident
export function createIncident({ orgId, incident }: { orgId: string, incident: Incident }) {
    const options = {
        method: "POST",
        endpoint: `/app/organization/${orgId}/incidents`,
        body: incident
    };
    return fetchCall(options);
}

// Update an incident
export function updateIncident({ incidentId, status, name, acknowledged, progress }: { incidentId: string} & Incident) {
    const options = {
        method: "PATCH",
        endpoint: `/app/incidents/${incidentId}`,
        body: {
            status, 
            name,
            acknowledged,
            progress
        }
    };
    return fetchCall(options);
}

// Delete an incident
export function deleteIncident({ incidentId }: { incidentId: string }) {
    const options = {
        method: "DELETE",
        endpoint: `/app/incidents/${incidentId}`
    };
    return fetchCall(options);
}

// Post files to an incident
export function createIncidentFileUploadURL({ incidentId, messageId, files }: { incidentId: string, messageId?:string, files: { name: string, numBytes: number, extension: string }[] }) {
    const options = {
        method: "POST",
        endpoint: `/app/incidents/${incidentId}/files`,
        body: {
            files: files,
            message: messageId
        }
    };
    return fetchCall(options);
}

export function getIncidentFilesList({incidentId, page,  limit, orderBy, orderDirection, fields, search}: {incidentId: string, page: number, limit: number, orderBy: string, orderDirection: string, fields:string[], search: string}) {
    const options = {
        method: "GET",
        endpoint: `/app/incidents/${incidentId}/files`,
        query: {
            page,
            limit,
            orderBy,
            orderDirection,
            search,
            fields
        }
    };
    return fetchCall(options);
}

export function createIncidentMessage({incidentId, message, messageType}: {incidentId: string, message: string, messageType?: string}) {
    const options = {
        method: "POST",
        endpoint: `/app/incidents/${incidentId}/messages`,
        body: {
            message,
            messageType
        }
    };
    return fetchCall(options);
}

export function getIncidentMessages({incidentId, page, limit, orderBy, orderDirection, fields, search}: {incidentId: string, page: number, limit: number, orderBy: string, orderDirection: string, fields:string[], search: string}) {
    const options = {
        method: "GET",
        endpoint: `/app/incidents/${incidentId}/messages`,
        query: {
            page,
            limit,
            orderBy,
            orderDirection,
            search,
            fields
        }
    };
    return fetchCall(options);
}

export function getIncidentMessage({messageId, fields}: {messageId: string, fields?: string[]}) {
    const options = {
        method: "GET",
        endpoint: `/app/incident-message/${messageId}`,
        query: {
            fields
        }
    };
    return fetchCall(options);
}

export function updateIncidentMessage({messageId, message}: {messageId: string, message: string}) {
    const options = {
        method: "PATCH",
        endpoint: `/app/incident-message/${messageId}`,
        body: {
            message
        }
    };
    return fetchCall(options);
}

export function deleteIncidentMessage({messageId}: {messageId: string}) {
    const options = {
        method: "DELETE",
        endpoint: `/app/incident-message/${messageId}`
    };
    return fetchCall(options);
}