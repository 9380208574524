// Utils
import { format } from "utils/format"

// Components
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { TextInput } from "components/core/inputs"
import { Text } from "components/core/typo"
import { RadioGroup } from "components/core/radio/group"

// Icons
import { VscGraphLine as FloatIcon } from "react-icons/vsc"
import { VscGraph as CounterIcon } from "react-icons/vsc"
import { LuGauge as GaugeIcon } from "react-icons/lu"

// API
import { createRessourceMetric } from "api/ressources"

// Hooks
import { useTranslation } from "react-i18next"
import { useState, useMemo } from "react"
import { useAPI } from "hooks/useAPI"

export function ControlledMetricsModalCreateRessource({metrics, setMetrics, open, setOpen, onClose}:{metrics:Metric[], setMetrics:any, open:boolean, setOpen:any, onClose?:any}){
    const { t } = useTranslation("common")

    const [newMetric, setNewMetric] = useState<Metric>({name:"", metricsType:""})

    const resetNewMetric = () => {
        setNewMetric({name:"", metricsType:""})
    }

    const handleSave = () => {
        resetNewMetric()
        setMetrics([...metrics, newMetric])
        setOpen(false)
    }

    const uniqueName = (name:string) => {
        return metrics?.filter((m:Metric) => m.name.toLowerCase() === name.toLowerCase()).length === 0
    }

    const handleNameChange = (name:string) => {
        setNewMetric((prev: Metric)=>({...prev, name}))
    }

    return <>
        <Modal size="xl" open={open} setOpen={setOpen} onClose={()=>{ resetNewMetric(); onClose();}} title={t("add-metric")}>
            <Modal.Body>
                {!uniqueName(newMetric.name) && <Text.Warning className="text-gray-300">{t("metric-name-not-unique")}</Text.Warning>}
                <div className="mt-2">
                    <TextInput  label={t("name")} placeholder={t("name")} theme="outlined" defaultValue={newMetric.name} onChange={handleNameChange} />
                </div>
                <TypeSelector value={newMetric.metricsType} setValue={(v:string)=> setNewMetric(prev=>({...prev, metricsType:v.toUpperCase()}))} />
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>{setOpen(false); resetNewMetric()}}>{t("cancel")}</Button>
                <Button disabled={newMetric.name === "" || newMetric.metricsType === "" || !uniqueName(newMetric.name)} onClick={handleSave}>{t("save")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export function ControlledMetricsModalEditRessource({ressource, metrics, setMetrics, open, setOpen, onClose}:{ressource: Ressource, metrics:Metric[], setMetrics:any, open:boolean, setOpen:any, onClose?:any}){
    const { t } = useTranslation("common")

    const [newMetric, setNewMetric] = useState<Metric>({name:"", metricsType:""})

    const resetNewMetric = () => {
        setNewMetric({name:"", metricsType:""})
    }

    const handleSave = () => {
        resetNewMetric()
        setMetrics([...metrics, newMetric])
        setOpen(false)
    }

    const uniqueName = (name:string) => {
        return metrics?.filter((m:Metric) => m.name.toLowerCase() === name.toLowerCase()).length === 0
    }

    const handleNameChange = (name:string) => {
        setNewMetric((prev:Metric)=>({...prev, name}))
    }

    const params = useMemo(()=> ({
        ressourceId: ressource.slug,
        name: newMetric.name,
        metricsType: newMetric.metricsType
    }),[ressource, newMetric])

    const [,{ execute, loading}] = useAPI(createRessourceMetric, params, {immediate: false, onResult: handleSave, successToastMessage: t("add-metric-success"), errorToastMessage: t("add-metric-error")})

    return <>
        <Modal size="xl" open={open} setOpen={setOpen} onClose={()=>{ resetNewMetric(); onClose();}} title={t("add-metric")}>
            <Modal.Body>
                {!uniqueName(newMetric.name) && <Text.Warning className="text-gray-300">{t("metric-name-not-unique")}</Text.Warning>}
                <div className="mt-2">
                    <TextInput  label={t("name")} placeholder={t("name")} theme="outlined" defaultValue={newMetric.name} onChange={handleNameChange} />
                </div>
                <TypeSelector value={newMetric.metricsType} setValue={(v:string)=> setNewMetric((prev:Metric)=>({...prev, metricsType:v.toUpperCase()}))} />
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>{setOpen(false); resetNewMetric()}}>{t("cancel")}</Button>
                <Button loading={loading} disabled={newMetric.name === "" || newMetric.metricsType === "" || !uniqueName(newMetric.name)} onClick={execute}>{t("save")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

function TypeSelector({value, setValue}:{value:string, setValue:any}){
    
    const { t } = useTranslation("common")
    const types = [
        {value:"float", description: t("metrics-types.float-description"), Icon: FloatIcon},
        {value:"counter", description: t("metrics-types.counter-description"), Icon: CounterIcon}, 
        {value:"gauge", description: t("metrics-types.gauge-description"), Icon: GaugeIcon}, 
    ]
    
    return <>
        <Text.TitleSmall className="pb-2 mt-5 -mb-5 text-gray-200">{t("select-metric-type")}</Text.TitleSmall>
        <RadioGroup value={types.find(t=>t.value.toLowerCase() === value.toLowerCase())|| {}} iconKey="Icon" setValue={(t:any)=>setValue(t.value)} values={types} stringify={(t:any)=>format.capitalizeFirstLetter(t.value)} descriptionKey="description" />     
    </>
}

