// Components
import { Edit } from "components/ressources/edit"
import { RoleGuard } from "components/auth/roleguard"

export function RessourceEdit(){
 
    return <>
        <RoleGuard requiredRole="admin">
            <Edit />
        </RoleGuard>
    </>
}