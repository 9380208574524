import { useContext } from "react";
import { Incidents, IncidentsContext } from "contexts/incidents";

/**
 * @example const {incidents, loading, error} = useRessources(); 
 * @description This hook returns the list of ressources from the IncidentsContext
 * @returns {Incidents} incidents list, loading boolean, error {ressources, loading, error}
 */
export function useIncidents():Incidents{
    return useContext(IncidentsContext);
}

