import { useTranslation } from "react-i18next"

export function NoOrganizations(){
    const { t } = useTranslation("common")

    return <>
        <div className="flex flex-col items-center justify-center mt-10">
            <h2 className="text-2xl font-bold">{t("no-organizations")}</h2>
            <p className="text-container-foreground">{t("no-organizations-message")}</p>
        </div>
    </>
}