// Hooks
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

// Utils
import classNames from "classnames"

// Icons
import { MdOutlineContentCopy as CopyIcon } from 'react-icons/md';

export function CopyButton({content, label, className}: {content: string, label: string, className?: string}){

    const {t} = useTranslation('common')
    const [copied, setCopied] = useState(false)
    const [copyText, setCopyText] = useState(t("copy-url"))
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const addTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const execute = () => {
        navigator.clipboard.writeText(content);
        setCopied(true);
        setCopyText("");
        addTimeout();
    };

    return <div 
            className={classNames('relative mr-16 group', className)}
            onMouseEnter={() => !copied && setCopyText(t(label))}
        >
            <CopyIcon onClick={execute} className="transition-all group-hover:cursor-pointer group-hover:text-gray-300" />
            {
                <p className={classNames('absolute ml-2 text-xs text-gray-300 -translate-y-1/2 top-1/2 left-full whitespace-nowrap ')}>
                    {copied ? <span className='fadeOutDelayed'>{t("copied")}</span> : <span className='hidden group-hover:block'>{copyText}</span>}
                </p>
            }
        </div>
}