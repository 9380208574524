// Components
import { AnnotationsList } from "components/annotations/list" 
import { WorkspaceInfos } from "components/annotations/workspace"
import { Spinner } from "components/core/spinner"
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs";
import { LabelsList } from "components/annotations/labels";
import { NavigateToAnnotationId } from "components/annotations/controls";

// Constants
import { to } from "constants/routes"

// Hooks
import { useAnnotationWorkspace } from "hooks/useAnnotationWorkspace"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

export function List(){
    const { t } = useTranslation("common")
    const { workspace, loading } = useAnnotationWorkspace()
    const { org:orgParams } = useParams()

    const crumbs:Crumb[] = [
        {
            name: t("env-annotations.title"),
            path: to.annotations(orgParams || "")
        },
        {
            name: workspace?.ressource.name,
            path:""
        }
    ]

    if (!workspace && loading) return <div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div>
    return <>

        <div className="flex justify-between gap-x-4 flex-col md:flex-row">
            <Breadcrumbs crumbs={crumbs} />
            <div className="w-full shrink-0 md:w-[298px] items-center flex">
                <NavigateToAnnotationId />
            </div>
        </div>

        <div className="mt-5">
            <WorkspaceInfos />
        </div>
        <AnnotationsList/>
        <div className="mt-12 mb-5">    
            <LabelsList/>
        </div>
    </>
}