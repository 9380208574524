// Components
import { Button } from "components/core/buttons"
import { TextArea, TextInput } from "components/core/inputs"
import { Uploader } from "components/core/uploader"
import { Select } from "components/core/select"
import { Spinner } from "components/core/spinner"
import { Modal } from "components/core/modal"

// Utils
import { uploadIncidentFilesAsync } from "components/incidents/uploadFile"

// API
import { createIncident, createIncidentMessage } from "api/incidents"

// Hooks
import { useTranslation } from "react-i18next"
import { Dispatch, SetStateAction, useState, useEffect, useCallback, useRef, useMemo } from "react"
import { useAPI } from "hooks/useAPI"
import { useActiveOrg } from "hooks/useActiveOrg"
import { getOrgRessources } from "api/ressources"
import { useSearch } from "hooks/useSearch"

export function CreateIncidentsModal({open, setOpen, defaultRessources, prependIncident, onCreate, setIncidents}: {open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, defaultRessources?:any[], onCreate?:()=>void, prependIncident?:boolean | undefined, setIncidents: Dispatch<SetStateAction<Incident[]>>}) {
    const { t } = useTranslation()
    const { activeOrg } = useActiveOrg()
    const [files, setFiles] = useState<File[]>()
    const newIncidentIdRef = useRef<number | undefined>();
    const getRessourcesParams = useMemo(() => ({orgId: activeOrg && activeOrg.slug}), [])
    const [ressources, setRessources] = useState<Ressource[] | undefined>(defaultRessources)
    const [ incident, setIncident] = useState<Incident>({
        name: "",
        description: "",
        ressource: ressources && ressources[0] && ressources[0].slug,
        status: "open"
    })

    const validateGetRessouresParams = useCallback(() => {
        return defaultRessources ? false : true
    }, [defaultRessources])

    const handleGetRessourcesResult = useCallback((r:any) => {
        setRessources(r.results)
        setIncident(prev => ({...prev, ressource: r.results && r.results[0] && r.results[0].slug}))
    }, [])
    const [,{loading:loadingRessources}] = useSearch(getOrgRessources, getRessourcesParams, { onResult:handleGetRessourcesResult, validateParams: validateGetRessouresParams})
    
    const resetState = () => {
        newIncidentIdRef.current = undefined;
        setFiles(undefined);
        setIncident({
            name: "",
            description: "",
            ressource: ressources && ressources[0] && ressources[0].slug,
            status: "open"
        });
    };
    
    const handleCreateMessageResult = useCallback((r:any) => {
        if (files && files.length > 0 && newIncidentIdRef.current) {
            uploadIncidentFilesAsync({incidentId: newIncidentIdRef.current.toString(), messageId: r.id, files:files})
        }
        setOpen(false)
    }, [files])
    
    const [, { execute: executePostMessage }] = useAPI(createIncidentMessage,{}, { immediate: false, onResult: handleCreateMessageResult})
    
    const handleCreateIncidentResult = useCallback((r:any) => {
        // Add the new incident to the list
        r.messageCount = 1;
        r.createdAt = new Date().toISOString();
        if (prependIncident) setIncidents((prev:Incident[]) => ([r,...prev]))

        newIncidentIdRef.current = r.id;
        executePostMessage({incidentId: r.id, message: incident.description || ""}).finally(()=>onCreate && onCreate())
        
    }, [incident, files])
    const [, {execute:executePostIncident, loading}] = useAPI(createIncident, {orgId: activeOrg && activeOrg.slug, incident}, {immediate: false, onResult: handleCreateIncidentResult, errorToastMessage: t("error-new-incident"), successToastMessage: t("success-new-incident")})
    useEffect(() => {
        if (open) {
            resetState();
        }
    }, [open]);
    
    
    return <>
        <Modal title={t("report-incident")} open={open} setOpen={setOpen} size="lg">
                {
                    !ressources && loadingRessources ? <div className="flex items-center justify-center p-4 h-14"><Spinner/></div>
                : <>
                
                    <IncidentMeta ressources={ressources || []} incident={incident} setIncident={setIncident} files={files} setFiles={setFiles} />
                    <Modal.Footer type="doubleButton">
                        <Button type="reset" theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                        <Button loading={loading} disabled={loading || !incident.name || incident.ressource == "" || !incident.ressource} onClick={executePostIncident}>{t("save")}</Button>
                    </Modal.Footer>
                </>
            }
        </Modal>
    </>
}

interface IncidentMetaProps {
    ressources: any[];
    loadingRessources?: boolean;
    incident: Incident;
    setIncident: Dispatch<SetStateAction<Incident>>;
    files?: File[];
    setFiles: Dispatch<SetStateAction<File[]|undefined>>;
}

function IncidentMeta({ressources, incident, setIncident, files, setFiles}: IncidentMetaProps) {
    const { t } = useTranslation("common")

    const [selectedRessource, setSelectedRessource] = useState<Ressource>(ressources[0])

    const handleSelectRessource = (r:Ressource) => {
        setIncident(prev => ({...prev, ressource: r?.slug}))
        setSelectedRessource(r)
    }

    return <>
        <div className="grid grid-cols-1 gap-4">
            <div className="mt-2">
                <TextInput theme="outlined" label={t("title")} placeholder={t("title")} defaultValue={incident.name} onChange={(v:string) => setIncident(prev => ({...prev, name: v}))} />
            </div>
            <div className="h-20">
                <Select noValuesMsg={t("no-ressources")} indexingKey={"slug"} label={t("ressource")} theme="outlined" options={ressources || []} selected={selectedRessource || ressources && ressources[0] || null} setSelected={handleSelectRessource} toString={(r:Ressource)=> r?.name} />
            </div>
            <div>
                <TextArea theme="outlined" label={t("description")} placeholder={t("description")} defaultValue={incident.description} onChange={(v:string) => setIncident(prev => ({...prev,description: v}))} />
            </div>
           
        </div>
        <div className="mt-4 mb-4">
            <Uploader.DropZone multiple id="file-upload" label={t("upload-file.title")} files={files} setFiles={setFiles}  />
        </div>
    </>
}