import { fetchCall } from 'api'

/*
  Get notifications settings
*/
export function getNotificationsSettings({fields, page, limit, orderBy, orderDirection}:
      {fields?: string[], page?: number, limit?: number, orderBy?: string, orderDirection?: string}){
    const options = {
      method: "GET",
      endpoint: "/app/notifications/user-settings",
      query: {fields,
              page,
              limit,
              orderBy,
              orderDirection}
    }
    return fetchCall(options)
}


/*
 Update noteifications settings
*/
export function updateNotificationsSettings({organization, filesNotification, jobsNotification, incidentsNotification, customNotification}: 
  {organization: string, filesNotification: string, jobsNotification: string, incidentsNotification: string, customNotification: string}){
    const options = {
      method: "PATCH",
      endpoint: "/app/notifications/user-settings",
      body: {organization, filesNotification, jobsNotification, incidentsNotification, customNotification}
    }
    return fetchCall(options)
}