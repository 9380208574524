// Icons
import { AiFillDelete as DeleteIcon } from "react-icons/ai"

// Components
import { ControlledMetricsModalCreateRessource, ControlledMetricsModalEditRessource } from "components/ressources/metrics/builder"
import { Spinner } from "components/core/spinner"
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"

// API
import { disableRessourceMetric } from "api/ressources"

// Hooks
import { useTranslation } from "react-i18next"
import { Dispatch, useState, SetStateAction, useMemo} from "react"
import { useAPI } from "hooks/useAPI"
import { Tooltip } from "components/core/tooltip"

export const defaultMetrics = {
    "worker": [],
    "api": [
        {
            name: "latency",
            metricsType: "FLOAT"
        },
        {
            name: "calls",
            metricsType: "COUNTER"
        },
        {
            name: "incidents",
            metricsType: "COUNTER"
        }
    ],
    "all": []
}

export type DefaultMetricTypes = keyof typeof defaultMetrics;

export const isDefaultMetric = (metric: Metric, type: DefaultMetricTypes="all"): boolean => {
    const typeMetrics = defaultMetrics[type] || [];
    const allMetrics = defaultMetrics["all"] || [];

    return [...typeMetrics, ...allMetrics].some(defaultMetric =>
        defaultMetric.name.toLowerCase() === metric.name.toLowerCase()
    );
};

interface CreateMetricsListProps {
    ressource: Ressource
    metrics: Metric[]
    handleDelete: (p:any)=>any
    loading?: boolean
}
export function ControlledMetricsListCreateRessource({ ressource, metrics, handleDelete, loading }: CreateMetricsListProps) {

    const { t } = useTranslation("common")
    return <>
        {
            loading ? <div className="flex items-center justify-center h-12 p-3 text-sm text-container-foreground">
                <Spinner />
            </div> : <>
                {
                    metrics.length === 0 && !loading && <div className="flex items-center h-12 p-3 text-sm text-container-foreground">
                        {t("no-metrics")}
                    </div>
                }
                {
                    metrics.map((metric, index) => (
                        <div key={index} className="flex items-center justify-between p-3 transition-all hover:bg-background/30">
                            <span className="flex flex-col w-full text-gray-300 sm:flex-row">
                                {metric.name}
                                <span className="text-xs text-container-foreground sm:hidden">{metric.metricsType.toLowerCase()}</span>
                            </span>
                            <span className="hidden w-full text-sm capitalize text-container-foreground sm:block">{metric.metricsType.toLowerCase()}</span>

                            <div className="flex items-center gap-x-2">
                                {
                                    !isDefaultMetric(metric, ressource?.type) ? <div className="text-red-500 hover:cursor-pointer" onClick={() => handleDelete(index)}>
                                        <DeleteIcon className="text-lg" />
                                    </div> :
                                    <div className="text-gray-300 opacity-20">
                                        <DeleteIcon className="text-lg" />
                                    </div>
                                }
                            </div>
                        </div>
                    ))
                }
            </>
        }
    </>
}

interface EditMetricsListProps {
    metrics: Metric[]
    setMetrics: Dispatch<SetStateAction<Metric[] | undefined>>
    ressource: Ressource
    loading?: boolean
}
export function ControlledMetricsListEditRessource({ ressource, metrics, setMetrics, loading }: EditMetricsListProps) {

    const { t } = useTranslation("common")
    const [open, setOpen] = useState(false)
    const [selectedMetric, setSelectedMetric] = useState<Metric>()

    const params = useMemo(()=> ({
        ressourceId: ressource.slug,
        metricName: selectedMetric?.name
    }),[selectedMetric])

    const [,{execute}] = useAPI(disableRessourceMetric, params, {immediate: false, successToastMessage: t("delete-metrics.success"), errorToastMessage: t("delete-metrics.error")})

    const handleDelete = () => {
        if (!selectedMetric) return;
        if (isDefaultMetric(selectedMetric)) return;
        setMetrics(prev => prev?.filter(metric => metric.name.toLowerCase() !== selectedMetric.name.toLowerCase()));
        execute();
        setOpen(false);
    }

    return <>
        {
            loading ? <div className="flex items-center justify-center h-12 p-3 text-sm text-container-foreground">
                <Spinner />
            </div> : <>
                {
                    metrics.length === 0 && !loading && <div className="flex items-center h-12 p-3 text-sm text-container-foreground">
                        {t("no-metrics")}
                    </div>
                }
                {
                    metrics.map((metric, index) => (
                        <div key={index} className="flex items-center justify-between p-3 transition-all hover:bg-background/30">
                            <div className="flex flex-col w-full text-gray-300 sm:flex-row">
                                <div>
                                    <p>{metric.name}</p>
                                    <div className="text-xs text-container-foreground font-mono">{metric.slug}</div>
                                </div>
                                <div className="text-xs text-container-foreground sm:hidden">{metric.metricsType.toLowerCase()}</div>
                            </div>
                            <span className="hidden w-full text-sm capitalize text-container-foreground sm:block">{metric.metricsType.toLowerCase()}</span>

                            <div className="flex items-center gap-x-2">
                                {
                                    !isDefaultMetric(metric, ressource?.type) ? <div className="text-red-500 hover:cursor-pointer" onClick={() => {setSelectedMetric(metric); setOpen(true)}}>
                                        <DeleteIcon className="text-lg" />
                                    </div> :
                                    <Tooltip content={t("setup-metrics.disabled-metrics")} className="translate-y-[20px]">
                                        <div className="text-gray-300 opacity-20 relative  ">
                                            <DeleteIcon className="text-lg" />
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    ))
                }
            </>
        }
        <Modal title={t("delete-metrics.title")} open={open} setOpen={setOpen}>
            <Modal.Body>
                <p>{t("delete-metrics.description", {name: selectedMetric?.name})}</p>
            </Modal.Body>

            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button theme="alert" loading={loading} onClick={()=>{handleDelete()}}>{t("delete")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export const MetricsList = {
    Create: ControlledMetricsListCreateRessource,
    Edit: ControlledMetricsListEditRessource
}

export const MetricsModal = {
    Create: ControlledMetricsModalCreateRessource,
    Edit: ControlledMetricsModalEditRessource
}