
import { useMediaQuery } from "hooks/useMediaQuery"
import { Button } from "components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "components/ui/drawer"
import { Dispatch, SetStateAction } from "react"

import { useTranslation } from "react-i18next"

interface AdaptativeModalProps {
    children: React.ReactNode
    title?: string
    description?: string
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    mediaQuery?: string
    hideDrawerFooter?: boolean
}

export function AdaptativeModal({ children, title, description, open, setOpen, mediaQuery="sm", hideDrawerFooter }: AdaptativeModalProps) {
  const isDesktop = useMediaQuery(mediaQuery)
  
  const { t } = useTranslation("common")

  if (isDesktop) {
    return (
        <Dialog open={open} onOpenChange={setOpen}>
      
            <DialogContent className="">
                {
                    (title || description) && (
                    <DialogHeader>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogDescription>{description}</DialogDescription>
                    </DialogHeader>
                    )
                }
                {children}
            </DialogContent>
        </Dialog>
    )
  }

  return (
        <Drawer open={open} onOpenChange={setOpen}>
      
            <DrawerContent>
                {
                    (title || description) && (
                        <DrawerHeader>
                            <DrawerTitle>{title}</DrawerTitle>
                            <DrawerDescription>{description}</DrawerDescription>
                        </DrawerHeader>
                    )
                }
                <div className="p-4">
                    {children}
                </div>
                {
                    !hideDrawerFooter && <DrawerFooter className="pt-2">
                        <DrawerClose asChild>
                            <Button variant="secondary">{t("cancel")}</Button>
                        </DrawerClose>
                    </DrawerFooter>
                }
            </DrawerContent>
        </Drawer>
  )
}
