// API
import { getModels } from "api/models"


// Hooks
import { useActiveOrg } from "hooks/useActiveOrg";
import { useMemo, useState, createContext, useCallback, Dispatch, SetStateAction} from "react";
import { useSearch } from "hooks/useSearch";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useFiltersFromURL } from "hooks/useTableFilters";

const ModelsContext = createContext<Models>({});

export type Models= {
    models?: Model[];
    setModels?: any;
    error?: any;
    loading?: boolean;
    paging?: any;
    setPage?: any;
    query?: string;
    setQuery?: any;
    filter?:Filter;
    filters?:Filter[];
    defaultFilter?:Filter;
    setFilter?: Dispatch<SetStateAction<Filter>>;
    execute?: any;
}

export type Filter = 
    "newest" | 
    "oldest" | 
    "recently-updated" | 
    "least-recently-updated" | 
    "name-asc" | 
    "name-desc" 
export type OrderBy = "created_at" | "updated_at" | "name" | "ressource" 
export type OrderDirection = "asc" | "desc"

// Record of Filter, value is object with orderBy and orderDirection
export const filterDict:Record<Filter, {orderBy:OrderBy, orderDirection:OrderDirection}> = {
    "newest": {orderBy: "created_at", orderDirection: "desc"},
    "oldest": {orderBy: "created_at", orderDirection: "asc"},
    "recently-updated": {orderBy: "updated_at", orderDirection: "desc"},
    "least-recently-updated": {orderBy: "updated_at", orderDirection: "asc"},
    "name-asc": {orderBy: "name", orderDirection: "asc"},
    "name-desc": {orderBy: "name", orderDirection: "desc"}
}

export const filters:Filter[] = Object.keys(filterDict) as Filter[]

export function modelsfilterHandler(filter:Filter):{orderBy:OrderBy, orderDirection:OrderDirection}{
    return filterDict[filter]
}

export const defaultFilter:Filter = "recently-updated"

const ModelsContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { t } = useTranslation("common")

    const urlFilters = useFiltersFromURL("models")

    const { activeOrg } = useActiveOrg()
    
    const [ models, setModels] = useState<Model[]>()
    const [ query, setQuery] = useState<string>(urlFilters?.filterParams.query?.toString() || "")
    const debounceQuery = useDebounce(query, 500);
    const [ filter, setFilter] = useState<Filter>(urlFilters?.filterParams.filter?.toString() as Filter || defaultFilter)
    
    const handleResult = useCallback((result:any) => {
        setModels(result.results || result)
    },[])
    
    const handleFilter = useCallback(():{orderBy:OrderBy, orderDirection:OrderDirection} => {
        if (!filters.includes(filter)) return filterDict[defaultFilter]
        return modelsfilterHandler(filter)
    },[filter])
    const defaultPage = isNaN(parseInt(urlFilters?.filterParams.page?.toString() || "")) ? 1 : parseInt(urlFilters?.filterParams.page?.toString() || "")
    const params = useMemo(() => ({organization: activeOrg && activeOrg.slug, search: query, orderBy: handleFilter().orderBy, orderDirection: handleFilter().orderDirection}), [debounceQuery, handleFilter])
    const fields = useMemo(() => (["id", "name", "version", "versions", "disabled"]), [])
    const [, {loading, paging, setPage, error, execute}] = useSearch(getModels, params, { defaultPage: defaultPage,onResult: handleResult, limit: 25, fields, errorToastMessage: t("error-try-again")} )
    return (
        <ModelsContext.Provider value={{execute, models, setModels, error, paging, setPage, query, setQuery,  filter, setFilter, filters, defaultFilter, loading}}>
            {children}
        </ModelsContext.Provider>
    );

};

export { ModelsContext, ModelsContextProvider };