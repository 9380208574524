import * as ObsPlot from "@observablehq/plot";

// Icons
import { TbTableExport as CsvExportIcon } from "react-icons/tb"

// Utils
import classNames from "classnames";

// Components
import { ExportToCsv } from "components/exports/export-to-csv";
import { ChildrenMenu, ChildrenStyles } from "components/core/moreMenu/children-Menu";

// Hooks
import {useEffect, useRef} from 'react';
import { useMemoCompare } from "hooks/useMemoCompare";
import { useRessource } from "hooks/useRessource";
import { useTranslation } from "react-i18next";
import { useElementSize } from "hooks/useElementSize";

function formatTicks(d: Date, language: string, showYear: boolean = false) {
    try {
        return d.toLocaleString(language, {
            timeZone: 'UTC',
            month: "short",
            day: "numeric",
            year: showYear ? "numeric" : undefined
        })
    }
    catch {
        return d.toLocaleString('fr-CA', {
            timeZone: 'UTC',
            month: "short",
            day: "numeric",
            year: showYear ? "numeric" : undefined
        })
    }
}

export function convertTimestampToDate(data: any): any {
    const dataWithDates = data.map((d: any) => {
        d= {
            ...d,
            value : d.value,
            timestamp: new Date(d.timestamp * 1000)
        }
        return d
    })
    return dataWithDates
}

function convertTimestampToIso(data: any): any {
    const dataWithDates = data.map((d: any) => {
        d= {
            ...d,
            value : d.value,
            timestamp: new Date(d.timestamp * 1000).toISOString()
        }
        return d
    })
    return dataWithDates
}

function Export({data, filename}:{data:any, filename:string}){
    const { t } = useTranslation("common")
    return <ExportToCsv data={data} filename={filename}>
        <div className={ChildrenStyles}>
            <CsvExportIcon className="mr-2"/>
            <span className="block text-sm whitespace-nowrap">{t("export-to-csv")}</span>
        </div>
    </ExportToCsv>
}

function FloatPlot({data, title, className}:{data:any, title:string, className?:string}){
    const { i18n } = useTranslation("common")
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useElementSize(ref)
    const { ressource } = useRessource()
    const dataWithDates = convertTimestampToDate(data)
    const isZerosOrEmpty = data.every((d: any) => !d.value)
    
    // sm : 640px
    // md : 768px
    // lg : 1024px
    // xl : 1280px
    // 2xl : 1536px
    const fontSize = width && ( width < 350 ? 25: width < 500 ? 18: width < 800 ? 15 : width < 900? 12 : 20)

    const fontMemo = useMemoCompare(fontSize, (prev, next) => {
        return prev === next
    })

    useEffect(() => {
        if (data === undefined) return;
        const chart = ObsPlot.plot({
            height: 300,
            style: {
                background: "transparent",
                overflow: "visible",
                padding: "18px",
                fontSize: `${fontMemo}px`,
                width: "100%",
            },
            marginLeft: 70,
            label: "",
            labelArrow: false,
            y: {
                grid: true,
                tickPadding: 10,
                ticks: 6,
            },
            x: {
                tickFormat: (d)=> formatTicks(d, i18n.language || 'fr-CA'),
                ticks: 4,
                interval: "day",
                tickPadding: 10,
            },
            color: {
                type: "diverging",
                scheme: "burd"
            },
            marks: [
                isZerosOrEmpty ? ObsPlot.ruleY([10], {stroke:"transparent"}): ObsPlot.ruleY([0], {stroke:"transparent"}),
                ObsPlot.ruleY([0], {stroke:"transparent"}),
                ObsPlot.lineY(dataWithDates, {x: "timestamp", y: "value", stroke: "#119a91"}),
                ObsPlot.dot(dataWithDates, {x: "timestamp", y: "value", fill: "white", stroke: '#119a91', strokeWidth: 2, r: 4}),
                ObsPlot.dot(dataWithDates, ObsPlot.pointerX({x: "timestamp", y: "value", fill: "#119a91", stroke: 'white', strokeWidth: 3, r: 6})),
                ObsPlot.tip(dataWithDates, ObsPlot.pointerX({ x: "timestamp", y: "value", fill: "#119a91", fontWeight: 600, anchor: "middle", stroke: "transparent", lineHeight:1.2, dy: -60,title: (d)=> `${formatTicks(new Date(d.timestamp),i18n.language, true)}\nValue: ${Math.round(d.value * 100) / 100}`})),
            ]
        });
        ref.current && ref.current.append(chart);
        return () => chart.remove();
    }, [data, dataWithDates, fontMemo, title, i18n.language]);
    
    return <>
        <div className="flex justify-between">
            <span className="capitalize">{title}</span>
            <ChildrenMenu className="w-fit">
                <Export data={convertTimestampToIso(data)} filename={`${ressource?.slug}-${title}`}/>
            </ChildrenMenu>
        </div>
        <div ref={ref} className={classNames("plot", className)}></div>
    </>
}

function CounterPlot({data, title, className}:{data:any, title:string, className?:string}){
    const { i18n } = useTranslation("common")
    const { ressource } = useRessource()
    const ref = useRef<HTMLDivElement>(null);
    const { width } = useElementSize(ref)
    const dataWithDates = convertTimestampToDate(data)
    const fontSize = width && ( width < 350 ? 25: width < 500 ? 18: width < 800 ? 15 : width < 900? 12 : 20)
    const fontMemo = useMemoCompare(fontSize, (prev, next) => {
        return prev === next
    })
    const isZerosOrEmpty = data.every((d: any) => !d.value)

    useEffect(() => {
        if (data === undefined) return;
        const chart = ObsPlot.plot({
            height: 300,
            style: {
                background: "transparent",
                width: "100%",
                overflow: "visible",
                fontSize: `${fontMemo}px`,
                paddingTop: "15px",
                marginBlockEnd: "20px"
            },
            label: "",
            labelArrow: false,
            marginLeft: 70,
            y: {
                grid: true,
                ticks: 6,
            },
            x: {
                tickFormat: (d)=> {
                    return formatTicks(d, i18n.language || 'fr-CA')
                },
                ticks: 4,
                interval: "day",
                tickPadding: 10,
            },
            color: {
                type: "diverging",
                scheme: "burd",
            },
        
            marks: [
                // eslint-disable-next-line no-constant-condition
                isZerosOrEmpty ? ObsPlot.ruleY([10], {stroke:"transparent"}): ObsPlot.ruleY([0], {stroke:"transparent"}),
                ObsPlot.barY(dataWithDates, { x: "timestamp", y: "value", fill: "#119a91", strokeWidth: 2, fillOpacity:0.2}),
                ObsPlot.text(dataWithDates, { x: "timestamp", y: "value",  dy: -15, fontSize: "14px", text:(({value}: {value: number})=>(value && value<100? `${value}`: ""))}),
                ObsPlot.tickY(dataWithDates, {x: "timestamp", y: "value", stroke: "#119a91", strokeWidth: 2}),
                ObsPlot.tip(dataWithDates, ObsPlot.pointerX({ x: "timestamp", y: "value", fill: "#119a91", fontWeight: 600, anchor: "middle", stroke: "transparent", lineHeight:1.2, dy: -60,title: (d)=> `${formatTicks(new Date(d.timestamp),i18n.language, true)}\nValue: ${Math.round(d.value * 100) / 100}`})),
            ],
            
        });
        ref.current && ref.current.append(chart);
        return () => chart.remove();
    }, [data, dataWithDates, fontMemo, title, i18n.language]);

    return <>
        <div className="flex justify-between">
            <span className="">{title}</span>
            <ChildrenMenu className="w-fit" >
                <Export data={convertTimestampToIso(data)} filename={`${ressource?.slug}-${title}`}/>
            </ChildrenMenu>
        </div>
        <div ref={ref} className={classNames("plot", className)}></div>
    </>
}


function SparklinePlot({data, title, className, width, height, showXLabels}:{data:any, title?:string, className?:string, width?: number, height: number, showXLabels?: boolean}){
    const { i18n } = useTranslation("common")
    const ref = useRef<HTMLDivElement>(null);
    const dataWithDates = convertTimestampToDate(data)
    const isZerosOrEmpty = data.every((d: any) => !d.value)
    const { width: defaultWidth } = useElementSize(ref)
    useEffect(() => {
        if (data === undefined) return;
        const chart = ObsPlot.plot({
            height: height,
            width: width || defaultWidth,
            style: {
                background: "transparent",
                width: `${width}px`,
                overflow: "visible",
                fontSize: `100%`,
                paddingTop: "0px",
                paddingBottom: "5px",
                marginBlockEnd: "0px"
            },
            label: "",
            labelArrow: false,
            marginLeft: 4,
            marginTop:0,
            marginBottom: showXLabels ? 40 : 0,
            y: {
                grid: false,
                ticks: 0,
            },
            x: {
                tickFormat: (d)=> {
                    return formatTicks(d, i18n.language || 'fr-CA')
                },
                ticks: showXLabels ? 2 : 0,
                interval: "day",
                tickPadding: 10,
            },
            color: {
                type: "diverging",
                scheme: "burd",
            },
        
            marks: [
                // eslint-disable-next-line no-constant-condition
                ObsPlot.barY(dataWithDates, { x: "timestamp", y: "value", fill: "#119a91", strokeWidth: 2, fillOpacity:0.2}),
                ObsPlot.tickY(dataWithDates, {x: "timestamp", y: "value", stroke: "#119a91", strokeWidth: 2}),
                isZerosOrEmpty ? ObsPlot.ruleY([1], {stroke:"transparent"}): ObsPlot.ruleY([0], {stroke:"transparent"}),
                ObsPlot.tip(dataWithDates, ObsPlot.pointerX({ x: "timestamp", y: "value", fill: "#119a91", fontWeight: 600,  opacity: 1, anchor: "middle", stroke: "transparent", lineHeight:1.1, dy: -60,title: (d)=> `${formatTicks(new Date(d.timestamp),i18n.language, true)}\nValue: ${Math.round(d.value * 100) / 100}`})),
            ],
            
        });
        ref.current && ref.current.append(chart);
        return () => chart.remove();
    }, [data, dataWithDates, title, i18n.language]);

    return <div className="lg:flex items-center ">
        {title&& <div className=" lg:max-w-[120px]">
            <span className="text-sm">{title}</span>
        </div>}
        <div ref={ref} className={classNames("plot text-white", className)}></div>
        
    </div>
}


function fillMissingDatesWithZeros(dataWithDates: any, startDate: Date, endDate: Date): any {
    // Generate a list of all dates between startDate and endDate
    const generateDateList = (start: Date, end: Date): Date[] => {
        const dateList = [];
        const currentDate = start;
        while (currentDate <= end) {
            dateList.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateList;
    };

    const allDates = generateDateList(startDate, endDate);

    // Map over the list of dates and fill missing dates with zeros
    const filledData = allDates.map(date => {
        const existingData = dataWithDates.find((d: any) => {
            return d.timestamp.toISOString().split('T')[0] === date.toISOString().split('T')[0];
        });

        if (existingData) {
            return existingData;
        }

        return {
            value: 0,
            timestamp: date
        };
    });

    return filledData;
}


function HeatMapPlot({data, title, className}:{data:any, title:string, className?:string}){
    const { i18n } = useTranslation("common")
    const { ressource } = useRessource()
    const ref = useRef<HTMLDivElement>(null);
    // const { width: widthRef } = useElementSize(ref);
    // const width = useDebounce(widthRef, 500);
    // const fontMemo = useMemoCompare(fontSize, (prev, next) => {
    //     return prev === next
    // })
    
    const startDate = new Date(new Date().setDate(new Date().getDate() - 120));
    const endDate = new Date()
    const dataWithDates= fillMissingDatesWithZeros(convertTimestampToDate(data), startDate, endDate);
    
    useEffect(() => {
        if (data === undefined) return;
        const chart = ObsPlot.plot({
            padding:0,
            aspectRatio: 1,
            labelArrow: false,
            height: 300,
            style: {
                background: "transparent",
                overflow: "visible",
                width: "100%",
                fontSize: `18px`,
                marginBlockEnd: "0px"
            },
            x: {
                interval: "week",
                tickFormat: (d) => {
                    const monthNumber = d.getMonth() - 1;
                    return ObsPlot.formatMonth(i18n.language, "short")(monthNumber);
                },
                tickSize: 0,
                ticks: 4,
                paddingInner: 0.1,
                label: "",
            },
            y: {
                tickFormat: (d) => {
                    const day = d
                    if (day === 1) return ObsPlot.formatWeekday(i18n.language, "narrow")(d); // Monday
                    if (day === 3) return ObsPlot.formatWeekday(i18n.language, "narrow")(d); // Wednesday
                    if (day === 5) return ObsPlot.formatWeekday(i18n.language, "narrow")(d); // Friday
                    return null; // Hide other days
                },
                label: "",
                tickSize: 0
            },
            color: {
                range: ["#161a2150", "#0e4429", "#006d32", "#39d353", "#39d353"],
            },
            marks: [
                ObsPlot.cell(dataWithDates, ObsPlot.group({ fill: "max" }, {
                    y: (d) => d.timestamp.getUTCDay(),
                    x: (d) => d.timestamp,
                    fill: (d) => d.value,
                    inset: 1,
                })),
                ObsPlot.tip(dataWithDates, 
                            ObsPlot.pointerX({ x: "timestamp", 
                                        y: "value", 
                                        fill: "#119a91", 
                                        fontWeight: 600, 
                                        anchor: "middle", 
                                        stroke: "transparent", 
                                        lineHeight:1.2,
                                        dy: -60,
                                        title: (d)=> `${formatTicks(new Date(d.timestamp),i18n.language, true)}\nValue: ${Math.round(d.value * 100) / 100}`})),

            ],
        })
    
        ref.current && ref.current.append(chart);
        return () => chart.remove();
    }, [data, dataWithDates,  title, i18n.language]);

    return <>
        <div className="flex justify-between">
            <span className="">{title}</span>
            <ChildrenMenu className="w-fit" >
                <Export data={convertTimestampToIso(data)} filename={`${ressource?.slug}-${title}`}/>
            </ChildrenMenu>
        </div>
        <div ref={ref} className={classNames("plot heatmap", className)}></div>
    </>
}


export const Plot = {
    Float: FloatPlot,
    Counter: CounterPlot,
    Sparkline: SparklinePlot,
    HeatMap: HeatMapPlot
}