// Hooks
import { useTranslation } from "react-i18next"
import { useAuth } from "hooks/useAuth"
import { useMemo } from "react"
import { useRoleGuard } from "components/auth/roleguard"

// Utils
import classNames from "classnames"

// Components
import { Container } from "components/core/container"
import { MoreMenu } from "components/core/moreMenu"
import { RoleGuard } from "components/auth/roleguard"
import { ConditionalLink } from "components/core/link"

// Constants
import { to } from "constants/routes"

// Icons
import { LuSettings as SettingsIcon } from "react-icons/lu"
import { AiOutlineAppstoreAdd as AddIcon } from "react-icons/ai"

type Organization = {
    slug: string
    name: string
    access: Access
}

type Access = {
    role: string
} 
export function ListOrganizations(){
    const { t } = useTranslation("common")
    const { user } = useAuth()
    const list:Organization[] = useMemo(() => user?.organizations || [], [user])

    return <>
        <Container subtitle={t("your-organizations")} className={classNames()} noPadding>
            {
                list.map(({slug, name, access}:Organization) => {
                    return <OrganizationRow key={slug} slug={slug} name={name} access={access} />
                })
            }
            {list.length === 0 && <ListEmptyRow />}
        </Container>
    </>
}

function OrganizationRow({slug, name, access}: Organization){

    const { t } = useTranslation("common")

    const hasAccess = useRoleGuard({userRole: access.role, requiredRole: "admin"})

    const listProjectsMenu = [
        {
            name: t("settings"),
            path: to.manageOrgAccess(slug),
            itemClassName: "",
            Icon: SettingsIcon
        },
        {
            name: t("add-ressource.title"),
            path: to.addResource(slug),
            itemClassName: "[&>svg]:h-6 [&>svg]:w-6",
            Icon: AddIcon
        }
    ]

    return <>
        <ConditionalLink condition={hasAccess} to={to.manageOrgAccess(slug)}>

            <div className="block p-3 hover:bg-background/30">
                <div>
                    <div className="flex items-center justify-between p-1">
                        <div className="flex items-center">
                            <img className="flex items-center justify-center object-scale-down w-8 h-8 p-2 mr-4 border-2 rounded-xl border-white/20" src={`/organizations/logos/${slug}.png`} onError={({currentTarget})=>{currentTarget.onerror = null; currentTarget.src="/organizations/logos/default.svg"}} alt={"logo "+name} />
                            <div>{name}</div>
                        </div>
                        <RoleGuard userRole={access.role} requiredRole="admin" redirect={false}>
                            <div>
                                <MoreMenu items={listProjectsMenu} />
                            </div>
                        </RoleGuard>
                    </div>
                </div>
            </div>
        </ConditionalLink>
    </>

}

function ListEmptyRow(){
    const { t } = useTranslation("common")

    return <>
        <div className="flex items-center justify-center w-full h-32 text-gray-400">
            <div className="text-center">
                <div className="text-2xl font-bold">{t("no-organizations")}</div>
                <div className="mt-2">{t("no-organizations-message")}</div>
            </div>
        </div>
    </>
}