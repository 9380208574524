import { fetchCall } from "api"

interface UpdateUser {
    firstname?: string
    lastname?: string
    email?: string
    password?: string
    language?: string
}

/*
  Update user
*/
export function updateUser({firstname, lastname, email, language}: UpdateUser){
    const options = {
      method: "POST",
      endpoint: `/app/users`,
      body: { firstname, lastname, email, language }
    }
    return fetchCall(options)
}
