/* eslint-disable @typescript-eslint/no-unused-vars */

// Components
import { Container } from "components/core/container"
import { Link } from "react-router-dom";
import { Modal } from "components/core/modal";

// Icons
import { FaCreditCard as CreditIcon } from "react-icons/fa6";
import { IoCheckmarkCircleOutline as SuccessIcon } from "react-icons/io5";

// API
import { getCheckoutSession, getCustomerPortalUrl } from "api/organizations";

// Hooks
import { useTranslation } from "react-i18next"
import { useAPI } from "hooks/useAPI";
import { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { ButtonLink } from "components/core/buttons";


function PaymentsSettings() {
    const { t } = useTranslation("common")
    const { org: orgParams } = useParams()
    const { user } = useAuth()
    const [open, setOpen] = useState(false)
    const [searchParams] = useSearchParams()
    
    const checkoutSuccess = useMemo(() => {
        const isSuccess = searchParams.get("checkout") === "success"
        if (isSuccess) setOpen(true)
        return isSuccess
    }, [searchParams])
    
    const handleClose = () => {
        setOpen(false)
    }
    
    const orgStatus = user.organizations && user.organizations.find((o:any) => o.slug === orgParams)?.subscriptionStatus
    return <>
            {
                orgStatus === "checkout_required" && <CheckoutRequired/> 
            }
            {
                orgStatus === "paid" && <CheckoutPaid/>
            }
            {
                orgStatus === "payment_failed" && <CheckoutFailed/>
            }
            {
                orgStatus === "canceled" && <CheckoutCanceled/>
            }
            
            {
                !orgStatus && <FreeVersion/>
            }
            
            {
                checkoutSuccess && <Modal  open={open} setOpen={setOpen} onClose={handleClose} >
                    <div className="flex flex-col gap-4 pb-6 items-center text-center">
                        <SuccessIcon className="text-7xl text-teal-500 "/>
                        <span className="text-3xl  tracking-tight">{t("payments-settings.thank-you")}</span>
                        <span className="text-xl font-semibold tracking-tight">{t("payments-settings.checkout-success")}</span>
                        <span className="text-base tracking-tight text-container-foreground">{t("payments-settings.checkout-success-description")}</span>
                    </div>
                </Modal>
            }
            
            
    </>
}


function CheckoutRequired(){
    const { t } = useTranslation("common")

    const { org: orgParams } = useParams()

    const params = useMemo(() => ({orgId: orgParams}), [orgParams])

    const [result,] = useAPI(getCheckoutSession, params ,{immediate: true})

    return <>
        <Container className="transition-all border-2 cursor-pointer bg-yellow-900/20 border-yellow-500" >
            <div className="flex items-start gap-4 ">
                <div>
                    <h2 className="font-bold text-yellow-500">{t("payments-settings.checkout-required.title")}</h2>
                    <p className="text-yellow-500">{t("payments-settings.checkout-required.description")}</p>
                    <ButtonLink to={result?.url} disabled={!result?.url} className="!p-2 mt-2" theme="warning">{t("payments-settings.checkout-required.link")}</ButtonLink>
                    {result && !result.url && <p className="text-white mt-2 text-sm ">{t("payments-settings.checkout-required.no-link")}</p>}
                </div>
            </div>
            
        </Container>
    </>
}

function CheckoutPaid(){
    const { t } = useTranslation("common")

    const { org: orgParams } = useParams()

    const params = useMemo(() => ({orgId: orgParams}), [orgParams])

    const [result,] = useAPI(getCustomerPortalUrl, params ,{immediate: true})

    return <>
    <Container className="transition-all bg-container" >
            <div className="flex items-start gap-4 ">
                <div>
                    <h2 className="font-bold ">{t("payments-settings.checkout-paid.title")}</h2>
                    <p className="text-gray-300">{t("payments-settings.checkout-paid.description")}</p>
                    <ButtonLink to={result?.url} disabled={!result?.url} className="!p-2 mt-2">{t("payments-settings.checkout-paid.link")}</ButtonLink>
                    {result && !result.url && <p className=" mt-2 text-sm text-yellow-500">{t("payments-settings.checkout-paid.no-link")}</p>}
                </div>
            </div>
        </Container>

    </>
}

function CheckoutFailed(){
    const { t } = useTranslation("common")

    const { org: orgParams } = useParams()

    const params = useMemo(() => ({orgId: orgParams}), [orgParams])

    const [result,] = useAPI(getCustomerPortalUrl, params ,{immediate: true})

    return <>
    <Container className="transition-all  bg-red-700/30" >
            <div className="flex items-start gap-4 ">
                <div>
                    <h2 className="font-bold ">{t("payments-settings.checkout-failed.title")}</h2>
                    <p className="text-gray-300">{t("payments-settings.checkout-failed.description")}</p>
                    <ButtonLink to={result?.url} disabled={!result?.url} theme="alert" className="!p-2 mt-2">{t("payments-settings.checkout-failed.link")}</ButtonLink>
                    {result && !result.url && <p className=" mt-2 text-sm text-red-200 ">{t("payments-settings.checkout-failed.no-link")}</p>}
                </div>
            </div>
        </Container>
     
    </>
}

function CheckoutCanceled(){
    const { t } = useTranslation("common")

    return <>
    <Container className="transition-all  bg-container" >
            <div className="flex items-start gap-4 ">
                <div>
                    <h2 className="font-bold ">{t("payments-settings.checkout-canceled.title")}</h2>
                    <p className="text-gray-300">{t("payments-settings.checkout-canceled.description")}</p>
                </div>
            </div>
        </Container>

    </>
}

function FreeVersion(){
    const { t } = useTranslation("common")

    return <>
        <Container className="transition-all border-2" >
            <div className="flex items-center gap-4">
                <div className="flex flex-col w-full ">
                    <span className="text-container-foreground">{t("payments-settings.free-version")}</span>
                </div>
            </div>
        </Container>

    </>
}

export const Payments = {
    Settings: PaymentsSettings

}