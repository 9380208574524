// Utils
import classNames from "classnames"

// Icons
import { FiSearch as SearchIcon } from "react-icons/fi"
import { MdOutlineSync as SyncIcon } from "react-icons/md";

// Components
import { TextInput } from "components/core/inputs"
import { LoadingBar } from "components/core/loadingBar"
import { Filter } from "components/filters/filter"
import { Dispatch, SetStateAction } from "react"

interface ContainerProps {
    className?: string,
    children?: React.ReactNode,
    props?: any,
    hoverEffect?: boolean,
    title?: string
    subtitle?: string,
    noPadding?: boolean
    theme?: keyof typeof themes
}

export const themes = {
    blank: "bg-inherit",
    default: "bg-container shadow border-container-light border",
    outlined: "bg-inherit border-0 ring-1 ring-inset ring-gray-200/70"
}

export function Container({className, children, hoverEffect, title, subtitle, noPadding=false, props, theme="default"}: ContainerProps){
    return <div className={classNames("rounded-md", themes[theme], className, hoverEffect && "hover:cursor-pointer hover:bg-container-light")} {...props}>
        {(title || subtitle) && <ContainerHeader title={title} subtitle={subtitle} theme={theme}/>}
        <div className={classNames(!noPadding && "p-4")}>
            {children}
        </div>
    </div>
}

const searchThemes = {
    blank: "bg-inherit",
    default: "bg-container-light",
    outlined: "bg-inherit border-0 ring-1 ring-inset ring-gray-200/70"
}

interface SearchContainerProps {
    loading: boolean,
    children: React.ReactNode,
    placeholder: string,
    noSearch?: boolean,
    filters?: any[],
    selectedFilter?: any,
    setSelectedFilter?: Dispatch<SetStateAction<any>>,
    setQuery: (v:string) => void,
    theme?: keyof typeof searchThemes,
    hideLoadingBar?: boolean,
    reload?: () => void
    loadingReload?: boolean
}
export function SearchContainer({loading, children, placeholder, noSearch, filters, selectedFilter, setSelectedFilter, setQuery, theme="default", hideLoadingBar=false, reload, loadingReload} : SearchContainerProps){
    
    return <Container noPadding theme={theme}>
        <div className={classNames("relative rounded-t-md focus-within:border-1 border-gray-300 border-inset", searchThemes[theme])}>
            <div className="flex items-center flex-1">
                <div className="flex-1">
                {!noSearch && <TextInput Icon={SearchIcon} placeholder={placeholder} className="!py-2.5 focus:ring-0 w-full outline-none rounded-b-none shadow-none bg-inherit rounded-md" theme="blank" onChange={setQuery} />}
                </div>
                <div className="flex items-center justify-end py-2.5 mx-2 gap-2">
                    {filters &&  <Filter filters={filters} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}/>}
                    {reload && <button onClick={reload} className="mr-1"><SyncIcon className={classNames("text-container-foreground size-5", loadingReload && "animate-spin")}/></button>}
                </div>
            </div>
        </div>
        {
            !hideLoadingBar && <LoadingBar loading={loading}/>
        }
        
        {children}
    </Container>
}

const headerThemes = {
    blank: "bg-inherit",
    default: "bg-container-light text-container-foreground",
    outlined: "bg-inherit border-0 ring-1 ring-inset ring-gray-200/70"
}

export function ContainerHeader({title, subtitle, children, className, theme="default"}: ContainerProps){
    return <div className={classNames("flex p-4 leading-3 rounded-t-md", className, headerThemes[theme])}>
            <div className={classNames("flex flex-col justify-center ")}>
                <h3 className={classNames("font-bold leading-3", (subtitle && title) && "mb-2")}>{title}</h3>
                <p className="text-sm font-medium leading-3 text-container-foreground">{subtitle}</p>
            </div>
            {
                children
            }
    </div>
}