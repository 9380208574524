// Components
import { SearchContainer,} from "components/core/container"
import { Spinner } from "components/core/spinner"
import { Skeleton } from "components/core/skeletons"
import { Paginate } from "components/core/paginate"
import { PageTitle } from "components/core/typo"
import { Pill } from "components/core/pill"
import { JobRow } from "components/jobs/list"

// Icons
import { AiOutlineClose as RemoveIcon } from "react-icons/ai"

// Utils
import { format } from "utils/format"

// Hooks
import { useTranslation } from "react-i18next"
import { useFilterDates } from "hooks/useFilterDates"
import { useRessourceJobs } from "hooks/useRessourceJobs"

function RessourceJobsList() {
    const { t, i18n } = useTranslation("common")
    const { filter:filterDates, setFilter:setFilterDates } = useFilterDates()
    const { jobs, filter:selectedFilter, setFilter:setSelectedFilter, filters, loading, paging, setPage, setQuery, execute:reload } = useRessourceJobs()
    
    const resetPage = () => {
        setPage(1)
    }
    
    if (!jobs && loading) return <>
        <Skeleton className="h-36" />
    </>
    return <>
        <div className="flex flex-wrap items-end justify-between gap-y-2 gap-x-2">
            <div className="flex flex-col items-start gap-x-3 gap-y-2">
                <PageTitle noMargin>{t("recent-jobs")}</PageTitle>
                {
                    filterDates.length > 0 && <Pill theme="accent"><span className="whitespace-pre-wrap">{filterDates[0] && format.date(filterDates[0], i18n.language)}{filterDates[1] && " - "}</span>
                        <span>
                            {filterDates[1] && format.date(filterDates[1], i18n.language)}
                        </span>
                        <RemoveIcon onClick={()=>setFilterDates([])} className="ml-1 cursor-pointer"/>
                    </Pill>
                }
            </div>
        </div>
        <div className="flex flex-col mt-3 lg:flex-row gap-x-5 gap-y-8">
            <div className="w-full">
                <SearchContainer loading={jobs && loading || false} 
                        reload={reload}
                        loadingReload={loading}
                        placeholder={t("search-jobs")} 
                        filters={filters}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={(v:any)=>{setSelectedFilter && setSelectedFilter(v); resetPage()}}
                        setQuery={setQuery}
                >
                    {
                        jobs && jobs.map((job: Job) => {
                            return <JobRow
                                key={'job-'+job.id}
                                {...job}
                            />
                        })
                    }
                    {
                        loading && !jobs && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
                    }
                    {
                        paging && paging.numResults === 0 && <ListEmptyRow />
                    }
                </SearchContainer>
                <Paginate {...paging} setPage={setPage} />
            </div>
        </div>
        
    </>
}

function ListEmptyRow() {

    const { t } = useTranslation("common")

    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("no-jobs")}
            </div>
        </div>
    </>
}

export const RessourceJobs = {
    List: RessourceJobsList
}
