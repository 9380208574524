import { format } from "utils/format"

/**
 * 
 * @param data JSON data to convert to CSV
 * @param filename Name of the file to download
 * @param children Element that will trigger the download on click
 */
export function ExportToCsv({data, filename, children}:{data:any, filename:string, children:React.ReactNode}){

    const downloadCSV = () => {
        const csv = format.jsonToCSV(data);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    return <>
        <div onClick={downloadCSV}>
            {children}
        </div>
    </>
}