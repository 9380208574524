import { authFetch } from 'api/auth/refresh';

interface FetchCallOptions {
  method: "GET" | "POST" | "PUT" | "DELETE | PATCH" | string;
  endpoint: string;
  form?: any;
  body?: any;
  query?: any;
  props?: any;
  headers?: any;
}

export interface QueryOptions {
  fields?: string[];
  page?: number;
  limit?: number;
  orderBy?: string;
  orderDirection?: "desc" | "asc";
}

/* Function to convert camelToSnake case */
export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const fetchCall = ({method, endpoint, form, body, query, headers, ...props} : FetchCallOptions)=>{
    const controller = new AbortController();
    const { signal } = controller;
    const url = new URL(`${process.env.REACT_APP_API_SERVER_URL}${endpoint}`)

    const options:any = {method: method, signal:signal, credentials: 'include', headers: headers || {}, ...props}

    if (form){
      const f = new FormData();
      Object.keys(form).forEach((key)=>{
        f.append(key, form[key]);
      })
      options["body"] = f
    }

    if (body){
      options["body"] = JSON.stringify(body)
      options.headers["Content-Type"] = "application/json"
    }

    if (query){
      Object.keys(query).forEach(key => {
        if (query[key]!=null){
          if (Array.isArray(query[key])) {
            for (let i = query[key].length - 1; i >= 0; i--) {
              url.searchParams.append(camelToSnake(key), query[key][i])
            }
          }
          else {
            url.searchParams.append(camelToSnake(key), query[key])
          }
        }
      })
    }
    return [authFetch(url, options), controller];
}