// Components
import { Container, ContainerHeader } from "components/core/container"
import { Skeleton } from "components/core/skeletons"
import { Paginate } from "components/core/paginate"
import { FilterDatesModal } from "components/ressources/filter"
import { LoadingBar } from "components/core/loadingBar"
import { Spinner } from "components/core/spinner"
import { PageTitle } from "components/core/typo"
import { Command, CommandGroup, CommandInput, CommandItem } from "components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"
import { Modal } from "components/core/modal"
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group"
import { Label } from "components/ui/label"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "components/ui/hover-card"
import { AnnotationPlot } from "components/annotations/plot"
import { MergeEditor } from "components/core/codemirror/merge";
import { OrgMembersContextProvider } from "contexts/org-members"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip"

// Constants
import { to } from "constants/routes"
import { annotedBySets, labelSets, defaultAnnotatedBySet, defaultLabelSet } from "contexts/annotations"

// Utils
import { format as utilsFormat } from "utils/format"
import classNames from "classnames"

// Icons
import { AiOutlineCalendar as CalendarIcon } from "react-icons/ai"
import { Check } from "lucide-react"
import { RiArrowDropDownLine as DropdownIcon } from 'react-icons/ri'

// API
import { getAnnotationLabels } from "api/annotations"
import { getAnnotation } from "api/annotations";

// Hooks
import { useAnnotations } from "hooks/useAnnotations"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Filter } from "components/filters/filter"
import { useState, useMemo, useCallback, Dispatch, SetStateAction } from "react"
import { useMediaQuery } from "hooks/useMediaQuery"
import { useSearch } from "hooks/useSearch"
import { useDebounce } from "hooks/useDebounce"
import { useAPI } from "hooks/useAPI"
import { useSearchParams } from "react-router-dom";
import { useOrgMembers } from "hooks/useOrgMembers"

export function AnnotationsList() {
    const { t } = useTranslation("common")
    const [open, setOpen] = useState(false)

    const { annotations, loading, paging, setPage, filter: selectedFilter, filters, setFilter: setSelectedFilter, datesFilter, setDatesFilter, hasAnyFilter, resetAllFilters } = useAnnotations()

    if (!annotations && loading) return <>
        <Skeleton className="h-44" />
    </>
    return <>
        <div className="flex flex-wrap items-end justify-between gap-y-2 gap-x-2">
            <PageTitle noMargin>{t("annotations.title")}</PageTitle>

            {
                hasAnyFilter && <>
                    <div className="flex justify-end">
                        <span onClick={resetAllFilters} className="text-container-foreground hover:text-gray-300 transition-colors text-sm cursor-pointer">{t("remove-filters")}</span>
                    </div>
                </>
            }
        </div>
        <Container noPadding className="mt-3">
            <ContainerHeader className="px-4 py-3 text-sm">
                <div className="flex flex-col sm:flex-row flex-wrap items-center justify-between w-full gap-2 gap-y-5">
                    <p className="flex text-container-foreground">{t("annotations.list")}</p>
                    <div className="flex items-center flex-wrap gap-y-4 sm:justify-end gap-x-2 justify-evenly w-full sm:w-fit">
                        <AuthorFilter />
                        <LabelsFilter />
                        <Filter selectedFilter={selectedFilter} filters={filters} setSelectedFilter={setSelectedFilter} />
                        <CalendarIcon onClick={() => setOpen(true)} className={classNames("w-5 h-5 cursor-pointer", datesFilter.length > 0 ? "text-primary" : " text-container-foreground")} />
                    </div>
                </div>
            </ContainerHeader>
            <LoadingBar loading={annotations && loading} />

            {
                annotations?.map(({ id, idx, privateId, createdAt, viewCount, labels }: Annotation) => {
                    return <AnnotationRow key={privateId}
                        id={id}
                        idx={idx}
                        privateId={privateId}
                        viewCount={viewCount}
                        createdAt={createdAt}
                        labels={labels}
                    />
                })
            }

            {
                loading && !annotations && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
            }
            {
                annotations && annotations.length === 0 && <ListEmptyRow />
            }
        </Container>
        <Paginate {...paging} setPage={setPage} loading={loading} />
        <FilterDatesModal open={open} setOpen={setOpen} dates={datesFilter} setDates={setDatesFilter} />
    </>
}

function AuthorFilter() {
    const { t } = useTranslation("common")
    const isDesktop = useMediaQuery('sm')
    const [open, setOpen] = useState(false)
    const { annotatedBy, annotatedBySet } = useAnnotations()
    const hasFilters = annotatedBy.length > 0 || annotatedBySet !== defaultAnnotatedBySet

    return <>
        <OrgMembersContextProvider>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger>
                    <>
                        <div aria-expanded={open} className={classNames("flex items-center gap-1 cursor-pointer transition-colors", hasFilters && "text-primary")}>
                            <p className="">{t("annotators")}</p>
                            <DropdownIcon className="text-xl" />
                        </div>
                    </>
                </PopoverTrigger>
                {
                    isDesktop ? <>
                        <PopoverContent className="w-[230px] p-0">
                            <AuthorFilterCommand />
                        </PopoverContent>
                    </>
                        :
                        <>
                            <Modal open={open} setOpen={setOpen} title={t("annotations.labels.title")}>
                                <Modal.Body>
                                    <AuthorFilterCommand />
                                </Modal.Body>
                            </Modal>
                        </>
                }

            </Popover>
        </OrgMembersContextProvider>
    </>
}

export function AuthorFilterCommand() {

    const { members, loading } = useOrgMembers()
    const { annotatedBy, setAnnotatedBy, annotatedBySet, setAnnotatedBySet } = useAnnotations()

    return <>
        <AuthorFilterCommandControlled members={members} loading={loading} annotatedBy={annotatedBy} setAnnotatedBy={setAnnotatedBy} annotatedBySet={annotatedBySet} setAnnotatedBySet={setAnnotatedBySet} />
    </>
}

interface AuthorFilterCommandControlledProps {
    members?: any[]
    loading?: boolean
    annotatedBy: string[];
    setAnnotatedBy: Dispatch<SetStateAction<string[]>>;
    annotatedBySet: typeof annotedBySets[number];
    setAnnotatedBySet: Dispatch<SetStateAction<typeof annotedBySets[number]>>;
}
export function AuthorFilterCommandControlled({ members, loading, annotatedBy, setAnnotatedBy, annotatedBySet, setAnnotatedBySet }: AuthorFilterCommandControlledProps) {
    const { t } = useTranslation("common")

    const [search, setSearch] = useState("");

    const filteredMembers = useMemo(() => {
        return members?.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
    }, [search, members])

    const handleSelect = (selected: string) => {
        if (annotatedBy.includes(selected)) {
            setAnnotatedBy(annotatedBy.filter((email: any) => email !== selected))
        } else {
            setAnnotatedBy((prev: any) => [...prev, selected])
        }
    }

    const handleAnnotedBySet = (set: typeof annotedBySets[number]) => {
        setAnnotatedBySet(set)
    }

    const handleReset = () => {
        setAnnotatedBy([])
        setAnnotatedBySet(defaultAnnotatedBySet)
    }

    const hasFilters = annotatedBy.length > 0 || annotatedBySet !== defaultAnnotatedBySet

    return <>

        <Command shouldFilter={false} >
            <CommandInput placeholder={t("annotations.authors.search")} onValueChange={setSearch} />
            <LoadingBar loading={loading} />

            <CommandGroup>
                <div className="max-h-[240px] overflow-y-auto">
                    {
                        filteredMembers?.map(({ email, name }) => {
                            return <CommandItem className={classNames("my-1 hover:!bg-background cursor-pointer gap-y-4 text-container-foreground")} key={email} value={email} onSelect={() => handleSelect(email)}>
                                <Check className={classNames(annotatedBy.includes(email) ? "opacity-100 text-primary" : "opacity-0", "mr-2 h-4 w-4")} />
                                {name}
                            </CommandItem>
                        })
                    }
                    {
                        (!filteredMembers || filteredMembers.length === 0) && loading && <div className="flex items-center justify-center h-14"><Spinner /></div>
                    }
                    {
                        filteredMembers && filteredMembers.length === 0 && !loading && <p className="pl-8 py-2.5 text-container-foreground text-sm">{t("annotations.authors.no-authors")}</p>
                    }
                </div>
            </CommandGroup>

            <div className="border-t">
                <CommandGroup >
                    <p className="py-1 mt-1 mb-1 text-sm text-container-foreground px-2">{t("annotations.authors.sets.title")}</p>
                    <RadioGroup className="gap-1" value={annotatedBySet} onValueChange={handleAnnotedBySet}>

                        {
                            annotedBySets.map((set) => {
                                return <div className="flex items-center space-x-2" key={set}>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild >
                                                <div className="flex items-center space-x-2 hover:bg-background w-full px-2 rounded-md cursor-pointer">
                                                    <RadioGroupItem value={set} id={set} />
                                                    <Label htmlFor={set} className="text-container-foreground cursor-pointer w-full py-2.5">{t(`annotations.authors.sets.${set}`)}</Label>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent align="start">{t(`annotations.authors.sets.${set}-description`)}</TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            })
                        }


                    </RadioGroup>
                </CommandGroup>

            </div>
            {
                hasFilters && <div className="text-sm px-2.5 py-2"><span className="text-red-600 hover:text-red-500 cursor-pointer" onClick={handleReset}>{t("reset")}</span></div>
            }
        </Command>
    </>
}

function LabelsFilter() {
    const isDesktop = useMediaQuery('sm')

    const { t } = useTranslation("common")
    const { labels, labelSet } = useAnnotations()
    const [open, setOpen] = useState(false)
    const hasFilters = labels.length > 0 || labelSet !== defaultLabelSet

    return <>


        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <>
                    <div aria-expanded={open} className={classNames("flex items-center gap-1 cursor-pointer", hasFilters && "text-primary")}>
                        <p className="">{t("annotations.labels.title")}</p>
                        <DropdownIcon className="text-xl" />
                    </div>
                </>
            </PopoverTrigger>
            {
                isDesktop ? <>
                    <PopoverContent className="w-[230px] p-0">
                        <LabelsFilterCommand />
                    </PopoverContent>
                </>
                    :
                    <>
                        <Modal open={open} setOpen={setOpen} title={t("annotations.labels.title")}>
                            <Modal.Body>
                                <LabelsFilterCommand />
                            </Modal.Body>
                        </Modal>
                    </>
            }


        </Popover>

    </>
}

function LabelsFilterCommand() {

    const { labels: selectedLabels, setLabels: setSelectedLabels, labelSet, setLabelSet } = useAnnotations()

    return <>
        <LabelsFilterCommandControlled selectedLabels={selectedLabels} setSelectedLabels={setSelectedLabels} labelSet={labelSet} setLabelSet={setLabelSet} />
    </>
}

interface LabelsFilterCommandControlledProps {
    selectedLabels: string[],
    setSelectedLabels: Dispatch<SetStateAction<string[]>>;
    labelSet: typeof labelSets[number];
    setLabelSet: Dispatch<SetStateAction<typeof labelSets[number]>>;
}
export function LabelsFilterCommandControlled({ selectedLabels, setSelectedLabels, labelSet, setLabelSet }: LabelsFilterCommandControlledProps) {
    const { t } = useTranslation("common")

    const [search, setSearch] = useState("");

    const debounceSearch = useDebounce(search, 800);
    const { ressource: ressourceParams } = useParams()
    const requestParams = useMemo(() => ({ ressourceId: ressourceParams, search }), [debounceSearch])
    const [labelsList, { loading }] = useSearch(getAnnotationLabels, requestParams, { limit: 999, errorToastMessage: t("annotations.labels.error-fetching") })

    const handleSelect = (selectedId: string) => {
        if (selectedLabels.includes(selectedId)) {
            setSelectedLabels(selectedLabels.filter((id) => id !== selectedId))
        } else {
            setSelectedLabels((prev: any) => [...prev, selectedId])
        }
    }

    const handleLabelSet = (labelSet: typeof labelSets[number]) => {
        if (labelSet === "empty") {
            setSelectedLabels([])
        }
        setLabelSet(labelSet)
    }

    const handleSearch = (value: string) => {
        setSearch(value)
    }

    const handleReset = () => {
        setSelectedLabels([])
        setLabelSet(defaultLabelSet)
    }

    const hasFilters = selectedLabels.length > 0 || labelSet !== defaultLabelSet

    return <>

        <Command shouldFilter={false}>
            <CommandInput onValueChange={handleSearch} placeholder={t("annotations.labels.search")} />
            <LoadingBar loading={loading} />

            <CommandGroup>
                <div className="max-h-[240px] overflow-y-auto">
                    {
                        labelsList?.map(({ label, id }) => {
                            return <CommandItem className={classNames("my-1 hover:!bg-background cursor-pointer gap-y-4 text-container-foreground")} key={id} value={label} onSelect={() => handleSelect(id)}>
                                <Check className={classNames(selectedLabels.includes(id) ? "opacity-100 text-primary" : "opacity-0", "mr-2 h-4 w-4")} />
                                {label}
                            </CommandItem>
                        })
                    }
                </div>
                {
                    (!labelsList || labelsList?.length === 0) && loading && <div className="flex items-center justify-center h-14"><Spinner /></div>
                }
                {
                    labelsList && labelsList.length === 0 && !loading && <p className="p-3 text-base text-container-foreground">{t("annotations.labels.no-labels")}</p>
                }

            </CommandGroup>

            <div className="border-t">
                <CommandGroup>
                    <p className="py-1 mt-1 mb-1 text-sm text-container-foreground px-2">{t("annotations.labels.labelSets.title")}</p>
                    <RadioGroup className="gap-1" value={labelSet} onValueChange={handleLabelSet}>

                        {
                            labelSets.map((labelSet) => {
                                return <div key={labelSet}>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild >
                                                <div className="flex items-center space-x-2 hover:bg-background w-full px-2 rounded-md cursor-pointer">
                                                    <RadioGroupItem value={labelSet} id={labelSet} />
                                                    <Label htmlFor={labelSet} className="text-container-foreground cursor-pointer w-full py-2.5">{t(`annotations.labels.labelSets.${labelSet}`)}</Label>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent align="start">{t(`annotations.labels.labelSets.${labelSet}-description`)}</TooltipContent>
                                        </Tooltip>

                                    </TooltipProvider>
                                </div>
                            })
                        }
                    </RadioGroup>
                </CommandGroup>

            </div>
            {
                hasFilters && <div className="text-sm px-2.5 py-2"><span className="text-red-600 hover:text-red-500 cursor-pointer" onClick={handleReset}>{t("reset")}</span></div>
            }
        </Command>

    </>
}

interface AnnotationRowProps {
    id: number,
    idx: number,
    privateId: string,
    createdAt: string,
    viewCount?: any,
    labels: Array<AnnotationLabel>
}

interface LabelOccurence {
    id: string
    count: number
    label: string
}

function AnnotationRow({ idx, privateId, createdAt, labels }: AnnotationRowProps) {
    const { t, i18n } = useTranslation("common")
    const { org: orgParams, ressource: ressourceParams } = useParams()
    const [searchParams] = useSearchParams()
    const { labels: filterLabels } = useAnnotations()
    const params = useMemo(() => ({ ressourceId: ressourceParams, annotationId: privateId, fields: ['idx', 'private_id', 'plotting',] }), [privateId])

    const validateParams = useCallback((params: any) => {
        return params.annotationId !== undefined
    }, [])

    const [annotation, { execute, loading }] = useAPI(getAnnotation, params, { immediate: false, validateParams })

    const handleToggleOpen = (isOpen: boolean) => {
        if (!annotation && isOpen) execute()
    }

    // Creating a sorted array of labels by count
    const sortedLabels = useMemo(() => {
        if (!labels) return
        const labelCounts = labels.reduce((acc: Record<string, LabelOccurence>, { label: { label, id } }) => {
            acc[id] = acc[id] ? { ...acc[id], count: acc[id].count + 1 } : { label, count: 1, id };
            return acc;
        }, {});

        return Object.values(labelCounts).sort((a, b) => b.count - a.count);
    }, [labels]);

    const displayLabels = filterLabels.length ? sortedLabels?.filter(l => filterLabels.includes(l.id)).slice(0, 3) : sortedLabels?.slice(0, 3);
    const notShownLabels = sortedLabels?.slice(displayLabels?.length);

    return <>
        <Link className="group" to={to.annotation(orgParams || "", ressourceParams || "", privateId) + "?" + searchParams.toString()}>
            <HoverCard openDelay={500} closeDelay={0} onOpenChange={handleToggleOpen}>
                <HoverCardTrigger asChild>
                    <div className="block p-2 px-3 group-even:bg-container-light group-even:group-hover:bg-background hover:bg-background hover:cursor-pointer">
                        <div className="items-center justify-between p-1 lg:flex lg:space-x-3 text-container-foreground">

                            <div className="flex items-center lg:w-1/2">
                                <p className="w-1/2 font-semibold text-gray-300 lg:w-1/12 sm:mr-10 sm:block">{idx}</p>
                                <p className="w-2/3 text-sm text-right lg:text-left sm:text-base">
                                    {utilsFormat.datetime(new Date(createdAt), i18n.language)}
                                </p>
                            </div>

                            <div className="flex items-center flex-wrap gap-2 justify-end font-mono text-sm sm:text-base pt-2 lg:pt-0">
                                {
                                    displayLabels && displayLabels.length > 0 ? <>
                                        {
                                            displayLabels.map(label => (
                                                <div key={label.id} className="flex pl-2 bg-gray-900 rounded-md text-sm items-center">
                                                    <span className="leading-none py-2 max-w-[100px] whitespace-nowrap text-ellipsis overflow-hidden">{label.label}</span>
                                                    <span className="px-2 py-2 h-full text-sm leading-4 ml-2 text-gray-200 rounded-r-md bg-slate-500">{label.count}</span>
                                                </div>
                                            ))
                                        }
                                        {
                                            notShownLabels && notShownLabels.length > 0 && <>
                                                <span className="py-1.5 px-2 rounded-md text-sm overflow-hidden">
                                                    <span className="mr-1">+</span>{notShownLabels.length}
                                                </span>
                                            </>
                                        }
                                        </> :
                                        <p className="opacity-80">{t("no-label")}</p>
                                }

                            </div>


                        </div>

                    </div>


                </HoverCardTrigger>
                <HoverCardContent alignOffset={10} sideOffset={-10} align="end" className="sm:w-[470px] w-full !p-0">
                    {
                        (loading || !annotation) ? <>
                            <div className="flex items-center justify-center h-40">
                                <Spinner />
                            </div>
                        </>
                            :
                            <>

                                {
                                    annotation && annotation.plotting ?
                                        annotation.plotting.vizualisation === "plot" ? <>
                                            <div className="pt-3 pb-0 text-center">{idx}</div>
                                            <AnnotationPlot padding="0px" width={380} height={180} hideTitle plotting={annotation.plotting} className="w-full pb-2" />
                                        </>
                                            :
                                            annotation.plotting.vizualisation === "editor-diff" ? <>
                                                <div className="pt-2 pl-3 pb-2 text-center">{idx}</div>
                                                <MergeEditor {...annotation.plotting.props} />
                                            </>
                                                :
                                                null
                                        :
                                        <div className="flex flex-col items-center justify-center py-10 px-3">
                                            <h2 className="text-xl font-bold">{t("annotations.not-supported")}</h2>
                                            <p className="max-w-md px-3 text-center text-container-foreground">{t("annotations.not-supported-message")}</p>
                                        </div>
                                }
                            </>
                    }

                </HoverCardContent>
            </HoverCard>
        </Link>
    </>
}

function ListEmptyRow() {
    const { t } = useTranslation("common")

    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("annotations.no-annotations")}
            </div>
        </div>
    </>
}
