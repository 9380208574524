import { fetchCall, QueryOptions } from 'api'


// List all the annotations Workspace for an organization
interface getOrgAnnotationWorkspacesOptions extends QueryOptions {
    orgId: string
}
export function getOrgAnnotationWorkspaces({orgId, fields, limit, page}:getOrgAnnotationWorkspacesOptions){
    const options = {
        method: "GET",
        endpoint: `/app/organization/${orgId}/annotation-workspaces`,
        query: {
            fields,
            page,
            limit
        },
    };
    return fetchCall(options);
}

// Get an annotation Workspace
interface getAnnotationWorkspaceOptions extends QueryOptions {
    ressourceId: string
}
export function getAnnotationWorkspace({ressourceId, fields, limit, page}:getAnnotationWorkspaceOptions){
    const options = {
        method: "GET",
        endpoint: `/app/annotation-workspace/${ressourceId}`,
        query: {
            fields,
            limit,
            page
        },
    };
    return fetchCall(options);
}

// Update an annotation Workspace
interface updateAnnotationWorkspaceOptions {
    ressourceId: string,
    workspace: {
        description?: string,
        inputExtractionScript?: string,
        outputExtractionScript?: string
    }
}
export function updateAnnotationWorkspace({ressourceId, workspace}:updateAnnotationWorkspaceOptions){
    const options = {
        method: "PUT",
        endpoint: `/app/annotation-workspace/${ressourceId}`,
        body: workspace
    };
    return fetchCall(options);
}

// Get annotations
interface getAnnotationsOptions extends QueryOptions {
    ressourceId: string,
    orderBy?: string,
    orderDirection?: "desc" | "asc",
    createdFromDate?: string,
    createdToDate?: string,
    label?: string,
    labelSet?: string,
    labels?: string[],
    annotatedBy?: string[],
    annotatedBySet?: string
}
export function getAnnotations({ressourceId, fields, limit, page, orderBy, orderDirection, createdFromDate, createdToDate, label, labels, labelSet, annotatedBy, annotatedBySet}:getAnnotationsOptions){
    const options = {
        method: "GET",
        endpoint: `/app/annotations/${ressourceId}`,
        query: {
            fields,
            page,
            limit,
            orderBy,
            orderDirection,
            createdFromDate,
            createdToDate,
            label,
            labels,
            labelSet,
            annotatedBy,
            annotatedBySet
        },
    };
    return fetchCall(options);
}

// Get an annotation
interface getAnnotationOptions extends QueryOptions {
    ressourceId: string,
    annotationId: string
}
export function getAnnotation({ressourceId, annotationId, fields}:getAnnotationOptions){
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressourceId}/annotations/${annotationId}`,
        query: {
            fields
        },
    };
    return fetchCall(options);
}

interface getAnnotationPreviousNextOptions extends getAnnotationOptions {
    
    createdFromDate?: string,
    createdToDate?: string,
    updatedFromDate?: string,
    updatedToDate?: string,
    labels?: string[],
    labelSet?: string,
    annotatedBy?: string[],
    annotatedBySet?: string,
}
export function getAnnotationPreviousNext({ressourceId, annotationId, fields, createdFromDate, createdToDate, updatedFromDate, updatedToDate, labels, labelSet, annotatedBy, annotatedBySet, orderBy}:getAnnotationPreviousNextOptions){
    const options = {
        method: "GET",
        endpoint: `/app/ressources/${ressourceId}/annotations/${annotationId}/previous-next`,
        query: {
            fields,
            createdFromDate,
            createdToDate,
            updatedFromDate,
            updatedToDate,
            labels,
            labelSet,
            annotatedBy,
            annotatedBySet,
            orderBy
        },
    };
    return fetchCall(options);
}

// Update an annotation
interface updateAnnotationOptions {
    ressourceId: string,
    annotationId: string,
    annotation: {
        label?: string
    }
    fields?: string[]
}
export function updateAnnotation({ressourceId, annotationId, annotation, fields}:updateAnnotationOptions){
    const options = {
        method: "PATCH",
        endpoint: `/app/ressources/${ressourceId}/annotations/${annotationId}`,
        body: annotation,
        query: {
            fields
        }
    };
    return fetchCall(options);
}


// Update an annotation
interface toggleAnnotationLabelsOptions {
    ressourceId: string,
    annotationId: string,
    labelId: string
}
export function toggleAnnotationLabels({ressourceId, annotationId, labelId}:toggleAnnotationLabelsOptions){
    const options = {
        method: "POST",
        endpoint: `/app/ressources/${ressourceId}/annotations/${annotationId}/toggle-label`,
        body: {labelId},
    };
    return fetchCall(options);
}


// Get annotations-labels
interface getAnnotationsLabelsOptions extends QueryOptions {
    ressourceId: string,
    search?: string
}
export function getAnnotationLabels({ressourceId, fields, limit, page, search}:getAnnotationsLabelsOptions){
    const options = {
        method: "GET",
        endpoint: `/app/annotation-labels/${ressourceId}`,
        query: {
            fields,
            limit,
            page,
            search
        },
    };
    return fetchCall(options);
}

// Post a new label
interface createLabelOptions {
    ressourceId: string,
    label: {
        label: string,
        keystroke: string,
        color: string
    }
}
export function createAnnotationLabel({ressourceId, label}:createLabelOptions){
    const options = {
        method: "POST",
        endpoint: `/app/annotation-labels/${ressourceId}`,
        body: label
    };
    return fetchCall(options);
}