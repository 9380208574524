// Components
import { Annotate } from "components/annotations"
import { Text } from "components/core/typo"
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs";
import { NavigateToAnnotationId } from "components/annotations/controls";

/// Constants
import { to } from "constants/routes"

// Hooks
import { useTranslation } from "react-i18next"
import { useAnnotationWorkspace } from "hooks/useAnnotationWorkspace";
import { useParams } from "react-router-dom";
import { AnnotationContextProvider } from "contexts/annotation";
import { useAnnotation } from "hooks/useAnnotation";
import { CopyButton } from "components/core/buttons/copy";

export function DetailsWithContext(){
    const { t } = useTranslation("common");
    const {annotation} = useAnnotation();
    const { workspace } = useAnnotationWorkspace()
    const { org:orgParams } = useParams()
    
    const crumbs:Crumb[] = [
        {
            name: t("env-annotations.title"),
            path: to.annotations(orgParams || "")
        },
        {
            name: workspace?.ressource?.name,
            path: to.annotationWorkspace(orgParams || "",workspace?.ressource?.slug || "")
        },
        {
            name: `Annotation ${(annotation && annotation.idx)|| ""}`,
            path: ""
        }
    ]
    return <>
        <div className="flex justify-between gap-x-4 flex-col md:flex-row">
            <Breadcrumbs crumbs={crumbs} />
            <div className="w-full shrink-0 md:w-[298px] items-center flex">
                
                <NavigateToAnnotationId />
            </div>
        </div>
        
        <div className="mt-10">
            <Text.PageTitle>{t("annotation.title", {idx: (annotation && annotation.idx)|| ""  })}
                {annotation && annotation.privateId && <span className="text-base ml-3 opacity-50 inline-flex">
                    <CopyButton label="copy-annotation-id" content={annotation && annotation.privateId}/>
                </span>}
            </Text.PageTitle>
        </div>
        <Annotate />
    </>
}

export function Details(){
    return <AnnotationContextProvider>
            <DetailsWithContext/>
          </AnnotationContextProvider>
}
