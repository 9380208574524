// Components
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "components/ui/popover"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"

// Icons
import { GoChevronDown } from "react-icons/go";
import { GoPlus } from "react-icons/go";
import { useAPI } from "hooks/useAPI";
import { updateModel } from "api/models";
import { useCallback, useMemo, useState } from "react";
import { useModel } from "hooks/useModel";
import { useTranslation } from "react-i18next";


export function AddOrUpdateMetadata() {
    const { t } = useTranslation("common");
    const {model, setModel} = useModel();
    const [metadataKey, setMetadataKey] = useState("");
    const [metadataValue, setMetadataValue] = useState("");
    const [open, setOpen] = useState(false);
    const params = useMemo(() => ({
        modelId: model?.id,
        updateMetadata: { [metadataKey]: metadataValue } // Use metadataKey as key
    }), [metadataKey, metadataValue, model?.id])

    const onResult = useCallback(({metadata}: {metadata: Record<string, string>}) => {
        setModel((prevModel:Model) => ({...prevModel, metadata}))
        setOpen(false);
        setMetadataKey("");
        setMetadataValue("");
    }, []);

    const [, {loading, execute}] = useAPI(updateModel, params, {immediate: false, onResult})

    return <>
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant={"ghost"} size={"sm"} className="text-lg px-1.5 w-fit"><GoPlus/><GoChevronDown/></Button>
            </PopoverTrigger>
                
            <PopoverContent align="end" className="w-96">
                <div className="flex flex-col gap-3">
                    <div>
                        <p className="font-medium">{t("add-or-update-metadata.title")}</p>
                        <p className="text-sm text-muted-foreground">{t("add-or-update-metadata.description")}</p>
                    </div>
                    <Input onChange={(e)=>setMetadataKey(e.currentTarget.value)} value={metadataKey} placeholder={t("metadata-key")} />
                    <Input onChange={(e)=>setMetadataValue(e.currentTarget.value)} value={metadataValue} placeholder={t("metadata-value")} />
                    <Button loading={loading} disabled={!metadataKey} onClick={execute}>{t("save")}</Button>
                </div>
            </PopoverContent>
        </Popover>

    </>
}

