// Components
import { Details } from "components/ressources/details"
import { RessourceGuard } from "components/auth/ressourceguard"
import { Spinner } from "components/core/spinner"
import { FilterDatesContextProvider } from "contexts/filter-dates"

// Hooks
import { useRessource } from "hooks/useRessource"
import { RessourceIncidentsContextProvider } from "contexts/ressource-incidents"
import { RessourceJobsContextProvider } from "contexts/resource-jobs"

export function RessourceDetails(){
    return <>
        <FilterDatesContextProvider>
            <RessourceIncidentsContextProvider>
                <RessourceJobsContextProvider>
                    <Details />
                </RessourceJobsContextProvider>
            </RessourceIncidentsContextProvider>
        </FilterDatesContextProvider>
    </>
}

export function RessourceDetailsWithGuard(){
    const { loading } = useRessource()

    return <>
        <RessourceGuard>
            {loading ? <div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div>:
            <RessourceDetails/>}
        </RessourceGuard>
    </>
}
