// API
import { getIncidentMessages } from "api/incidents"

// Hooks
import { useMemo, useState, createContext, useCallback} from "react";
import { useParams } from "react-router-dom";
import { useSearch } from "hooks/useSearch";
import { useTranslation } from "react-i18next";

const IncidentMessagesContext = createContext<IncidentMessagesContextResult>({
    messages: [],
});

export type IncidentMessagesContextResult= {
    messages: any[];
    setMessages?: any;
    error?: any;
    loading?: boolean;
    paging?: any;
    setPage?: any;
}

// Used by the incident page to display a new posted message without having to refresh the page
export const incidentMessageQueryFields = ["id", "message", "created_at", "updated_at", "author.id", "author.email", "author.lastname", "message_type", "files.name", "files.obj_key", "files.extension"]

const IncidentMessagesContextProvider = ({ children }:{children: React.ReactNode}) => {

    const { t } = useTranslation("common")

    const { incident:incidentParams } = useParams<{incident:string}>()

    const [messages, setMessages] = useState<any[]>([])

    const handleResult = useCallback((result:any) => {
        // Keep all previous messages and add the new ones if id is not already in the list (prevent duplicates)
        // reverse result to have the oldest messages first (in case of pagination)
        const reversedResult = [...result.results].reverse()
        setMessages(prev => [ ...reversedResult.filter((message:any) => !prev.find((prevMessage:any) => prevMessage.id === message.id)), ...prev])
    },[])

    const paramsMessages = useMemo(() => ({incidentId: incidentParams, orderDirection:"desc", orderBy:"created_at"}), [])
    const fieldsMessages = useMemo(() => (incidentMessageQueryFields), [])
    const [,{paging, setPage, error, loading}] = useSearch(getIncidentMessages, paramsMessages, {fields: fieldsMessages, onResult:handleResult, limit: 1000000, errorToastMessage: t("comments.error-loading")})
    
    return (
        <IncidentMessagesContext.Provider value={{messages, setMessages, error, paging, setPage, loading}}>
            {children}
        </IncidentMessagesContext.Provider>
    );
    
};

export { IncidentMessagesContext, IncidentMessagesContextProvider };