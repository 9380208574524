import { Editor } from 'components/plate-ui/editor';
import { Plate } from '@udecode/plate-common';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FixedToolbar } from 'components/plate-ui/fixed-toolbar';
import { FixedToolbarButtons } from 'components/plate-ui/fixed-toolbar-buttons';
import { TooltipProvider } from 'components/plate-ui/tooltip';
import { ToolbarGroup, ToolbarButton } from 'components/plate-ui/toolbar';
import { createPlateEditor } from '@udecode/plate-common';
import { plugins } from 'components/core/editor/plugins';

// Components
import { Spinner } from 'components/core/spinner';

// Hooks
import { useTranslation } from 'react-i18next';

// Icons
import { MdOutlineModeEditOutline as EditIcon } from "react-icons/md";
import { useCallback, useMemo } from 'react';


interface TextEditorProps {
  title?: string;
  initialValue: any;
  value?: any;
  setValue?: (value: any) => void;
  editing?: boolean;
  toggleEditing?: (value: boolean) => void;
  onSave?: () => void;
  loadingSave?: boolean;
  placeholder?: string;
}

const defaultInitialValue = [
  {
    type: 'p',
    children: [{ text: '' }],
  },
];
export function TextEditor({title, placeholder, initialValue, setValue, onSave, editing=true, toggleEditing, loadingSave}: TextEditorProps){
    const editor = useMemo(() => createPlateEditor({plugins}), []);
    const init = useMemo(() => initialValue || defaultInitialValue, [initialValue]);
      
    const handleCancel = useCallback(() => {
        editor.reset();
        editor.insertNodes(init, {at: [0]})
        if (editor.children.length > init.length) editor.removeNodes({at: [editor.children.length-1]}) // Prevents the editor from adding an extra paragraph
    }, [editor, init]);
      
    return (<>
      <TooltipProvider>
      <DndProvider backend={HTML5Backend}>
          <Plate editor={editor} initialValue={init} onChange={setValue} >
            {
              toggleEditing ? <ToggleEditingToolbar title={title} editing={editing} setEditing={toggleEditing} onSave={onSave} onCancel={handleCancel} loading={loadingSave}/> : <DefaultToolbar />
            }
            <Editor placeholder={editing? "":placeholder} disabled={!editing}/>
          </Plate>
      </DndProvider>
    </TooltipProvider>
    </>)
}

interface ToggleEditingToolbarProps {
  title?: string;
  editing: boolean;
  setEditing: (value: boolean) => void;
  onSave?: () => void;
  onCancel?: () => void;
  loading?: boolean;
}
export function ToggleEditingToolbar({title, editing, setEditing, onSave, onCancel, loading}: ToggleEditingToolbarProps) {
  const { t } = useTranslation('common');
  const handleCancel = () => {
    onCancel?.();
    setEditing(false);
  }
  const handleSave = () => {
    onSave?.();
  }
  return <>
    <FixedToolbar>
      {
        editing ? 
            <div className='flex justify-between items-end w-full'>
                <FixedToolbarButtons />
                <ToolbarGroup className='flex items-end'>
                  <ToolbarButton className='!text-gray-400' onClick={handleCancel}>{t("cancel")}</ToolbarButton>
                  <ToolbarButton className='!text-primary' onClick={handleSave}>{loading ? <Spinner />:t("save")}</ToolbarButton>
                </ToolbarGroup>
            </div>
        : 
          <div className='flex justify-between items-center w-full'>
            <ToolbarGroup noSeparator className='leading-3 px-3 text-sm text-container-foreground'>
              <p>{title}</p>
            </ToolbarGroup>
            <ToolbarGroup noSeparator>
              <ToolbarButton onClick={()=>setEditing(true)}><EditIcon/></ToolbarButton>
            </ToolbarGroup>
          </div>
      }
    </FixedToolbar>
  </>
}

export function DefaultToolbar() {
  return <>
    <FixedToolbar>
      <FixedToolbarButtons />
    </FixedToolbar>
  </>
}