/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface UrlAndName {
    url: string;
    name: string;
}

export async function downloadFile(url: string): Promise<Blob> {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Failed to download file.');
    return response.blob();
}

export async function downloadFilesAsZip(fileUrls: UrlAndName[], zipFileName: string): Promise<void> {

    const zip = new JSZip();
    const dirName = zipFileName.replace(".zip", "");
    if (!zipFileName.endsWith(".zip")) zipFileName += ".zip";

    // Download each file and add it to the zip
    for (const [, {name, url}] of fileUrls.entries()) {
        const fileBlob = await downloadFile(url);
        zip.file(`${dirName}/${name}`, fileBlob);
    }

    // Generate the ZIP file
    zip.generateAsync({type: "blob"}).then(function(content) {
        // Trigger the download
        saveAs(content, zipFileName);
    });
}

export const sanitizeFileName = (fileName: string): string => {
    const sanitizedFileName = fileName.replace(/[<>:"\/\\|?*\x00-\x1F]/g, '_').trim();
    return sanitizedFileName;
};
  