// Components
import { Text } from "components/core/typo"
import { IncidentsCreateAndList } from "components/incidents"
import { IncidentDetailsPage } from "pages/incidents/details"

// Hooks
import { useTranslation } from "react-i18next"

function ListIncidents() {
    const { t } = useTranslation()
 
    return <>
        <div>
            <Text.PageTitle>{t("incidents")}</Text.PageTitle>
        </div>
        <IncidentsCreateAndList />
    </>
}

export const Incidents = {
    List: ListIncidents,
    Details: IncidentDetailsPage
}