import Markdown from 'markdown-to-jsx'
import { options } from 'components/core/changelog/options';

interface ChangelogProps {
    content : string;
}
export function Changelog({content}: ChangelogProps){

    return <>
    
        <Markdown options={options}>
            {content}
        </Markdown>
    </>
}