// API
import { getOrgIncidents } from "api/incidents"

// Utils
import { query as utilsQuery } from "utils/query"

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg";
import { useMemo, useState, createContext, useCallback, Dispatch, SetStateAction} from "react";
import { useSearch } from "hooks/useSearch";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFilterDates } from "hooks/useFilterDates";

const RessourceIncidentsContext = createContext<Incidents>({});

export type Incidents= {
    incidents?: Incident[];
    setIncidents?: any;
    error?: any;
    loading?: boolean;
    paging?: any;
    setPage?: any;
    setQuery?: any;
    status?: IncidentStatus;
    setStatus?: any;
    filter?:Filter;
    filters?:Filter[];
    defaultFilter?:Filter;
    setFilter?: Dispatch<SetStateAction<Filter>>;
    execute?: any;
}

export type Filter = 
    "newest" | 
    "oldest" | 
    "recently-updated" | 
    "least-recently-updated" | 
    "name-asc" | 
    "name-desc" | 
    "ressource-asc" | 
    "ressource-desc" |
    "acknowledged" |
    "not-acknowledged"
export type OrderBy = "created_at" | "updated_at" | "name" | "ressource" | "acknowledged"
export type OrderDirection = "asc" | "desc"

// Record of Filter, value is object with orderBy and orderDirection
export const filterDict:Record<Filter, {orderBy:OrderBy, orderDirection:OrderDirection}> = {
    "newest": {orderBy: "created_at", orderDirection: "desc"},
    "oldest": {orderBy: "created_at", orderDirection: "asc"},
    "recently-updated": {orderBy: "updated_at", orderDirection: "desc"},
    "least-recently-updated": {orderBy: "updated_at", orderDirection: "asc"},
    "name-asc": {orderBy: "name", orderDirection: "asc"},
    "name-desc": {orderBy: "name", orderDirection: "desc"},
    "ressource-asc": {orderBy: "ressource", orderDirection: "asc"},
    "ressource-desc": {orderBy: "ressource", orderDirection: "desc"},
    "acknowledged": {orderBy: "acknowledged", orderDirection: "desc"},
    "not-acknowledged": {orderBy: "acknowledged", orderDirection: "asc"},
}

export const filters:Filter[] = Object.keys(filterDict) as Filter[]

export function incidentsfilterHandler(filter:Filter):{orderBy:OrderBy, orderDirection:OrderDirection}{
    return filterDict[filter]
}

export const defaultFilter:Filter = "recently-updated"

const RessourceIncidentsContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { t } = useTranslation("common")
    
    const { activeOrg } = useActiveOrg()
    const { ressource:ressourceParams } = useParams()
    const [ incidents, setIncidents] = useState<Incident[]>()
    const [ query, setQuery] = useState<string>("")
    const [ status, setStatus] = useState<IncidentStatus>()
    const { filter: filterDates } = useFilterDates()
    const debounceQuery = useDebounce(query, 500);
    const debounceFilter = useDebounce(filterDates, 500);
    const [ filter, setFilter] = useState<Filter>(defaultFilter)
    
    const handleResult = useCallback((result:any) => {
        setIncidents(result.results || result)
    },[])

    const handleFilter = useCallback(():{orderBy:OrderBy, orderDirection:OrderDirection} => {
        return incidentsfilterHandler(filter)
    },[filter])

    const params = useMemo(() => ({
        orgId: activeOrg && activeOrg.slug,
        ressourceId:ressourceParams,
        search: query,
        status,
        orderBy: handleFilter().orderBy,
        orderDirection: handleFilter().orderDirection,
        createdAfter: utilsQuery.filterAfterDate(filterDates[0]),
        createdBefore: utilsQuery.filterBeforeDate(filterDates[1] || filterDates[0])
    }), [debounceQuery, debounceFilter, status, handleFilter])
    
    const fields = useMemo(() => (["id","severity", "acknowledged", "created_at", "name", "status", "short_description", "description", "message_count"]), [])
    
    const [, {execute, loading, paging, setPage, error}] = useSearch(getOrgIncidents, params, {onResult: handleResult, limit: 5, fields, errorToastMessage: t("error-try-again")} )
    
    return (
        <RessourceIncidentsContext.Provider value={{execute, incidents, setIncidents, error, paging, setPage, setQuery, status, setStatus, filter, setFilter, filters, defaultFilter, loading}}>
            {children}
        </RessourceIncidentsContext.Provider>
    );

};

export { RessourceIncidentsContext, RessourceIncidentsContextProvider };