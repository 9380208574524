// Components
import { Section } from "components/core/section"
import { TextInput } from "components/core/inputs"
import { Select } from "components/core/select"
import { Text } from "components/core/typo"
import { EditPassword } from "components/account/edit-password"

// Icons
import { TbLanguage as LanguageIcon } from "react-icons/tb"

// Hooks
import { useAuth } from "hooks/useAuth"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { Button } from "components/core/buttons"
import { useAPI } from "hooks/useAPI"

// API
import { updateUser } from "api/users"


// Utils
import { validate } from "utils/validate"
import { toast } from "react-hot-toast"
import { EditNotification } from "./edit-notifications"

const languages = [
    {name: "English", value:"EN"},
    {name: "Français", value:"FR"}
]

export function EditInfos(){
    const { t, i18n } = useTranslation("common")
    const { user, setUser } = useAuth()
    const [ language, setLanguage ] = useState(languages.find((l:any) => l.value === user.language))
    const [ newUser, setNewUser ] = useState<Record<string,any>>({})
    const handleNewValue = (value:any, key:string) => {
        value = value.trim()
        if (value === user[key] || value === "") {
            const {[key]: _, ...rest} = newUser
            setNewUser(rest)
            return
        }
        setNewUser((prev:any) => ({...prev, [key]: value}))
    }
    const handleResult = (result:any) => {
        setUser && setUser((prev:any) => ({...prev, ...result}))
        if (result?.language !== user.language) {
            i18n.changeLanguage(result.language.toLowerCase())
        }
        setNewUser({})
        toast.success(t("update-user.success"))
    }
    const [, {error, loading, execute}] = useAPI(updateUser, newUser, 
        {
            immediate: false, 
            onResult: handleResult
        })
    useEffect(() => {
        if (error && error.code !== "object_already_exists") toast.error(t("update-user.error"))
    }, [error, t])

    const disabled = Object.keys(newUser).length === 0 || ((newUser?.email && !validate.isEmail(newUser.email)) || loading)

    return <>
         <Section.Contained title={t("basic-account-infos")} description={t("remember-to-save")}>

                <div className="grid gap-3 mb-4 sm:grid-cols-2">
                    <div>
                        <TextInput onChange={(v:any)=> handleNewValue(v, "firstname")} defaultValue={user.firstname} theme="outlined" label={t("firstname")} placeholder={t("firstname")} />
                    </div>
                    <div>
                        <TextInput onChange={(v:any)=> handleNewValue(v, "lastname")} defaultValue={user.lastname} theme="outlined" label={t("lastname")} placeholder={t("lastname")} />
                    </div>
                </div>

                <TextInput onChange={(v:any)=>{ handleNewValue(v, "email");}} defaultValue={user.email} theme="outlined" label={t("email")} placeholder={t("email")} />
                <div className="mt-3">
                    {(error && error.code === "object_already_exists") && <Text.Error>{t("update-user.email-exists")}</Text.Error>}
                </div>
                <div className="mt-5">
                    <p className="block mb-1 text-sm font-medium text-gray-300">{t("language")}</p>
                    <Select buttonPadding={false} Icon={LanguageIcon} options={languages} indexingKey={"value"} selected={language} setSelected={(v:any)=>{setLanguage(v); handleNewValue(v.value, "language")}} toString={(d:any)=>d.name} />
                </div>
                
                <div className="flex justify-end mt-4 xl:mt-0">
                    <Button onClick={execute} disabled={disabled} loading={loading} className="w-full xl:mt-4 sm:w-44">{t("save")}</Button>
                </div>
        </Section.Contained>

        <div className="lg:mt-8">
            <Section.Contained title={t("authentication")} description="">
                <EditPassword />
            </Section.Contained>
        </div>
        <div className="lg:mt-8">
            <Section.Contained title={t("notifications-settings.title")} description={t("notifications-settings.description")}>
                <EditNotification/>
            </Section.Contained>
        </div>
   
    </>
}
