// Components
import { PropertyList, PropertyContainer } from "components/core/property-list"
import { Plot } from "components/core/plot";
import { AddOrUpdateMetadata } from "components/models/metadata";
import { Button } from "components/core/buttons";
import { DownloadModelTrigger } from "components/models/download";

// Utils
import { DateTime } from "luxon";

// Hooks
import { useModel } from "hooks/useModel";
import { useTranslation } from "react-i18next";
import { CodeBlock } from "components/core/syntax-highlighter";
import { useParams } from "react-router-dom";
import { useState } from "react";

export function Summary(){
    const {model, loading} = useModel();
    const {t, i18n} = useTranslation("common");
    const { org } = useParams();
    const createdDate = model?.createdAt ? DateTime.fromISO(model?.createdAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED) : "";
    const codeIntegration = `import hectiq_console as hc\nhc.set_organization("${org}")\nhc.download_model(name="${model?.name}", version="${model?.version}")`
    const [downloading, setDownloading] = useState(false)
    return <>
        
        <PropertyContainer>
            <PropertyList title={t("about")}>
                {
                    <>
                    <PropertyList.Item loading={loading} title={t("name")} value={model?.name}/>
                    <PropertyList.Item loading={loading} title={t("version")} value={model?.version}/>
                    <PropertyList.Item loading={loading} title={t("created-at")} value={createdDate}/>
                    <PropertyList.Item loading={loading} title={t("id")} value={model?.id}/>
                    </>
                }
                
            </PropertyList>
            
            <PropertyList title={t("data")} titleChildren={<div className="-mt-1"><AddOrUpdateMetadata/></div>}>
                {
                    model?.metadata ? <>
                        {
                            (Object.keys(model?.metadata).map((key, index) => {
                                return <PropertyList.Item loading={loading} key={index} title={key} value={model?.metadata![key]} />
                            }))
                        }
                    </>
                    :
                    <>
                        <PropertyList.EmptyState loading={loading}>
                            {t("no-data")}
                        </PropertyList.EmptyState>
                    </>
                }
            </PropertyList>
            
            <PropertyList loading={loading} title={t("downloads")}>
                {
                    model?.downloadsTimeseries && <Plot.Sparkline height={64} showXLabels={true}  data={model?.downloadsTimeseries}/>
                }
            </PropertyList>
            <DownloadModelTrigger loading={downloading} setLoading={setDownloading}>
                <Button loading={downloading} disabled={downloading} className="w-full">{t("download")}</Button>
            </DownloadModelTrigger>
            <div>
            <PropertyList loading={loading} title={t("ingregration")}>
                <CodeBlock language="python" copyValue={codeIntegration}>
                    {codeIntegration}
                </CodeBlock>
            </PropertyList>
            
            </div>
        </PropertyContainer>
        
    </>
} 