// Components
import { Container } from "components/core/container";
import { Controls } from "components/annotations/controls";
import { Skeleton } from "components/core/skeletons";
import { RadioOption } from "components/core/radio/group";
import { KeyStrokeIcon } from "components/annotations/labels";
import { LoadingBar } from "components/core/loadingBar";
import { AnnotationPlot } from "components/annotations/plot";
import { PageTitle } from "components/core/typo";
import { Editor } from "components/core/codemirror";
import { Stats, StatsHighlightProps } from "components/ressources/stats"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "components/ui/hover-card";

// Icons
import { HiOutlineEye as ShowIcon } from "react-icons/hi2";
import { BsFiletypeJson as JsonIcon } from "react-icons/bs";

// Constants
import { routes } from "constants/routes";

// API
import { getAnnotationLabels, getAnnotation, toggleAnnotationLabels } from "api/annotations";

// Utils
import classNames from "classnames";
import { format as utilsFormat } from "utils/format";

// Hooks
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useSearch } from "hooks/useSearch";
import { useAPI } from "hooks/useAPI";
import { Plot } from "components/core/plot";
import { useAnnotation } from "hooks/useAnnotation";
import { MergeEditor } from "components/core/codemirror/merge";
import { useAuth } from "hooks/useAuth";
import { useSearchParams } from "react-router-dom";

export function Annotate(){
    const { i18n, t } = useTranslation("common")
    const {annotation, loading, setAnnotation, annotationParams, previous, next, hasFilterParams } = useAnnotation();
    const [searchParams] = useSearchParams();
    
    if (!annotation && loading) return <>
        <Skeleton className="h-[500px]"/>
    </>
    if (!annotation) return <Navigate to={routes.notFound} replace/>
    return <>
        <div className="flex items-start justify-between flex-col">
            {annotation.viewCount && <Plot.Sparkline data={annotation.viewCount.data} 
                                        title={t("annotation-view-count", 
                                        {days: annotation.viewCount.data.length, count: annotation.viewCount.total || "0"})} 
                                        width={300}
                                        showXLabels={true}
                                        height={120}/>}
            <Controls hasUrlParams={hasFilterParams} previous={previous? previous+ "?"+searchParams.toString(): ""} next={next? next+ "?"+searchParams.toString():""}/>
        </div>

        <Container className="relative " noPadding>
            <div className="absolute top-0 left-0 flex items-start w-full h-4 overflow-hidden rounded-t-md">
                <LoadingBar loading={loading}/>
            </div>

            <div className="flex justify-between pt-2 px-4 text-container-foreground pb-1">
                <div className="text-sm font-semibold">{annotation.idx}</div>
                <div className="text-sm">{utilsFormat.datetime(new Date(annotation.createdAt || ""), i18n.language)}</div>
            </div>

            <DisableOnLoading loading={loading}>
                {
                    annotation && annotation.plotting ? 
                        annotation.plotting.vizualisation==="plot"? <AnnotationPlot plotting={annotation.plotting} className="w-full"/>:
                        annotation.plotting.vizualisation==="editor-diff"? <MergeEditor {...annotation.plotting.props} />
                        : null
                    :
                    <div className="flex flex-col items-center justify-center mb-5 h-96">
                        <h2 className="text-2xl font-bold">{t("annotations.not-supported")}</h2>
                        <p className="text-container-foreground text-center px-3 max-w-md">{t("annotations.not-supported-message")}</p>
                    </div>
                }
            </DisableOnLoading>
        </Container>

        <DisableOnLoading loading={loading}>
            <LabelsList annotationId={annotationParams || ""} loading={loading} votes={annotation?.labels || []} setVotes={(labels: any)=>setAnnotation((d:any)=>({...d, labels}))}/>
        </DisableOnLoading>
        
        <DisableOnLoading loading={loading}>
            {
                annotation.metadata && <JsonViewer title={t("metadata")} 
                    highlight={annotation.plotting && annotation.plotting.highlight} 
                    data={annotation.metadata} 
                    loading={loading}
                    show={true}
                    fileName={"metadata-"+ annotationParams} />
            }
            <LargeDataViewer title={t("inputs")} 
                            fileName={"inputs-"+ annotationParams} 
                            fields={["inputs"]}
                            extractData={(d:any)=>d && d.inputs} />
            <LargeDataViewer title={t("outputs")}
                            fileName={"-outputs-"+ annotationParams} 
                            fields={["outputs"]}
                            extractData={(d:any)=>d && d.outputs} />
        </DisableOnLoading>

    </>
}


function LargeDataViewer({title, fileName, fields, extractData}:{title:string, fileName:string, extractData: any,fields?:Array<string> }){
    /* Load the data on demand */
    const [show, setShow] = useState<boolean>(false);
    const { ressource:ressourceParams, annotation:annotationParams } = useParams();
    const params = useMemo(() => ({ressourceId: ressourceParams, annotationId: annotationParams, fields}), [annotationParams])
    const [annotation, {loading, execute}] = useAPI(getAnnotation, params, {immediate: false})
    
    useEffect(() => {
        if (show ) execute();
    }, [show]);

    return <DisableOnLoading loading={loading}>
                <JsonViewer title={title}
                        data={extractData(annotation)} 
                        noHighlight={true}
                        show={show}
                        loading={loading}
                        setShow={setShow}
                        fileName={fileName} />
                </DisableOnLoading>

}

export function DisableOnLoading({children, loading}:{children:any, loading:boolean}){
    return <>
        <div className={classNames(loading && "opacity-60 pointer-events-none select-none", "opacity-100 transition-opacity")}>
            {children}
        </div>
    </>
}

function LabelsList({votes, setVotes, loading, annotationId}: {votes:AnnotationLabel[], setVotes:Dispatch<SetStateAction<AnnotationLabel[]>>, loading?:boolean, annotationId:string}) {
    const { t } = useTranslation("common")
    const { ressource:ressourceParams } = useParams()

    const { user } = useAuth()
    
    const requestParams = useMemo(() => ({ressourceId: ressourceParams}), [])
    const [ labels,{ loading:loadingLabels} ] = useSearch<AnnotationLabelDetails>(getAnnotationLabels, requestParams, { limit:90000, errorToastMessage: t("annotations.labels.error-fetching")})
    const [, { execute: executeToggle }] = useAPI(toggleAnnotationLabels, {ressourceId: ressourceParams, annotationId: annotationId}, {immediate:false})
    
    const newVote: AnnotationLabel = {
        created_at: new Date().toISOString(),
        author : user,
    } as AnnotationLabel

    const connectedUserVotes = votes && votes.filter((v:AnnotationLabel) => v.author.email === user?.email)

    const handleToggleNewVote = (label:AnnotationLabelDetails) => {
        if (connectedUserVotes && connectedUserVotes.filter((v:any) => v.label.id === label.id && v.author.email === user?.email).length>0){
            setVotes(votes.filter((v:AnnotationLabel) => v.label.id !== label.id || v.author.email !== user?.email))
        }
        else {
            votes ? setVotes([...votes, {...newVote, label}]) : setVotes([{...newVote, label}])
        }
        executeToggle({labelId: label.id})
    }

    const handleKeyDown = useCallback((label:AnnotationLabelDetails) => {
        handleToggleNewVote(label)
    }, [votes])
    
    useEffect(() => {
        if (!document) return;
    
        const pressedKeys = new Set();
    
        const keyDownHandler = (e: KeyboardEvent) => {
            pressedKeys.add(e.key);
    
            // Call handleKeyDown only if exactly one key is pressed
            if (pressedKeys.size === 1) {
                const label = labels && labels.find((l:AnnotationLabelDetails) => l.keystroke?.toLowerCase() === e.key.toLowerCase());
                if (label && !loading) {
                    const activeElement = document.activeElement as HTMLElement;
                    if (activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA")) return;
                    handleKeyDown(label);
                }
            }
        };
    
        const keyUpHandler = (e: KeyboardEvent) => {
            pressedKeys.delete(e.key);
        };
    
        document.addEventListener("keydown", keyDownHandler);
        document.addEventListener("keyup", keyUpHandler);
    
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
            document.removeEventListener("keyup", keyUpHandler);
        };
    }, [labels, votes, loading]);
    
    

    if (!labels && loadingLabels) return <>
        <Skeleton className="mt-5 h-44"/>
    </>
    
    return <>

        <div id="labels-list" className={classNames("grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5", labels && labels.length === 2 ? "":"lg:grid-cols-3")}>
            {
                (labels|| []).map((label:AnnotationLabelDetails) => {
                    const userVotedForLabel = connectedUserVotes && connectedUserVotes.filter((v:any) => v.label.id === label.id).length>0;
                    const nbVotes = votes && votes.filter(({label:{id}}:AnnotationLabel) => id === label.id).length;
                    const votesAuthors = votes && votes.filter(({label:{id}}:AnnotationLabel) => id === label.id).map((v:AnnotationLabel) => v.author.name)
                    return <div key={label.id}>
                        <RadioOption
                            noPadding
                            option={label}
                            checked={userVotedForLabel}
                            setValue={handleToggleNewVote} hideCheck >
                                <div className={classNames("flex justify-between w-full items-centerfont-mono rounded-md overflow-hidden", userVotedForLabel ? "bg-container-light bg-gray-700 ring-white/80 ring-2" : nbVotes > 0 ? "bg-gray-700":"bg-gray-900 hover:bg-gray-900/70 hover:ring-white/20 hover:ring-2" )}>
                                    
                                    <div className="flex gap-3 p-4 text-container-foreground">
                                        {label.keystroke &&  <KeyStrokeIcon>{label.keystroke}</KeyStrokeIcon>}
                                        <span >
                                            {label.label}
                                        </span>
                                    </div>
                                    {
                                        nbVotes ? 
                                        <>
                                            <HoverCard openDelay={0} closeDelay={0}>
                                                <HoverCardTrigger>
                                                    <span className="bg-slate-500 text-gray-200 block h-full py-4 min-w-[40px]">
                                                        <div className="flex justify-center items-center">
                                                            
                                                            {nbVotes}
                                                        </div>
                                                    </span>
                                                </HoverCardTrigger>
                                                <HoverCardContent align="end" className="">
                                                    <div className="flex flex-col gap-1">
                                                        <span className="text-sm font-semibold">{t("annotations.labels.who-voted")}</span>
                                                        <span className="text-xs">{votesAuthors.join(", ")}</span>
                                                    </div>
                                                </HoverCardContent>
                                            </HoverCard>
                                        </>
                                        : <div className="h-6"></div>
                                    }
                                </div>
                        </RadioOption>
                    </div>
                })
            }
        </div>
    </>

}

function ExportJson ({data, fileName}:{data:any, fileName?:string}){
    const { t } = useTranslation("common")
    const handleExport = useCallback(() => {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, 2));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", fileName +".json" || "data.json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }, [data])
    return <>
        
        <div onClick={handleExport} className="flex items-center text-xs transition-colors cursor-pointer text-container-foreground gap-x-1 hover:text-primary-light">
            <JsonIcon className="text-base" />
            <span>
                {t("annotations.export-to-json")}
            </span>
        </div>
    </>
}

const ExportContainter = ({children}:{children:any}) => {
    return <>
        <div className="flex justify-between space-x-6 px-3 py-2 border-b-2 bg-container-light rounded-t-md border-gray-600/20">
            {children}
        </div>
    </>
}

interface JsonViewerProps {
    data: any;
    title: string;
    highlight?: any;
    fileName?: string;
    noHighlight?: boolean;
    loading?: boolean;
    show?: boolean;
    setShow?: Dispatch<SetStateAction<boolean>>;
}
export const JsonViewer = ({data, highlight, noHighlight, title, fileName, loading, show, setShow}:JsonViewerProps) => {
    const { t } = useTranslation("common")
    const string = useMemo(()=>{
        if (!show) return "";
        return JSON.stringify(data, null, 2)
    }, [show, data]);

    const highlights = useMemo(() => {
        if (!highlight) return null;
        return highlight.map((h:any) => {
            return {
                title: t(h.title),
                targetNumber: h.target,
                targetUnit: h.unit
            }
        })
    }, [highlight]);
    return <>
        <div className="mt-14">
            <PageTitle>{t(title)}</PageTitle>
            <div>
                {!noHighlight && <div className="grid grid-cols-1 gap-5 mb-5 sm:grid-cols-3">
                    {
                        (!data &&  show)? <>
                            <Skeleton className="h-24" />
                            <Skeleton className="h-24" />
                            <Skeleton className="h-24" />
                        </>
                            :
                            <>
                                {highlights && highlights.length > 0 && 
                                highlights.map((props: StatsHighlightProps)=>
                                    <Stats.Highlight key={props.title} {...props} />
                                )}
                            </>
                    }
                </div>}
                {
                    data && <ExportContainter>
                        {setShow && <div onClick={()=>setShow!(!show)} className=" flex items-center text-xs transition-colors cursor-pointer text-container-foreground gap-x-1 hover:text-primary-light">
                            <ShowIcon className="text-base" />
                            <span>
                                {!show? t("annotations.show-content"): t("annotations.hide-content")}
                            </span>
                        </div>}
                        {data && <ExportJson data={data} fileName={fileName}/>}
                    </ExportContainter>
                }
                <div>
                    {(!show)?
                        <div className="flex flex-col justify-center items-center w-full h-32 text-container-foreground bg-container-light">
                            <p>{t("annotations.data-hidden")}</p>
                            {setShow&&<div onClick={()=>setShow!(!show)} className="flex items-center mt-2 text-sm transition-colors cursor-pointer text-container-foreground gap-x-1 hover:text-primary-light">
                                <ShowIcon className="text-base" />
                                <span>
                                    {t("annotations.show-content")}
                                </span>
                            </div>}
                        </div>:
                        (loading)?
                        <div className="flex flex-col justify-center items-center w-full h-32 text-container-foreground bg-container-light">
                            <p>{t("loading")}</p>
                        </div>:
                    (!data && show)?
                        <div className="flex rounded-md flex-col justify-center items-center w-full h-32 text-container-foreground bg-container">
                            <p>{t("annotations.no-data")}</p>
                        </div>
                        :<Editor className="overflow-y-auto rounded-b-md max-h-80 disableActiveLine" language="json" theme="nord" value={string} />
                    }
                </div>
            </div>
        </div>
    </>
}
