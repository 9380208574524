// Utils
import { toast } from "react-hot-toast"

// Hooks
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { Casing } from "utils/casing";

interface useSearchOptions {
    onResult?: any;
    limit?: number;
    immediate?: boolean;
    fields?: string[];
    dynamicFields?: string[];
    validateParams?: any;
    defaultPage?: number;
    successToastMessage?: string;
    errorToastMessage?: string;
}

interface useSearchResult {
    loading: boolean;
    error: any;
    setResult: any;
    execute: any;
    page: number;
    paging: any;
    setError: any;
    setLoading: any;
    setPage: any;
    hasMore?: boolean;
}

/**
 *
 * @param method Request method
 * @param params Request params
 * @param param2 Object options, include: onResult, limit, immediate, fields, dynamicFields, validateParams
 * @returns [result, {loading, error, setResult, execute, page, paging, setError, setLoading, setPage, hasMore}]
 */
export function useSearch<T = any>(method:(extraParams:any)=>any, params?:any, {onResult,
                                  limit=50,
                                  immediate=true,
                                  fields,
                                  dynamicFields,
                                  successToastMessage,
                                  defaultPage=1,
                                  errorToastMessage,
                                  validateParams}:useSearchOptions={}):[T[] | undefined, useSearchResult]{
  const [page, setPage] = useState(defaultPage < 1 ? 1 : defaultPage);
  const [paging, setPaging] = useState<any>({ page: defaultPage < 1 ? 1 : defaultPage});
  const [result, setResult] = useState<T[]>();
  const [loading, setLoading] = useState(immediate);
  const [error, setError] = useState<any>();
  const activeController = useRef<any>();
  const isMounted = useIsMounted();
  
  const validateParamsMemo = useMemo(()=>validateParams, [validateParams]);

  const execute = useCallback(()=>{
    if (!method) return;
    if (validateParamsMemo && !validateParamsMemo(params)) return;
    setLoading(true);
    setError(null);
    const handleSuccess =  (snakeRes:any)=>{
      const res = Casing.recursiveCamelize(snakeRes);
      if (!isMounted.current) return;
      if (res.page > res.totalPages) {
        res.page = res.totalPages;
      }
      setResult(res.results);
      setPaging({page: res.page, limit: res.limit, totalPages: res.totalPages, numPageResults: res.numResults, numResults: res.totalResults})
      setLoading(false);
      if (onResult){
        return onResult(res);
      }
      if (successToastMessage){
        toast.success(successToastMessage);
      }
    }
    const onError = (res:any)=>{
      if (!isMounted.current) return;
      setLoading(false);
      try {
        res.json()
            .then(({detail, code, payload}:any)=>{setLoading(false); setError({code, detail, payload})})
            .catch(()=>{setLoading(false); setError({code: 'default'})});
      }
      catch {
        setLoading(false);
        setError({code: 'default'});
      }
      if (errorToastMessage){
        toast.error(errorToastMessage);
      }
    }
    if (activeController.current) activeController.current.abort();
    const [promise, {controller}] = method({...params, fields: fields || dynamicFields, page, limit})
    activeController.current = controller;
    return promise.then(handleSuccess).catch(onError);
  }, [method, onResult, params, dynamicFields, page])

  const hasMore = useMemo(()=>{
    if (!paging) return;
    return (paging.page < paging.totalPages) || paging.page > 1;
  }, [paging]);

  // Fire when page reload
  useEffect(() => {
    if (immediate) execute();
  }, [execute]);
  
  return [result, {loading, error, setResult, execute, page, paging, setError, setLoading, setPage, hasMore}];
}

