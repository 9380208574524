import { useCallback, useState, useEffect } from "react"
import classNames from "classnames"

import { AiOutlineCheck as CheckIcon } from "react-icons/ai"

const themes = {
    blank: "",
    default: "block w-full px-3 py-3 text-white bg-container-light rounded-md shadow-md focus:outline-none hover:placeholder:text-gray-300 ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6",
    outlined: "block w-full px-3 py-3 text-white bg-inherit border-0 rounded-md focus:outline-none hover:placeholder:text-gray-300 ring-1 ring-inset ring-gray-200/70 placeholder:text-gray-300/50 focus:ring-2 focus:ring-inset focus:ring-teal-400 sm:text-sm sm:leading-6",
    dark: "block w-full px-3 py-3 text-white bg-background/80 rounded focus:outline-none hover:placeholder:text-gray-300 ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6",
}

interface TextInputProps {
    ref?: any,
    autoFocus?: boolean,
    className?: string,
    type?:  "text" | "password" | "email" | "search" | "tel" | "url" | "hidden" | "number"
    min?: number,
    max?: number,
    label?: string,
    placeholder?: string,
    defaultValue?: string,
    onChange?: any,
    onBlur?: any,
    onPaste?: any,
    props?: any,
    theme?: keyof typeof themes,
    trim?: boolean
    autoComplete?: "new-password" | "off",
    description?: string,
    requirements?: string[],
    clear?: boolean,
    Icon?: any,
    updateFromChange?: boolean
}

export function TextInput({ref, updateFromChange, min, max, autoFocus, className, Icon, clear, requirements, description, theme="default", type, label, placeholder, defaultValue, onChange, onBlur, props, trim = false, autoComplete}: TextInputProps){
    
    const [value, setValue] = useState(defaultValue || "")

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!(onChange && updateFromChange)) setValue(trim? e.currentTarget.value.trim() :e.currentTarget.value)
        const changeResult = onChange && onChange(trim? e.currentTarget.value.trim():e.currentTarget.value)
        if (updateFromChange && onChange) setValue(changeResult)
    }, [onChange, trim])

    const handleBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(trim? e.currentTarget.value.trim() :e.currentTarget.value)
        onBlur && onBlur(trim? e.currentTarget.value.trim():e.currentTarget.value)
    }, [onBlur, trim])

    useEffect(()=>{
        if (clear) {
            setValue("")
            onChange && onChange("")
        }
    }, [clear]);

    return <>

        {label && <label className="block text-sm font-medium text-gray-300">
            {label}
        </label>}
        {requirements && <div className="mb-2">
            {requirements.map((r:any, i) => <p key={i} className="flex items-center mt-1 text-xs text-container-foreground">{r.msg} 
                <CheckIcon className={classNames(r.valid ==="true"? "opacity-100 text-primary": "opacity-0", "ml-2 transition-all")} />            
            </p>)}
        </div>}
        <div className={classNames(label && "mt-2", Icon && "relative")}>
            {Icon && <Icon className="absolute ml-4 text-lg -translate-y-1/2 pointer-events-none text-container-foreground top-1/2"/>}
            <input
                ref={ref}
                autoFocus={autoFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                type={type}
                min={min}
                max={max}
                autoComplete={autoComplete}
                className={classNames(className, themes[theme], "filter-none remove-autofill", Icon && "!pl-11")}
                placeholder={placeholder}
                {...props}
            />
            {description && <p className="mt-2 text-xs text-container-foreground">{description}</p>}
        </div>
    </>
}

export function TextArea({className, onPaste, clear, theme="default", label, placeholder, defaultValue, onChange, onBlur, props, trim = false}: TextInputProps){
    
    const [value, setValue] = useState(defaultValue || "")

    const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(trim? e.currentTarget.value.trim() :e.currentTarget.value)
        onChange && onChange(trim? e.currentTarget.value.trim():e.currentTarget.value)
    }, [onChange, trim])

    const handleBlur = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(trim? e.currentTarget.value.trim() :e.currentTarget.value)
        onBlur && onBlur(trim? e.currentTarget.value.trim():e.currentTarget.value)
    }, [onBlur, trim])

    useEffect(()=>{
        if (clear) {
            setValue("")
            onChange && onChange("")
        }
    }, [clear]);

    return <>

        {label && <label className="block text-sm font-medium text-gray-300">
            {label}
        </label>}
        <div className={classNames(label && "mt-2")}>
            <textarea
                onChange={handleChange}
                onBlur={handleBlur}
                onPaste={onPaste}
                value={value}
                className={classNames(className, themes[theme], "filter-none remove-autofill min-h-[48px] hide-scrollbar")}
                placeholder={placeholder}
                {...props}
            />
        </div>
    </>
}