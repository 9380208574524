// Components
import { PageTitle, Text } from "components/core/typo"
import { Container, ContainerHeader } from "components/core/container"
import { LoadingBar } from "components/core/loadingBar"
import { Spinner } from "components/core/spinner"
import { Paginate } from "components/core/paginate"
import { Skeleton } from "components/core/skeletons"
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { TextInput } from "components/core/inputs"

// API
import { createAnnotationLabel, getAnnotationLabels } from "api/annotations"

// Icons
import { MdOutlineAdd as AddIcon } from "react-icons/md";

// Hooks
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useSearch } from "hooks/useSearch"
import React, { useState, useMemo, useCallback, Dispatch, SetStateAction } from "react"
import { useAPI } from "hooks/useAPI"
import { CopyButton } from "components/core/buttons/copy"

function ListEmptyRow(){
    const { t } = useTranslation("common")

    return <>
        <div className="block p-3 hover:bg-background/30">
            <div className="flex items-center justify-between p-1 text-container-foreground">
                {t("annotations.labels.no-labels")}
            </div>
        </div>
    </>
}

export function LabelsList(){
    const { t } = useTranslation("common")
    const [ labels, setLabels ] = useState<AnnotationLabelDetails[]>()
    const [ openCreateLabel, setOpenCreateLabel ] = useState(false)
    const { ressource:ressourceParams } = useParams()
    const requestParams = useMemo(() => ({ressourceId: ressourceParams}), [])

    const handleResult = useCallback((result:any) => {
        setLabels(result.results || result)
    }, [])

    const [ ,{ execute, loading, paging, setPage }] = useSearch(getAnnotationLabels, requestParams, { limit:10, onResult: handleResult, errorToastMessage: t("annotations.labels.error-fetching")})
    if (!labels && loading) return <>
        <Skeleton className="h-44"/>
    </>
    return <>
        <div className="flex flex-wrap items-end justify-between gap-y-2 gap-x-2">
            <div className="flex flex-col items-start gap-x-3 gap-y-2">
                <PageTitle noMargin>{t("annotations.labels.title")}</PageTitle>
            </div>
            <p className="flex items-center gap-x-0.5 text-sm transition-colors disabled:hover:cursor-default hover:cursor-pointer text-container-foreground hover:text-gray-300" onClick={()=>setOpenCreateLabel(true)}>
                <AddIcon className="text-lg"/>{t("annotations.labels.create-label")}
            </p> 
        </div>
        <Container noPadding className="mt-3">
            <ContainerHeader className="flex px-4 py-4 text-sm">
                <div className="flex items-center justify-between w-full">
                    <p className="flex text-container-foreground">{t("annotations.labels.list-available-labels")}</p>
                </div>
            </ContainerHeader>
            <LoadingBar loading={loading}/>

            {
                labels?.map(({id, label, keystroke}:AnnotationLabelDetails) => {
                    return <LabelRow key={id} id={id || ""} label={label} keystroke={keystroke} />
                })
            }

            {
                loading && !labels && <div className="flex items-center justify-center p-4 h-14"><Spinner /></div>
            }
            {
                labels && labels.length === 0 && <ListEmptyRow />
            }
        </Container>
        <Paginate {...paging} setPage={setPage} loading={loading}/>
        <AddLabelModal open={openCreateLabel} setOpen={setOpenCreateLabel} reloadList={execute} labels={labels}/>
    </>
}

interface LabelRowProps {
    id: string,
    label: string,
    keystroke?: string,
}
function LabelRow({label, id, keystroke}:LabelRowProps){
    return <>
        <div className="block p-2 px-3 even:bg-container-light/50">
            <div className="flex items-center justify-between p-1 text-container-foreground">
             
                <div className="flex items-center w-1/2 space-x-2">
                    {label}
                    <span className="inline-flex ml-2 text-gray-300 "><CopyButton content={id} label={"copy-id"} className="opacity-50"/></span>
                </div>
                {
                    keystroke && <KeyStrokeIcon>
                        {keystroke}
                    </KeyStrokeIcon>
                }
            </div>
        </div>
    </>
}

export function KeyStrokeIcon({children}: {children: React.ReactNode}){
    return <div className="border-2 border-gray-300/10 text-[10px] font-semibold p-1 px-1.5 leading-none rounded-sm text-gray-300">
        {children}
    </div>
}

interface ModalProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}

interface AddLabelModalProps extends ModalProps {
    reloadList?: () => void,
    setLabels?: Dispatch<SetStateAction<AnnotationLabelDetails[] | undefined>>
    labels?: AnnotationLabelDetails[]
}
    
function AddLabelModal({open, setOpen, reloadList, setLabels, labels}:AddLabelModalProps){
    const { t } = useTranslation("common")
    const { ressource:ressourceParams } = useParams()
    
    const [label, setLabel] = useState<AnnotationLabelDetails>({
        id: "",
        label: ""
    })
    
    const requestParams = useMemo(() => ({ressourceId: ressourceParams, label}), [label])
    
    const handleResult = useCallback(((result:any)=>{
        if (reloadList) reloadList()
        if (setLabels && !reloadList) setLabels((labels:AnnotationLabelDetails[] | undefined) => {
            if (!labels) return labels
            return [...labels, result]
        })
        setOpen(false)
    }),[])
    
    const isValidLabel = useMemo(() => {
        if (!labels) return true
        return !labels.find(({label:labelName}) => labelName.trim().toLowerCase() === label.label.trim().toLowerCase())
    }, [label, labels])
    
    const [, {execute, loading}] = useAPI(createAnnotationLabel, requestParams, {immediate:false, onResult:handleResult, errorToastMessage: t("annotations.labels.error-create-label"), successToastMessage: t("annotations.labels.success-create-label")})
    return <>
        <Modal title={t("annotations.labels.create-label")} open={open} setOpen={setOpen}>
            <Modal.Body>
                {
                    !isValidLabel && <Text.Warning className="mb-2">{t("annotations.labels.label-already-exists")}</Text.Warning>
                }
                <TextInput clear={!open} theme="outlined" placeholder={t("annotations.labels.label-placeholder")} onChange={(v:string)=>setLabel({...label, label: v})} defaultValue={label.label} />
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button disabled={loading || !isValidLabel} loading={loading} onClick={()=>{execute();}}>{t("add")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}
