// Icons
import { IconType } from 'react-icons';
import { BiChevronRight as ChevronRight } from 'react-icons/bi';
import { MdOutlineContentCopy as CopyIcon } from 'react-icons/md';
import { AiOutlineHome as HomeIcon } from 'react-icons/ai';

// Components
import { Link } from 'react-router-dom';

// Constants
import { routes } from 'constants/routes';

// Utils
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { Skeleton } from '../skeletons';

export type Crumb = {
    name: string;
    path: string;
    loading?: boolean;
    Icon?: IconType | undefined;
}

type BreadcrumbsProps = {
    crumbs: Crumb[];
    showHome?: boolean;
    separator?: string | React.ReactNode | IconType;
    noMargin?: boolean;
    copy?: boolean;
    onlyIcons?: boolean;
    homeIcon?: boolean
    onlyHomeIcon?: boolean;
}
export function Breadcrumbs({crumbs, showHome, separator, noMargin, onlyIcons, homeIcon, onlyHomeIcon = false, copy = true,}: BreadcrumbsProps){

    const { t } = useTranslation('common')
    const [copied, setCopied] = useState(false)
    const [copyText, setCopyText] = useState(t("copy-url"))
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    if (showHome) crumbs = [{name: t("home"), path: routes.home, Icon: homeIcon ? HomeIcon : undefined}, ...crumbs]

    const addTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    
    const copyUrl = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url);
        setCopied(true);
        setCopyText("");
        addTimeout();
    };

    return <>
        <div className={classNames('flex text-container-foreground items-center flex-wrap', !noMargin && "my-5")}>
            {
                crumbs.map(({name, path, loading, Icon}, i)=>
                    (loading?
                    <Skeleton key={'crumb-'+i} className="h-5 w-32"/>:
                    <span key={'crumb-'+i} className='flex flex-wrap items-center'>
                        <div className='flex items-center'>
                            <Link to={path} className={classNames('hover:text-gray-300 flex items-center', (copy && i === crumbs.length -1 && "mr-3"))}>{Icon && <Icon/>}{(!onlyIcons && !(path === routes.home && onlyHomeIcon)) && <span className={Icon && "ml-2"}>{name}</span>}</Link>
                            {
                                i < crumbs.length - 1 && <>
                                    {
                                        separator ? separator: <ChevronRight className="mx-2 text-xl" />
                                    }
                                </>
                            }
                        </div>
                        {
                            i === crumbs.length - 1 && copy && 
                            <div 
                                className='relative mr-16 group'
                                onMouseEnter={() => !copied && setCopyText(t("copy-url"))}
                            >
                                <CopyIcon onClick={copyUrl} className="transition-all group-hover:cursor-pointer group-hover:text-gray-300" />
                                {
                                    <p className={classNames('absolute ml-2 text-xs text-gray-300 -translate-y-1/2 top-1/2 left-full whitespace-nowrap')}>
                                        {copied ? <span className='fadeOutDelayed'>{t("copied")}</span> : <span className='hidden group-hover:block'>{copyText}</span>}
                                    </p>
                                }
                            </div>
                            
                        }
                    </span>)
                )
            }
          
        </div>
    </>
}
