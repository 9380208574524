/* eslint-disable @typescript-eslint/no-unused-vars */
import { Notification, NotificationType } from "contexts/notification-bar"

import { TextSuccess, TextWarning, TextError, TextInfo } from "components/core/typo";

import { ConditionalLink } from "components/core/link";

// Icons
import { MdOutlineClose as CloseIcon } from "react-icons/md";

interface NotificationBarProps {
    notification: Notification | null;
    onClose: () => void;
}

interface TextComponentProps {
    children: React.ReactNode;
    className?: string;
  }

const types:Record<NotificationType, React.FC<{children: React.ReactNode, className?:string}>> = {
    warning: TextWarning,
    success: TextSuccess,
    info: TextInfo,
    error: TextError,
    blank: BlankNotification
}

function BlankNotification({children, className}: {children: React.ReactNode, className?: string}){
    return <div className={className}>{children}</div>
}

export function NotificationBar({ notification, onClose }: NotificationBarProps) {
    if (!notification) return null;

    const NotificationComponent = types[notification.type] || BlankNotification;
    
    return <>
        <ConditionalLink condition={notification.href ? true:false} to={notification.href || ""} >
            <NotificationComponent className="w-full !p-2 !px-3 !border-t border !rounded-none sm:!rounded-t-[4px] sm:!rounded-b-md">
                <div className="flex justify-between w-full">
                    {typeof notification.message === 'string' ? (
                        <span className="notification-text">{notification.message}</span>
                    ) : (
                        notification.message
                    )}
                    {notification.isRemovable && (
                        <button onClick={onClose}>
                            <CloseIcon className="text-lg" />
                        </button>
                    )}
                </div>
            </NotificationComponent>
        </ConditionalLink>
    </>
}
  

