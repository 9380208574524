import React, { useEffect, useRef } from 'react';

export const AutoFocusContainer = ({ children }: {children:React.ReactNode}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            const firstInputOrTextarea = containerRef.current.querySelector('input, textarea') as HTMLInputElement | HTMLTextAreaElement | null;
            if (firstInputOrTextarea) {
                firstInputOrTextarea.focus();
            }
        }
    }, []);

    return (
        <div ref={containerRef}>
            {children}
        </div>
    );
};

