// Components
import { Message } from "components/incidents/timeline/messages"
import { Event } from "components/incidents/timeline/events"
import { Spinner } from "components/core/spinner"

// Hooks
import { useTranslation } from "react-i18next"
import { useIncident } from "hooks/useIncident"
import { useIncidentMessages } from "hooks/useIncidentMessages"

export function Timeline() {
    const { t } = useTranslation("common");
    const { incident } = useIncident();
    const { messages, loading, paging, setPage } = useIncidentMessages();

    if (!incident) return null;

    const { page, totalPages } = paging || {};
    const { author, status, deletedAt, createdAt } = incident;

    const isClosed = status?.toLowerCase() === "closed";

    const shouldDisplayOpenEvent = () => messages.length === 0 || page >= totalPages;

    if (loading && !page) {
        return (
            <div className="flex items-center justify-center p-4 h-14">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="relative grid grid-cols-1 pt-5 border-b-2 border-b-container-light">
            {page < totalPages && (
                <div className="absolute top-0 flex items-center justify-center p-1 mb-3 transition-all -translate-x-1/2 -translate-y-full group rounded-xl bg-primary/40 hover:cursor-pointer hover:bg-primary left-1/2"
                    onClick={() => !loading && setPage((prev: number) => Math.min(prev + 1, totalPages))}>
                    {loading ? <div><Spinner size="xs" /></div> :
                        <span className="px-2 text-xs text-gray-300 transition-all group-hover:text-white">
                            {t("comments.load-more")}
                        </span>}
                </div>
            )}

            {shouldDisplayOpenEvent() && (
                <Event type={author.name? "incident_open": "incident_open_anonymous"} authorName={author.name} date={createdAt} />
            )}

            {messages.map(({id, message, author, messageType, createdAt, files}) => {
                return (
                    <div key={"message-" + id}>
                        {
                            (!messageType || messageType === "message") ? <Message id={id} message={message} author={author} createdAt={createdAt} files={files}/> :
                                <Event
                                    authorName={author.name || t("system")}
                                    type={messageType}
                                    customMessage={message}
                                    date={createdAt}
                                />
                        }

                    </div>
                );
            })}

            {isClosed && deletedAt && messages.length === 0 && (
                <Event type="incident_closed" authorName={t("system")} />
            )}
        </div>
    );
}
