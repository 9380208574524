import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiChevronUpDown as ChevronUpDownIcon } from 'react-icons/hi2'
import { BiCheck as CheckIcon } from 'react-icons/bi'
import classNames from 'classnames'
import { Spinner } from 'components/core/spinner'
import { Link } from 'react-router-dom'

interface SelectProps {
    label?: string,
    options: any[],
    to?: (value: any) => string,
    selected: any,
    setSelected: any,
    toString?: any,
    toDescription?: any,
    indexingKey?: any,
    position?: keyof typeof positions,
    Icon?: any,
    buttonPadding?: boolean,
    loading?: boolean,
    theme?: keyof typeof themes,
    noValuesMsg?: string,
    optionsClassNames?: string
}

const positions = {
    bottom: "flex flex-col -bottom-2 translate-y-full ",
    bottomRight: "flex flex-col -bottom-2 translate-y-full right-0",
    top: "-top-2 -translate-y-full flex flex-col-reverse",
    topRight: "-top-2 -translate-y-full flex flex-col-reverse right-0"
}

const themes = {
    default: "",
    outlined: "block w-full !pl-1 py-3.5 text-white bg-inherit border-0 rounded-md focus:outline-none hover:placeholder:text-gray-300 ring-1 ring-inset ring-gray-200/70 placeholder:text-gray-300/50  sm:text-sm sm:leading-6",
}

export function Select({ label, 
                        options, 
                        noValuesMsg, 
                        to, 
                        selected, 
                        setSelected, 
                        Icon, 
                        theme = "default", 
                        toString, 
                        toDescription, 
                        indexingKey, 
                        position = "bottom", 
                        buttonPadding = true,
                        optionsClassNames,
                         loading = false }: SelectProps) {
    return (
        <div>
            {label && <label className="block mb-2 text-sm font-medium text-gray-300">
            {label}
            </label>}
            <Listbox value={selected} onChange={setSelected} by={typeof indexingKey === 'function' ? (a,b)=>(indexingKey(a) === indexingKey(b)) : indexingKey || ((a, b) => a === b)}>

                <div className="relative">
                    <Listbox.Button className={({ open }) => classNames("w-full text-left cursor-default bg-inherit focus:outline-none transition-color py-2", open && theme === "outlined" && "!ring-teal-400 ring-2", themes[theme], buttonPadding && " pl-3 pr-5", selected &&selected.activeStyle && "!text-white font-medium", open && theme === "default" && "!text-white ", open ? " text-container-foreground" : "hover:cursor-pointer group hover:text-gray-100 text-container-foreground")}>
                    {loading && <div className='flex py-0.5 pl-2 item-center'><Spinner /></div>}
                        <span className="flex items-center mr-1 truncate transition-colors">{<div className={classNames("mr-2 text-lg [&>img]:opacity-70 [&>img]:group-hover:opacity-100 [&>img]:transition-all")}>{Icon && <Icon />}</div>}<div className='truncate'>{selected? (toString ? toString(selected) : selected): noValuesMsg}</div></span>
                        <span className={classNames("absolute inset-y-0 right-0 flex items-center pointer-events-none", buttonPadding && "pr-1.5")}>
                            <ChevronUpDownIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                            
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className={classNames(options.length === 0 && "hidden","z-50 absolute w-full flex gap-y-1 overflow-auto text-sm rounded py-1 bg-background ring-2 ring-black/10 max-h-80 focus:outline-none", positions[position], optionsClassNames)}>
                            {options.map((option) => (
                                to ? <Link to={to(option)} key={typeof indexingKey === 'function' ? indexingKey(option) : option[indexingKey]}>
                                    <Listbox.Option

                                        className={({ active, selected }) => classNames(active ? "" : "text-container-foreground ", selected ? "bg-container-light" : "hover:bg-container-light/50", "relative cursor-default select-none rounded py-0.5 mx-1 pl-7 pr-1 hover:cursor-pointer")
                                        }
                                        value={option}
                                    >
                                        {({ selected }) => (
                                            <Option selected={selected} option={toString ? toString(option) : option} description={toDescription && toDescription(option)} />
                                        )}
                                    </Listbox.Option>
                                </Link> : 
                                <Listbox.Option
                                    key={typeof indexingKey === 'function' ? indexingKey(option) : option[indexingKey]}
                                    className={({ active, selected }) => classNames(active ? "" : "text-container-foreground ", selected ? "bg-container-light" : "hover:bg-container-light/50", "relative cursor-default select-none rounded py-0.5 mx-1 pl-7 pr-1 hover:cursor-pointer")
                                    }
                                    value={option}
                                >
                                    {({ selected }) => (
                                        <Option selected={selected} option={toString ? toString(option) : option} description={toDescription && toDescription(option)} />
                                    )}
                                </Listbox.Option>
                            ))}
                            {loading && <div className='flex justify-center py-2 item-center'><Spinner /></div>}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

function Option({selected, option, description}:{selected:boolean, option:any, description?:string}){

    return <>
        <div className={classNames(selected ? 'font-medium text-white ' : '', "relative py-2 text-sm")}>
            {
                selected && <CheckIcon className='absolute text-lg -translate-x-full -left-1 text-primary' />
            }
            <span className="block font-medium transition-colors">{option}</span>
            <span className="block text-[13px]  opacity-80 transition-colors">{description}</span>

        </div>
    </>
}