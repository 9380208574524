// Components
import { TextInput } from "components/core/inputs"
import { Button } from "components/core/buttons"
import { Text } from "components/core/typo"

// Utils
import { validate } from "utils/validate"
import { toast } from "react-hot-toast"

// API
import { changePassword } from "api/auth"

// Hooks
import { useAPI } from "hooks/useAPI"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"

export function EditPassword(){
    const { t } = useTranslation("common")
    const [ oldPassword, setOldPassword ] = useState("")
    const [ newPassword, setNewPassword ] = useState("")
    const [ confirmPassword, setConfirmPassword ] = useState("")
    const [requirements, setRequirements] = useState(validate.passwordRequirements(newPassword));
    
    const handleNewPassword = (value:string) => {
        setNewPassword(value)
        setRequirements(validate.passwordRequirements(value))
    }

    const [r, {error, loading, execute}] = useAPI(changePassword, {currentPassword: oldPassword, password: newPassword}, {immediate: false, successToastMessage: t("update-user.success")})
    
    const disabled = !oldPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword || !validate.isPassword(newPassword)
    
    useEffect(() => {
        if (error && error.code !== "invalid_credentials") toast.error(t("update-user.error"))
    }, [error, t])

    return <>
        {error && error.code === "invalid_credentials" && <Text.Error className="mb-2">{t("invalid-password")}</Text.Error>}
        <div className="mb-4">
            <TextInput clear={!!r} onChange={setOldPassword} type="password" autoComplete="new-password" theme="outlined" label={t("old-password")} />
        </div>
    
        <div className="mb-4">
            <TextInput clear={!!r} onChange={handleNewPassword} requirements={[...t("update-user.password-requirements", requirements)] || []}  type="password" autoComplete="new-password" theme="outlined" label={t("new-password")} />
        </div>
        
        <TextInput clear={!!r} onChange={setConfirmPassword} type="password" autoComplete="new-password" theme="outlined"  label={t("confirm-password")}/>

        <div className="flex justify-end mt-4">
            <Button onClick={execute} disabled={disabled} loading={loading} className="w-full xl:mt-4 sm:w-44">{t("save")}</Button>
        </div>
    </>
    
    
}