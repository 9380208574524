export const supportedImgs = ["jpg", 
                              ".jpg",
                              "jpeg",
                                ".jpeg", 
                              "png", 
                              ".png",
                              "gif", 
                                ".gif",
                              "svg", 
                                ".svg",
                              "apng", 
                              "avif", 
                              "bmp", 
                              "ico", 
                              "webp"]

export const supportedText = ["html", "txt", "pdf", "md", ".md", ".html", ".txt", ".pdf"]

export const supportedFiles = {
    images: supportedImgs,
    text: supportedText
}