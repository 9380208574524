// Hooks
import { useTranslation } from "react-i18next"
import { useAuth } from "hooks/useAuth"
import { useState } from "react"
import { useAPI } from "hooks/useAPI"
import { Navigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { useCallback } from "react";

// API
import { resetPassword, validateResetPasswordToken } from "api/auth"

// Constants
import { routes } from "constants/routes"

// Components
import { TextInput } from "components/core/inputs"
import { Text } from "components/core/typo"
import { Button } from "components/core/buttons"
import { InternalLink } from "components/core/internalLink";
import classNames from "classnames";

// Utils
import { validate } from "utils/validate";
import { Spinner } from "components/core/spinner";

export function ResetPasswordForm({className}: {className?: string}) {
    const { t } = useTranslation("common")

    const [searchParams] = useSearchParams();
    const params = useMemo(() => ({ token: searchParams.get("t") }), [searchParams]);
    const { setUser } = useAuth();

    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [requirements, setRequirements] = useState(validate.passwordRequirements(password));
    const [isValidToken, { loading:loadingToken }] = useAPI(validateResetPasswordToken, params);
    const [result, { loading, error, execute }] = useAPI(resetPassword, { token: params.token, password },
        {
            onResult: (({ user }: { user: any }) => {
                if (user && setUser) setUser(user)
            }),
            successToastMessage: t("password-reset-success"),
            immediate: false
        });
    
    const handleChangePassword = (password:string) => {
        setPassword(password)
        setRequirements(validate.passwordRequirements(password))
    }

    const onSubmit = useCallback((e:any)=>{
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    if (loadingToken && !isValidToken) return <>
        <div className="flex justify-center h-72">
            <Spinner size="md"/>
        </div>
    </>

    if (!loadingToken && !isValidToken) return <>
            <div className="h-72">
                <Text.Title description={t("invalid-token.description")}>{t("invalid-token.title")}</Text.Title>
                <InternalLink to={routes.login} className="mt-5">{t("return-to-login")}</InternalLink>
            </div>
    </>

    if (result){
        return <Navigate to={routes.home} state={result}/>
    }

    return <form onSubmit={onSubmit} className={classNames(className)} autoComplete="off">

        {error && <Text.Error {...error} className="mb-3"></Text.Error>}
        <Text.Title>{t("reset-password")}</Text.Title>

        <TextInput onChange={handleChangePassword} requirements={[...t("update-user.password-requirements", requirements)]} autoComplete="new-password" placeholder={t("new-password")}   type="password" theme="default" />

        <div className="mt-7">
            <TextInput onChange={setPassword2} autoComplete="new-password" placeholder={t("confirm-password")} type="password" theme="default" />
        </div>

        <div className="mt-7">
            <Button disabled={!validate.isPassword(password) || !(password === password2) || password === ""} type="submit" className="w-full" loading={loading}>{t("reset")}</Button>
        </div>
        <InternalLink to={routes.login} className="mt-5">{t("return-to-login")}</InternalLink>

    </form>
}