import { createContext, useMemo, useState } from "react";

// Hooks
import { useSearch } from "hooks/useSearch";
import { useParams } from "react-router-dom";

// API
import { getOrganizationMembers } from "api/organizations";

const OrgMembersContext = createContext<any>({});

const OrgMembersContextProvider = ({ children }:{children: React.ReactNode}) => {
    const {org: orgId} = useParams()
    const params = useMemo(()=> ({orgId}), [orgId])
    const [members, {loading, setResult: setMembers}] = useSearch(getOrganizationMembers, params)
    const [selectedMember, setSelectedMember] = useState<any>(null)
    
    return (
        <OrgMembersContext.Provider value={{members, setMembers, selectedMember, setSelectedMember, loading, orgId: params.orgId}}>
            {children}
        </OrgMembersContext.Provider>
    );

};

export { OrgMembersContext, OrgMembersContextProvider };