// Components
import { Popover as HeadlessPopover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

// Utils
import { cloneElement } from 'react';
import classNames from 'classnames';

import { Dispatch, SetStateAction } from 'react';

// Icons
import { RiArrowDropDownLine as DropdownIcon } from 'react-icons/ri'

// Hooks
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useEffect } from 'react';
import { useRef } from 'react';

interface PopoverProps {
  children: React.ReactNode;
  removeDefaultLabel?: boolean;
  panelClassName?: string;
  label: string | React.ReactNode;
  position?: keyof typeof positions;
  hideChevron?: boolean;
  addCloseProp?: boolean;
}

const positions = {
  "bottom-start": "origin-top-left left-0 top-full",
  "bottom-end": "origin-top-right right-0 top-full",
  "bottom-center": "origin-top top-full left-1/2 -translate-x-1/2",
  "top-start": "origin-bottom-left left-0 bottom-full",
  "top-end": "origin-bottom-right right-0 bottom-full",
}

export function Popover({ children, panelClassName, removeDefaultLabel, label, position = "bottom-center", hideChevron = false, addCloseProp }: PopoverProps) {
  return (
    <HeadlessPopover className="relative">
      {({ open, close }) => (
        <>
          <HeadlessPopover.Button
            className={`
                  ${open ? 'opacity-70' : ''}
                  group flex w-full items-center focus:outline-none`}
          >
            {
              !removeDefaultLabel ? <div className={classNames('flex items-center gap-x-2 rounded-md p-2', open ? "hover:cursor-default" : "hover:bg-container-light hover:border-background-ligther ")}>
                {label}
                {!hideChevron && <DropdownIcon className='-mr-1 text-2xl text-gray-400' aria-hidden="true" />}
              </div> : label
            }


          </HeadlessPopover.Button>
          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <HeadlessPopover.Panel className={classNames("absolute z-10 min-w-fit w-full", positions[position], panelClassName)}>
              <div className="overflow-hidden border rounded-md shadow border-gray-700/50">
                <div className="relative bg-container-light" >
                  {addCloseProp ? cloneElement(children as any, { closePopover: close }) : children}
                </div>
              </div>
            </HeadlessPopover.Panel>
          </Transition>
        </>
      )}
    </HeadlessPopover>
  )
}

interface PopoverControlledProps {
  label: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  blank?: boolean;
  className?: string;
  position?: keyof typeof positions;
}

export const popoverItemStyles = "last:border-b-0 leading-none sm:text-sm text-base border-b border-gray-300/10 p-4 py-3 flex items-center cursor-pointer hover:backdrop-brightness-90"

export function PopoverControlled({ children, label, open, setOpen, blank, position="bottom-center", className }: PopoverControlledProps) {

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  // On escape key press, close the filter container
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [setOpen])

  return <>
    <div ref={ref} className='relative'>
      {label}
      {
        !blank ? <div className={classNames(open ? "visible opacity-full translate-y-0" : "invisible opacity-0 translate-y-1", "transition-all absolute bg-container shadow-md rounded-md border z-10 border-gray-300/10", positions[position], className)}>
          {children}
        </div>
          :
          children
      }
    </div>

  </>
}

