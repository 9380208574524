import classNames from "classnames"

import { Link } from "react-router-dom"
import { Spinner } from "../spinner"

const themes = {
    blank: "",
    default: "leading-5 bg-primary shadow border border-white border-opacity-20 transition-all disabled:bg-opacity-30 disabled:bg-primary/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-primary/70 text-gray-white p-3  rounded-md",
    smDefault: "leading-5 bg-primary shadow border border-white border-opacity-20 transition-all disabled:bg-opacity-30 disabled:bg-primary/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-primary/70 text-gray-white p-2  rounded-md",
    backgroundLightSmall: "leading-5 bg-container-light shadow  border-white border-opacity-20 transition-all disabled:bg-opacity-30 disabled:hover:bg-background/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-container-light p-2 rounded-md",
    accentOutlined: "leading-5 bg-transparent shadow border-2 border-primary transition-all disabled:bg-opacity-30 disabled:hover:bg-primary/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-primary text-primary hover:text-white p-3 rounded-md",
    subtilLink: "leading-5 bg-transparent transition-all disabled:opacity-30 disabled:hover:text-primary/30 disabled:ring-opacity-0 disabled:border-opacity-0 text-gray-400 hover:text-white",
    alert: "leading-5 bg-red-500 shadow border border-red-700 border-opacity-20 transition-all disabled:bg-opacity-30 disabled:hover:bg-red-500/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-red-700  text-gray-white p-3 rounded-md",
    warning: "leading-5 bg-yellow-700 shadow border border-yellow-700 border-opacity-20 transition-all disabled:bg-opacity-30 disabled:hover:bg-yellow-500/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-yellow-800  text-gray-white p-3 rounded-md",
    gray: "leading-5 bg-gray-500 shadow border border-gray-700 border-opacity-20 transition-all disabled:bg-opacity-30 disabled:hover:bg-gray-500/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-gray-600  text-gray-white p-3 rounded-md",
    alertOutlined: "leading-5 bg-transparent shadow border-2 border-red-500 transition-all disabled:bg-opacity-30 disabled:hover:bg-red-500/30 disabled:ring-opacity-0 disabled:border-opacity-50 hover:bg-red-500 text-red-500 hover:text-white p-3 rounded-md",
    grayOutlined: "leading-5 bg-transparent shadow border-2 border-gray-500 transition-all disabled:bg-opacity-30 disabled:hover:bg-gray-500/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-gray-500 text-gray-500 hover:text-white p-3 rounded-md",
    paginate: "leading-5 bg-transparent shadow border-2 border-gray-500 transition-all disabled:bg-opacity-30 disabled:hover:bg-gray-500/30 disabled:ring-opacity-0 disabled:border-opacity-0 hover:bg-gray-500 text-gray-500 hover:text-white p-3 rounded-md",
    textDestructive: "text-destructive hover:brightness-90 transition-all",
}

type Theme = keyof typeof themes

interface ButtonProps {
    className?: string,
    children?: any,
    onClick?: any,
    props?: any
    theme?: Theme,
    disabled?: boolean,
    loading?: boolean,
    type?: "button" | "submit" | "reset",
    description?: string
}

export function Button({className, description, children, onClick, theme="default", disabled, loading, ...props}: ButtonProps){

    return <>
        <button disabled={disabled} className={classNames(className, themes[theme], "disabled:hover:cursor-default hover:cursor-pointer")} {...props} onClick={onClick} >
            {loading? <Spinner className="mx-auto" size="sm" /> : children }
        </button>
        {description && <p className="mt-2 text-xs text-container-foreground">{description}</p>}
    </>
}

interface ButtonLinkProps extends ButtonProps {
    to?: string
    target? : "_blank" | "_self" | "_parent" | "_top"
}

export function ButtonLink({to, className, children, target, onClick, theme="default", disabled, loading, ...props}: ButtonLinkProps){

    return <>
        <button disabled={disabled} className={classNames(className, themes[theme], "disabled:hover:cursor-default hover:cursor-pointer")} {...props} onClick={onClick} >
            {loading? <Spinner className="mx-auto" size="sm" /> : <Link className="block" target={target} to={to || ""}>{children}</Link>}
        </button>
    </>
}

Button.Link = ButtonLink
