import { useContext } from "react";
import { Ressources, RessourcesContext } from "contexts/ressources";

/**
 * @example const {ressources, setRessources} = useRessources(); 
 * @description This hook returns the list of ressources from the RessourcesContext
 * @returns {Ressources} ressources list, setRessources list {ressources, setRessources}
 */
export function useRessources():Ressources{
    return useContext(RessourcesContext);
}

