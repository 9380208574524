// Components
import { TextInput } from "components/core/inputs"
import { Button } from "components/core/buttons"
import { MetricsList, MetricsModal } from "components/ressources/metrics/list"
import { Section } from "components/core/section"

// Icons
import { AiFillGithub as GithubIcon } from "react-icons/ai"
import { BsLink as LinkIcon } from "react-icons/bs"
import { MdAddchart as AddMetricIcon } from "react-icons/md"

// Types
import { Dispatch, SetStateAction } from "react"

// Utils
import { isDefaultMetric, defaultMetrics, DefaultMetricTypes } from "components/ressources/metrics/list"

// API
import { getMetrics } from "api/ressources"

// Hooks
import { useTranslation } from "react-i18next"
import { useState, useMemo, useCallback } from "react"
import { useSearch } from "hooks/useSearch"
import { Select } from "components/core/select"

export function ControlledRessourceBuilder({ ressource, setRessource, onSubmit, loading }: { ressource: Ressource, setRessource: Dispatch<SetStateAction<Ressource>>, onSubmit: (e: any) => void, loading?: boolean }) {
    const { t } = useTranslation("common")
    const disabled = ressource.name === "" || loading;
    const [open, setOpen] = useState(false)
    const handleDelete = (index:number) => {
        if (isDefaultMetric(ressource.metrics[index], ressource?.type)) return
        const newMetrics = ressource.metrics.filter((_, i) => i !== index)
        setRessource((prev:any) => ({...prev, metrics: newMetrics}))
    }
    return <>
        <form onSubmit={onSubmit}>
            <Section.Contained title={t("general-infos")}>
                <RessourceMeta ressource={ressource} setRessource={setRessource} />
            </Section.Contained>
           
            <div className="sm:mt-8">
                <Section.Contained noPadding title={t("setup-metrics.title")} description={t("setup-metrics.description")} topBar={<AddMetricButton setOpen={setOpen} />}>
                    <MetricsList.Create ressource={ressource} metrics={ressource.metrics} handleDelete={handleDelete} />
                    <MetricsModal.Create metrics={ressource.metrics} setMetrics={(metrics: Metric[]) => setRessource(prev => ({ ...prev, metrics }))} open={open} setOpen={setOpen} onClose={()=>setOpen(false)}/>
                </Section.Contained>
            </div>
            <div className="flex justify-end mt-4 xl:mt-0">
                <Button type="submit" loading={loading} className="w-full xl:mt-5 sm:w-44" disabled={disabled}>{t("save")}</Button>
            </div>
        </form>
    </>
}

export function ControlledRessourceEditor({ ressource, setRessource, onSubmit, loading }: { ressource: Ressource, setRessource: Dispatch<SetStateAction<Ressource>>, onSubmit: (e: any) => void, loading?: boolean }) {
    const { t } = useTranslation("common")
    const disabled = ressource.name === "" || loading ;
    const [open, setOpen] = useState(false)

    const paramsMetrics = useMemo(()=> ({
        ressource: ressource.slug
    }),[ressource.slug])

    const validateParams = useCallback((params:any) => {
        return params.ressource !== undefined
    }, [])

    const [metrics, {loading: loadingMetrics, setResult: setMetrics }] = useSearch<Metric>(getMetrics, paramsMetrics, {immediate: true, validateParams })


    return <>
        <form onSubmit={onSubmit}>
            <Section.Contained title={t("general-infos")} description={t("remember-to-save")}>
                <RessourceMeta ressource={ressource} setRessource={setRessource} />
            </Section.Contained>
            <div className="flex justify-end mt-4 xl:mt-0">
                <Button type="submit" loading={loading} className="w-full xl:mt-5 sm:w-44" disabled={disabled}>{t("save")}</Button>
            </div>
            <div className="sm:mt-8">
                <Section.Contained noPadding title={t("setup-metrics.title")} description={t("setup-metrics.description")} topBar={<AddMetricButton setOpen={setOpen} />}>
                    <MetricsList.Edit metrics={metrics || []} setMetrics={setMetrics} loading={loadingMetrics} ressource={ressource} />
                    <MetricsModal.Edit ressource={ressource} metrics={metrics || []} setMetrics={setMetrics} open={open} setOpen={setOpen} onClose={()=>setOpen(false)} />
                </Section.Contained>
            </div>
            
        </form>
    </>
}

function AddMetricButton({setOpen}:{setOpen:Dispatch<SetStateAction<boolean>>}){
    const { t } = useTranslation("common")
    return <>
        <div className="flex justify-end">
            <Button type="button" theme="blank" className="flex items-center mb-3 transition-colors w-fit h-fit text-container-foreground hover:text-gray-300" onClick={()=>setOpen(true)}>
                <AddMetricIcon className="mr-1 text-lg" /> <span className="text-sm ">{t("add-metric")}</span>
            </Button>
        </div>
    </>
}

function RessourceMeta({ ressource, setRessource }: { ressource: Ressource, setRessource: Dispatch<SetStateAction<Ressource>> }) {
    const { t } = useTranslation("common")
    const typeOptions = useMemo(() => ([
        {
            name: t("api.label"),
            description: t("api.description"),
            value: "api",
        },
        {
            name: t("worker.label"),
            description: t("worker.description"),
            value: "worker"
        }
    ]), []);

    const foundType = useMemo(()=>typeOptions.find(t => t.value === ressource.type), [ressource]);
    
    const handleSetFormat = (v:string) => {
        const typeMetrics = [...defaultMetrics["all"],...defaultMetrics[v as DefaultMetricTypes]] || [];
        setRessource(prev => ({ ...prev, type:v, metrics: typeMetrics}))
    }

    return <>
        <div className="grid grid-cols-1 gap-4 mb-4">
            <div>
                <TextInput placeholder={t("name")} theme="outlined" label={t("name")} defaultValue={ressource.name} onChange={(v: string) => setRessource(prev => ({ ...prev, name: v }))} />
            </div>
            <div>
                <TextInput placeholder={t("description")} theme="outlined" label={t("description")} defaultValue={ressource.description} onChange={(v: string) => setRessource(prev => ({ ...prev, description: v }))} />
            </div>
        </div>
        <div className="h-20 mb-4">
                <Select noValuesMsg={t("no-ressource-format")} 
                        indexingKey={"value"} 
                        label={t("format")} 
                        theme="outlined" 
                        options={typeOptions} 
                        selected={foundType || typeOptions[0]}
                        setSelected={({value}: {value:string}) => handleSetFormat(value)} 
                        toString={(r: any) => r?.name}
                        toDescription={(r: any) => r?.description} />
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
                <div className="flex mb-2 text-xl">
                    <LinkIcon className="mr-1" />
                    <span className="text-sm font-medium text-gray-300">URL</span>
                </div>
                <TextInput placeholder={t("url-example")} theme="outlined" defaultValue={ressource.url} onChange={(v: string) => setRessource(prev => ({ ...prev, url: v }))} />
            </div>
            <div>
                <div className="flex mb-2 text-xl">
                    <GithubIcon className="mr-1" />
                    <span className="text-sm font-medium text-gray-300">Github URL</span>
                </div>
                <TextInput placeholder={t("git-url-example")} theme="outlined" defaultValue={ressource.githubUrl} onChange={(v: string) => setRessource(prev => ({ ...prev, githubUrl: v }))} />
            </div>
        </div>
    </>
}