// Components
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "components/ui/dropdown-menu"
import { Dialog, DialogContent } from "components/ui/dialog"

// Types
import { Dispatch, SetStateAction } from "react"

// Icons
import { RiArrowDropDownLine as DropdownIcon } from 'react-icons/ri'
import { AiOutlineClose as CloseIcon } from 'react-icons/ai'
import { BsCheckLg as CheckIcon } from "react-icons/bs"

// Utils
import classNames from "classnames"

// Hooks
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "hooks/useMediaQuery"
import { DialogTitle } from "@radix-ui/react-dialog"


interface FilterProps {
    selectedFilter?: any;
    setSelectedFilter?: Dispatch<SetStateAction<any>>;
    filters?: any[];
    hasFilter?: boolean;
    reset?: () => void;
}

export function Filter({selectedFilter, setSelectedFilter, filters, hasFilter, reset}:FilterProps){
    const [open, setOpen] = useState<boolean>(false)

    return <>
        <FilterPopover open={open} setOpen={setOpen} filters={filters} hasFilter={hasFilter} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} reset={reset}/>
    </>
}

interface FilterPopoverProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    filters?: any[];
    selectedFilter?: any;
    setSelectedFilter?: Dispatch<SetStateAction<any>>;
    hasFilter?: boolean;
    reset?: () => void;
}

function FilterPopover({open, setOpen, selectedFilter, filters, setSelectedFilter, hasFilter, reset}:FilterPopoverProps){
    const { t } = useTranslation("common")
    const isDesktop = useMediaQuery("sm")
    return <>
    <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger>
            <FilterLabel className={classNames(hasFilter && "text-primary")} />
        </DropdownMenuTrigger>
        {
            isDesktop ? 
            <DropdownMenuContent align="end">
                <div className="text-sm font-light leading-none w-72">
                    <div className="flex justify-between p-4 py-3">
                        {t("sort-by")}
                        <CloseIcon onClick={()=>setOpen(false)} className="text-sm cursor-pointer"/>
                    </div>
                    {
                        filters && filters.map((filter, i)=>
                        <DropdownMenuItem key={filter+i} onClick={()=>setSelectedFilter?.(filter)} className={classNames(i===0 && "border-t", selectedFilter === filter && "backdrop-brightness-90","last:border-b-0 border-b border-gray-300/10 p-4 py-2.5 flex items-center cursor-pointer hover:backdrop-brightness-90 hover:bg-inherit")}>
                            <div className="w-6">{filter === selectedFilter && <CheckIcon className="text-primary-light"/>}</div>{t("filter-options."+filter)}
                        </DropdownMenuItem>)
                    }
                    {
                        hasFilter && <div className="text-sm px-2.5 py-2"><span className="text-red-600 hover:text-red-500 cursor-pointer" onClick={reset}>{t("reset")}</span></div>
                    }
                    </div>
            </DropdownMenuContent>
            :
            <Dialog open={open} onOpenChange={setOpen} >
                <DialogContent className="">
                    <DialogTitle>{t("sort-by")}</DialogTitle>
                    <div>
                        {
                            filters && filters.map((filter, i)=>
                                <div  key={filter+i} 
                            onClick={()=>{setSelectedFilter?.(filter); setOpen(false); }} 
                            className={classNames(i===0 && "border-t","last:border-b-0 border-b py-2 border-gray-300/10 flex items-center cursor-pointer hover:backdrop-brightness-90")}
                            >
                                    <div className="w-6">{filter === selectedFilter && <CheckIcon className="text-primary-light"/>}</div>{t("filter-options."+filter)}
                                </div>
                            )
                        }
                    </div>
                </DialogContent>
            </Dialog>
        }
        
    </DropdownMenu>
    
    </>
}

function FilterLabel({className}:{className?:string}){
    const { t } = useTranslation("common")
    return <div className={classNames("text-container-foreground flex items-center text-sm select-none", className)}>
        {t("sort")} <DropdownIcon className="text-xl"/>
    </div>
}