// Utils
import classNames from "classnames";
import { calendar as utilsCalendar } from "utils/calendar";

// Icons
import { HiMiniChevronLeft as PreviousMonthIcon, HiMiniChevronRight as NextMonthIcon } from "react-icons/hi2"

// Hooks
import { useTranslation } from "react-i18next";
import { useState, Dispatch, SetStateAction } from "react";

interface CalendarProps {
    date?: Date;
    onPreviousMonth?: () => void;
    onNextMonth?: () => void;
    minPickableDate?: Date;
    maxPickableDate?: Date;
    maxPickDelta?: number;
    className?: string;
    showReset?: boolean;
    showJumpToday?: boolean;
    select: 'single' | 'multiple' | 'range';
    selectedDates: Date[];
    setSelectedDates: Dispatch<SetStateAction<Date[]>>;
}

        
export function CalendarCompactMonth({ date, className, minPickableDate, maxPickableDate, maxPickDelta, showReset = true, showJumpToday = true, select, selectedDates, setSelectedDates }: CalendarProps) {
    const { t } = useTranslation("common");

    const weekDays: any[] = [
        t('calendar-days.sunday'),
        t('calendar-days.monday'),
        t('calendar-days.tuesday'),
        t('calendar-days.wednesday'),
        t('calendar-days.thursday'),
        t('calendar-days.friday'),
        t('calendar-days.saturday')
    ]

    const months: any[] = [
        t('calendar-months.january'),
        t('calendar-months.february'),
        t('calendar-months.march'),
        t('calendar-months.april'),
        t('calendar-months.may'),
        t('calendar-months.june'),
        t('calendar-months.july'),
        t('calendar-months.august'),
        t('calendar-months.september'),
        t('calendar-months.october'),
        t('calendar-months.november'),
        t('calendar-months.december')
    ]

    const initialDate = date || new Date()
    const [activeDate, setActiveDate] = useState<Date>(initialDate)
    const monthView = utilsCalendar.getMonthDatesPadded(activeDate)
    
    const handlePreviousMonth = () => {
        const previousMonth = new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1);
        setActiveDate(previousMonth);
    }

    const handleNextMonth = () => {
        const nextMonth = new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1);
        setActiveDate(nextMonth);
    }

    const jumpToDate = (date: Date = new Date()) => {
        setActiveDate(date)
    }

    const handleReset = () => {
        setActiveDate(date || new Date())
        setSelectedDates && setSelectedDates([])
    }
    
    const toggleDate = (date: Date) => {
        if (maxPickableDate && (date > utilsCalendar.toEndOfDate(maxPickableDate))) return // Date is after maxPickableDate
        if (minPickableDate && (date < utilsCalendar.toStartOfDate(minPickableDate))) return // Date is before minPickableDate
        if (maxPickDelta && selectedDates.length > 0 && utilsCalendar.getDatesDelta(date, selectedDates[0]) > maxPickDelta) return // Date delta is greater than maxPickDelta
        if (maxPickDelta && selectedDates.length > 0 && date < utilsCalendar.toStartOfDate(selectedDates[0])) return // Date is before first selected date and maxPickDelta is set
        if (utilsCalendar.dayInArray(date, selectedDates)){
            return setSelectedDates(utilsCalendar.removeDayFromArray(date, selectedDates))
        }
        else if (select === 'single'){
            return setSelectedDates([date])
        }
        else if (select === 'multiple'){
            return setSelectedDates([...selectedDates, date])
        }
        else if (select === 'range'){
           return setSelectedDates(utilsCalendar.createDatesRange(date, selectedDates))
        }
    }
    return <>
        <div className="flex justify-between pb-5">
            <PreviousMonthIcon onClick={handlePreviousMonth} className="w-6 h-6 cursor-pointer text-container-foreground" />
            <span className="text-sm font-semibold select-none text-container-foreground">{months[activeDate.getMonth()].long} {!utilsCalendar.isSameYear(initialDate, activeDate) && activeDate.getFullYear()}</span>
            <NextMonthIcon onClick={handleNextMonth} className="w-6 h-6 cursor-pointer text-container-foreground" />
        </div>
        <div className={classNames("grid w-full grid-cols-7 mb-2", className)}>
            {
                weekDays.map((day, index) => {
                    return <div key={index} className="col-span-1 text-sm text-center text-container-foreground">{day.narrow}</div>
                })
            }
        </div>
        <div className="grid grid-cols-7 rounded-md bg-container-light">
            {
                monthView.map((monthDay, index) => {
                    return <div
                        key={'day-' + index}
                        onMouseDown={() => toggleDate(monthDay)}
                        onMouseEnter={(e) => e && e.buttons && toggleDate(monthDay)}
                        className={
                            classNames("col-span-1 cursor-pointer row-span-1 text-sm p-1 text-center aspect-square flex items-center justify-center border border-container-foreground/30 ",
                                !utilsCalendar.isSameMonth(activeDate, monthDay) && "text-container-foreground",
                                index === 0 && "rounded-tl-md", // First day of month
                                index === 6 && "rounded-tr-md", // Last day of first week
                                index === monthView.length - 7 && "rounded-bl-md", // First day of last week
                                index === monthView.length - 1 && "rounded-br-md", // Last day of month
                                utilsCalendar.isSameDay(new Date(), monthDay) && "text-primary", // Today
                                minPickableDate && monthDay < utilsCalendar.toStartOfDate(minPickableDate) && "text-container-foreground/30 hover:cursor-default", // Date is before minPickableDate
                                maxPickableDate && monthDay > utilsCalendar.toEndOfDate(maxPickableDate) && "text-container-foreground/30 hover:cursor-default", // Date is after maxPickableDate
                                maxPickDelta && selectedDates.length > 0 && utilsCalendar.getDatesDelta(monthDay, selectedDates[0]) > maxPickDelta && "text-container-foreground/30 hover:cursor-default", // Date delta is greater than maxPickDelta
                                maxPickDelta && selectedDates.length > 0 && monthDay < utilsCalendar.toStartOfDate(selectedDates[0]) && "text-container-foreground/30 hover:cursor-default", // Date is before first selected date and maxPickDelta is set
                            )}>
                            <div className={
                                classNames(
                                    "select-none w-full h-full flex items-center justify-center",
                                    utilsCalendar.dayInArray(monthDay, selectedDates) && "bg-primary rounded-md text-white", // Selected date
                                    select === 'range' && selectedDates.length === 2 && utilsCalendar.isInRange(monthDay, selectedDates[0], selectedDates[1], true) && "bg-primary/20 text-white rounded-md",
                                )
                            }>
                            {
                                monthDay.getDate()
                            }
                            </div>
                    </div>
                })
            }

        </div>
        <div className="flex justify-between h-3 mt-2">
            {
                showReset && selectedDates.length > 0 && <div className="flex items-center">
                    <p className="text-sm text-red-600 transition-colors cursor-pointer hover:text-red-500" onClick={handleReset}>{t("reset")}</p>
                </div>
            }
            {
                showJumpToday && !utilsCalendar.isSameMonth(new Date(), activeDate) && <div className="flex items-center justify-end w-full">
                    <p className="text-sm transition-colors cursor-pointer text-container-foreground hover:text-gray-300" onClick={() => jumpToDate()}>{t("today")}</p>
                </div>
            }
        </div>
    </>
}

export const Calendar = {
    CompactMonth: CalendarCompactMonth
}