import { createModel } from "api/models";
import { Button } from "components/core/buttons";
import { TextInput } from "components/core/inputs";
import { Modal } from "components/core/modal";
import { Uploader } from "components/core/uploader";
import { useAPI } from "hooks/useAPI";
import { useActiveOrg } from "hooks/useActiveOrg";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { setStateType } from "types/utils";
import { uploadModelFilesAsync } from "./upload";
import { useModels } from "hooks/useModels";
import { Text } from "components/core/typo"

// SetStateType

export function CreateModelModal({open, setOpen}: {open: boolean, setOpen: setStateType<boolean>}){
    const [draft, setDraft] = useState<Model>({name: "", version: ""});
    const [files, setFiles] = useState<File[] | undefined>([]);
    const {orgParams} = useActiveOrg();
    const {models, setModels} = useModels();
    const onResult = useCallback((r:Model)=>{
        if (models!=undefined){
            setModels((e: Model[])=>[r, ...e])
            if (!files || files.length===0){
                setOpen(false)
            }
        }
    }, [models]);
    const [model, {loading, execute, error}] = useAPI<Model>(createModel, {organization: orgParams, ...draft}, {immediate:false, onResult});
    const {t} = useTranslation("common");

    // Would be better using Promise...
    useEffect(()=>{
        if (model){
            if (files && files.length > 0) {
                uploadModelFilesAsync({modelId: (model?.id || ""), files, onFinally:()=>setOpen(false)})
            }
        }
    }, [model]);

    return <>
        <Modal title={t("create-model.title")} open={open} setOpen={setOpen} size="lg">
        <Modal.Body>
            <TextInput 
            label={t("create-model.name")} 
            description={t("create-model.name-description")}
            theme="outlined" 
            placeholder={t("create-model.name-placeholder")} 
            defaultValue={draft.name}
             onChange={(name:string)=>setDraft((e)=>({...e, name}))} />

            <div className="h-4"/>
            <TextInput 
            label={t("create-model.version")} 
            theme="outlined" 
            placeholder={t("create-model.version-placeholder")} 
            defaultValue={draft.version}
             onChange={(version:string)=>setDraft((e)=>({...e, version}))} />


        <div className="mt-4 mb-4">
            <Uploader.DropZone multiple id="file-upload" label={t("create-model.upload-title")} files={files} setFiles={setFiles}  />
        </div>

        </Modal.Body>
        {error && <Text.Error {...error} className="mb-3"></Text.Error>}
        <Modal.Footer type="doubleButton">
            <Button type="reset" theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
            <Button loading={loading} disabled={loading || !draft.name || !draft.version} onClick={execute}>{t("create")}</Button>
        </Modal.Footer>
        </Modal>
    </>
}

export function CreateModelButton(){
    const [open, setOpen] = useState(false);
    const {t} = useTranslation("common");
    return <>
        <Button theme="smDefault"  onClick={()=>setOpen(true)}><FiPlus className="inline-flex mb-[0.5px] ml-3"/><span className="mr-2">   {t("create-model.button")}</span></Button>
        <CreateModelModal open={open} setOpen={setOpen}/>
    </>
}