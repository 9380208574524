import { useContext } from "react";
import { ModelContextResult, ModelContext } from "contexts/model";

/**
 * @example const {model, loading, error} = useRessources(); 
 * @description This hook returns the list of ressources from the ModelsContext
 * @returns {Models} model list, loading boolean, error {ressources, loading, error}
 */
export function useModel(): ModelContextResult{
    return useContext(ModelContext);
}

