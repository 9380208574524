// Hooks
import { useAuth } from "hooks/useAuth"
import { useParams } from "react-router-dom"

// Constants
import { routes } from "constants/routes"

// Components
import { Navigate } from "react-router-dom"

export function OrganizationDetails(){

    const { user } = useAuth()
    const { org } = useParams()
    const organization = user && user.organizations.find((o:any) => o.slug === org)

    if (!user) return null

    if (user && !organization) return <Navigate to={routes.notFound} replace/>

    return <>
        details
    </>
}