
// Hooks
import { createContext, useEffect, useMemo} from "react";
import { Navigate, useParams } from "react-router-dom";
import { useSearch } from "hooks/useSearch";
import { useActiveOrg } from "hooks/useActiveOrg";

// API
import { getOrgRessources } from "api/ressources";

// Constants
import { routes } from "constants/routes";

// Utils
const RessourcesContext = createContext<Ressources>({});

export type Ressources= {
    ressources?: any[];
    setRessources?: (ressources:any) => void;
    reload?: () => void;
    loading?: boolean;
}

const RessourcesContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { org:orgParams } = useParams()
    const { activeOrg } = useActiveOrg()
    const selectedOrg = useMemo(()=> ({ orgId: orgParams || activeOrg.slug}), [orgParams])
    
    const [ressources, {execute, error, loading, setResult: setRessources}] = useSearch(getOrgRessources, selectedOrg, {immediate: false, fields: ["slug", "name", "status", "github_url", "type", "url", "last_seen", "organization"]})
    useEffect(()=>{
        if(!selectedOrg.orgId) return
        execute()
    }, [selectedOrg])
    
    if (error) return <Navigate to={routes.notFound} replace={true} />

    return (
        <RessourcesContext.Provider value={{ressources, setRessources, reload:execute, loading: loading}}>
            {children}
        </RessourcesContext.Provider>
    );

};

export { RessourcesContext, RessourcesContextProvider };
