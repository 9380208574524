import classNames from "classnames"

import { Nav } from "components/nav"
import { TopBar } from "components/nav/topbar"

interface FullLayoutProps {
    className?: string,
    center?: boolean,
    props?: any
    children?: React.ReactNode
}

function FullLayout({className, children, center, props}: FullLayoutProps){
    return <div className={classNames("max-w-screen overflow-x-hidden min-h-screen py-10", center && "flex justify-center items-center", className)} {...props}>
        {children}
    </div>
}

interface DashboardLayoutProps {
    className?: string,
    props?: any
    children?: React.ReactNode
}


function DashboardLayout({className, children, props}: DashboardLayoutProps){

    return <div className={classNames(className, "flex flex-col sm:flex-row overflow-hidden")} {...props}>
        <div className="flex-shrink-0 hidden w-0 transition-all sm:block sm:w-16 lg:w-64 bg-background">
            <Nav.Desktop />
        </div>
        <div className="w-full overflow-hidden">
            <div className="min-h-screen pt-14">
                <Nav.Mobile />
                <TopBar.Xl />
                <div className="max-w-[1500px] p-5 lg:px-10 lg:mx-auto">
                    {children}
                </div>
            </div>
        </div>

    </div>
}


export const Layout = {
    Full: FullLayout,
    Dashboard: DashboardLayout
}