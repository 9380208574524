// Hooks
import { useState } from "react"
import { useTranslation } from "react-i18next"

// Utils
import classNames from "classnames"

// Components
import { Link, NavLink } from "react-router-dom"
import { Logo } from "components/tools/logo"
import { SideBar } from "components/nav/sidebar"
import { TopBar } from "components/nav/topbar"
import { SelectActiveOrg } from "components/organizations/select-active-org"
import { ScrollArea } from "components/ui/scroll-area"

// Constants
import { useNavigationPages} from "constants/navigation-main"
import { routes } from "constants/routes"

// Icons
import { FiLogOut as LogoutIcon } from 'react-icons/fi'


export function NavMobile(){
    const { t } = useTranslation("common")
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const pages = useNavigationPages()
    return <>
        <TopBar.Small setSidebarOpen={setSidebarOpen} />
        <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} >
            <div className="flex flex-col h-full">
                <div className="my-5 mb-10">
                    <Logo.Small className='h-6'/>
                </div>

                <ul>
                    {
                        pages.map(({path, name, Icon}, i)=>
                            <MobileNavItem setOpen={setSidebarOpen} path={path} name={t(name)} Icon={Icon} key={i} />
                        )
                    }
                </ul>

                <div className="flex-1">
                    <BottomNavMobile />
                </div>
            </div>

        </SideBar>
    </>
}

interface NavItemProps {
    path: string | ((org:any)=>string),
    name: string,
    Icon?: any,
    indexingKey?: string,
    className?: string,
}

function NavItem({path, name, Icon, indexingKey, className}: NavItemProps){

    return <li key={indexingKey} className="relative block">
        <NavLink to={path.toString()}>
            {
                ({isActive}) => <>
                    <span className={classNames("flex group items-center justify-center lg:justify-start h-10 lg:px-4 transition-all", (!isActive || path === routes.noOrganizations) && "text-container-foreground hover:text-gray-100", className)}>
                        {Icon && <Icon className={classNames("text-xl lg:ml-1 flex-shrink-0")}/>}
                        <span className="hidden ml-3 lg:block whitespace-nowrap">{name}</span>
                    </span>
                </>
            }
        </NavLink>
    </li>
}

function MobileNavItem({path, name, Icon, indexingKey, setOpen}: NavItemProps & {setOpen?: (p:any)=>any}){

    return <li key={indexingKey} className="list-none" >
        <NavLink onClick={()=>setOpen && setOpen(false)} to={path.toString()} className="block">
        {
            ({isActive}) => <>
                <span className={classNames("flex items-center transition-all mb-5", (!isActive || path === routes.noOrganizations) && "text-container-foreground mb-3 hover:text-white")}>
                    {Icon && <Icon className={classNames("text-2xl lg:ml-1")}/>}
                    <span className="ml-2">{name}</span>
                </span>
            </>
        }
        </NavLink>
    </li>
}

export function NavDesktop(){
    const { t } = useTranslation("common")
    const pages = useNavigationPages()

    return <div className="h-full">
        <div className="fixed z-50 flex flex-col transition-none flex-shrink-0 h-[100vh] transition-all ">

        <div className="flex items-center justify-center w-12 py-5 m-3 overflow-hidden transition-all border rounded shadow lg:w-56 lg:mb-0 lg:border-b-0 lg:rounded-b-none h-14 lg:h-24 bg-container border-container-light">
            <Link to={routes.home}>
                <Logo.Xl className="h-6 hidden lg:block min-w-[9rem]"/>
                <Logo.Small className="block h-4 mx-auto lg:hidden"/>
            </Link>
        </div>
        
        <ul className="flex flex-col overflow-x-hidden overflow-y-auto h-full gap-3 pt-2 m-3 mt-0 transition-all border rounded shadow lg:rounded-t-none lg:border-t-0 lg:gap-1.5 bg-container border-container-light sm:w-12 lg:w-56">
            <ScrollArea className="h-full">
                <div className="flex flex-col gap-1.5">
                    {
                        pages.map(({path, name, Icon}, i)=>
                            <NavItem path={path} name={t(name)} Icon={Icon} key={i} />
                        )
                    }
                </div>
            </ScrollArea>
            <div className="flex flex-col justify-end">
                <div className="hidden pl-2 mb-2 lg:block">
                    <SelectActiveOrg />
                </div>
                <div className="mb-3.5 lg:hidden">
                    <SelectActiveOrg.Modal />
                </div>
                <BottomNav />
            </div>
        </ul>
        </div>
    </div>
}
function BottomNav(){
    const { t } = useTranslation("common")
    return <div className="flex flex-col py-2 border-t border-gray-500/40">
        <NavItem path={routes.logout} Icon={LogoutIcon} name={t("logout")} />
    </div>
}

function BottomNavMobile(){
    const { t } = useTranslation("common")
    return <div className="flex flex-col justify-end w-full h-full">
        <div className="mb-2">
            <SelectActiveOrg />
        </div>
        <div className="flex items-end ">
            <div className="w-full pt-5 border-t border-gray-500/40">
                <MobileNavItem path={routes.logout} Icon={LogoutIcon} name={t("logout")} />
            </div>
        </div>
    </div>
}

export const Nav = {
    Mobile: NavMobile,
    Desktop: NavDesktop
}