// Components
import { Text } from "components/core/typo"
import { Container } from "components/core/container"

// Utils
import classNames from "classnames"
import { themes as containerThemes } from "components/core/container"

interface SectionProps {
    children?: React.ReactNode,
    title?: string,
    description?: string,
    position?: "top" | "middle" | "bottom" | "single",
    topBar?: React.ReactNode,
    noPadding?: boolean,
    theme?: keyof typeof containerThemes
}

const positions = {
    top: "xl:rounded-b-none",
    middle: "xl:rounded-none xl:border-t-black/20",
    bottom: "xl:rounded-t-none xl:border-t-black/20",
    single: ""
}

export function SectionContained({children, theme="default", title, description, position = "single", topBar, noPadding}: SectionProps){
    return <div className="flex flex-col mb-5 xl:flex-row gap-x-20 xl:mb-0">
        <div className={classNames("w-full md:w-[450px] xl:w-[320px]", topBar && "-mb-5 mt-7")}>
            <Text.TitleSmall description={description}>{title}</Text.TitleSmall>
        </div>
        <div className="flex-1">
            {topBar}
            <Container theme={theme} noPadding={noPadding} className={classNames("flex-1", positions[position])}>
                {children}
            </Container>
        </div>
    </div>
}

export function SectionBlank({children, title, description, position = "single"}: SectionProps){
    return <div className="flex flex-col mb-5 xl:flex-row gap-x-20 xl:mb-0">
        <div className={classNames("w-full md:w-[450px] xl:w-[320px]", (title || description) && "mt-5")}>
            {(title||description) && <Text.TitleSmall description={description}>{title}</Text.TitleSmall>}
        </div>
        <div className={classNames("flex-1", positions[position])}>
            {children}
        </div>
    </div>
}

export const Section = {
    Contained: SectionContained,
    Blank: SectionBlank
}