// Constants
import roles from "constants/roles";
import { routes } from "constants/routes";

// Components
import { Navigate } from "react-router-dom";

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg";
import { useMemo } from "react";
import { useRouteOrg } from "hooks/useRouteOrg";

interface RoleGuardProps {
    userRole?: string;
    requiredRole: string;
    strictAccess?: boolean;
    children: React.ReactNode;
    redirect?: boolean;
}


export function useRoleGuard({userRole, requiredRole, strictAccess}: {userRole?: string, requiredRole: string, strictAccess?: boolean}){
  const { activeOrg } = useActiveOrg();
  const { routeOrg } = useRouteOrg();

  // Determine the effective role
  const effectiveRole = useMemo(() => {
    return userRole || routeOrg?.access?.role || activeOrg?.access?.role;
  }, [userRole, activeOrg, routeOrg]);

  // Check if the role is valid
  const isRoleValid = useMemo(() => {
    return Object.keys(roles).includes(effectiveRole);
  }, [effectiveRole, roles]);

  // Determine if the user is allowed
  const isAllowed = useMemo(() => {
    if (!isRoleValid) return false;
    if (strictAccess) return effectiveRole === requiredRole;
    return roles[effectiveRole] >= roles[requiredRole];
  }, [isRoleValid, strictAccess, effectiveRole, requiredRole, roles]);

  return isAllowed;
}


/**
 * 
 * @param userRole The current user's role
 * @param requiredRole The role required to access the children
 * @param roles All the possible roles
 * @param strictAccess If true, the user must have the exact role specified in requiredRole. If false, the user must have a role that is at least as high as the role specified in requiredRoleé
 * @param children The children to render if the user has the required role
 * @returns 
 */
export function RoleGuard({userRole, requiredRole, strictAccess, children, redirect = true}: RoleGuardProps){
 
    const isAllowed = useRoleGuard({userRole, requiredRole, strictAccess})

    if (redirect && !isAllowed) return <Navigate to={routes.notFound} replace />

    if (!isAllowed) return <></>
    
    return <>
        {children}
    </>
}


