// Syntax highlighter
import Prism from 'components/core/syntax-highlighter/prism';
import 'components/core/syntax-highlighter/themes/prism-one-dark.css';

export const languages = {
    "python": "language-python",
    "bash": "language-bash",
    "javascript": "language-javascript",
    "typescript": "language-typescript",
    "css": "language-css",
    "html": "language-html",
    "tsx": "language-tsx",
    "jsx": "language-jsx",
    "json": "language-json",
}

// Components
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';

// Utils
import classNames from "classnames"

// Icons
import { MdOutlineContentCopy as CopyIcon } from 'react-icons/md';
import { BsCheckLg as CheckIcon } from 'react-icons/bs';

// Hooks
import { useEffect, useState, useRef } from "react";

interface CodeBlockProps {
    children: React.ReactNode
    copyValue?: string
    title?: string
    className?: string
    noCopy?: boolean
    language?: keyof typeof languages
}

export function CodeBlock({children, copyValue, title, className, noCopy=false, language="python"}: CodeBlockProps){
    
    const ref = useRef<HTMLDivElement>(null)
    const [ copied, setCopied ] = useState(false)
    const handleCopy = () => {
        if (noCopy) return
        navigator.clipboard.writeText(copyValue || children && children.toString() || "")
        setCopied(true)
        const timeout = setTimeout(() => {
            setCopied(false)
            return () => clearTimeout(timeout)
        }, 2000);
    }

    useEffect(() => {
        if (ref.current){
            Prism.highlightElement(ref.current)
        }
    }, [])
    return <>
        <div className={classNames("flex flex-col w-full rounded-lg bg-container/70 group", className)}>
            {
                title && <div className="px-4 py-2 text-sm text-white rounded-t-lg bg-container-light/70">
                    {title}
                </div>
            }
            <ScrollArea className={classNames("relative p-4 py-4 !whitespace-pre !bg-inherit rounded-b-lg", !title && "rounded-t-lg")}>
                <code>
                    {
                        !noCopy && <div className="absolute invisible text-lg transition-all translate-y-1/2 opacity-0 cursor-pointer top-1 text-container-foreground hover:text-white group-hover:visible group-hover:opacity-100 right-2">
                            {
                                copied ? <CheckIcon />: <CopyIcon onClick={handleCopy} />
                            }
                        </div>
                    }
                    <div ref={ref} className={classNames('', languages[language])}>
                        {children}
                    </div>
                </code>
                <ScrollBar className='mx-1 mb-1' orientation='horizontal'/>
            </ScrollArea>
        </div>
        
    </>
}