import { useContext } from "react";
import { Jobs, RessourceJobsContext } from "contexts/resource-jobs";

/**
 * @example const {jobs, loading, error} = useRessources(); 
 * @description This hook returns the list of ressources from the JobsContext
 * @returns {Jobs} jobs list, loading boolean, error {ressources, loading, error}
 */
export function useRessourceJobs():Jobs{
    return useContext(RessourceJobsContext);
}

