// Hooks
import { useTranslation } from "react-i18next"

// Constants
import { routes } from "constants/routes";

// Components
import { Layout } from "layouts";
import { Button } from "components/core/buttons";
import { Title } from "components/core/typo";
import { Logo } from "components/tools/logo";

export function Error404(){
    const { t } = useTranslation("errors")

    return <Layout.Full center>
        <Logo.Small toHome className="absolute left-0 w-10 translate-x-1/2 top-5"/>
        <div className="flex flex-col items-center justify-center px-10 pb-10 overflow-x-hidden lg:flex-row-reverse lg:gap-20">

            <div className="w-2/3 my-10 lg:w-96">
                <img src="/images/404-colored.svg" alt="" className="select-none" />
            </div>
            <div className="max-w-[500px]">
                <Title description={t("404.message")}>{t("404.title")}</Title>
                <Button.Link className="w-full mt-5 lg:w-48 bg-primary" to={routes.home}>{t("404.back-home")}</Button.Link>
            </div>
        </div>
    </Layout.Full>
}