import { useContext } from "react";
import { AuthContext } from "contexts/auth";

// Types
import { Auth } from "contexts/auth";

/**
 * @example const {user, isConnected, loading} = useAuth(); 
 * @description This hook returns the Auth object from the AuthContext
 * @returns {Auth} Auth object
 */
export function useAuth():Auth{
    return useContext(AuthContext);
}

