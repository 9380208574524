
// Components
import { Layout } from 'layouts';
import { LoginForm } from "components/auth/forms/login-form"
import { Logo } from "components/tools/logo"

export function Login(){

    return <Layout.Full center>
        <div className="my-10">
            <Logo.Xl className="w-64 mb-12" />
            <LoginForm className="w-screen px-4 sm:w-[480px]" />
        </div>
    </Layout.Full>
}

