import { Link } from "react-router-dom"


interface ConditionalLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    condition: boolean;
    children: React.ReactNode;
    to: string;
}

export function ConditionalLink({ condition, children, to }: ConditionalLinkProps, props: ConditionalLinkProps) {
    return condition ? <Link  {...props} to={to}>{children}</Link> : <>{children}</>
}