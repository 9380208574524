import { useContext } from "react";
import { AnnotationContextResult, AnnotationContext } from "contexts/annotation";

/**
 * @example const {annotation} = useAnnotation(); 
 * @description This hook returns a annotation from the AnnotationContext
 * @returns {Annotation} annotation {annotation}
 */
export function useAnnotation():AnnotationContextResult{
    return useContext(AnnotationContext);
}

