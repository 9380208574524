import { fetchCall } from 'api'

/*
  Check if user is logged in
*/
export const isLoggedIn =() => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/is-logged-in",
  }
  return fetchCall(options)
};

/*
  Login with email and password
*/
interface LoginParams {
  email: string;
  password: string;
}
export function login({email, password}:LoginParams){
  const options = {
    method: "POST",
    endpoint: "/app/auth/login",
    body: {email, password}
  }
  return fetchCall(options)
}

/*
  Logout
*/
export const logout = () => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/logout",
  }
  return fetchCall(options)
};

/*
  Get user
*/
export function getUser(){
  const options = {
    method: "GET",
    endpoint: "/app/auth/user"
  }
  return fetchCall(options)
}

/*
  Send an email to reset the password
*/
export const sendResetPasswordEmail = ({email}:{email:string}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/send-reset-password-email",
    body: {email}
  }
  return fetchCall(options)
};

/*
Reset the password with a signed token.
*/
export const resetPassword = ({password, token} : {password:string, token:string}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/reset-password",
    body: {password, token}
  }
  return fetchCall(options)
};

/*
Validate the current token
*/
export const validateResetPasswordToken = ({token}: {token:string}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/validate-reset-password-token",
    body: {token}
  }
  return fetchCall(options)
};

/*
Validate the signup token
*/
export const validateSignupToken = ({token}: {token:string}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/validate-signup-token",
    body: {token}
  }
  return fetchCall(options)
};

/*
Validate the signup token
*/
export const signup = ({token, email, firstname, lastname, password}: {token:string, email:string, firstname:string, lastname:string, password:string}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/signup",
    body: {token, email, firstname, lastname, password}
  }
  return fetchCall(options)
};

/*
 Update password
*/
export const changePassword = ({password, currentPassword} : {password:string, currentPassword:string}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/change-password",
    body: {password, currentPassword}
  }
  return fetchCall(options)
}