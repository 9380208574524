
// Utils
import { cn } from "utils/cn";

// Hooks
import { useMediaQuery } from "hooks/useMediaQuery";
import { screenSizes } from "hooks/useMediaQuery";

export function TwoColumnLayout({left, right, className, breakpoint = "xl"}: {left: React.ReactNode, right: React.ReactNode, className?: string, breakpoint?: keyof typeof screenSizes}) {
    const isDesktop = useMediaQuery(breakpoint);
    
    return <div className={cn("grid grid-cols-11 w-full gap-8", !isDesktop && "row-start-2", className)}>
        <div className={cn("flex flex-col gap-5", isDesktop ? "col-span-7 2xl:col-span-8" : "col-span-11")}>
            {left}
        </div>
        <div className={cn("", isDesktop ? "max-w-[400px] col-span-4 2xl:col-span-3": "col-span-11 row-start-1")} >
            {right}
        </div>
    </div>
}