const CHAR_UPPER_A = 0x41
const CHAR_LOWER_A = 0x61
const CHAR_UPPER_Z = 0x5a
const CHAR_LOWER_Z = 0x7a
const CHAR_0 = 0x30
const CHAR_9 = 0x39
const CHAR_MINUS = 0x2d
const CHAR_SPACE = 0x20
const CHAR_UNDERSCORE = 0x5f

function isUpper(c: number) {
  return CHAR_UPPER_A <= c && c <= CHAR_UPPER_Z
}

function isLower(c: number) {
  return CHAR_LOWER_A <= c && c <= CHAR_LOWER_Z
}

function isDigit(c: number) {
  return CHAR_0 <= c && c <= CHAR_9
}

function toUpper(c: number) {
  return c - 0x20
}

function toLower(c: number) {
  return c + 0x20
}

export function camelize(str: string) {
  const firstChar = str.charCodeAt(0)

  if (isDigit(firstChar) || firstChar === CHAR_MINUS || isNaN(firstChar)) {
    return str
  }

  let changed = isUpper(firstChar)
  const transformed = changed ? [toLower(firstChar)] : [firstChar]

  const length = str.length
  for (let i = 1; i < length; i++) {
    let c = str.charCodeAt(i)

    if (c === CHAR_UNDERSCORE || c === CHAR_SPACE || c === CHAR_MINUS) {
      changed = true
      c = str.charCodeAt(++i)

      if (isNaN(c)) {
        return str
      }

      if (isLower(c)) {
        transformed.push(toUpper(c))
      } else {
        transformed.push(c)
      }
    } else {
      transformed.push(c)
    }
  }

  if (!changed) {
    return str
  }

  return String.fromCharCode.apply(undefined, transformed)
}

export function decamelize(str: string, sep?: string) {
  const firstChar = str.charCodeAt(0)

  if (!isLower(firstChar) || isNaN(firstChar)) {
    return str
  }

  let changed = false
  const transformed = [firstChar]

  let separator = CHAR_UNDERSCORE

  if (sep && sep.charCodeAt(0)) {
    separator = sep.charCodeAt(0)
  }

  const length = str.length
  for (let i = 1; i < length; i++) {
    const c = str.charCodeAt(i)

    if (isUpper(c)) {
      changed = true
      transformed.push(separator)
      transformed.push(toLower(c))
    } else {
      transformed.push(c)
    }
  }

  if (!changed) {
    return str
  }

  return String.fromCharCode.apply(undefined, transformed)
}

export function recursiveCamelize(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((v: any) => recursiveCamelize(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      return {
        ...result,
        [camelize(key)]: recursiveCamelize(obj[key]),
      };
    }, {});
  }
  return obj;
}

export function recursiveDecamelize(obj: any, sep = "_"): any {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((v: any) => recursiveDecamelize(v, sep));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      return {
        ...result,
        [decamelize(key, sep)]: recursiveDecamelize(obj[key], sep),
      };
    }, {});
  }
  return obj;
}

export const Casing = {
  camelize,
  decamelize,
  recursiveCamelize,
  recursiveDecamelize
}