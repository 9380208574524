function getMonthDatesPadded(inputDate: Date): Date[] {
    const result: Date[] = [];

    const getLastDateOfMonth = (date: Date): Date => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    const getFirstDateOfMonth = (date: Date): Date => {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    };

    const startDate = getFirstDateOfMonth(inputDate);
    const endDate = getLastDateOfMonth(inputDate);

    // Pad the beginning of the month with dates from the previous month until we get to a Sunday
    let paddingDate = new Date(startDate);
    while (paddingDate.getDay() !== 0) {
        paddingDate = new Date(paddingDate.getFullYear(), paddingDate.getMonth(), paddingDate.getDate() - 1);
        result.unshift(new Date(paddingDate));
    }

    // Add all the days of the current month
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        result.push(new Date(currentDate));
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
    }

    // Pad the end of the month with dates from the next month until we get to a Saturday
    let postPaddingDate = new Date(endDate);
    while (postPaddingDate.getDay() !== 6) {
        postPaddingDate = new Date(postPaddingDate.getFullYear(), postPaddingDate.getMonth(), postPaddingDate.getDate() + 1);
        result.push(new Date(postPaddingDate));
    }

    return result;
}

function isSameMonth(day1:Date, day2:Date){
    return day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
}

function isSameYear(day1: Date, day2: Date): boolean {
    return day1.getFullYear() === day2.getFullYear();
}

function isSameDay(day1: Date, day2: Date): boolean {
    return day1.getDate() === day2.getDate() && 
           day1.getMonth() === day2.getMonth() && 
           day1.getFullYear() === day2.getFullYear();
}

function isInRange(targetDate: Date, startDate: Date, endDate: Date, excludeEquals = false): boolean {
    return excludeEquals ? targetDate > startDate && targetDate < endDate : targetDate >= startDate && targetDate <= endDate;
}

function dayInArray(day: Date, days: Date[]): boolean {
    return days.some(d => isSameDay(d, day));
}

function removeDayFromArray(day: Date, days: Date[]): Date[] {
    return days.filter(d => !isSameDay(d, day));
}

function createDatesRange(newDate: Date, selectedDates: Date[]): Date[] {
    // Clone the original array to prevent mutation
    const updatedDates = [...selectedDates];

    // If there are no dates, simply add the new date
    if (updatedDates.length === 0) {
        updatedDates.push(newDate);
    } 
    // If there's only one date, add the new date in the correct order
    else if (updatedDates.length === 1) {
        if (newDate < updatedDates[0]) {
            updatedDates.unshift(newDate);
        } else {
            updatedDates.push(newDate);
        }
    } 
    // If there are already two dates
    else {
        if (newDate < updatedDates[0]) {
            updatedDates[0] = newDate;  // Replace the first date
        } else if (newDate > updatedDates[1]) {
            updatedDates[1] = newDate;  // Replace the last date
        } else {
            // Determine which date the newDate is closer to, and replace that date
            if (Math.abs(updatedDates[0].getTime() - newDate.getTime()) <
                Math.abs(updatedDates[1].getTime() - newDate.getTime())) {
                updatedDates[0] = newDate;  // Replace the first date
            } else {
                updatedDates[1] = newDate;  // Replace the second date
            }
        }
    }

    return updatedDates;
}

export function toEndOfDate(date: Date): Date{
    const newDate = new Date(date)
    newDate.setHours(23,59,59,999)
    return newDate
}

export function toStartOfDate(date: Date): Date {
    const newDate = new Date(date)
    newDate.setHours(0,0,0,0)
    return newDate
}

export function getDatesDelta(date1:Date, date2:Date):number{
     // Convert both dates to milliseconds
    const date1_ms: number = date1.getTime();
    const date2_ms: number = date2.getTime();

    // Calculate the difference in milliseconds
    const difference_ms: number = Math.abs(date1_ms - date2_ms);
    
    // Convert back to days and return
    return Math.round(difference_ms / (1000 * 60 * 60 * 24));
}

export function getDatesYearDelta(date1: Date, date2: Date): number {
    return Math.abs(date1.getFullYear() - date2.getFullYear());
}
    
export const calendar = {
    getMonthDatesPadded,
    getDatesDelta,
    getDatesYearDelta,
    isSameMonth,
    isSameYear,
    isSameDay,
    isInRange,
    dayInArray,
    removeDayFromArray,
    createDatesRange,
    toEndOfDate,
    toStartOfDate
}