// Utils
import classNames from "classnames"
import { themes } from "components/core/container"

// Hooks
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

interface TimeSelectorProps {
    selected: string
    setSelected: Dispatch<SetStateAction<"day" | "week" | "month" | "year">>
    onChange?: (option: any)=> any
    options: ("day" | "week" | "month" | "year")[]
    theme?: keyof typeof themes
}

export function TimeSelector({selected, setSelected, onChange, options, theme="default"}: TimeSelectorProps){
    const {t} = useTranslation()
    
    return <div className={classNames("flex p-1 rounded-md  w-fit gap-x-2 h-fit", themes[theme])}>
        {
            options.map((option, index)=> {
                return <div key={index} className={classNames("py-1 px-2 text-sm rounded-md hover:cursor-pointer", option === selected && "bg-primary")} onClick={()=> {
                    onChange && onChange(option)
                    setSelected(option)
                }}>
                    {t("time-selector."+option)}
                </div>
            })
        }
    </div>

}