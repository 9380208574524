export const toastDurations = {
    short: 3000,
    medium: 4000,
    long: 6000,
};

export const toastPosition= "bottom-right"

export const toastOptions = {
    duration: toastDurations.medium,
    success: {
      iconTheme: {
        primary: 'rgb(17 154 146)',
        secondary: 'white',
      },
    }
}