import { useContext } from "react";
import { RessourceContextResult, RessourceContext } from "contexts/ressource";

/**
 * @example const {ressource} = useRessource(); 
 * @description This hook returns a ressource from the RessourceContext
 * @returns {Ressource} ressource {ressource}
 */
export function useRessource():RessourceContextResult{
    return useContext(RessourceContext);
}

