// Utils
import classNames from "classnames"
import { convertBytesToMB, formatBytesToKBOrMB } from "utils/sizes"

// Hooks
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"

// Icons
import { BiUpload as UploadIcon } from "react-icons/bi"

interface FileUploaderProps {
    id?: string
    multiple?: boolean
    label?: string
    types?: string[]
    maxSize?: number
    files: File[] | undefined
    setFiles: Dispatch<SetStateAction<File[] | undefined>>
    onDragActive?: () => void
    onDragInactive?: () => void
    onNotAllowed?: () => void
    onAllowed?: () => void
}

/**
 * 
 * @param types list of allowed file types ex: [".png", ".jpg"]
 * @param maxSize max size in MB
 * @returns 
 */
export function FileUploader({id, multiple, label, types, maxSize, files, setFiles}:FileUploaderProps) {
    
    const [dragActive, setDragActive] = useState(false)
    const [notAllowed, setNotAllowed] = useState(false)
    
    return <>
        <BlankUploader multiple={multiple} id={id} label={label} types={types} maxSize={maxSize} setFiles={setFiles} onAllowed={()=>setNotAllowed(false)} onNotAllowed={()=> setNotAllowed(true)} onDragActive={()=>setDragActive(true)} onDragInactive={()=>setDragActive(false)}>
            <DropZone id={id} active={dragActive} error={notAllowed} files={files} setFiles={setFiles} hasFiles={ files && files?.length > 0} types={types} maxSize={maxSize} /> 
        </BlankUploader>
    </>
}

interface BlankUploaderProps {
    id?: string
    multiple?: boolean
    label?: string
    types?: string[]
    maxSize?: number
    setFiles: Dispatch<SetStateAction<File[] | undefined>>
    onDragActive?: () => void
    onDragInactive?: () => void
    onNotAllowed?: () => void
    onAllowed?: () => void,
    children: React.ReactNode
}

function BlankUploader({id, multiple, children, label, types, maxSize, setFiles, onDragActive, onDragInactive, onNotAllowed, onAllowed}:BlankUploaderProps){
    
    const validateTypes = (files:FileList) => {
        if (!types) return true
        for (let i = 0; i < files.length; i++) {
            if (!RegExp(`(${types.join("|")})$`).test(files[i].name)) return false
        }
        onAllowed && onAllowed()
        return true
    }
    
    const validateMaxSize = (files:FileList) => {
        if (!maxSize) return true
        let sumOfSizes = 0
        for (let i = 0; i < files.length; i++) {
            sumOfSizes += convertBytesToMB(files[i].size)
            if (sumOfSizes > maxSize) return false
        }
        return true
    }
    
    const handleDrag = (e:React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") onDragActive && onDragActive()
        else if (e.type === "dragleave") {
            onDragInactive && onDragInactive()
        }
    }
        
    const handleDrop = function(e:React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        onDragInactive && onDragInactive()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const validatedFiles:File[] = []

            for (let i=0; i < e.dataTransfer.files.length; i++) {
                if (validateTypes(e.dataTransfer.files) && validateMaxSize(e.dataTransfer.files)) {
                    validatedFiles.push(e.dataTransfer.files[i])
                }
                else {
                    onNotAllowed && onNotAllowed()
                }
            }
            if (multiple) return setFiles(prev=>prev ? [...prev, ...validatedFiles] : validatedFiles)
            else return setFiles([validatedFiles[0]])
        }
    };
    
    const handleChanges = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const validatedFiles:File[] = []

            for (let i=0; i < e.target.files.length; i++) {
                if (validateTypes(e.target.files) && validateMaxSize(e.target.files)) {
                    validatedFiles.push(e.target.files[i])
                }
                else {
                    onNotAllowed && onNotAllowed()
                }
            }
            if (multiple) return setFiles(prev=>prev ? [...prev, ...validatedFiles] : validatedFiles)
            else return setFiles([validatedFiles[0]])
        }
    }
    
    return <>
        {label && <label className="block mb-2 text-sm font-medium text-gray-300">
            {label}
        </label>}
        <div onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
            <input id={id} type="file" multiple={multiple} className="hidden" accept={types?.join(",")} onChange={handleChanges} />
            {
                children
            }
        </div>
    </>
}

function DropZone({id, active, error, files, setFiles, hasFiles, types, maxSize}:{id?:string, active: boolean, error?: boolean, files:File[]|undefined, setFiles:Dispatch<SetStateAction<File[] | undefined>>, hasFiles?: boolean, types?: string[], maxSize?:number}) {
    const { t } = useTranslation("common")
    return <>
        <div className={classNames(active && "p-4", "transition-all bg-background p-3 rounded")}>
            <div className={classNames((active || hasFiles) && "!border-accent", active ? "p-3" :"p-4", error && !active && "!border-red-500", " border-container-foreground border-dashed h-full rounded-sm border-2 transition-all")}>
                <div className="min-h-[120px] flex flex-col justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <UploadIcon className="text-3xl" />
                        <p className={classNames("text-container-foreground  transition-all text-center")}><label htmlFor={id} className="text-white hover:cursor-pointer">{t("upload-file.choose-file")}</label>{t("upload-file.or-drag")}</p>
                    </div>
                    {
                        !(files) ? <>
                            {types && <p className="mt-1 text-container-foreground">{t("upload-file.allowed-types")} {types.join(", ").toUpperCase()}</p>}
                            {maxSize && <p className="mt-1 text-container-foreground">{t("upload-file.max-size")} {maxSize} MB</p>}
                        </> :
                        <div className="flex flex-col w-full mt-5 space-y-2">
                            {
                                files && files.map((file:File, index) => {
                                    return <div key={"file-"+index}className="flex items-center justify-between w-full hover:bg-cyan-800/20 hover:border-cyan-500 border-b-2 border-transparent px-2 py-1">
                                        <span className="font-medium">{file.name}</span>
                                        <span className="space-x-3">
                                            <span className="text-gray-300">{formatBytesToKBOrMB(file.size)}</span>
                                            <span className="ml-2 text-red-500 hover:cursor-pointer hover:underline" onClick={()=>setFiles((prev)=>prev?.filter((_,i)=>i!==index))}>
                                                {t("remove")}
                                            </span>
                                        </span>
                                    </div>
                                })
                            }

                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}

export const Uploader = {
    Blank : BlankUploader,
    DropZone : FileUploader
}