import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMemo, useCallback, useEffect } from 'react';

interface FilterParams {
  [key: string]: string | number; // Adjust the value type as needed
}

export interface TableFilter {
  id: string; // unique identifier for the table/filter
  filterParams: FilterParams;
}

export const useFiltersFromURL = (tableId: string): TableFilter => {
  const location = useLocation();
  const [searchParams,] = useSearchParams();

  return useMemo(() => {
    const filterParams: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      if (key.startsWith(`${tableId}-`)) {
        const filterKey = key.replace(`${tableId}-`, '');
        filterParams[filterKey] = value as string;
      }
    });

    return {
      id: tableId,
      filterParams,
    };
  }, [location.search]);
};

const useSetFilterToURL = (getSetArray: { key: string, getter: () => any, setter: (value: any) => void, defaultValue?:any }[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const allPossibleFilterKeys = getSetArray.map(({ key }) => key);

  return useCallback((filtersState: TableFilter) => {
    // Start with a clean slate of search params for the current tableId to remove any that are no longer valid
    allPossibleFilterKeys.forEach(key => {
      const fullKey = `${filtersState.id}-${key}`;
      const getSetArrayItem = getSetArray.find(item => item.key === key);
      if (searchParams.has(fullKey) || getSetArrayItem?.getter() === getSetArrayItem?.defaultValue) {
        searchParams.delete(fullKey);
      }
    });

    // Then add or update parameters based on the current filter state
    Object.entries(filtersState.filterParams).forEach(([key, value]) => {
      const fullKey = `${filtersState.id}-${key}`;
      const getSetArrayItem = getSetArray.find(item => item.key === key);
      if (value !== null && value !== undefined && value !== '' && (Array.isArray(value) ? value.length > 0 && value !== getSetArrayItem?.defaultValue : true)) {
        searchParams.set(fullKey, value.toString());
      }
    });

    // Update the URL with the new set of search parameters
    setSearchParams(searchParams, { replace: true });
  }, [searchParams]);
};



export function useSyncFiltersWithUrl(tableId: string, 
  getSetArray: { key: string, getter: () => any, setter: (value: any) => void, defaultValue?:any }[],
  onFilterSet?:() => void
  
  ) {
  const filtersFromURL = useFiltersFromURL(tableId);
  const setFilterToURL = useSetFilterToURL(getSetArray);
  // Initialize/update component state based on URL
  useEffect(() => {
    getSetArray.forEach(({ key, setter, getter }) => {
      const newValue = filtersFromURL.filterParams[key];
      if (newValue !== undefined && newValue !== getter()) {
        setter(newValue);
      }
    });
  }, []);

  // Define the filter state for the URL
  const filtersState: TableFilter = useMemo(() => {
    const state = {
      id: tableId,
      filterParams: getSetArray.reduce<FilterParams>((acc, { key, getter, defaultValue }) => {
        const value = getter();
        if (value !== null && value !== undefined && value !== '' && (Array.isArray(value) ? value.length > 0 : true, value !== defaultValue)) {
          acc[key] = value;
        }
        return acc;
      }, {})
      
    };
    
    return state
    
  }, getSetArray.map(item => item.getter()));

  // Update URL when filter state changes
  useEffect(() => {
    setFilterToURL(filtersState);
    onFilterSet?.();
  }, [filtersState]);
  
  const [searchParams,] = useSearchParams();
  
  const isAllDefaultOrEmpty = useMemo(() => {
    if (searchParams.size === 0) return true;

    const filterKeys = getSetArray.map(f => f.key);
    for (const key of filterKeys) {
      if (searchParams.has(tableId + "-" + key)) {
        const value = searchParams.get(tableId + "-" + key);
        const getSetArrayItem = getSetArray.find(item => item.key === key);
        const isDefaultOrEmpty = value === "" || value === getSetArrayItem?.defaultValue?.toString() || (Array.isArray(value) ? value.length === 0 : false);
        if (!isDefaultOrEmpty) {
          return false; // Found a parameter that is neither default nor empty
        }
      }
    }
    return true; // All parameters are default or empty
  }, [searchParams, getSetArray, tableId]);
  
  const isSynced = useMemo(() => {
    
    if (isAllDefaultOrEmpty) return true;

    return getSetArray.every(({ key, getter, defaultValue }) => {
      const fullKey = `${tableId}-${key}`;
      const valueInURL = searchParams.get(fullKey);
      const currentValue = getter();
      const defaultValueStr = Array.isArray(defaultValue) ? defaultValue.join(',') : defaultValue ? defaultValue.toString() : '';
      const currentValueStr = Array.isArray(currentValue) ? currentValue.join(',') : currentValue?.toString();
      if (!valueInURL) {
        // If the parameter is not in the URL, it's considered synced if it matches the default value
        return currentValue === defaultValue || currentValueStr === defaultValueStr;
      } else {
        // If the parameter is in the URL, it must match the current value of the filter
        return valueInURL === currentValueStr;
      }
    });
  }, [isAllDefaultOrEmpty, searchParams, tableId]);
  
  return {isAllDefaultOrEmpty, isSynced};
}