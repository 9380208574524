export const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days
export const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
export const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

function getCurrentUnixTimestamp(): number {
    return Math.floor(Date.now() / 1000);
}

function getUnixTimestampWithDate(date:Date):number{
    return Math.floor(date.getTime() / 1000);
}

function getUnixTimestampOneWeekAgo(): number {
    return Math.floor((Date.now() - oneWeekInMilliseconds) / 1000);
}

function getUnixTimestampWithDayDelta(dayDelta: number): number {
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    return Math.floor((Date.now() + dayDelta * millisecondsInADay) / 1000);
}

function getDayDeltaBetweenTimestamps(startTimestamp: number, endTimestamp: number): number {
    const SECONDS_IN_A_DAY = 86400;
    const differenceInSeconds = endTimestamp - startTimestamp;
    const differenceInDays = Math.floor(differenceInSeconds / SECONDS_IN_A_DAY);

    return differenceInDays;
}

function startOfDayGMT0(inputDate: Date): Date {
    // Convert input date to its representation in GMT+0
    const gmt0Date = new Date(inputDate.getUTCFullYear(), inputDate.getUTCMonth(), inputDate.getUTCDate());
    // set time zone offset to 0
    gmt0Date.setUTCHours(0, 0, 0, 0);
    // Return the date at the start of the day
    return gmt0Date;
}

function createStartTimestamp(date: Date): number {
    return startOfDayGMT0(date).getTime() / 1000;
}

function endOfDayGMT0(inputDate: Date): Date {
    // Construct a string representation of the end of the day in GMT+0
    const dateString = `${inputDate.getUTCFullYear()}-${String(inputDate.getUTCMonth() + 1).padStart(2, '0')}-${String(inputDate.getUTCDate()).padStart(2, '0')}T23:59:59.999Z`;

    // Parse this string to get a Date object
    return new Date(dateString);
}

function createEndTimestamp(date: Date): number {
    return Math.floor(endOfDayGMT0(date).getTime() / 1000);
}

function getTimezoneOffsetInSeconds(): number {
    const offsetInMinutes = new Date().getTimezoneOffset();
    return offsetInMinutes * 60;
}

export const time = {
    getCurrentUnixTimestamp,
    getUnixTimestampWithDate,
    getUnixTimestampOneWeekAgo,
    getUnixTimestampWithDayDelta,
    getDayDeltaBetweenTimestamps,
    getTimezoneOffsetInSeconds,
    oneMonthInMilliseconds,
    oneWeekInMilliseconds,
    oneDayInMilliseconds,
    createStartTimestamp,
    createEndTimestamp,
    startOfDayGMT0,
    endOfDayGMT0
}