// Components
import { Text } from "components/core/typo"
import { Summary } from "components/ressources/summary"
import { Button } from "components/core/buttons"
import { Link } from "react-router-dom"
import { RoleGuard } from "components/auth/roleguard"

// Icons
import { AiOutlineAppstoreAdd as AddRessourceIcon } from "react-icons/ai"

// Hooks
import { useParams } from "react-router-dom"
import { useActiveOrg } from "hooks/useActiveOrg"
import { useAuth } from "hooks/useAuth"

// Constants
import { to } from "constants/routes"
import { useTranslation } from "react-i18next"

export function RessourcesSummary(){
    const { activeOrg } = useActiveOrg()
    const { org:orgParams } = useParams()
    const { t } = useTranslation("common")

    const orgId = orgParams || activeOrg?.slug

    const { user } = useAuth()

    const org = user.organizations && user.organizations.find((o:any) => o.slug === orgId)

    return <>
        <Text.PageTitle className="mb-10">{org?.name} </Text.PageTitle>
        <div className="flex items-center justify-end pb-2 space-x-3">
            <RoleGuard requiredRole="admin" userRole={org?.access?.role} redirect={false}>
                <Link to={to.addResource(activeOrg?.slug)} className="mb-2">
                    <Button className="flex items-center" theme="subtilLink">
                        <AddRessourceIcon className="mr-1 text-xl shrink-0"/> {t("add-ressource.title")}
                    </Button>
                </Link>
            </RoleGuard>
        </div>
        <Summary/>
    </>
}