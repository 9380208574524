
export const options = {
    overrides: {
        div: {
          props: {
            className: "text-base md:text-lg text-gray-500 relative"
          }
        },
        h1: {
            props: {
                className: 'mt-12 mb-5 text-3xl text-slate-200 font-medium text-left sm:text-4xl text-white',
            },
        },
        h2: {
            props: {
                className: 'pt-4 md:pt-0 mb-0 md:text-left pr-4 md:translate-y-[30px] text-lg  text-cyan-300',
            },
        },
        h3: {
            props: {
                className: 'font-semibold text-white ',
            },
        },
        p: {
            props: {
                className: 'text-gray-300 py-2 lg:leading-7 text-base md:text-lg ',
            },
        },
        a: {
            props: {
                className: 'text-gray-300 text-orange-500 text-base md:text-lg',
            },
        },
        table: {
            props: {
                className: 'text-gray-300 min-w-full divide-y divide-gray-300 mb-4 text-base md:text-lg',
            },
        },
        th: {
            props: {
                className: 'text-gray-300 py-1.5 px-3 text-left text-sm font-semibold',
            },
        },
        ul: {
            props: {
                className: 'md:border-l-2 text-secondary list-decimal list-outside pl-3 ml-3 space-y-1 text-base md:text-lg max-w-3xl md:ml-[8em] md:pl-[4em] ',
            },
        },
        li: {
            props: {
                className: 'text-gray-300 text-base md:text-lg pb-4',
            },
        },
        ol: {
            props: {
                className: 'ml-6 lg:ml-12 py-3 list-disc',
            },
        },
        strong: {
            props: {
                className: 'border-b-2 border-cyan-300 font-medium text-white bg-cyan-800/20',
            },
        },
        code: {
            props: {
                className: 'text-pink-200 bg-pink-800/20 px-1 border-b-2 border-pink-300 text-[90%]',
            },
        },
        img: {
            props: {
                className: 'w-full max-w-2xl shadow-2xl rounded-lg mt-2 mb-6',
            },
        },
    },
}