// Components
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle, 
    DialogClose
} from "components/ui/dialog";
import { Button } from "components/ui/button";

// API
import { deleteModel } from "api/models";

// Constants
import { to } from "constants/routes";

// Hooks
import { useTranslation } from "react-i18next";
import { useAPI } from "hooks/useAPI";
import { useModel } from "hooks/useModel";
import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useModels } from "hooks/useModels";

export function DeleteModalDialog({open, setOpen}: {open: boolean, setOpen: (open: boolean) => void}) {
    const {model, loading} = useModel();
    const {setModels} = useModels();
    const {t} = useTranslation();
    const {org} = useParams<{org:string}>();
    const navigate = useNavigate();
    const params = useMemo(() => ({modelId: model?.id}), [model]);
    const handleSuccess = useCallback(() => {
        setOpen(false);
        setModels?.((prev:Model[])=> prev?.filter((m:Model) => m.id !== model?.id) || []);
        navigate(to.models(org || ""));
    }, [org, model]);
    
    const [,{execute, loading: loadingDelete}] = useAPI(
        deleteModel,
        params, 
        {
            immediate: false, 
            successToastMessage: t("delete-model.success"), 
            errorToastMessage: t("delete-model.error"), 
            onResult: handleSuccess, onCatch: ()=>setOpen(false)
        });
    
    return <>
    <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>{t("delete-model.title")}</DialogTitle>
            </DialogHeader>
            <DialogDescription>
                {t("delete-model.description")}
            </DialogDescription>
            <DialogFooter>
                <DialogClose asChild>
                    <Button className="w-full" variant={"secondary"}>{t("cancel")}</Button>
                </DialogClose>
                <Button disabled={loading || loadingDelete} loading={loadingDelete} onClick={execute} className="w-full" variant={"destructive"}>{t("delete")}</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
    </>
}