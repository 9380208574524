import { fetchCall } from 'api'

/*
  Get user organizations
*/
export function getOrganizations({fields}: {fields?: string[]}){
    const options = {
      method: "GET",
      endpoint: "/app/organizations",
      query: {fields}
    }
    return fetchCall(options)
}

/*
  Invite user to organization
*/
export function inviteUserToOrganization({orgId, email, role}: {orgId: string, email: string, role?: string}){
  const options = {
    method: "POST",
    endpoint: `/app/organization/${orgId}/invite`,
    body: { email, role }
  }
  return fetchCall(options)
}

/*
  Get members of an organization
*/
export function getOrganizationMembers({orgId}: {orgId: string}){
  const options = {
    method: "GET",
    endpoint: `/app/organization/${orgId}/members`
  }
  return fetchCall(options)
}

/*
  Revoke user from an organization
*/
export function revokeUserFromOrganization({orgId, email}: {orgId: string, email: string}){
  const options = {
    method: "POST",
    endpoint: `/app/organization/${orgId}/revoke`,
    body: { email }
  }
  return fetchCall(options)
}

/*
  Update role of a user in an organization
*/
export function updateOrganizationMemberRole({orgId, email, role}: {orgId: string, email: string, role: string}){
  const options = {
    method: "POST",
    endpoint: `/app/organization/${orgId}/update-role`,
    body: { email, role }
  }
  return fetchCall(options)
}

/*List the API keys */
export function getOrganizationApiKeys({orgId, fields, page, limit, orderBy, orderDirection,}: {orgId: string, fields?: string[], page?: number, limit?: number, orderBy?: string, orderDirection?: string}){
  const options = {
    method: "GET",
    endpoint: `/app/organization/${orgId}/api-keys`,
    query: {fields, page, limit, orderBy, orderDirection}
  }
  return fetchCall(options)
}

/* Create an API key */
export function createOrganizationApiKey({orgId, name}: {orgId: string, name: string}){
  const options = {
    method: "POST",
    endpoint: `/app/organization/${orgId}/api-keys`,
    body: { name }
  }
  return fetchCall(options)
}

/* Get Customer Portal URL */
export function getCustomerPortalUrl({orgId}: {orgId: string}){
  const options = {
    method: "GET",
    endpoint: `/app/organization/${orgId}/customer-portal`
  }
  return fetchCall(options)
}

/* Get Checkout Session */
export function getCheckoutSession({orgId}: {orgId: string}){
  const options = {
    method: "GET",
    endpoint: `/app/organization/${orgId}/checkout-session`
  }
  return fetchCall(options)
}