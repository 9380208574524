// Icons
import { IconType } from "react-icons/lib";
import { FaUsersCog as OrganizationIcon } from "react-icons/fa";
import { MdRocketLaunch as RessourceIcon } from "react-icons/md"
import { HiShieldExclamation as IncidentIcon } from "react-icons/hi"
import { HiMiniBeaker as ExperimentIcon } from "react-icons/hi2"
import { FiLayers as ModelIcon } from "react-icons/fi";

// Constants
import { to, routes } from "constants/routes";

// Hooks
import { useRoleGuard } from "components/auth/roleguard";
import { useActiveOrg } from "hooks/useActiveOrg";

type Page = {
    name: string;
    path: string | ((p:any)=>string);
    Icon: IconType;
    subPages?: Page[];
    className?: string;
    to?: any;
}

export function useNavigationPages() {
    const isAdmin = useRoleGuard({requiredRole: "admin"});
    const { activeOrg } = useActiveOrg();

    return [
        {
            name: "ressources",
            path: activeOrg ? to.ressources(activeOrg?.slug) : routes.noOrganizations,
            Icon: RessourceIcon,
        },
        {
            name: "incidents",
            path: activeOrg ? to.incidents(activeOrg?.slug) : routes.noOrganizations,
            Icon: IncidentIcon,
        },
        {
            name: "models",
            path: activeOrg ? to.models(activeOrg?.slug) : routes.noOrganizations,
            Icon: ModelIcon,
        },
        {
            name: "annotations.title",
            path: activeOrg ? to.annotations(activeOrg?.slug) : routes.noOrganizations,
            Icon: ExperimentIcon
        },
        {
            name: "organization",
            path: isAdmin ? to.manageOrgAccess(activeOrg?.slug) : routes.organizations,
            Icon: OrganizationIcon,
        }
    ] as Page[];
}
