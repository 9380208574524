import { routes } from "constants/routes";

// Method to decide if the error requires to refresh the token
function shouldRefreshToken(error:any){
  return error.status === 403;
}

function fetchWithErrorHandling(url: any, options: any){
  return fetch(url, options).then((res)=>{
      if (res.status===200){
        return res.json()
      }
      throw res
  })
}

function refreshToken(){
  const url = new URL(`${process.env.REACT_APP_API_SERVER_URL}/app/auth/update-token`)
  return fetchWithErrorHandling(url, {method: "POST", credentials: "include"})
}

function configureRefreshFetch({ refreshToken, shouldRefreshToken }: any) {

  const allowList = [
    routes.login,
    routes.forgotPassword,
    routes.resetPassword,
    routes.signup
  ]

  let refreshingTokenPromise:any = null
  return (url: any, options: any) => {
    if (refreshingTokenPromise !== null) {
      return (
        refreshingTokenPromise
          .then(() => fetchWithErrorHandling(url, options))
          // Even if the refreshing fails, do the fetch so we reject with
          // error of that request
          .catch(() => fetchWithErrorHandling(url, options))
      )
    }
    return fetchWithErrorHandling(url, options)
        .catch(error => {
          if (shouldRefreshToken(error)) {
            if (refreshingTokenPromise === null) {
              refreshingTokenPromise = new Promise<void>((resolve, reject) => {
                refreshToken()
                  .then(() => {
                    refreshingTokenPromise = null
                    resolve()
                  })
                  .catch((refreshTokenError: any) => {
                    refreshingTokenPromise = null
                    reject(refreshTokenError)
                  })
              })
            }
        return refreshingTokenPromise
          .catch(() => {
            const foundRoute = allowList.find((path) => window.location.pathname.includes(path))

            if (!foundRoute){
              window.localStorage.removeItem("user")
              window.location.href = `${routes.login}?ref=${encodeURIComponent(window.location.pathname+window.location.search)}`;
            }
            throw error
          })
          .then(() => {
            // Refetch.
            return fetchWithErrorHandling(url, options)
          })
      } else {
        throw error
      }
    })
  }
}

const authFetch = configureRefreshFetch({
  shouldRefreshToken,
  refreshToken
})

export { authFetch };