// Components
import { Text } from "components/core/typo"
import { Create } from "components/ressources/create"
import { RoleGuard } from "components/auth/roleguard"
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs"

// Constants
import { to } from "constants/routes"

// Hooks
import { useTranslation } from "react-i18next"
import { useRouteOrg } from "hooks/useRouteOrg"


export function RessourceCreate(){
    const { t } = useTranslation("common")
    const { routeOrg } = useRouteOrg()

    const crumbs:Crumb[] = [
        {
            name: t("ressources"),
            path: to.ressources(routeOrg.slug)
        },
        {
            name: t("add-ressource.title"),
            path: ""
        }
    ]

    return <>
        <RoleGuard requiredRole="admin">
            <Breadcrumbs crumbs={crumbs} />
            <div className="flex flex-wrap justify-between mt-12 mb-10">
                <Text.PageTitle noMargin>{routeOrg.name+" - " + t("add-ressource.title")}</Text.PageTitle>
            </div>
            <Create />
        </RoleGuard>
    </>
}