


// Components
import { Container } from "components/core/container"
import { Spinner } from "components/core/spinner"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "components/ui/dialog";
import { Button } from "components/ui/button";
import { TextInput } from "components/core/inputs";
import { Label } from "components/ui/label";
import { CodeBlock } from "components/core/syntax-highlighter";
import { Text } from "components/core/typo";

// Constants and utils
import { OrgApiKeysContextProvider } from "contexts/api-keys"
import { format as utilsFormat } from "utils/format";

// API
import { createOrganizationApiKey } from "api/organizations";

// Hooks
import { useTranslation } from "react-i18next"
import { useApiKeys } from "hooks/useApiKeys"
import { useAPI } from "hooks/useAPI";
import { useActiveOrg } from "hooks/useActiveOrg";
import { useEffect, useState } from "react";
import { DialogDescription } from "@radix-ui/react-dialog";

    
function KeyListWithContext(){
    return <>
        <OrgApiKeysContextProvider>
            <KeyList/>
        </OrgApiKeysContextProvider>
    </>
}

function ListEmptyRow(){
    const { t } = useTranslation("common")

    return <>
        <div className="flex items-center justify-center w-full h-32 text-gray-400">
            <div className="text-center">
                <div className="text-2xl font-bold">{t("no-api-keys.title")}</div>
                <div className="mt-2">{t("no-api-keys.description")}</div>
            </div>
        </div>
    </>
}

function Row({name, createdAt}: ApiKey){
    const { i18n} = useTranslation("common")
    return <>
    <div className="hover:bg-background/30">
        <div className="flex flex-wrap items-center justify-between p-4">
            <div className="flex flex-col flex-wrap gap-0.5 md:w-1/3 sm:w-1/2">
                <div className="">{name}</div>
            </div>
            <div className="text-sm text-gray-300">{utilsFormat.datetime(new Date(createdAt || ""), i18n.language)}</div>
        </div>
    </div>
</>
}

function KeyList(){
    const { t } = useTranslation("common")
    const { apiKeys, loading } = useApiKeys()

    return <>
        <Container subtitle={t("api-keys")} noPadding>

            { apiKeys && apiKeys.length === 0 && <ListEmptyRow/> }

            { apiKeys && apiKeys.map(({name, createdAt}: ApiKey, index) => {
                return <Row key={name + "-" + index} name={name} createdAt={createdAt} />
            })}

            { loading && !apiKeys && <div className="flex justify-center p-5"><Spinner/></div>}

        </Container>
    </>
}

interface CreateKeyTriggerProps extends CustomTriggerProps {
    name: string
}
export function CreateKeyTrigger({children, name, disabled, onResult, setLoading, className}:CreateKeyTriggerProps){
    const { t } = useTranslation("common")
    const {activeOrg} = useActiveOrg()
    const { reload } = useApiKeys()

    const handleResult = (result:any) => {
        reload?.()
        onResult?.(result)
    }
    const [,{execute, loading}] = useAPI(
        createOrganizationApiKey, 
        {
            orgId: activeOrg?.slug, 
            name
        }, 
        {
            immediate: false,
            errorToastMessage: t("create-api-key.error"),
            successToastMessage: t("create-api-key.success"),
            onResult: handleResult
        }
    )

    const handleTrigger = ()=> {
        if (loading || disabled) return
        execute()
    }

    useEffect(()=>{
        setLoading?.(loading)
    }, [loading])

    return <>
        <div onClick={handleTrigger} className={className}>
            {children}
        </div>
    </>
}

interface CreateKeyDialogProps {
    open: boolean
    setOpen: (open:boolean)=>void
}
export function CreateKeyDialog({open, setOpen}:CreateKeyDialogProps){
    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [key, setKey] = useState<string | undefined>(undefined)
    const disabled = name.trim().length === 0 || key !== undefined

    const handleResult = (result:any) => {
        setKey(result?.value)        
    }

    useEffect(()=>{
        if (key) setKey(undefined)
    }, [open])

    return <>
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>{t("create-api-key.title")}</DialogTitle>
                </DialogHeader>
                {
                    !key && <DialogDescription className="text-container-foreground">
                        { t("create-api-key.description")}
                    </DialogDescription>
                }
                {
                    key ? 
                    <div>
                        <div className="flex items-end justify-between">
                            <Label>{t("your-key")}</Label>
                            <Button variant={"link"} className="p-0 h-fit">
                                <a href="https://hectiq-console.surge.sh/authenticate.html" target="_blank" rel="noreferrer">
                                    {t("view-documentation")}
                                </a>
                            </Button>
                        </div>
                        <CodeBlock copyValue={key} language="json" className="mt-2">
                            {key}
                        </CodeBlock>
                        
                        <Text.Warning className="mt-2">{t("create-api-key.created-description")}</Text.Warning>
                    </div>
                    :
                    <div>
                        <Label>{t("name")}</Label>
                        <TextInput theme="outlined" onChange={setName} />
                    </div>
                }
                <DialogFooter>
                    <Button variant={"secondary"} className="w-full" onClick={()=>setOpen(false)}>{t("close")}</Button>
                    <CreateKeyTrigger className="w-full" name={name} setLoading={setLoading} disabled={disabled} onResult={handleResult}>
                        <Button loading={loading} disabled={disabled} className="w-full">{t("create")}</Button>
                    </CreateKeyTrigger>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    </>
}


export const Keys = {
    ListWithContext: KeyListWithContext,
    List: KeyList,
    CreateTrigger: CreateKeyTrigger,
    CreateKeyDialog
}
