import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames';
import { Fragment } from 'react'

// Icons
import { MdOutlineClose as CloseIcon } from 'react-icons/md'

const sizes = {
    sm: "max-w-sm",
    md: "max-w-md md:max-w-lg",
    lg: "max-w-lg md:max-w-2xl",
    xl: "max-w-xl md:max-w-4xl"
}

interface ModalProps {
    children: React.ReactNode;
    label?: string | React.ReactNode;
    title?: string | React.ReactNode;
    size?: keyof typeof sizes;
    onClose?: any;
    open: boolean;
    setOpen: any;
}

export function Modal({children, title, open, setOpen, onClose, size='md'}: ModalProps) {

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>{onClose && onClose(); setOpen(false)}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
 
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={classNames("w-full transform overflow-hidden rounded-xl bg-container-light p-6 text-left align-middle shadow-xl transition-all", sizes[size])}>
                  <div className='absolute top-4 right-4'><CloseIcon onClick={()=> {onClose && onClose(); setOpen(false)}} className='text-xl text-gray-300 transition-all hover:cursor-pointer hover:scale-110'/></div>
                  <Dialog.Title
                    as="h3"
                    className="pb-3 text-lg font-medium leading-6 text-gray-200"
                  >
                    { typeof title === "string" ? title : title}
                  </Dialog.Title>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

function ModalBody({children, className}: {children: React.ReactNode, className?: string}) {
  return <div className={classNames("pb-3 text-container-foreground", className)}>
    {children}
  </div>
}

const footerTypes= {
  none: "",
  singleButton: "[&>button]:w-full md:[&>button]:w-1/2 md:[&>button]:float-right [&>button]:leading-5",
  doubleButton: "flex justify-between gap-x-4 [&>button]:w-full [&>button]:leading-5"
}

function ModalFooter({children, className, type="none"}: {children: React.ReactNode, className?: string, type?: keyof typeof footerTypes}) {
  return <div className={classNames("pt-3", className, footerTypes[type])}>
    {children}
  </div>
}

Modal.Footer = ModalFooter
Modal.Body = ModalBody