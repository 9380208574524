// Components
import { Text } from "components/core/typo"
import { useTranslation } from "react-i18next"
import { useModels } from "hooks/useModels"
import { Skeleton } from "components/core/skeletons"
import { CreateModelButton } from "./create"
import { Link } from "react-router-dom"
import { to } from "constants/routes"
import { useActiveOrg } from "hooks/useActiveOrg"
import { StatusIndicator } from "components/models/status";

function ModelCard({ model }: { model: Model }) {
    const { orgParams } = useActiveOrg();
    return <Link to={to.model((orgParams || ""), model.id || "")}>
        <div className="bg-container hover:bg-container/70 shadow rounded-lg p-4 flex items-center justify-between">
            <div className="w-full">
                <div className="flex items-center justify-between gap-x-2">
                    <div className="text-lg font-bold">{model.name}</div>
                    <StatusIndicator disabled={!!model?.disabled} />
                </div>
                <div className="flex justify-between w-full">
                    <div className="text-sm text-cyan-300 font-mono">{model.version}</div>
                </div>
            </div>
        </div>
    </Link>
}
export function ModelsList() {
    const { models, loading } = useModels();
    const { t } = useTranslation("common")


    return (<>

        <div className="flex items-center justify-between">
            <Text.PageTitle className="mb-10">{t("models")}</Text.PageTitle>
            <CreateModelButton />
        </div>

        {
            loading ?
                <>
                    <div className="flex flex-col gap-3">
                        <Skeleton className="h-20" />
                        <Skeleton className="h-20" />
                        <Skeleton className="h-20" />
                    </div>
                </> 
            :
                models?.length === 0 ? <div className="block p-3 hover:bg-background/30">
                    <div className="text-center mx-auto max-w-md">
                        <h3 className="text-xl font-medium">{t("no-models.title")}</h3>
                        <p className="text-muted-300 mb-4">{t("no-models.description")}</p>
                        <CreateModelButton />
                    </div>
                </div> 
            :
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {models?.map((model, i) => <ModelCard key={i} model={model} />)}
                </div>
        }
    </>)
}