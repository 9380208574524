import { useContext } from "react";
import { FilterDatesContextResult, FilterDatesContext } from "contexts/filter-dates";

/**
 * @example const {filter, setFilter} = useFilterDates(); 
 * @description This hook returns a filter from the FilterDatesContext
 */
export function useFilterDates():FilterDatesContextResult{
    return useContext(FilterDatesContext);
}

