export function isEmail(email: string) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
}


const MIN_PASSWORD_LENGTH = 8;
/*  Minimum 8 characters in length.
    At least one uppercase letter.
    At least one lowercase letter.
    At least one digit.
    Can contain any non-whitespace character. */
const PASSWORD_REGEX = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\S]{${MIN_PASSWORD_LENGTH},}$`);
export function isPassword(password:string){
    return PASSWORD_REGEX.test(password);
}

function hasUppercase(password:string){
    return /[A-Z]/.test(password);
}

function hasLowercase(password:string){
    return /[a-z]/.test(password);
}

function hasDigit(password:string){
    return /[0-9]/.test(password);
}

export function passwordRequirements(password:string){
    if (!password || password === "") return {
        "minLength": MIN_PASSWORD_LENGTH,
        "hasUppercase": "",
        "hasLowercase": "",
        "hasDigit": "",
        "isLongEnough": ""
    }
    return {
        "minLength": MIN_PASSWORD_LENGTH,
        "hasUppercase": hasUppercase(password).toString(),
        "hasLowercase": hasLowercase(password).toString(),
        "hasDigit": hasDigit(password).toString(),
        "isLongEnough": (password.length >= MIN_PASSWORD_LENGTH).toString()
    }
}

function cleanUrl(url:string){
    // If url starts with www, add https://
    if (url.startsWith("www.")){
        return "https://" + url;
    }
    return url;
}

function isUrl(url:string){
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\\/\w .-]*)*\/?$/;
    return urlRegex.test(url);
}

export const validate = {
    isEmail,
    isPassword,
    hasUppercase,
    hasLowercase,
    hasDigit,
    passwordRequirements,
    isUrl,
    cleanUrl,
    MIN_PASSWORD_LENGTH: MIN_PASSWORD_LENGTH
}
