
// Utils
import { DateTime } from "luxon";
import { downloadFilesAsZip, sanitizeFileName } from "utils/files";

// API
import { onDownloadModel } from "api/models";

// Hooks
import { useModel } from "hooks/useModel";
import { useAPI } from "hooks/useAPI";


export function DownloadModelTrigger({loading, setLoading, children, ...props}: {children: React.ReactNode, loading?: boolean, setLoading?: (loading: boolean) => void} & React.HTMLProps<HTMLDivElement>) {
    const {model} = useModel();
    const [,{execute:onDownload}] = useAPI(onDownloadModel, {modelId: model?.id}, {immediate: false});

    const filesUrlsAndNames = model?.files?.map((file) => {
        return {
            url: file.downloadUrl,
            name: file.name
        }
    });
    const fileName = sanitizeFileName(`${model?.name}-${model?.version}-${DateTime.now().toISODate()}`);
    
    const handleDownload = () => {
        if (loading) return
        setLoading?.(true);
        onDownload();
        return filesUrlsAndNames && downloadFilesAsZip(filesUrlsAndNames, fileName).then(()=>setLoading?.(false))
    }

    if (!filesUrlsAndNames) return null;
    return <>
        <div {...props} onClick={handleDownload}>
            {children}
        </div>
    </>
}