import { Text } from "components/core/typo";
import { Skeleton } from "components/core/skeletons";
import { cn } from "utils/cn";

export function PropertyContainer({children}: {children: React.ReactNode}) {
    
    return <>
        <div className="flex flex-col gap-4">
            {children}
        </div>
    </>
}

export function PropertyList({children, titleChildren, title, loading}: {children: React.ReactNode, titleChildren?: React.ReactNode, title?: string, loading?: boolean}) {
  return <>
    <div>
        {
            title && <>
                <div className="flex justify-between space-x-2 text-center sm:text-left">
                    <Text.TitleSmall className="block ">{title}</Text.TitleSmall>
                    {titleChildren}
                </div>
                
            </>
        }
        {
            loading ? <Skeleton className="h-6" /> : <>
                <div className="flex flex-col gap-2">
                    {children}
                </div>
            </>
        }
    </div>
  </>
    
}

interface PropertyItemProps extends React.HTMLAttributes<HTMLDivElement>{
    title?: string;
    value?: string;
    children?: React.ReactNode;
    loading?: boolean;
}
export function PropertyItem({title, value, loading, children, className, ...props}: PropertyItemProps) {
    
    if (loading) return <><Skeleton className="h-6" /></>
    return <>
        <div {...props} className={cn("flex items-center gap-2 justify-between hover:bg-slate-800 hover:border-b hover:border-b-cyan-200 hover:bg-cyan-700/30 border-b border-b-background", className)}>
            <span className="text-muted-foreground">{title}</span>
            <span className="text-right">{value || children}</span>
        </div>
    </>
}

export function PropertyVerticalItem({title, value, loading, children}: PropertyItemProps) {
    
    if (loading) return <><Skeleton className="h-6" /></>
    return <>
        <div className="gap-2">
            <div className="text-muted-foreground">{title}</div>
            <div>{value || children}</div>
        </div>
    </>
}

export function PropertyEmptyState({children, loading}: {children: React.ReactNode, loading?: boolean}) {
    if (loading) return <><Skeleton className="h-6" /></>
    return <div className="text-muted-foreground">{children}</div>
}

PropertyList.Item = PropertyItem;
PropertyList.VerticalItem = PropertyVerticalItem;
PropertyList.EmptyState = PropertyEmptyState;