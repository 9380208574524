// Components
import { Link, Navigate } from "react-router-dom"
import { Spinner } from "components/core/spinner"
import { RessourcesEmptyOrganizations } from "pages/ressources/empty-organizations"
import { Text } from "components/core/typo"
import { Container } from "components/core/container"

// Constants
import { to } from "constants/routes"

// API
import { getOrgAnnotationWorkspaces } from "api/annotations"

// Icons
import { BsChevronRight as ChevronRightIcon } from "react-icons/bs"

// Hooks
import { useAuth } from "hooks/useAuth"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useSearch } from "hooks/useSearch"
import { useMemo } from "react"

function ListWorkspaces() {
    const { t } = useTranslation("common")
    const { org: orgParams } = useParams()
    const params = useMemo(() => ({orgId: orgParams}), [])
    const [workspaces, { loading }] = useSearch(getOrgAnnotationWorkspaces, params, {fields: ["description","ressource.slug", "ressource.name"],errorToastMessage: t("annotations.workspaces.error-fetching")})
    
    if (loading) return <><div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div></>
    if (workspaces?.length === 0) return <NoWorkspaces/>
    if (workspaces?.length === 1) return <Navigate to={to.annotationWorkspace(orgParams || "", workspaces[0]?.ressource?.slug || "")} replace />
    return <>
        <Text.PageTitle className="mb-10">{t("annotations.workspaces.title")}</Text.PageTitle>
        <WorkspaceGrid>
            {
                workspaces?.map(({description, ressource}:{description:string, ressource:Ressource}) => {
                    return <WorkspaceContainer key={ressource.slug}
                        description={description}
                        ressource={ressource}
                    />
                })
            }
        </WorkspaceGrid>
    </>
}

interface RessourceContainerProps {
    ressource: Ressource,
    description: string
}

function WorkspaceContainer({description, ressource}:RessourceContainerProps){
    const { org: orgParams } = useParams()
    return <>
        <Link to={to.annotationWorkspace(orgParams|| "", ressource.slug || "")} className="block w-full h-full hover:cursor-pointer">
            <Container className="hover:bg-container/70 group">
                <div className="flex items-center justify-between px-2">
                    <div className="flex flex-col">
                        <p>{ressource.name}</p>
                        <p className="text-sm text-container-foreground">
                            {description}
                        </p>
                    </div>
                    
                    <div className="transition-all text-container-foreground group-hover:text-gray-300">
                        <ChevronRightIcon/>
                    </div>
                </div>
            </Container>
        </Link>
    </>
}

function WorkspaceGrid({children}:{children:React.ReactNode}){

    return <>
        <div className="grid gap-4">
            {children}
        </div>
    </>
}

export function ListWorkspacesWithGuard(){
    const { user} = useAuth()
 
    if (user && !user.organizations) return <RessourcesEmptyOrganizations/>

    return <>
        <ListWorkspaces/>
    </>

}

function NoWorkspaces(){
    const { t } = useTranslation("common")
    return <>
        <div className="flex flex-col items-center justify-center mb-5">
            <h2 className="text-2xl font-bold">{t("annotations.workspaces.no-workspaces")}</h2>
            <p className="text-container-foreground">{t("annotations.workspaces.no-workspaces-message")}</p>
        </div>
    </>
}