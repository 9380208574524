// Components
import { Spinner } from "components/core/spinner";
import { Disclosure } from "@headlessui/react";
import { LogContent } from "components/jobs/logs";

// Icons
import { 
    GoCheckCircleFill as CheckIcon, 
    GoCircle as CircleIcon,
    GoXCircleFill as XIcon
} from "react-icons/go";
import { BiSolidChevronDown as ChevronDownIcon } from "react-icons/bi"
import { BiSolidChevronUp as ChevronUpIcon } from "react-icons/bi"

// Utils
import classNames from "classnames";
import { format } from "utils/format"

// Hooks
import { useTranslation } from "react-i18next";
import { useCallback,  useState } from "react";


function StepStatus(step: JobStep) {
    return <div className="flex items-center justify-between text-container-foreground group relative">

                {step.status==="running"?<Spinner className="w-5 h-5 mr-2 text-yellow-500" />:
                step.status==="success"?<CheckIcon className="w-5 h-5 mr-2 text-emerald-500 " />:
                step.status==="failed"?<XIcon className="w-5 h-5 mr-2 text-red-500 " />:
                <CircleIcon className="w-5 h-5 mr-2 text-cyan-300 " />
                }
                <span className='hidden font-mono text-sm group-hover:block absolute top-1 -translate-y-1/3 left-full bg-background rounded-md text-cyan-500 px-2 py-1'>{step.status|| '-'}</span>
                
            </div>
}


export function StepRow(step: JobStep) {
    const {t, i18n} = useTranslation();
    const timeLapsed = step.startedAt && step.endedAt && (new Date(step.endedAt).getTime() - new Date(step.startedAt).getTime());
    const disclosureDisabled = !step.logDownloadUrl;

    const [log, setLog] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const download = useCallback(()=>{
        if (!step.logDownloadUrl) return;
        setLoading(true);
        const xhr = new XMLHttpRequest();
        xhr.open("GET", step.logDownloadUrl, true);
        xhr.onreadystatechange = ()=>{
            setLoading(false);
            if (xhr.status==200 && xhr.readyState === 4 ) {
                setLog(xhr.responseText);
            }
        };
        xhr.send()
    }, [step.logDownloadUrl]);


    return  <Disclosure>
                <Disclosure.Button className="w-full block p-1 hover:bg-background/30" disabled={disclosureDisabled}>
                    {({open}) =>(<div className={classNames("md:flex items-center justify-between p-2 rounded-md text-container-foreground", open && "bg-container-light")}>
                                 <div>
                                    {!step.logDownloadUrl? <div className="w-5 h-5 mr-2"/>:
                                    loading? <Spinner className="w-5 h-5 mr-2" />:
                                     open? <ChevronUpIcon className="w-5 h-5 mr-2" />:
                                        <ChevronDownIcon className="w-5 h-5 mr-2" />
                                    }
                                    </div>
                                <div className="flex items-center space-x-2 w-full md:w-1/2 md:truncate md:px-3 ">
                                    <StepStatus {...step} />
                                    <p className="text-white font-medium">{step.name}</p>
                                </div>
                                <div className="flex items-center justify-between md:w-1/2">
                                    <div className="flex-1">
                                        <p className="text-container-foreground">{step.startedAt && format.datetime(new Date(step.startedAt), i18n.language)}</p>
                                    </div>
                                    <div className="text-right md:text-right">
                                        <p className={classNames("text-sm font-mono", timeLapsed? "text-white": "text-container-foreground")}> { timeLapsed?
                                        format.timeLapsed(timeLapsed/1000): 
                                        t("job-not-finished")}</p>
                                    </div>
                                </div>
                                </div>)
                    }
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                    {({open}) => {
                        // if (!open) return null;
                        if (open && !log && step.logDownloadUrl && !loading ) download();
                        return <LogContent value={log}/>}
                    }
                </Disclosure.Panel>
            </Disclosure>
  
  
 
}