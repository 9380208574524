// Components
import { Integration } from "components/ressources/integration"

export function RessourceDocumentation() {

    return <>
        <div className="mt-10">
            <Integration/>
        </div>
    </>
}