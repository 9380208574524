import { useMemo, useEffect, createContext, useCallback } from "react";

// Hooks
import { useAPI } from 'hooks/useAPI';

// API
import { logout, getUser } from 'api/auth';
import { useLocation, useNavigate } from "react-router-dom";

// Constants
import { routes } from "constants/routes";

export type Auth = {
    isConnected?: boolean;
    user?: any;
    error?: any;
    loading?: boolean;
    loadingLogout?: boolean;
    setUser?: (user: any) => void;
    logout?: () => void;
    loginWithRedirect?: () => void;
}

const AuthContext = createContext<Auth>({});

interface AuthContextProviderProps {
    children: React.ReactNode;
}

const AuthContextProvider = ({ children }:AuthContextProviderProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useMemo(()=>({}), []);
    const validateRouteIsNotLogout = useMemo(()=>{
        return location.pathname !== routes.logout;
    }, [location.pathname]);
    // Request the user with cache of 30 seconds
    const [user, {loading, setResult, execute, error}] = useAPI(getUser, params, {immediate: false,defaultLoading: validateRouteIsNotLogout}); 
    const [, logoutProps] = useAPI(logout, params, {immediate: false, onResult: ()=>setResult()});

    const loginWithRedirect = useCallback(()=>{
        if (location.pathname === '/logout') return;
        return navigate(`/login?msg=login_redirect&ref=${encodeURIComponent(location.pathname+location.search)}`);
    }, [location, navigate]);

    useEffect(()=>{
        if (location.pathname === '/logout') return;
        execute();
    }, [location.pathname, execute])

    useEffect(()=>{
        if (error){
            if ((error.status === 403 ) || (error.action === "logout")){
                return navigate(`/login?error=idle_timeout&ref=${encodeURIComponent(location.pathname+location.search)}`);
            }
        }
    }, [error, location.pathname, navigate, location.search]);

    return (
        <AuthContext.Provider value={{ isConnected: !!user, user, error, loading: loading, loadingLogout: logoutProps?.loading , setUser: setResult, logout: logoutProps?.execute, loginWithRedirect }}>
          {children}
        </AuthContext.Provider>
    );

};

export { AuthContext, AuthContextProvider };
