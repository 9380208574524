// Components
import { Button } from 'components/core/buttons';

// Icons
import { MdNavigateBefore as PreviousIcon, MdNavigateNext as NextIcon } from 'react-icons/md';

// Hooks
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface IPaginateProps {
    page: number;
    setPage: (page: any) => void;
    numPageResults: number;
    numResults: number;
    totalPages: number;
}

export function Paginate({page, setPage, numPageResults, numResults, totalPages}: IPaginateProps){
    const {t} = useTranslation("common");
    page = page ? page > totalPages ? totalPages : page : 1;
    return <div className="flex items-center justify-between mt-3">
            <div className="hidden sm:block text-container-foreground">
                <p className="text-sm ">
                    <span>{t("page")} {page|| 1} {t("of")} {totalPages || 1} </span>
                    <span>({numPageResults || 0} {t("of")} {numResults || 0} {numResults>1? t("results").toLowerCase(): t("result").toLowerCase()})</span>
                </p>
            </div>
            <div className="flex items-center justify-end space-x-3">
                <Button 
                    theme='blank'
                    className={classNames('flex items-center disabled:text-gray-500 text-primary-light hover:text-primary transition-colors')}
                    onClick={()=>setPage((prev:number)=>Math.max(1, prev-1))}
                    disabled={page<=1 || !totalPages}
                >
                    <PreviousIcon className='mr-1 text-xl'/>{t("paginate.previous")}
                </Button>
                <Button 
                    theme='blank'
                    className={classNames('flex items-center disabled:text-gray-500 text-primary-light hover:text-primary transition-colors')}
                    onClick={()=>setPage((prev:number)=>{
                        return Math.min(prev+1, totalPages)
                    })}
                    disabled={page >= totalPages || !totalPages }
                >
                    {t("paginate.next")}<NextIcon className='ml-0.5 text-xl'/>
                </Button>
            </div>
        </div>
}
