// Pages
import { RessourceDetailsWithGuard } from "pages/ressources/details"
import { RessourcesSummary } from "pages/ressources/summary"
import { RessourcesEmpty } from "pages/ressources/empty"
import { RessourcesEmptyOrganizations } from "pages/ressources/empty-organizations"
import { RessourceCreate } from "pages/ressources/create"
import { RessourceEdit } from "pages/ressources/edit"
import { RessourceDocumentation } from "pages/ressources/documentation"

// Hooks
import { useActiveOrg } from "hooks/useActiveOrg"
import { useRessources } from "hooks/useRessources"
import { useAuth } from "hooks/useAuth"

// Components
import { Navigate, Outlet } from "react-router-dom"
import { Spinner } from "components/core/spinner"

// Constants
import { to } from "constants/routes"
import { DetailsHeader } from "components/ressources/details"
import { useRessource } from "hooks/useRessource"

function OrgRedirect(){
    const { activeOrg } = useActiveOrg()
    if (!activeOrg) return <></>
    return <Navigate to={to.ressources(activeOrg.slug)} replace={true} />
}

function AllRessources() {
    
    const { ressources, loading } = useRessources()
    const { activeOrg } = useActiveOrg()
    
    const isActiveOrgSameAsRessourceOrg = ressources && ressources.length > 0 && ressources[0].organization.slug === activeOrg.slug

    if (loading || !ressources || !isActiveOrgSameAsRessourceOrg) return <><div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div></>
    return <>
        <RedirectHandler ressources={ressources}/>
    </>
}

function RedirectHandler({ressources}:{ressources:any[]}){
    const { activeOrg } = useActiveOrg()
    
    return <>
        {ressources?.length === 1 ? <Navigate to={to.ressource(activeOrg.slug,ressources[0]?.slug)} replace />: <RessourcesSummary />}
        {ressources?.length === 0 && <RessourcesEmpty/>}
    </>
}

function AllRessourcesWithGuard(){
    const { user } = useAuth()

    if (user && !user.organizations) return <RessourcesEmptyOrganizations/>

    return <>
        <AllRessources/>
    </>

}

function RessourceHeader(){
    const { ressource } = useRessource()

    return <>
            <DetailsHeader ressource={ressource} />
            <Outlet/>
    </>
}
export const Ressources = {
    All: AllRessourcesWithGuard,
    Header: RessourceHeader,
    Details: RessourceDetailsWithGuard,
    Redirect: OrgRedirect,
    Empty: RessourcesEmpty,
    Create: RessourceCreate,
    Edit: RessourceEdit,
    Documentation: RessourceDocumentation
}