import { useContext } from "react";
import { Jobs, SimilarJobsContext } from "contexts/similar-job"

/**
 * @example const {jobs, loading, error} = useRessources(); 
 * @description This hook returns the list of ressources from the JobsContext
 * @returns {Jobs} jobs list, loading boolean, error {ressources, loading, error}
 */
export function useSimilarJobs():Jobs{
    return useContext(SimilarJobsContext);
}

