// Components
import { Container } from "components/core/container";
import { Pulse } from "components/tools/pulse";
import { Link } from "react-router-dom";

// Icons
import { BsChevronRight as ChevronRightIcon } from "react-icons/bs"

// Hooks
import { useRessources } from "hooks/useRessources";

export function Summary(){
    const { ressources } = useRessources()
    
    if (!ressources) return <></>

    return <>
        <div className="flex flex-wrap [&>a]:flex-1 [&>a]:min-w-full gap-4">
            {
                ressources?.map((ressource:any) => {
                    return (
                        <SummaryCard key={ressource.slug} slug={ressource.slug} title={ressource.name} description={ressource.description} status={ressource.status}>
                        </SummaryCard>
                    )
                })
            }
        </div>
    </>
}

interface SummaryCardProps {
    title: string
    slug: string
    description?: string
    status: 'active' | 'init' | string
    children?: React.ReactNode
}
export function SummaryCard({title, description, status, children, slug}: SummaryCardProps){
    return <>
            <Link to={slug} className="block w-full h-full hover:cursor-pointer">
                <Container className="hover:bg-container/70 group">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col  max-w-[90%]">
                                <div className="flex items-center w-full ml-2">
                                    <div className="mr-2">
                                        {status === "active" ? <Pulse.Success size={1} /> : <Pulse.Error size={1} />}
                                    </div>
                                    {title}
                                </div>
                                <p className="text-container-foreground">{description}</p>
                                {children}
                            </div>
                            <div className="transition-all text-container-foreground group-hover:text-gray-300">
                                <ChevronRightIcon/>
                            </div>
                        </div>
                </Container>
            </Link>
            
        
    </>
}