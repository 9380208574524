// Components
import { Paginate } from "components/core/paginate"
import { Select } from "components/core/select"
import { Skeleton } from "components/core/skeletons"

// API
import { getNotificationsSettings, updateNotificationsSettings } from "api/notifications"

// Hooks
import { useSearch } from "hooks/useSearch"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAPI } from "hooks/useAPI"

export function EditNotification(){
    const {t} = useTranslation();
    const params = useMemo(() => ({}), [])
    const [settings, {loading, setResult, setPage, paging}] = useSearch<NotificationSettings>(getNotificationsSettings, params);
    const [, {execute: updateSettings}] = useAPI(updateNotificationsSettings, {}, {immediate: false, successToastMessage: t("update-user-settings.success")})
    const options = [
        {
            name: t("notifications-settings.inactive.label"),
            description: t("notifications-settings.inactive.description"),
            value: "off"
        },
        {
            name: t("notifications-settings.pdf-email.label"),
            description: t("notifications-settings.pdf-email.description"),
            value: "active",
            activeStyle: true
        }
    ]

    const optionsJobs = [
        {
            name: t("notifications-settings.inactive.label"),
            description: t("notifications-settings.inactive.description"),
            value: "off"
        },
        {
            name: t("notifications-settings.on-job-fail.label"),
            description: t("notifications-settings.on-job-fail.description"),
            value: "on-fail",
            activeStyle: true
        }
    ]

    const optionsIncidents = [
        {
            name: t("notifications-settings.inactive.label"),
            description: t("notifications-settings.inactive.description"),
            value: "off"
        },
        {
            name: t("notifications-settings.on-incident-creation.label"),
            description: t("notifications-settings.on-incident-creation.description"),
            value: "on-creation",
            activeStyle: true
        }
    ]

    const customOptions = {
        "fruit-dor": [
            {
                name: t("notifications-settings.e22033c358b0.report.label"),
                options: [
                    {
                        name: t("notifications-settings.e22033c358b0.report.inactive.label"),
                        description: t("notifications-settings.e22033c358b0.report.inactive.description"),
                        value: "<no-report/>",
                        activeStyle: false
                    },
                    {
                        name: t("notifications-settings.e22033c358b0.report.active.label"),
                        description: t("notifications-settings.e22033c358b0.report.active.description"),
                        value: "<report/>",
                        activeStyle: true
                    }
                ]
            }
        ]
    }


    return <>
        {settings? settings.map((setting) => {
            const customOrgOptions = setting.organization.slug==="fruit-dor"? customOptions["fruit-dor"] : []
            return (
            <div  key={setting.organization.slug} className="py-5" >
                <p className="font-medium ">{setting.organization.name}</p>

                {customOrgOptions.map(customOption => 
                        <div key={customOption.name} 
                            className="flex flex-col items-center justify-between px-3 py-1 pt-3 mt-2 space-x-2 sm:pt-1 sm:flex-row bg-container-light rounded-b-md">
                        <p className="text-center sm:text-left text-container-foreground">{t(customOption.name)}</p>
                        <div >
                            <Select options={customOption.options}
                            key={customOption.name}
                            position="topRight"
                            buttonPadding={true}
                            indexingKey="value"
                            toDescription={(d:any)=>d.description}
                            toString={(d:any)=>d.name}
                            optionsClassNames="sm:min-w-[250px] min-w-[200px]"
                            selected={setting.customNotification? (customOption.options.filter((o) => o.value === setting.customNotification)|| customOption.options)[0] : customOption.options[0]}
                                setSelected={(({value}: {value: string}) => {
                                    updateSettings({organization: setting.organization.slug, customNotification: value});
                                    setResult((d: NotificationSettings[]) => d.map((s: NotificationSettings) => {
                                        if(s.organization.slug === setting.organization.slug){
                                            return {...s, customNotification: value}
                                        }
                                        return s;
                                    }
                                    ))
                                })}
                            />
                        </div>
                    </div>
                        )}


                <div className="flex flex-col items-center justify-between px-3 py-1 pt-3 mt-2 space-x-2 sm:pt-1 sm:flex-row bg-container-light rounded-b-md">
                    <p className="text-center sm:text-left text-container-foreground">{t("notifications-settings.ressource-files-notification")}</p>
                    <div >
                        <Select options={options} 
                            position="topRight"
                            buttonPadding={true}
                            indexingKey="value"
                            selected={setting.filesNotification? (options.filter((o) => o.value === setting.filesNotification)|| options)[0] : options[0]}
                            setSelected={(({value}: {value: string}) => {
                                updateSettings({organization: setting.organization.slug, filesNotification: value});
                                setResult((d: NotificationSettings[]) => d.map((s: NotificationSettings) => {
                                    if(s.organization.slug === setting.organization.slug){
                                        return {...s, filesNotification: value}
                                    }
                                    return s;
                                }
                                ))
                            })}
                            toDescription={(d:any)=>d.description}
                            toString={(d:any)=>d.name}
                            optionsClassNames="sm:min-w-[250px] min-w-[200px]"
                            />
                    </div>
                </div>

                <div className="flex flex-col items-center justify-between px-3 py-1 pt-3 mt-2 space-x-2 sm:pt-1 sm:flex-row bg-container-light rounded-b-md">
                    <p className="text-center sm:text-left text-container-foreground">{t("notifications-settings.jobs-notification")}</p>
                    <div >
                        <Select options={optionsJobs} 
                            position="topRight"
                            buttonPadding={true}
                            indexingKey="value"
                            selected={setting.jobsNotification? (optionsJobs.filter((o) => o.value === setting.jobsNotification)|| optionsJobs)[0] : optionsJobs[0]}
                            setSelected={(({value}: {value: string}) => {
                                updateSettings({organization: setting.organization.slug, jobsNotification: value});
                                setResult((d: NotificationSettings[]) => d.map((s: NotificationSettings) => {
                                    if(s.organization.slug === setting.organization.slug){
                                        return {...s, jobsNotification: value}
                                    }
                                    return s;
                                }
                                ))
                            })}
                            toDescription={(d:any)=>d.description}
                            toString={(d:any)=>d.name}
                            optionsClassNames="sm:min-w-[250px] min-w-[200px]"
                            />
                    </div>
                </div>
                    
                <div className="flex flex-col items-center justify-between px-3 py-1 pt-3 mt-2 space-x-2 sm:pt-1 sm:flex-row bg-container-light rounded-b-md">
                    <p className="text-center sm:text-left text-container-foreground">{t("notifications-settings.incidents-notification")}</p>
                    <div >
                        <Select options={optionsIncidents} 
                            position="topRight"
                            buttonPadding={true}
                            indexingKey="value"
                            selected={setting.incidentsNotification? (optionsIncidents.filter((o) => o.value === setting.incidentsNotification)|| optionsIncidents)[0] : optionsIncidents[0]}
                            setSelected={(({value}: {value: string}) => {
                                updateSettings({organization: setting.organization.slug, incidentsNotification: value});
                                setResult((d: NotificationSettings[]) => d.map((s: NotificationSettings) => {
                                    if(s.organization.slug === setting.organization.slug){
                                        return {...s, incidentsNotification: value}
                                    }
                                    return s;
                                }
                                ))
                            })}
                            toDescription={(d:any)=>d.description}
                            toString={(d:any)=>d.name}
                            optionsClassNames="sm:min-w-[250px] min-w-[200px]"
                            />
                    </div>
                </div>
               
                        

            </div>)
            }):
            <Skeleton className="w-full h-12" />
            }
        {paging && paging.totalPages>1 && <Paginate {...paging} setPage={setPage} loading={loading}/>}
    </> 
    
}
