
// Icons
import { GoIssueOpened as OpenIcon, GoIssueClosed as ClosedIcon } from "react-icons/go"
import { RiDeleteBin5Fill as DeleteIcon } from "react-icons/ri"
import { MdRadioButtonChecked as RadioOnIcon, MdRadioButtonUnchecked as RadioOffIcon } from "react-icons/md"
import { MdOutlineModeEditOutline as EditIcon, MdOutlinePercent as PercentIcon } from "react-icons/md"
import { TbProgressCheck as ProgressIcon } from "react-icons/tb"
import { AiFillAlert as AlertOn, AiOutlineAlert as AlertOff } from "react-icons/ai";
import { FaRegHandRock as ClosedHand, FaHandPaper as OpenedHand } from "react-icons/fa";
import { MdOutlineStarBorder as PriorityOff, MdOutlineStar as PriorityOn  } from "react-icons/md";
import { MdWorkOutline as WorkOff, MdOutlineWork as WorkOn } from "react-icons/md";

// Types
import { IconType } from "react-icons"

// Utils
import classNames from "classnames"
import { format } from "utils/format"

// Constants
import { to } from "constants/routes"

// Components
import { Text } from "components/core/typo"
import { Spinner } from "components/core/spinner"
import { Timeline } from "components/incidents/timeline/timeline"
import { AddMessageToTimeline } from "components/incidents/timeline/messages"
import { ChildrenMenu, ChildrenStyles } from "components/core/moreMenu/children-Menu"
import { Modal } from "components/core/modal"
import { Button } from "components/core/buttons"
import { TextInput } from "components/core/inputs"
import { Pill } from "components/core/pill"

// API
import { updateIncident, deleteIncident } from "api/incidents"

// Hooks
import { useTranslation } from "react-i18next"
import { useIncident } from "hooks/useIncident"
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs"
import { useActiveOrg } from "hooks/useActiveOrg"
import { useParams } from "react-router-dom"
import { useAPI } from "hooks/useAPI"
import { Dispatch, SetStateAction, useCallback, useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { usePostTimelineEvent } from "components/incidents/timeline/events"
import { useIncidents } from "hooks/useIncidents"

export function IncidentDetails(){
    const { t, i18n } = useTranslation("common")
    const { incident, loading } = useIncident()
    const { activeOrg } = useActiveOrg()
  
    if (loading) return <div className="flex items-center justify-center w-full h-96"><Spinner size="md"/></div>
    if (!incident) return null

    const crumbs:Crumb[] = [
        {
            name: t("incidents"),
            path: to.incidents(activeOrg?.slug || "")
        },
        {
            name: incident.name || "",
            path: ""
        }
    ]
    
    const author = incident.author.firstname && incident.author.lastname? `${incident.author.firstname} ${incident.author.lastname}` : t("anonymous")
    return <>
        <Breadcrumbs crumbs={crumbs} />
        {
            incident.progress ? incident.progress > 0 &&<div className="mb-1">
            <IncidentProgress/>  
            </div>: null
        }
        <div className="flex items-center justify-between">
            <div className="w-full">
                <IncidentTitle/>
            </div>
        </div>
        <div className="relative pb-5 text-sm border-b-2 border-b-container-light">
            
            <div className="flex flex-wrap items-center flex-1 gap-x-4 gap-y-2">
                <StatusBadge status={incident.status}/>
                <p className="flex-1 text-gray-300"><span className="font-semibold">{author}</span>{" " + t("opened-issue-on", {date: format.datetime(new Date(incident.createdAt || ""), i18n.language)})}</p>
            </div>
            
        </div>
            
        <div className="mt-10">
            <Timeline />
            <div className="mt-5 mb-5">                
                <AddMessageToTimeline />
            </div>
        </div>
    </>
}

export function IncidentProgress(){
    const { incident } = useIncident()
    const { t } = useTranslation("common")
    const progress = useMemo(()=> incident?.progress || 0, [incident?.progress])
    return <>
        <Pill theme="violet">
           {t("progress")+ " " + progress + "%"}
        </Pill>
    </>
}

function IncidentTitle(){
    const { t } = useTranslation("common")
    const { incident, setIncident } = useIncident()
    const [editable, setEditable] = useState<boolean>(false)
    const [newName, setNewName] = useState<string>(incident?.name || "")

    const [,{execute, loading}] = useAPI(
        updateIncident, {incidentId: incident?.id, name: newName.trim()}, 
        {
            immediate: false,
            errorToastMessage: t("error-updating-issue"),
            successToastMessage: t("success-update-issue"),
            onResult: ()=>setEditable(false)
        })
    
    const handleEditOnClick = () => {
        setEditable(true)
    }
    const handleCancel = () => {
        setNewName(incident?.name || "")
        setEditable(false)
    }
    const handleSave = () => {
        execute()
        setIncident((prev:any)=>({...prev, name: newName.trim()}))
    }

    if (!incident) return null
    return <>
        <div className={classNames("flex justify-end sm:hidden mb-2", editable ? "flex":"hidden")}>
            <MoreMenu />
        </div>
        <div className={classNames("flex items-center", editable && "flex-col sm:flex-row w-full mb-4 gap-y-3")}>
            
            <div className="flex items-center justify-between w-full">
                { editable &&  
                    <div className="flex flex-col w-full sm:flex-row gap-y-2">
                        <div className="w-full sm:pr-2">
                            <TextInput onChange={setNewName} autoFocus theme="outlined" className="w-full" defaultValue={incident.name} placeholder={incident.name}/>
                        </div>
                        <div className="flex items-center text-sm sm:mr-8 gap-x-2">
                            <Button loading={loading} disabled={newName.trim() === incident.name || loading || newName.trim()===""} className="w-full sm:w-32" onClick={handleSave}>{t("save")}</Button>
                            <Button className="w-full font-semibold bg-opacity-75 sm:w-32" theme="gray" onClick={handleCancel}>{t("cancel")}</Button>
                        </div>
                    </div>
                }
                {
                    !editable && <div className="flex items-center">
                        <Text.PageTitle>{incident.name}</Text.PageTitle>
                        <EditIcon onClick={handleEditOnClick} className="ml-1.5 mt-0.5 text-xl cursor-pointer shrink-0 text-container-foreground hover:text-primary-light"/>
                    </div>
                }
                <div className={classNames(editable && "hidden sm:block")}>
                    <MoreMenu />
                </div>
            </div>
   
        </div>
    </>
}

function MoreMenu(){
    const { t } = useTranslation("common")
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [openProgressModal, setOpenProgressModal] = useState<boolean>(false)
    const { incident } = useIncident()
    return <>
        <ChildrenMenu className="flex flex-col min-w-[12rem] p-1 text-sm">
            <AcknowledgeIncident/>
            <p onClick={()=>setOpenProgressModal(true)} className={classNames(ChildrenStyles, "gap-x-2")}><ProgressIcon />{t("progress")}</p>
            {
                incident && incident.name && <>
                    <PrependIncidentName orginalName={incident.name} prepend="🚨" title={t("urgent")} ActiveIcon={AlertOn} InactiveIcon={AlertOff}/>
                    <PrependIncidentName orginalName={incident.name} prepend="🙋‍♂️" title={t("help-asked")} ActiveIcon={OpenedHand} InactiveIcon={ClosedHand}/>
                    <PrependIncidentName orginalName={incident.name} prepend="🧑🏼‍💻️" title={t("in-development")} ActiveIcon={WorkOn} InactiveIcon={WorkOff}/>
                    <PrependIncidentName orginalName={incident.name} prepend="🌟" title={t("priority")} ActiveIcon={PriorityOn} InactiveIcon={PriorityOff}/>
                </>
            }
            <p onClick={()=>setOpenDeleteModal(true)} className={classNames(ChildrenStyles, "text-red-500 gap-x-2")}><DeleteIcon />{t("delete")}</p>
        </ChildrenMenu>
        <DeleteModal open={openDeleteModal} setOpen={setOpenDeleteModal} />
        <ProgressModal open={openProgressModal} setOpen={setOpenProgressModal} />
    </>
}

interface ChangeIncidentNameProps {
    orginalName:string
    prepend?:string
    title?:string
    ActiveIcon?:IconType
    InactiveIcon?:IconType
}

function PrependIncidentName({orginalName, prepend, title, ActiveIcon, InactiveIcon}:ChangeIncidentNameProps){
    const { t } = useTranslation("common")
    const { incident:incidentParams } = useParams<{incident:string}>()
    const { setIncident } = useIncident()

    const prependIsPresent = useMemo(() => {
        const name = orginalName.trim()
        if (prepend && name.includes(prepend)) return true
        return false
    }, [orginalName, prepend])

    const newName = useMemo(() => {
        const name = orginalName.trim()
        if (prepend && prependIsPresent) return name.replace(prepend, "")
        if (prepend) return prepend + " " + name
        return name
    }, [orginalName, prepend])

    const handleResult = () => {
        setIncident((prev:any)=>({...prev, name: newName}))
    }

    const [,{execute}] = useAPI(
        updateIncident, {incidentId: incidentParams, name: newName.trim()}, 
        {
            immediate: false,
            errorToastMessage: t("error-updating-issue"),
            successToastMessage: t("success-update-issue"),
            onResult: handleResult
        })

    return <>
        <p onClick={()=>{execute()}} className={classNames(ChildrenStyles, "gap-x-2")}>{prependIsPresent ? ActiveIcon && <ActiveIcon /> : InactiveIcon ?<InactiveIcon/>: ActiveIcon? <ActiveIcon/>:null}{title}</p>
    </>

}

function DeleteModal({open, setOpen}: {open:boolean, setOpen:Dispatch<SetStateAction<boolean>>}){
    const { t } = useTranslation("common")
    const { activeOrg } = useActiveOrg()
    const { incident:incidentParams } = useParams<{incident:string}>()
    const { incident } = useIncident()
    const { execute:reload } = useIncidents()
    const navigate = useNavigate()

    const handleResult = () => {
        setOpen(false)
        reload()
        navigate(to.incidents(activeOrg?.slug || ""))
    }

    const [,{execute, loading}] = useAPI(
        deleteIncident, {incidentId: incidentParams}, 
        {
            immediate: false,
            errorToastMessage: t("delete-incident.error"),
            successToastMessage: t("delete-incident.success"),
            onResult: handleResult
        })

    return <>
        <Modal open={open} setOpen={setOpen} title={t("delete-incident.title")}>
            <Modal.Body>
                {t("delete-incident.description", {name: incident?.name})}
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button loading={loading} disabled={loading} onClick={execute} theme="alertOutlined">{t("delete")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

function ProgressModal({open, setOpen}: {open:boolean, setOpen:Dispatch<SetStateAction<boolean>>}){
    const { t } = useTranslation("common")
    const { incident:incidentParams } = useParams<{incident:string}>()
    const { incident, setIncident } = useIncident()
    const [progress, setProgress] = useState<number>(incident?.progress || 0)
    const { execute: executePostEvent } = usePostTimelineEvent(incidentParams || "", "incident_progress", progress.toString() )
    
    const min = 0
    const max = 100
    
    useEffect(() => {
        setProgress(incident?.progress || 0)
    }, [incident?.progress])

    const handleResult = () => {
        setOpen(false)
        setIncident((prev:any)=>({...prev, progress: progress}))
        executePostEvent()
    }

    const [,{execute, loading}] = useAPI(
        updateIncident, {incidentId: incidentParams, progress: progress}, 
        {
            immediate: false,
            errorToastMessage: t("error-updating-issue"),
            successToastMessage: t("success-update-issue"),
            onResult: handleResult
        })
    
    const handleInputChange = (value:any) => {
        if (value === "") {
            setProgress(0)
            return ""
        }
        if (isNaN(value)) return ""
        if (value > max) {
            setProgress(max)
            return max
        }
        if (value < min) {
            setProgress(min)
            return min
        }
        if (typeof value === "string") setProgress(parseInt(value))
        return value
    }
    return <>
        <Modal open={open} setOpen={setOpen} title={t("incident-progress")}>
            <Modal.Body>
                <div className="relative">
                    <TextInput theme="outlined" updateFromChange defaultValue={progress.toString()} onChange={handleInputChange} />
                    <PercentIcon className="absolute text-xl text-gray-300 -translate-y-1/2 right-3 top-1/2" />
                </div>
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button loading={loading} disabled={loading || progress === incident?.progress} onClick={execute}>{t("save")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export function AcknowledgeIncident() {
    const { t } = useTranslation("common")
    const { incident, setIncident } = useIncident()
    const { incident:incidentParams } = useParams<{incident:string}>()
    const [active, setActive] = useState<boolean>(incident?.acknowledged || false)
    const messageType = useMemo(() => !incident?.acknowledged ? "incident_acknowledged" : "incident_removed_acknowledged", [incident?.acknowledged])
    
    const onUpdateAcknowledgedResult = useCallback((result: any) => {
        executePostEvent().finally(() => {
            setActive(result.acknowledged)
        })
        setIncident((prev: any) => {
            return { ...prev, acknowledged: result.acknowledged }
        })
    }, [messageType])

    const { execute: executePostEvent } = usePostTimelineEvent(incidentParams || "", messageType, "")
    const [, { execute: executeUpdateAcknowledged, loading: loadingUpdateAcknowledged }] = useAPI(updateIncident, { incidentId: incidentParams, acknowledged: !incident?.acknowledged || false }, { immediate: false, onResult: onUpdateAcknowledgedResult, errorToastMessage: t("error-updating-issue"), successToastMessage: t("success-update-issue") })
    
    const handleToggle = () => {
        if (!loadingUpdateAcknowledged) {
            executeUpdateAcknowledged()
            setActive(!active)
        }
    }
    return <>
        <div 
            onClick={handleToggle} 
            className={classNames(ChildrenStyles, "gap-x-2")}>
            {active ? <><RadioOnIcon/>{t("acknowledged")}</> : <><RadioOffIcon/>{t("acknowledged")}</>}
        </div>
    </>
}

function StatusBadge({status}:{status?:string}){
    return <div className={classNames("flex items-center w-fit p-1.5 px-2.5 rounded-full text-sm font-semibold", status?.toLowerCase() === "open" ? "bg-primary" : "bg-violet-500")}>
        { status?.toLowerCase() === "open" ? <OpenIcon className="mr-1 text-lg"/> : <ClosedIcon className="mr-1 text-lg"/>}<p className="capitalize">{status?.toLowerCase()}</p>
    </div>
}
