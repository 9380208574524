// API
import { getJob } from "api/jobs"

// Components
import { Navigate } from "react-router-dom"

// Constants
import { routes } from "constants/routes"

// Hooks
import { useMemo, useState, createContext, useCallback} from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "hooks/useAPI";

const JobContext = createContext<JobContextResult>({});

export type JobContextResult= {
    job?: Job;
    jobId?: string;
    setJob?: any;
    error?: any;
    loading?: boolean;
}

const JobContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { job:jobParams } = useParams<{job:string}>()

    const [job, setJob] = useState<Job>()

    const validateParams = useCallback(() => {
        if (!jobParams) return false
        return true
    }, [])

    const params = useMemo(() => {
        return {jobId: jobParams, fields: ["id", 
                                        "name", 
                                        "description", 
                                        "metadata", 
                                        "status", 
                                        "steps", 
                                        "log_file_key", 
                                        "started_at", 
                                        "ended_at", 
                                        "rank",
                                        "name_rank",
                                        "next_previous"]}
    }, [jobParams])
    
    const [, { loading, error }] = useAPI(getJob, params, {validateParams, onResult: setJob})
    
    if (error) return <Navigate to={routes.notFound} replace></Navigate>
    return (
        <JobContext.Provider value={{job, jobId: jobParams, setJob, error, loading}}>
            {children}
        </JobContext.Provider>
    );
    
};

export { JobContext, JobContextProvider };