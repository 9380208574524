
// Hooks
import { useTranslation } from "react-i18next"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

// API
import { revokeUserFromOrganization, updateOrganizationMemberRole } from "api/organizations"

// Icons
import { RiDeleteBin5Fill as DeleteIcon } from "react-icons/ri"
import { MdAdminPanelSettings as RoleIcon } from "react-icons/md"

// Components
import { Container } from "components/core/container"
import { Spinner } from "components/core/spinner"
import { MoreMenu } from "components/core/moreMenu"
import { Modal } from "components/core/modal"
import { RadioGroup } from "components/core/radio/group"
import { OrgMembersContextProvider } from "contexts/org-members"

// Constants
import roles from "constants/roles"
import { Button } from "components/core/buttons"
import { useAPI } from "hooks/useAPI"
import { useAuth } from "hooks/useAuth"
import { useOrgMembers } from "hooks/useOrgMembers"

interface MemberProps {
    name: string
    email: string
    role: string
}

function ManageAccessWithContext(){
    return <>
        <OrgMembersContextProvider>
            <ManageAccess/>
        </OrgMembersContextProvider>
    </>
}

function ManageAccess(){
    const { t } = useTranslation("common")
    const { members, loading } = useOrgMembers()
    const [openManageRole, setOpenManageRole] = useState(false)
    const [openDeleteUser, setOpenDeleteUser] = useState(false)
    const { user } = useAuth()

    return <>
        <ManageRoles open={openManageRole} setOpen={setOpenManageRole} />
        <DeleteUser open={openDeleteUser} setOpen={setOpenDeleteUser} />
        <Container subtitle={t("manage-access")} noPadding>

            { members && members.length === 0 && <ListEmptyRow/> }

            { members && members.map(({name, email, role}: MemberProps) => {
                return <MemberRow key={email} name={name} role={role} email={email} showMenu={user&& user.email!==email} setOpenRoleModal={setOpenManageRole} setOpenDeleteModal={setOpenDeleteUser}/>
            })}

            { loading && !members && <div className="flex justify-center p-5"><Spinner/></div>}

        </Container>
    </>
}

function UserModal({title, setSelectedMember, open, setOpen, children}: {title?:string, setSelectedMember:any, open: any, setOpen: any, children: any }){
    const resetModal = ()=> {
        setTimeout(()=>{
            setSelectedMember && setSelectedMember(null)
            setOpen && setOpen(false)
        }, 200)
    }
    return <>
        <Modal title={title} open={open} setOpen={setOpen} onClose={()=>resetModal()}>
            {children}
        </Modal>
    </>
}

function ManageRoles({ open, setOpen}: {open: any, setOpen: any }){
    const { t } = useTranslation("common")
    const {org: orgId} = useParams()
    const {selectedMember, setSelectedMember, members, setMembers} = useOrgMembers()
    const roles_keys = useMemo(()=> Object.keys(roles), [])
    const [selectedRole, setSelectedRole] = useState(selectedMember?.role || roles_keys[0])
    useEffect(()=>{
        setSelectedRole(selectedMember?.role || roles_keys[0])
    }, [selectedMember, roles_keys])

    const handleResult = ()=>{
        setMembers([...members.map((m: any)=> {
            if (m.email === selectedMember?.email){
                return {...m, role: selectedRole}
            }
            return m
        })])
        setOpen(false)
    }

    const [,{loading, execute}] = useAPI(updateOrganizationMemberRole, {orgId, email: selectedMember?.email, role: selectedRole}, 
        {
            immediate: false, 
            onResult: handleResult,
            onCatch: ()=>setOpen(false),
            successToastMessage: t("role-gestion.success", 
            {name: selectedMember?.name}), 
            errorToastMessage: t("role-gestion.error")
        })

    return <>
        <UserModal title={t("role-gestion.title")} open={open} setOpen={setOpen} setSelectedMember={setSelectedMember}>
            <Modal.Body>
                <div>{t("role-gestion.description", {name: selectedMember?.name})}</div>
            </Modal.Body>
            <RadioGroup value={selectedRole} setValue={setSelectedRole} values={roles_keys} stringify={(d:string)=>t("roles."+d)} />
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button disabled={!selectedRole || !selectedMember || loading} loading={loading} onClick={execute}>{t("save")}</Button>
            </Modal.Footer>
        </UserModal>
    </>
}

function DeleteUser({open, setOpen}: {open: any, setOpen: any }){
    const { t } = useTranslation("common")
    const {org: orgId} = useParams()
    const {selectedMember, setSelectedMember, members, setMembers} = useOrgMembers()
    const params = useMemo(()=> ({orgId, email: selectedMember?.email}), [orgId, selectedMember])

    const handleResult = ()=>{
        setMembers([...members.filter((m: any)=> m.email !== selectedMember?.email)])
        setOpen(false)
    }

    const [, {loading, execute}] = useAPI(revokeUserFromOrganization, params, 
        {
            immediate: false, 
            onResult: handleResult,
            onCatch: ()=>setOpen(false),
            errorToastMessage: t("revoke-user.error"),
            successToastMessage: t("revoke-user.success", {name: selectedMember?.name})
        })

    return <>
        <UserModal title={t("revoke-user.title")} open={open} setOpen={setOpen} setSelectedMember={setSelectedMember}>
            <Modal.Body>
                <div>{t("revoke-user.description", {name: selectedMember?.name})}</div>
            </Modal.Body>
            <Modal.Footer type="doubleButton">
                <Button theme="gray" onClick={()=>setOpen(false)}>{t("cancel")}</Button>
                <Button onClick={execute} loading={loading} theme="alertOutlined">{t("revoke")}</Button>
            </Modal.Footer>
        </UserModal>
    </>
}

function MemberRow({name, email, role, showMenu, setOpenRoleModal, setOpenDeleteModal}: MemberProps & {showMenu:boolean} & {setOpenRoleModal: any} & {setOpenDeleteModal:any}){
    const { t } = useTranslation("common")
    const { setSelectedMember } = useOrgMembers()
    const MemberMenu = [
        {
            name: t("role"),
            iconClassName: "text-xl",
            Icon: RoleIcon,
            onClick: ()=>{ setSelectedMember && setSelectedMember({name, email, role}); setOpenRoleModal && setOpenRoleModal(true); },
        },
        {
            name: t("remove"),
            itemClassName: "text-red-500",
            iconClassName: "text-lg",
            Icon: DeleteIcon,
            onClick: ()=>{ setSelectedMember && setSelectedMember({name, email}); setOpenDeleteModal && setOpenDeleteModal(true); },
        }
    ]

    return <>
        <div className="hover:bg-background/30">
            <div className="flex flex-wrap items-center justify-between p-4">
                <div className="flex flex-col flex-wrap gap-0.5 md:w-1/2 sm:w-1/2">
                    <div className="">{name}</div>
                    <div className="block text-sm text-container-foreground">{email}</div>
                    <div className="block text-sm text-container-foreground sm:hidden">{t("roles."+role)}</div>
                </div>
                
                <div className="hidden sm:w-1/6 text-container-foreground sm:block">
                    {t("roles."+role)}
                </div>
                <div className="w-12 h-8">
                    {
                        showMenu && <MoreMenu items={MemberMenu} />
                    }
                </div>
            </div>
        </div>
    </>
}

function ListEmptyRow(){
    const { t } = useTranslation("common")

    return <>
        <div className="flex items-center justify-center w-full h-32 text-gray-400">
            <div className="text-center">
                <div className="text-2xl font-bold">{t("no-organizations")}</div>
                <div className="mt-2">{t("no-organizations-message")}</div>
            </div>
        </div>
    </>
}

export const Manage = {
    Access: ManageAccessWithContext
}
