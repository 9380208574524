import { useRef } from "react";

export const useChildrenIndexFromClass = (parentClass:string) => {
    const elementRef = useRef<any>(null);
  
    const getIndex = (): number | undefined => {
      let currentElement = elementRef.current;
      let index: number | undefined = undefined;
  
      // Traverse up to find the closest steps container
      while (currentElement) {
        if (currentElement.parentElement?.classList.contains(parentClass)) {
          const stepsContainer = currentElement.parentElement;
          const childrenArray = Array.from(stepsContainer.children);
          index = childrenArray.indexOf(currentElement);
          break;
        }
        currentElement = currentElement.parentElement;
      }
  
      return index;
    };
  
    return { elementRef, getIndex };
};