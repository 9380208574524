// Components
import { Container } from "components/core/container"
import { Text } from "components/core/typo"
import { Stats } from "components/ressources/stats"
import { RoleGuard } from "components/auth/roleguard"
import { Link } from "react-router-dom"
import { Plot } from "components/core/plot"
import ErrorBoundary from "components/core/errors/error-boundary"
import { Skeleton } from "components/core/skeletons"
import { MetaData } from "components/ressources/metadata"
import { TimeSelector } from "components/tools/time-selector"
import { Spinner } from "components/core/spinner"
import { Breadcrumbs, Crumb } from "components/core/breadcrumbs"
import { RessourceFiles } from "components/ressources/files"
import { RessourceIncidents } from "components/ressources/incidents"
import { FilterDatesModal } from "components/ressources/filter"
import { themes } from "components/core/container"
import { Integration } from "components/ressources/integration"
import { RessourceJobs } from "components/ressources/jobs"
import { HiGlobeAmericas as APIIcon } from "react-icons/hi2";
import { HiCommandLine as WorkerIcon } from "react-icons/hi2";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "components/ui/dropdown-menu"
import { Button } from "components/core/buttons"

// Constants
import { to, routes } from "constants/routes"

// Utils
import { Pulse } from "components/tools/pulse";
import { filterByMetricsType } from "utils/dict"
import { time as utilsTime } from "utils/time"
import { format } from "utils/format"
import classNames from "classnames"

// Icons
import { LuSettings as EditIcon } from "react-icons/lu"
import { AiOutlineCalendar as CalendarIcon } from "react-icons/ai"
import { HiOutlineClipboardDocumentList as IntegrationIcon } from "react-icons/hi2"
import { AiOutlineAppstoreAdd as AddRessourceIcon } from "react-icons/ai"
import { BsThreeDots as DotsIcon } from "react-icons/bs"


// API
import { getMetricsData, getMetricsSummary, getIncidentCounts } from "api/ressources"

// Hooks
import {  useMemo, useState } from "react";
import { useRessource } from "hooks/useRessource"
import { useAPI } from "hooks/useAPI"
import { useTranslation } from "react-i18next"
import { useActiveOrg } from "hooks/useActiveOrg"
import { useFilterDates } from "hooks/useFilterDates"
import { useDebounce } from "hooks/useDebounce"

export function Details() {
    const { ressource } = useRessource()
    const active = ressource?.status === "active"
    const [selectedFreq, setSelectedFreq] = useState<"day" | "week" | "month" | "year">("month")

    const [start, setStart] = useState<number>(utilsTime.getUnixTimestampWithDayDelta(-30))
    const [end, setEnd] = useState<number>(utilsTime.getUnixTimestampWithDayDelta(0)+utilsTime.getTimezoneOffsetInSeconds())

    if (!ressource) return null
    return <>
        {
            <>
                <div className="flex flex-wrap-reverse justify-end mb-5 gap-x-2 gap-y-4">
                    {
                        active && <TimeSelector options={["day", "week", "month"]} selected={selectedFreq} setSelected={setSelectedFreq} onChange={(option) => {
                            setStart(utilsTime.getUnixTimestampWithDayDelta(option === "day" ? -1: option === "week" ? -7 : option === "month" ? -30 : -365))
                            setEnd(utilsTime.getUnixTimestampWithDayDelta(0)+utilsTime.getTimezoneOffsetInSeconds())
                            }} 
                        />
                    }
                    <FilterDates />
                </div>
                {
                    active && <>
                        <StatsHighlights ressource={ressource} start={start} end={end} />
                        <DetailsPlots ressource={ressource} start={start} end={end} />
                    </>
                }
                {
                    !active && <>
                        <Integration />
                    </>
                }

                {ressource.type==="worker"&& <div className="mt-10">
                    <RessourceJobs.List />
                </div>}
                <div className="mt-10">
                    <RessourceFiles.List />
                </div>
                <div className="mt-10">
                    <RessourceIncidents.List />
                </div>
            </>
        }
    </>
}

function FilterDates(){
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
    const { filter:filterDates, setFilter:setFilterDates} = useFilterDates()

    return <>
        <div onClick={()=>setOpenFilterModal(true)} className={classNames(filterDates.length > 0 && "text-primary-light sm:text-gray-300","flex items-center justify-center p-1 px-2 text-lg rounded-md cursor-pointer gap-x-2 min-h-[36px]", themes["default"])}>
            <DatesIndicator dates={filterDates} />
            <CalendarIcon className="block" />
        </div>
        <FilterDatesModal open={openFilterModal} setOpen={setOpenFilterModal} dates={filterDates} setDates={setFilterDates} />
    </>
}

export function DatesIndicator({ dates }: { dates: Date[]}){
    return <>
        {
            dates.length > 0 && <div className="hidden sm:flex">
            {
                dates.map((date, index) => {
                    return <span className="hidden text-xs text-gray-300 whitespace-pre-wrap sm:block" key={"date-"+index}>{index === 1 && " - "}{format.date(date)}</span>
                })
            }
            </div>
        }
    </>
}

function ResourceTypeBadge(){
    const { ressource } = useRessource()
    if (!ressource) return null;
    return <div className="flex items-center justify-start">
        <span className={classNames("flex items-center px-1 py-1 rounded-md text-sm", ressource.type==="api"? "text-cyan-300 bg-cyan-900": "text-pink-200 bg-pink-900")}>
        {ressource.type==="api"?<APIIcon className="text-lg" />:<WorkerIcon className="text-lg" />}
        <span className="capitalize ml-1 font-mono">{ressource.type}</span>
    </span></div>
}

export function DetailsHeader({ ressource }: { ressource: any }) {
    const { t } = useTranslation()
    const { activeOrg } = useActiveOrg()
    const active = ressource?.status === "active"
    if (!ressource) return null
    const crumbs:Crumb[] = [
        {
            name: t("ressources"),
            path: to.ressources(activeOrg.slug || "") 
        },
        {
            name: ressource?.name || "",
            path:""
        }
    ]
    return <>
        <div className="sm:flex gap-4 justify-between items-center">
            
            <Breadcrumbs crumbs={crumbs} />
            <RoleGuard requiredRole="admin" userRole={activeOrg?.access?.role} redirect={false}>
                <Link to={to.addResource(activeOrg?.slug)} className="">
                    <Button className="flex items-center" theme="subtilLink">
                        <AddRessourceIcon className="mr-1 text-xl shrink-0"/> {t("create-ressource")}
                    </Button>
                </Link>
            </RoleGuard>
        </div>
        <div className="flex items-center">
            <div className="mr-4">
                {
                    // active == success
                    // init == Error
                    active ? <Pulse.Success size={2} /> : <Pulse.Error size={2} />
                }
            </div>
            <div className="flex items-center justify-between w-full">
                <Text.PageTitle className="">{ressource?.name}</Text.PageTitle>
                <MoreMenu />
            </div>
        </div>
        <div className="pl-1 mb-5 grid grid-cols-1 gap-2">
            <ResourceTypeBadge />
            <MetaData.Details metadata={ressource} />
        </div>

    </>
}

function MoreMenu(){
    const { t } = useTranslation()

    return <>
        <DropdownMenu>
            <DropdownMenuTrigger className="p-2 px-3 hover:bg-container-light rounded-md hover:border-background-ligther ">
                <DotsIcon className=""/>
            </DropdownMenuTrigger>
            
            <DropdownMenuContent align="end">
                <Link to={routes.ressourceDocumentation}>
                    <DropdownMenuItem>
                            <IntegrationIcon className="mr-2 text-lg shrink-0" />
                            <span>{t("documentation")}</span>
                    </DropdownMenuItem>
                </Link>
                <RoleGuard requiredRole="admin" redirect={false}>
                    <Link to={routes.editRessource} className="flex items-center">
                        <DropdownMenuItem>
                                <EditIcon className="mr-2 text-lg shrink-0" />
                                <span>{t("edit")}</span>
                        </DropdownMenuItem>
                    </Link>
                </RoleGuard>
            </DropdownMenuContent>
        </DropdownMenu>     
    </>
}

function StatsHighlights({ ressource, start, end }: { ressource: any, start: number, end: number }) {
    const { t } = useTranslation()
    
    const { filter:filterDates } = useFilterDates()
    const debounceFilterDates = useDebounce(filterDates, 500)

    const params = useMemo(() => { return { ressource: ressource.slug, 
        start: filterDates[0] ? utilsTime.createStartTimestamp(filterDates[0]) : start, 
        end: filterDates[1] ? utilsTime.createEndTimestamp(filterDates[1]) : filterDates[0] ? utilsTime.createEndTimestamp(filterDates[0]) : end
    }}, [debounceFilterDates])
    const [summary, { loading }] = useAPI(getMetricsSummary, params)

    // Hour : max 10 days,
    // Day : max 21 days,
    // Week : max 182 days,
    // Month : max 365 days,
    const dayDelta = utilsTime.getDayDeltaBetweenTimestamps(start, end)
    const freq = dayDelta <= 1 ? "hour" : dayDelta <= 21 ? "day" : dayDelta <= 182 ? "week" : "month"

    const paramsIncidents = useMemo(() => { 
        return { 
            ressource: ressource.slug, 
            start: filterDates[0] ? utilsTime.createStartTimestamp(filterDates[0]) : start, 
            end: filterDates[1] ? utilsTime.createEndTimestamp(filterDates[1]) : filterDates[0] ? utilsTime.createEndTimestamp(filterDates[0]) : end,
            freq 
        }
    }, [debounceFilterDates, freq])
    const [incidents, { loading: loadingIncidentCounts }] = useAPI(getIncidentCounts, paramsIncidents, { immediate: true })
    const incidentsCount = incidents?.results.reduce((acc: any, obj: any) => {
        return acc + obj.value;
    }, 0) || 0;
    // round up to 2 decimals
    const avgLatency = Math.round((summary?.latency.data.value || 0) * 100) / 100
    const nbCalls = summary?.calls.data.value || 0
    return <>
        <div className="grid grid-cols-1 gap-5 mb-5 sm:grid-cols-3">
            {
                (!summary && loading) || (!incidents && loadingIncidentCounts) ? <>
                    <Skeleton className="h-24" />
                    <Skeleton className="h-24" />
                    <Skeleton className="h-24" />
                </>
                    :
                    <>
                        <Stats.Highlight title={t("average-latency")} targetNumber={avgLatency} targetUnit=" ms" />
                        <Stats.Highlight title={t("api-calls")} targetNumber={nbCalls} />
                        <Stats.Highlight title={t("incidents")} targetNumber={incidentsCount || 0} />
                    </>
            }

        </div>
    </>
}

function DetailsPlots({ ressource, start, end }: { ressource: any, start: number, end: number }) {
    const { t } = useTranslation()

    const { filter:filterDates } = useFilterDates()

    const debounceFilterDates = useDebounce(filterDates, 500)

    const metricsParams = useMemo(() => {
        return { 
            ressource: ressource?.slug, 
            start: filterDates[0] ? utilsTime.createStartTimestamp(filterDates[0]) : start, 
            end: filterDates[1] ? utilsTime.createEndTimestamp(filterDates[1]) : filterDates[0] ? utilsTime.createEndTimestamp(filterDates[0]) : end
        }
    }, [ressource, start, end, debounceFilterDates])

    const [data, { loading }] = useAPI(getMetricsData, metricsParams)

    const counters = data && filterByMetricsType(data.metrics, "COUNTER")
    const floats = data && filterByMetricsType(data.metrics, "FLOAT")
    // TODO! Other metrics types
    if (!data && loading) return <>
        <PlotGrid>
            <Skeleton className="h-72" />
            <Skeleton className="h-72" />
        </PlotGrid>
    </>
    if (!ressource || !counters || !floats) return null
    return <>
        <ErrorBoundary>
            <PlotGrid>
                {
                    floats.map((metric: any, index: number) => {

                        return <Container key={index} className="relative">
                            {
                                metric.data.length > 0 ?
                                    <>
                                        {
                                            data && loading && <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                <Spinner />
                                            </div>
                                        }
                                        <Plot.Float data={metric.data} title={metric.meta.name} className={classNames(data && loading && "opacity-20")}/>
                                    </>
                                    :
                                    <>
                                        <span className="block">{metric.meta.name}</span>
                                        <div className="flex justify-center my-20 text-container-foreground">{t("no-data-for-this-period")}</div>
                                    </>
                            }
                        </Container>
                    })
                }
                {
                    counters.map((metric: any, index: number) => {
                        return <Container key={index} className="relative">
                            {
                                metric.data.length > 0 ?
                                    <>
                                        {
                                            data && loading && <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                <Spinner />
                                            </div>
                                        }
                                        <Plot.Counter data={metric.data} title={metric.meta.name} className={classNames(data && loading && "opacity-20")}/>
                                    </>
                                    :
                                    <>
                                        <span className="block">{metric.meta.name}</span>
                                        <div className="flex justify-center my-20 text-container-foreground">{t("no-data-for-this-period")}</div>
                                    </>
                            }
                        </Container>
                    })
                }
            </PlotGrid>
        </ErrorBoundary>
    </>

}

function PlotGrid({ children }: { children: React.ReactNode }) {
    return <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 auto-rows-max">
        {children}
    </div>
}
