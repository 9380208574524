// Hooks
import { Dispatch, SetStateAction, createContext, useState } from "react";


// API

const AnnotationHistoryContext = createContext<AnnotationHistoryResult>({
    history: [],
    setHistory: () => {}
});

export type AnnotationHistoryResult = {
    history: any[];
    setHistory: Dispatch<SetStateAction<any[]>>;
}

const AnnotationHistoryContextProvider = ({ children }:{children: React.ReactNode}) => {
   

    const [history, setHistory] = useState<any[]>([])

    return (
        <AnnotationHistoryContext.Provider value={{history, setHistory}}>
            {children}
        </AnnotationHistoryContext.Provider>
    );

};

export { AnnotationHistoryContext, AnnotationHistoryContextProvider };