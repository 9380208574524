// hooks
import { useTranslation } from "react-i18next"
import { useAPI } from "hooks/useAPI"
import { useCallback, useState } from "react"

// Utils
import classNames from "classnames"
import { validate } from "utils/validate"

// Components
import { TextInput } from "components/core/inputs"
import { Button } from "components/core/buttons"
import { InternalLink } from "components/core/internalLink"
import { Text } from "components/core/typo"

// Constants
import { routes } from "constants/routes"

// API
import { sendResetPasswordEmail } from "api/auth"

interface ForgotPasswordProps {
    className?: string,
    props?: any,
}
export function ForgotPasswordForm({className, props}: ForgotPasswordProps){
    const {t} = useTranslation("common")

    const [email, setEmail] = useState<string>("")

    const [result, {loading, error, execute}] = useAPI(sendResetPasswordEmail, {email: email.trim()},
        { onResult: (()=>{}),
        immediate: false});

    const onSubmit = useCallback((e:Event)=>{
        if (e) e.preventDefault();
        execute();
    }, [execute]);

    return <form onSubmit={onSubmit} className={classNames(className)} {...props}>
        {result && <Text.Success className="mb-3">{t("confirmation-forgot-password")}</Text.Success>}
        {error && <Text.Error {...error} className="mb-3"></Text.Error>}
        <Text.Title description={t("forgot-password-description")}>{t("forgot-password")}</Text.Title>
        <TextInput type="email" label={t("email-associated-account")} placeholder={t("email-placeholder")} theme="default" onChange={setEmail} />

        <div className="mt-7">
            <Button type="submit" className="w-full" disabled={!validate.isEmail(email.trim())} loading={loading}>{t("send-email")}</Button>
        </div>
        <InternalLink to={routes.login} className="mt-5">{t("return-to-login")}</InternalLink>
    </form>
}