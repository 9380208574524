// Components
import { AuthorFilterCommandControlled, LabelsFilterCommandControlled } from "components/annotations/list";
import { OrgMembersContextProvider } from "contexts/org-members";
import { AdaptativeModal } from "components/core/modal/adaptative";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "components/ui/tabs"
import { FilterDates } from "components/ressources/filter";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";

// Utils
import classNames from "classnames";

// Constants
import { to } from "constants/routes";

// Icons
import { LuArrowRightSquare as ArrowRightIcon } from "react-icons/lu";
import { LuListFilter as FiltersIcon } from "react-icons/lu";
import { LuArrowRight as GoIcon } from "react-icons/lu";

// Hooks
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useKeyCombo } from "hooks/useKeyCombo";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useAnnotation } from "hooks/useAnnotation";
import { useOrgMembers } from "hooks/useOrgMembers";

export function Controls({previous, next, routeMethodName, hasUrlParams}: {previous: string, next: string, routeMethodName?: string, hasUrlParams?: boolean}){
    const { t} = useTranslation("common");
    const { org: orgParams, ressource: ressourceParams } = useParams<{org:string, ressource:string}>();
    const navigate = useNavigate();
    
    const routeMethod = useMemo(() => {
        switch (routeMethodName) {
            case "annotation": return to.annotation;
            case "job": return to.job;
            default: return to.annotation;
        }
    }, [routeMethodName]);

    const handleNext = useCallback(() => {
        if (!next) return;
        navigate(routeMethod(orgParams || "",ressourceParams || "", next))
    }, [next])
    
    const handlePrevious = useCallback(() => {
        if (!previous) return;
        navigate(routeMethod(orgParams || "",ressourceParams || "", previous))
    }, [previous])
    useKeyCombo("ArrowLeft", handlePrevious, [previous]);
    useKeyCombo("ArrowRight", handleNext, [next]);
     
    return <>
        
        <div className="flex items-center justify-between w-full py-3 text-container-foreground">

            
            <FiltersMenu />
            <div className="flex gap-x-3 items-center">
                
                {
                    previous ? <Link replace={hasUrlParams} to={routeMethod(orgParams || "",ressourceParams || "", previous)}>
                        <div onClick={handlePrevious} className={classNames("flex items-center gap-x-1", previous ? "hover:text-primary-light cursor-pointer ":" opacity-30")}>
                            <ArrowRightIcon className="text-3xl transform rotate-180 sm:text-lg"/> <span className="hidden sm:block">{t("annotations.controls.previous")}</span>
                        </div>
                    </Link> : 
                    <div className="flex items-center pointer-events-none gap-x-1 opacity-30">
                        <ArrowRightIcon className="text-3xl transform rotate-180 sm:text-lg"/> <span className="hidden sm:block">{t("annotations.controls.previous")}</span>
                    </div>
                }     
                {
                    next ? <Link replace={hasUrlParams} to={routeMethod(orgParams || "",ressourceParams || "", next)}>
                        <div onClick={handleNext} className="flex items-center cursor-pointer gap-x-1 hover:text-primary-light">
                            <span className="hidden sm:block">{t("annotations.controls.next")}</span> <ArrowRightIcon className="text-3xl sm:text-lg" />
                        </div>
                    </Link> :
                    
                    <div className="flex items-center pointer-events-none gap-x-1 opacity-30">
                        <span className="hidden sm:block">{t("annotations.controls.next")}</span> <ArrowRightIcon className="text-3xl sm:text-lg" />
                    </div>
                    
                }
                
            </div>
        </div>
            
    </>
}

export function FiltersMenu() {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation("common")
    const { hasFilterParams } = useAnnotation()
    return <>
        <div onClick={()=>setOpen((prev)=>!prev)} className={classNames("flex items-center gap-1 cursor-pointer hover:text-primary-light", hasFilterParams && "text-primary-light")}>
            <FiltersIcon className="text-3xl sm:text-lg"/>
            <span className={classNames("capitalize hidden sm:block", hasFilterParams && "text-primary-light")}>{t("filters")}</span>
        </div>
        <AdaptativeModal title={t("annotations.navigation-filters")} description={t("annotations.navigation-filters-description")} open={open} setOpen={setOpen} >
            <Tabs defaultValue="annotators">
                <TabsList className="grid w-full grid-cols-3 gap-2">
                    <TabsTrigger value="annotators" className="">{t("annotators")}</TabsTrigger>
                    <TabsTrigger value="labels">{t("annotations.labels.title")}</TabsTrigger>
                    <TabsTrigger value="dates">{t("dates")}</TabsTrigger>
                    
                    
                </TabsList>
                
                <TabsContent value="annotators">
                    <OrgMembersContextProvider>
                        <AnnotatorFilters />
                    </OrgMembersContextProvider>
                </TabsContent>
                
                <TabsContent value="labels">
                    <LabelsFilters />
                </TabsContent>
                
                <TabsContent value="dates">
                    <DatesFilters />
                </TabsContent>
            
            </Tabs>
            
           
            
        </AdaptativeModal>
                       
    </>
       
}

function AnnotatorFilters() {
    const { members, loading } = useOrgMembers()
    
    const {annotatedBy, setAnnotatedBy, annotatedBySet, setAnnotatedBySet} = useAnnotation()
    return <>
        <AuthorFilterCommandControlled members={members} loading={loading} annotatedBy={annotatedBy} setAnnotatedBy={setAnnotatedBy} annotatedBySet={annotatedBySet} setAnnotatedBySet={setAnnotatedBySet} />
    </>
    
}

function LabelsFilters() {
    const { labels:selectedLabels, setLabels: setSelectedLabels, labelSet, setLabelSet } = useAnnotation()
    
    return <>
        <LabelsFilterCommandControlled selectedLabels={selectedLabels} setSelectedLabels={setSelectedLabels} labelSet={labelSet} setLabelSet={setLabelSet} />
    </>
}

function DatesFilters(){
    const { datesFilter, setDatesFilter } = useAnnotation()
    return <>
        <div className="pt-8 px-10">
            <FilterDates dates={datesFilter} setDates={setDatesFilter} />
        </div>
    </>
}

export  function NavigateToAnnotationId(){
    const { t } = useTranslation("common")
    const [id, setId] = useState("")
    const navigate = useNavigate()
    const { org:orgParams, ressource:ressourceParams } = useParams<{org:string, ressource:string}>()
    
    return <>
        <div className="flex items-center gap-1 w-full">
            <Input onChange={(e)=>setId(e.currentTarget.value)} className="border border-background-light px-2 h-9 text-xs tracking-tight w-full" placeholder={t("annotations.controls.navigate-to-id")} />
            <Button disabled={!id.trim()} onClick={()=>navigate(to.annotation(orgParams || "", ressourceParams || "", id.trim()))} className="h-9 text-xl px-2 text-container-foreground border" variant={"ghost"}><GoIcon /></Button>
        </div>
    </>
}

