// API
import { getResourceJobs } from "api/jobs"

// Utils
import { query as utilsQuery } from "utils/query"

// Hooks
import { useMemo, useState, createContext, useCallback, Dispatch, SetStateAction} from "react";
import { useSearch } from "hooks/useSearch";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFilterDates } from "hooks/useFilterDates";

const RessourceJobsContext = createContext<Jobs>({});

export type Jobs= {
    jobs?: Job[];
    setJobs?: any;
    error?: any;
    loading?: boolean;
    paging?: any;
    setPage?: any;
    setQuery?: any;
    setStatus?: any;
    filter?:Filter;
    filters?:Filter[];
    defaultFilter?:Filter;
    setFilter?: Dispatch<SetStateAction<Filter>>;
    execute?: any;
}

export type Filter = 
    "newest" | 
    "oldest" | 
    "name-asc" | 
    "name-desc";
export type OrderBy = "started_at" | "name";
export type OrderDirection = "asc" | "desc";

// Record of Filter, value is object with orderBy and orderDirection
export const filterDict:Record<Filter, {orderBy:OrderBy, orderDirection:OrderDirection}> = {
    "newest": {orderBy: "started_at", orderDirection: "desc"},
    "oldest": {orderBy: "started_at", orderDirection: "asc"},
    "name-asc": {orderBy: "name", orderDirection: "asc"},
    "name-desc": {orderBy: "name", orderDirection: "desc"}
}

export const filters:Filter[] = Object.keys(filterDict) as Filter[]

export function filterHandler(filter:Filter):{orderBy:OrderBy, orderDirection:OrderDirection}{
    return filterDict[filter]
}

export const defaultFilter:Filter = "newest"

const RessourceJobsContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { t } = useTranslation("common")
    const { ressource: ressourceParams } = useParams<{ressource:string}>();

    const [ query, setQuery] = useState<string>("")
    const { filter: filterDates } = useFilterDates()
    const debounceQuery = useDebounce(query, 500);
    const debounceFilter = useDebounce(filterDates, 500);
    const [ filter, setFilter] = useState<Filter>(defaultFilter)
    

    const handleFilter = useCallback(():{orderBy:OrderBy, orderDirection:OrderDirection} => {
        return filterHandler(filter)
    },[filter])

    const params = useMemo(() => ({
        ressourceId:ressourceParams,
        search: query,
        status,
        orderBy: handleFilter().orderBy,
        orderDirection: handleFilter().orderDirection,
        createdAfter: utilsQuery.filterAfterDate(filterDates[0]),
        createdBefore: utilsQuery.filterBeforeDate(filterDates[1] || filterDates[0])
    }), [debounceQuery, debounceFilter, status, handleFilter])
    
    const fields = useMemo(() => (["id","started_at", "ended_at", "name", "status", "description", "rank", "name_rank"]), [])
    
    const [jobs, {execute, loading, paging, setPage, setResult:setJobs, error}] = useSearch<Job>(getResourceJobs, params, {limit:10, fields, errorToastMessage: t("error-try-again")} )
    
    return (
        <RessourceJobsContext.Provider value={{execute, jobs, setJobs, error, paging, setPage, setQuery, filter, setFilter, filters, defaultFilter, loading}}>
            {children}
        </RessourceJobsContext.Provider>
    );

};

export { RessourceJobsContext, RessourceJobsContextProvider };