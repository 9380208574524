
// Hooks
import { createContext, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAPI } from "hooks/useAPI";

// API
import { getRessource } from "api/ressources";

// Constants
import { routes } from "constants/routes";

const RessourceContext = createContext<RessourceContextResult>({});

export type RessourceContextResult= {
    ressource?: any;
    resourceId?: string;
    setRessource?: any;
    loading?: boolean;
}

const RessourceContextProvider = ({ children }:{children: React.ReactNode}) => {
    const navigate = useNavigate()
    const { ressource:ressourceParams } = useParams()
    const params = useMemo(()=> ({ ressourceId: ressourceParams, fields: ["slug", "name", "status", "github_url", "type", "url", "last_seen", "organization", "description"] }), [ressourceParams])
    const validateParams = useCallback((params:any) => {
        return params.ressourceId !== undefined
    }, [])

    const [ressource, setRessource] = useState<Ressource>()

    const handleResult = useCallback((result:any) => {
        setRessource(result.results || result)
    }, [])

    const handleErrors = useCallback(() => {
        navigate(routes.notFound)
    }, [navigate])
    const [, { loading: loadingRessource}] = useAPI(getRessource, params, 
        {
            immediate: true, 
            onResult: handleResult,
            onCatch: handleErrors,
            validateParams
        })
    
    return (
        <RessourceContext.Provider value={{ressource, resourceId: ressourceParams, setRessource, loading:loadingRessource}}>
            {children}
        </RessourceContext.Provider>
    );

};

export { RessourceContext, RessourceContextProvider };
