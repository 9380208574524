// API
import { getIncident } from "api/incidents"

// Components
import { Navigate } from "react-router-dom"

// Constants
import { routes } from "constants/routes"

// Hooks
import { useMemo, useState, createContext, useCallback} from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "hooks/useAPI";

const IncidentContext = createContext<IncidentContextResult>({});

export type IncidentContextResult= {
    incident?: Incident;
    setIncident?: any;
    error?: any;
    loading?: boolean;
}

const IncidentContextProvider = ({ children }:{children: React.ReactNode}) => {
    const { incident:incidentParams } = useParams<{incident:string}>()

    const [incident, setIncident] = useState<Incident>()

    const validateParams = useCallback(() => {
        if (!incidentParams) return false
        return true
    }, [])

    const params = useMemo(() => {
        return {incidentId: incidentParams, fields: ["id", "name","acknowledged", "progress", "created_at", "deleted_at", "description","status","author.firstname", "author.lastname", "author.id"]}
    }, [incidentParams])
    
    const [, { loading, error }] = useAPI(getIncident, params, {validateParams, onResult: setIncident})
    
    if (error) return <Navigate to={routes.notFound} replace></Navigate>
    return (
        <IncidentContext.Provider value={{incident, setIncident, error, loading}}>
            {children}
        </IncidentContext.Provider>
    );
    
};

export { IncidentContext, IncidentContextProvider };