export function uploadFile(url:string, file:File, fields:any, progressHandler?:any) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        if (progressHandler){
            xhr.upload.addEventListener("progress", progressHandler, false);
        }      
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
            reject({
                status: xhr.status,
                statusText: xhr.statusText
            });
            }
        };
        xhr.onerror = function () {
            reject({
                status: xhr.status,
                statusText: xhr.statusText
            });
        };
        const formdata = new FormData();
        Object.keys(fields).forEach(d=>{
            formdata.append(d, fields[d])
        })
        formdata.append("file", file);
    
        xhr.send(formdata);
    });
  }