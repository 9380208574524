import * as Plot from "@observablehq/plot";

// Utils
import classNames from "classnames";
import { convertTimestampToDate } from "components/core/plot";

// Hooks
import { useEffect, useRef} from "react";

export function AnnotationPlot({plotting, className, hideTitle, padding="18px", width=900, height=420}:{plotting:any, title?:string, className?:string, hideTitle?:boolean, width?:number, height?:number, padding?:string}){
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (plotting === undefined) return;
        const { line, ruleX, ruleY, areaY, tip} = plotting;
        const rulesX = ruleX && ruleX.map(({data, ...props}:any) => {
            data =  data.map((v:number)=> v*1000)
            return Plot.ruleX(data, {...props})
        })
        const rulesY = ruleY && ruleY.map(({data, ...props}:any) => {
            return Plot.ruleY(data, {...props})
        })
        const marks = line && line.map(({data, ...props}:any) => {
            data = convertTimestampToDate(data)
            return Plot.lineY(data, props)
        })

        const areasY = areaY && areaY.map(({data, ...props}:any) => {
            data = convertTimestampToDate(data)
            return Plot.areaY(data, {x:"timestamp", y1:"y1", y2:"y2", ...props})
        })

        const tips = tip && tip.map(({data, ...props}:any) => {
            data = convertTimestampToDate(data)
            return Plot.tip(data, Plot.pointerX({fill: "#119a91", 
                                                    fontWeight: 600, 
                                                    anchor: "middle", 
                                                    stroke: "transparent", 
                                                    lineHeight:1.2, 
                                                    dy: -60,
                                                   ...props}))
        })
        const chart = Plot.plot({
            
            width: width,
            height: height,
            style: {
                background: "transparent",
                overflow: "visible",
                padding: padding,
                width: "100%",
            },
            marginLeft: 50,
            marginTop: 0,
            label: "",
            labelArrow: false,
            y: {
                grid: true
            },
            x: {
                ticks: 5
            },
            color: {
                type: "diverging",
                scheme: "burd"
            },
            marks: [
                ...rulesX,
                ...marks,
                ...areasY,
                ...tips,
                ...rulesY
            ]
        });
        ref.current && ref.current.append(chart);
        return () => chart.remove();
    }, [plotting]);
    
    return <>
        {!hideTitle && <p className="px-8 pt-2 text-xl font-medium text-left">{plotting && plotting.title}</p>}
        <div ref={ref} className={classNames("plot", className)}></div>
    </>
}